var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasNestedMenu)?_c('div',[_c('router-link',{attrs:{"to":_vm.item.link,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(_vm._s(_vm.item.label))])])]}}],null,false,3335486022)}),_c('div',{staticClass:"nested-menu"},_vm._l((_vm.item.children),function(subitem,index){return _c('router-link',{key:index,staticClass:"nested-menu-item",attrs:{"to":subitem.link,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-angle-right"})]),_c('span',[_vm._v(_vm._s(subitem.label))])])])]}}],null,true)})}),1)],1):(_vm.hasChildren)?_c('div',{staticClass:"dropdown is-hoverable"},[_c('router-link',{staticClass:"dropdown-trigger",attrs:{"to":_vm.item.link,"custom":"","aria-haspopup":"true","aria-controls":"dropdown-menu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_c('span',[_vm._v(_vm._s(_vm.item.label))]),(_vm.hasChildren)?_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-caret-right"})]):_vm._e()])])]}}])}),_c('div',{staticClass:"dropdown-menu",attrs:{"id":"dropdown-menu","role":"menu"}},[_c('div',{staticClass:"dropdown-content"},_vm._l((_vm.item.children),function(subitem,index){return _c('router-link',{key:index,staticClass:"dropdown-item",attrs:{"to":subitem.link,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(_vm._s(subitem.label))])])]}}],null,true)})}),1)])],1):_c('router-link',{attrs:{"to":_vm.item.link,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"},on:{"click":navigate}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(_vm._s(_vm.item.label))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }