<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Ρωτήστε τους Ειδικούς <span class="tag">Προσθήκη Ερώτησης</span>
        </div>
      </div>
      <div class="card-header-icon">
        <router-link :to="{ name: 'content.questions.list' }" class="button">
          <span class="icon is-small"><i class="fa fa-list"/></span
          ><span>Λίστα</span>
        </router-link>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="field">
          <label class="label">Ερώτηση *</label>
          <div class="control">
            <textarea
              v-validate="'required'"
              v-model.trim="newQuestion.content"
              class="textarea"
              type="text"
              name="content"
            />
            <p v-show="errors.has('content')" class="help is-danger">
              Εισάγετε ερώτηση
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Πελάτης *</label>
          <div class="control">
            <customers-search
              :customer="newQuestion.customer"
              @selectCustomer="selectCustomer"
            />
            <p v-show="errors.has('customer')" class="help is-danger">
              Επιλέξτε πελάτη
            </p>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { pick, omit, get } from 'lodash';
import AddItem from '@/views/components/AddItem';
import CustomersSearch from '../components/CustomersSearch';

export default {
  components: {
    CustomersSearch,
  },

  extends: AddItem,

  data() {
    return {
      newQuestion: {
        content: '',
        customer: null,
        firstName: '',
        lastName: '',
        email: '',
        customer_id: 0,
      },
      isSaving: false,
    };
  },

  methods: {
    ...mapActions({
      addQuestion: 'questions/add/addQuestion',
    }),

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addQuestion(omit(this.newQuestion, ['customer']));
        this.isSaving = false;

        this.$router.push({ name: 'content.questions.list' });
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η ερώτηση αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.newQuestion = {
        content: '',
        customer: {},
        firstName: '',
        lastName: '',
        email: '',
        customer_id: 0,
      };

      this.$validator.reset();
    },

    selectCustomer(customer) {
      this.newQuestion = {
        ...this.newQuestion,
        customer,
        ...pick(customer, ['firstName', 'lastName']),
        customer_id: get(customer, 'id', 0),
        email: get(customer, 'emails.profile[0]', 'emails.guests[0]'),
      };
    },
  },
};
</script>
