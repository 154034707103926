<script>
import { mapGetters, mapMutations } from 'vuex';
import request from '@/utils/request';
import PaginatedList from '@/views/components/PaginatedList';
import OrderPrint from '@/views/Dashboard/views/Orders/components/OrderPrint/';
import OrdersHeader from './components/OrdersHeader';
import Order from './components/Order';

export default {
  components: {
    OrdersHeader,
    OrderPrint,
    Order,
  },
  extends: PaginatedList,
  data: () => ({
    isMassSending: false,
    isMassAddingTransactions: false,
    isMassRestoring: false,
  }),
  computed: {
    ...mapGetters({
      items: 'orders/list/getData',
      paginationParams: 'orders/list/getPaginationParams',
      hasLoadedOrders: 'orders/list/getIsLoading',
      isLoadedOrders: 'orders/list/getIsLoaded',
      checkoutMethods: 'checkoutMethods/list/getCheckoutMethods',
      sendMethods: 'getSendMethods',
      orderMethods: 'getOrderMethods',
    }),
  },
  methods: {
    ...mapMutations({
      setData: 'orders/list/setData',
    }),

    resetUpdateOptions() {},
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;

        if (response) {
          await request.delete(`/orders/${this.selectedItem.uuid}`);

          await this.navigate();

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Η παραγγελία διαγράφτηκε επιτυχώς!!',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    prepareExportXLS() {
      this.exportXLS(
        this.selections,
        [
          'common_id',
          'created_at',
          'items',
          'amount',
          {
            key: 'customer_fullName',
            value: 'customer.fullName',
          },
          {
            key: 'customer_email',
            value: 'customer.email',
          },
          {
            key: 'send_method',
            value: 'send_method.title',
          },
          {
            key: 'checkout_method',
            value: 'checkout_method.title',
          },
        ],
        'orders',
      );
    },
    prepareExportCSV() {
      this.exportCSV(
        this.selections,
        [
          'common_id',
          'created_at',
          'items',
          'amount',
          {
            key: 'customer_fullName',
            value: 'customer.fullName',
          },
          {
            key: 'customer_email',
            value: 'customer.email',
          },
          {
            key: 'send_method',
            value: 'send_method.title',
          },
          {
            key: 'checkout_method',
            value: 'checkout_method.title',
          },
        ],
        'orders',
      );
    },
  },
};
</script>
