<template>
  <LoadingContainer :is-loading="isLoadingVideos && !isLoadedVideos">
    <div class="card">
      <PaginatedListHeaderTemplate
        :total="paginationParams.count"
        :selections="selections"
        :has-selections="hasSelections"
        title="Videos"
      >
        <template v-slot:options>
          <router-link :to="{ name: 'content.videos.add' }" class="button">
            <span class="icon is-small"><i class="fa fa-plus"/></span
            ><span>Προσθήκη</span>
          </router-link>
        </template>
      </PaginatedListHeaderTemplate>
      <div class="card-content">
        <Filters
          :options="options"
          :is-loading="isFiltering"
          :filters="paginationParams.filters"
          general-label="Αναζήτηση με χρήση τίτλου"
          @navigate="navigate"
        />

        <NewPagination
          :items="videos"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column is-full">
            <LoadingContainer
              :is-loading="isFiltering && isLoadedVideos"
              :is-opaque="true"
              class="table-wrapper"
            >
              <table
                v-if="videos.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Τίτλος"
                        attribute="title"
                        @sort="sort"
                      />
                    </th>
                    <th>Tags</th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Προβολή"
                        attribute="active"
                        @sort="sort"
                      />
                    </th>
                    <th class="hidden-print">Ενέργειες</th>
                  </tr>
                </thead>
                <tbody>
                  <video-item
                    v-for="(item, index) in videos"
                    :item="item"
                    :index="paginationParams.indexStartsFrom + index"
                    :key="item.id"
                    :selections="selections"
                    @askToDeleteItem="askToDeleteItem"
                    @toggleSelection="toggleSelection"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν videos
              </h4>
            </LoadingContainer>
          </div>
        </div>

        <NewPagination
          v-if="videos.length"
          :items="videos"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />
      </div>

      <confirmation
        :model-name="selectedItem.title"
        :is-open="isOpen"
        description="Είστε βέβαιος για τη διαγραφή αυτού του Video;"
        @closeModal="closeModal"
      />
    </div>
  </LoadingContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/utils/request';
import PaginatedList from '@/views/components/PaginatedList';
import PaginatedListHeaderTemplate from '@/views/components/PaginatedList/components/PaginatedListHeaderTemplate';
import VideoItem from './components/Video';

export default {
  components: {
    VideoItem,
    PaginatedListHeaderTemplate,
  },
  extends: PaginatedList,
  metaInfo: {
    title: 'Videos',
  },
  data: () => ({
    options: [
      {
        label: 'Γενική Αναζήτηση',
        title: 'general',
        type: 'string',
        value: null,
      },
      {
        label: 'Προβολή',
        title: 'active',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Έχει Tags',
        title: 'has_tags',
        type: 'boolean',
        value: null,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      videos: 'videos/list/getData',
      paginationParams: 'videos/list/getPaginationParams',
      isLoadingVideos: 'videos/list/getIsLoading',
      isLoadedVideos: 'videos/list/getIsLoaded',
    }),
  },
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.getVideos(newVal.query);
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router.replace({
              name: this.$route.name,
              query: this.filterParams({
                page: 1,
                size: 50,
                filters: {},
                sort: {},
                paginated: 1,
              }),
            });
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getVideos: 'videos/list/getVideos',
    }),
    async navigate(params = {}) {
      try {
        this.$router.push({
          name: this.$route.name,
          query: this.filterParams({ ...this.paginationParams, ...params }),
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;

        if (response) {
          await request.delete(`/videos/${this.selectedItem.uuid}`);

          await this.navigate({
            timestamp: new Date().getTime(),
          });

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Το video διαγράφτηκε',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    prepareExportXLS() {
      this.exportXLS(this.selections, ['title'], 'categories');
    },
    prepareExportCSV() {
      this.exportCSV(this.selections, ['title'], 'categories');
    },
  },
};
</script>
