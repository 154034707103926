<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Χρήστης <span class="tag">{{ user.name }}</span>
        </div>
      </div>

      <div class="card-header-icon">
        <div class="field has-addons is-marginless">
          <div class="control">
            <router-link :to="{ name: 'users.list' }" class="button">
              <span class="icon is-small"><i class="fa fa-list"/></span
              ><span>Λίστα</span>
            </router-link>
          </div>
          <div v-if="!isAuth" class="control">
            <button class="button" @click="askToDeleteItem">
              <span class="icon is-small"><i class="fa fa-trash"/></span
              ><span>Διαγραφή</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="field">
          <label class="label">Όνομα *</label>
          <div class="control">
            <input
              v-validate="'required'"
              v-model.trim="newUser.name"
              class="input"
              type="text"
              name="name"
            />
            <p v-show="errors.has('name')" class="help is-danger">
              Εισάγετε όνομα
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Email *</label>
          <div class="control">
            <input
              v-validate="'required|email'"
              v-model.trim="newUser.email"
              class="input"
              type="email"
              name="email"
            />
            <p v-show="errors.has('email:required')" class="help is-danger">
              Εισάγετε email
            </p>
            <p v-show="errors.has('email:email')" class="help is-danger">
              Εισάγετε έγκυρο email
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Δικαιώματα *</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select
                :disabled="isAuth"
                v-model.trim="newUser.authority"
                name="authority"
              >
                <option value="1">Super Admin</option>
                <option value="2">Admin</option>
                <option value="3">Editor</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>

    <confirmation
      :model-name="user.name"
      :is-open="isOpen"
      description="Είστε βέβαιος για τη διαγραφή αυτού του administrator;"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import _pick from 'lodash/pick';
import request from '@/utils/request';
import EditItem from '@/views/components/EditItem';

export default {
  extends: EditItem,
  data() {
    return {
      user: {},
      newUser: {},
    };
  },
  computed: {
    isAuth() {
      return this.$store.state.session.user.id === this.user.id;
    },
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const { data } = await request.get(`/users/${to.params.uuid}`);
      next(vm => vm.setData(data));
    } catch (err) {
      next(vm => vm.$router.push('/error'));
    }
  },
  methods: {
    ...mapActions(['updateUser', 'deleteUser']),
    ...mapMutations('session', ['updateUserName']),
    setData(data) {
      this.user = data.user;
      this.newUser = _pick(this.user, ['name', 'email', 'authority']);
    },
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateUser({ user: this.user, data: this.newUser });

        if (this.isAuth) {
          this.$store.commit('updateUserName', { name: this.newUser.name });
        }

        this.isSaving = false;

        this.$router.push({ name: 'users.list' });
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Ο χρήστης αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.newUser = _pick(this.user, ['name', 'email', 'authority']);

      this.$validator.reset();
    },
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;
        if (response) {
          await this.deleteUser({ user: this.user });

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Ο λογαριασμός χρήστη διαγράφτηκε επιτυχώς!!',
          });

          this.$router.push('/users');
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
