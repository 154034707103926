<template>
  <div id="app">
    <vue-progress-bar />
    <router-view />
    <toast :is-open="isToastOpen" :toast="toast" @closeToast="closeToast" />
  </div>
</template>

<script>
import Toast from '@/views/components/Toast';
import EventBus from '@/event-bus';

export default {
  metaInfo: {
    title: 'Περιβάλλον Διαχείρισης',
    titleTemplate: '%s | Carespot',
  },
  components: {
    Toast,
  },
  data() {
    return {
      isToastOpen: false,
      toast: {
        type: 'primary',
        content: '',
      },
    };
  },
  mounted() {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish();
  },
  async created() {
    // Toast handle
    EventBus.$on('showToast', toast => {
      this.toast = toast;
      this.isToastOpen = true;
    });
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress;
        // parse meta tags
        this.$Progress.parseMeta(meta);
      }
      //  start the progress bar
      this.$Progress.start();
      //  continue to next page
      next();
    });
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach(() => this.$Progress.finish());
  },
  methods: {
    closeToast() {
      this.isToastOpen = false;
      this.toast = {
        type: 'primary',
        content: '',
      };
    },
  },
};
</script>

<style lang="sass">
@import "~bulma/sass/utilities/initial-variables"
$primary: #22A684
$link: #22A684
$button-focus-border-color: #22A684
$red: #ff3860
$danger: #ff3860
@import "~bulma/bulma"
@import "~font-awesome/css/font-awesome.min.css"
@import "~animate.css/animate.css"
@import "./tooltip"

html
  background-color: #EBEBEB
  font-size: 13px
  color: #424C55

.pac-container
  min-width: 100%
  width: auto !important

hr
  margin: 1rem 0

.container.is-fluid
  margin: 0 20px

.table-wrapper
  overflow-x: auto

.box
  border-radius: 2px

.field
  margin-bottom: .75rem
  &:last-child
    margin-bottom: 0

  &.has-addons .control:not(:first-child):not(:last-child)
    .input, .button
      border-radius: 2px
    .button
      border-bottom-left-radius: 0
      border-top-left-radius: 0

.help
  font-size: .8rem

.modal
  z-index: 23
  .modal-background
    background-color: rgba(10, 10, 10, 0.3)
  .modal-card
    overflow-y: auto
    &:last-child
      border-radius: 0 0 5px 5px

.box,
.card,
.notification
  box-shadow: 0 2px 3px rgba(0, 0, 0, .16)
  border-radius: 4px

.card
  margin-bottom: 1.5rem
  &-header
    border-bottom: 1px solid #dbdbdb
    box-shadow: none
    &:first-child:last-child
      border: none
    &-title
      padding: 1.25rem
    &-icon
      padding: .75rem 1.25rem
  &-content
    padding: 1.25rem
    > *:last-child
      margin-bottom: 0
  &-footer
    padding: 1.25rem
    &:first-child:last-child
      border: none

.column
  > .card:last-of-type
    margin-bottom: 0

.input,
.textarea,
.button
  border-radius: 2px
  box-shadow: none
  .has-shadow
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1)
  &[readonly]
    background-color: whitesmoke

.input[disabled],
.textarea[disabled],
.select select[disabled]
  border: 1px solid #dbdbdb
  &:hover
    border: 1px solid #dbdbdb

.tabs
  &:not(:last-child)
    margin-bottom: 1rem

.table
  &:not(:last-of-type)
    margin-bottom: 0
  th
    text-align: center
  th, td
    border-style: dashed !important
    vertical-align: middle !important

.quillWrapper
  .ql-toolbar
    border-color: #DBDBDB
    border-radius: 2px 2px 0 0
  .ql-container
    border-color: #DBDBDB
    border-radius: 0 0 2px 2px
    font-size: 1rem !important
    .ql-editor
      font-size: 1rem
      min-height: 200px
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      ol,
      p,
      ul
        &:not(:last-child)
          margin-bottom: 1rem
  &:hover
    .ql-toolbar, .ql-container
      border-color: #B5B5B5

.visible-print
  display: none

.dropdown-menu
  .dropdown-item
    &:hover
      background-color: rgba(0, 0, 0, .1)
    .icon
      margin-right: 5px

@media print
  *, *::before, *::after
    box-shadow: none !important
    text-shadow: none !important
  html, body
    background-color: #ffffff
    font-size: 8px !important
  .navbar, aside
    display: none !important
  .visible-print
    display: block !important
  .hidden-print
    display: none !important
  .app-main
    margin-left: 0 !important
    padding-top: 0 !important
  .table
    border-collapse: collapse !important
    td, th
      background-color: #ffffff !important
      border: 1px solid #dddddd !important
      color: #444444 !important
      &.visible-print
        display: table-cell !important
      > *
        color: #444444 !important

.CodeMirror
  border: 1px solid #dbdbdb
  border-radius: 2px
  height: 200px

.multiselect
  font-size: 1rem
  margin: 0
  min-height: 2.25em

  &:last-child
    margin-bottom: 0

  &__select
    height: 100%
  &__tags
    min-height: calc(2.25em - 2px)
    padding: 0 40px 0 8px
    border-radius: 2px
    border: 1px solid #dbdbdb
  &__input,
  &__single
    color: #363636
    padding-left: 0
  &__placeholder,
  &__single,
  &__input
    font-size: 1rem
    margin-bottom: 0
    padding-top: 4px
  &__input
    line-height: 1.5rem
    margin-bottom: 4px
    min-height: 1.5rem
  &__placeholder
    font-size: 1rem
    margin-bottom: 0
  &__option
    align-items: center
    border-bottom: 1px solid #EEEEEE
    display: flex
    font-size: 1rem

    &:after
      top: 50%
      transform: translateY(-50%)
  &__select
    height: auto
    bottom: 1px
  &--disabled &
    &__tags,
    &__single
      background-color: transparent
  &.multiple &
    &__tags
      padding: 0 40px 0 8px
      &-wrap
        display: inline-flex
        flex-wrap: wrap
        margin-top: 2px
    &__tag
      margin-bottom: 2px

  &__spinner
    height: 29px

  .option__image
    height: 40px
    width: 40px

  .option__desc
    display: flex
    flex-direction: column

  .option__title
    margin-bottom: 5px
    margin-left: 10px
    white-space: pre-wrap

  .option__small
    font-size: .9rem
    font-weight: 700
    white-space: pre-wrap

.top-sticky
  background-color: #ebebeb
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1)
  left: 0 !important
  padding: 0 20px
  transition: left 0.5s ease-in-out, width 0.5s ease-in-out
  width: 100% !important
  z-index: 10 !important
  .has-sidebar &
    left: 140px !important
    width: calc(100% - 140px) !important
  .level
    padding-bottom: 5px
    padding-top: 5px
    .title
      font-size: 1.2rem
    .heading
      font-size: 0.8rem

.has-sticky-submit
    padding-bottom: 30px
</style>
