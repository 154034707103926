<template>
  <div :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background" />
    <div class="modal-content has-text-centered">
      <div class="box">
        <h5 class="title is-5">Ενημέρωση στοιχείων απο ERP</h5>

        <vue-loading
          :size="{ width: '60px', height: '60px' }"
          type="spin"
          color="#22A684"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueLoading from 'vue-loading-template';

export default {
  name: 'SyncModal',
  components: {
    VueLoading,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.modal-content {
  width: 400px;
}
</style>
