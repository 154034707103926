<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Ιστορικό Άρθρων <span v-if="total > 0" class="tag">{{ total }}</span>
        </div>
      </div>
    </div>
    <div class="card-content">
      <LogsContentMenu />

      <NewPagination
        :items="items"
        :total="total"
        :current-page="currentPage"
        :pages-count="pagesCount"
        :items-per-page="itemsPerPage"
        :is-loading="isLoading"
        @navigate="navigate"
      />
      <LoadingContainer
        :is-loading="isFiltering"
        :is-opaque="true"
        class="table-wrapper"
      >
        <div class="columns">
          <div class="column is-full">
            <table
              v-if="items.length"
              class="table is-bordered is-striped is-marginless is-fullwidth"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Χρήστης</th>
                  <th>Περιγραφή</th>
                  <th>Αντικείμενο</th>
                  <th>Ημ/νια</th>
                </tr>
              </thead>
              <tbody>
                <revision
                  v-for="(revision, index) in items"
                  :revision="revision"
                  :index="indexStartsFrom + index"
                  :key="revision.id"
                />
              </tbody>
            </table>
            <h4 v-else class="title is-4 has-text-centered">
              Δε βρέθηκαν αρχεία
            </h4>
          </div>
        </div>
      </LoadingContainer>
    </div>
  </div>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import request from '@/utils/request';
import RevisionsList from '../../../../components/RevisionsList';
import LogsContentMenu from '../../components/LogsContentMenu';
import Revision from './components/Revision';

export default {
  components: {
    Revision,
    LogsContentMenu,
  },
  extends: RevisionsList,
  async beforeRouteEnter(to, from, next) {
    try {
      const response = await Promise.all([
        request.get('/posts-revisions', {
          params: _isEmpty(to.query)
            ? {
                page: 1,
                size: 50,
              }
            : to.query,
        }),
        request.get('/users'),
      ]);

      const { revisions } = response[0].data;
      const { users } = response[1].data;
      next(vm => vm.setData(revisions, users));
    } catch (err) {
      next(vm => vm.$router.push('/error'));
    }
  },
  watch: {
    $route: {
      async handler(newVal) {
        try {
          this.isFiltering = true;
          const { data } = await request.get('/posts-revisions', {
            params: newVal.query,
          });
          this.setRevisionsData(data.revisions);
          this.isFiltering = false;
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
    },
  },
};
</script>
