import _get from 'lodash/get';
import { loadState } from '@/utils/localStorage';

const state = {
  data: {
    customer: null,
    email: '',
    customer_type: 'guest',
    checkout_method_id: null,
    send_method_id: null,
    order_method_id: null,
    products: [],
    customer_notes: null,
    customer_notes_courier: null,
    is_gift: 0,
    gift_wishes: null,
    gift_cost: 0,
    send_charge: 0,
    checkout_charge: 0,
    charge_address: null,
    send_address: null,
    invoice: null,
    coupon: null,
  },
  ..._get(loadState(), 'newOrder', {}),
  isSendingCountryCyprus: false,
};

export default state;
