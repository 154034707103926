<template>
  <div v-sticky sticky-offset="offset">
    <nav class="level">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Τυπος</p>
          <p class="title">
            <ProductBadge :type="product.type" />
            <template v-if="subTypes.length">
              <!-- eslint-disable -->
              <span
                v-for="(type, index) in subTypes"
                :key="index"
                class="subtype"
                >&nbsp;- {{ type }}</span
              >
            </template>
          </p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Κοινο ID</p>
          <p class="title">{{ product.common_id }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">UUID</p>
          <p class="title">{{ product.uuid }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">ERP ID</p>
          <p class="title">{{ product.erp_id || '-' }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">STATUS</p>
          <p class="title">
            <span :class="['tag is-medium', classes]">{{ status }}</span>
          </p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">ABC (ERP)</p>
          <p class="title">{{ abc }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">STOCK</p>
          <p class="title">{{ product.stock }}</p>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import _capitalize from 'lodash/capitalize';
import ProductBadge from '@/views/components/ProductBadge';

export default {
  components: {
    ProductBadge,
  },
  props: {
    product: Object,
  },
  data: () => ({
    offset: { top: 45 },
  }),
  computed: {
    status() {
      const { product } = this;
      const { type } = product;
      const title = this.getTitle(type);
      /* eslint-disable */
      if (product.deleted_at !== null) {
        return `Απενεργοποιημένο ${title}`;
      } else if (product.active === true) {
        return `Εμφανές ${title}`;
      } else if (product.active === false) {
        return `Μη Εμφανές ${title}`;
      } else {
        return title;
      }
    },
    classes() {
      const product = this.product;
      /* eslint-disable */
      if (product.deleted_at !== null) {
        return 'is-danger';
      } else if (product.active === true) {
        return 'is-primary';
      } else if (product.active === false) {
        return 'is-warning';
      } else {
        return 'is-white';
      }
    },
    subTypes() {
      const product = this.product;
      const types = [];

      if (product.bundles.length) {
        types.push('Bundle item');
      }

      if (product.sets.length) {
        types.push('Set item');
      }

      if (product.groups.length) {
        types.push('Group item');
      }

      return types;
    },
    abc() {
      const { erp_abc_color, erp_abc_green_level } = this.product;

      if (!erp_abc_color) {
        return '-';
      }

      if (erp_abc_color === 'green' && erp_abc_green_level) {
        return `${_capitalize(erp_abc_color)} / ${_capitalize(
          erp_abc_green_level,
        )}`;
      }

      return _capitalize(erp_abc_color);
    },
  },
  methods: {
    getTitle(type) {
      switch (type) {
        case 'group':
          return 'Group';
        case 'set':
          return 'Set';
        default:
          return 'Προιόν';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.level-item {
  .title {
    align-items: center;
    display: inline-flex;

    .subtype {
      font-size: 1.4rem;
    }
  }
}
</style>
