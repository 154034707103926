<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Φωτογραφίες
        </div>
      </div>
      <div v-if="canUploadImages" class="card-header-icon">
        <button type="button" class="button" @click="openUploadModal()">
          <span class="icon is-small"><i class="fa fa-plus"/></span
          ><span>Προσθήκη</span>
        </button>
      </div>
    </div>
    <div class="card-content">
      <LoadingContainer
        :is-loading="isLoading"
        :is-opaque="true"
        :is-vertically-aligned="true"
      >
        <template v-if="newPhotos.length">
          <draggable
            :value="newPhotos"
            class="columns is-multiline"
            @change="handleReorder"
          >
            <div
              v-for="photo in newPhotos"
              :key="photo.id"
              class="column is-one-quarter"
            >
              <image-card
                :photo="photo"
                :can-edit-avatar="canEditAvatar"
                :can-delete-avatar="canDeleteAvatar"
                @selectPhotoToPreview="selectPhotoToPreview"
                @selectPhotoToEdit="selectPhotoToEdit"
                @selectPhotoToDelete="selectPhotoToDelete"
              />
            </div>
          </draggable>
        </template>

        <div v-else>
          <h5 class="title is-5 is-marginless has-text-centered">
            Δε βρέθηκαν φωτογραφίες
          </h5>
        </div>

        <image-upload-modal
          :is-open="isOpenUploadModal"
          :is-saving="isSaving"
          @closeUploadModal="closeUploadModal"
          @upload="add"
        />

        <image-preview-modal
          v-if="selectedPhoto"
          :photo="selectedPhoto"
          :is-open="isOpenPreviewModal"
          @closePreviewModal="closePreviewModal"
        />

        <image-edit-modal
          v-if="selectedPhoto"
          :photo="selectedPhoto"
          :is-open="isOpenEditModal"
          :is-saving="isSaving"
          @editPhoto="editPhoto"
          @closeEditModal="closeEditModal"
        />

        <confirmation
          v-if="selectedPhoto"
          :is-open="isOpenDeleteModal"
          :is-saving="isSaving"
          @closeModal="closeDeleteModal"
        >
          <h5 class="title is-5">
            Είστε βέβαιος για τη διαγραφή αυτής της φωτογραφίας;
          </h5>
        </confirmation>
      </LoadingContainer>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';
import { mapActions } from 'vuex';
import Draggable from 'vuedraggable';
import LoadingContainer from '@/views/components/LoadingContainer';
import ImageUploadModal from '@/views/components/ImageUploadModal';
import ImagePreviewModal from '@/views/components/ImagePreviewModal';
import Confirmation from '@/views/components/Confirmation';
import Notification from '@/views/components/Notification';
import ImageEditModal from './components/ImageEditModal';
import ImageCard from './components/ImageCard';

export default {
  components: {
    Draggable,
    LoadingContainer,
    ImageUploadModal,
    ImagePreviewModal,
    Confirmation,
    Notification,
    ImageEditModal,
    ImageCard,
  },
  props: {
    model: Object,
    photos: Array,
    isLoading: Boolean,
    canUploadImages: {
      type: Boolean,
      default: true,
    },
    canEditAvatar: {
      type: Boolean,
      default: true,
    },
    canDeleteAvatar: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    newPhotos: [],
    isSaving: false,
    selectedPhoto: null,
    isOpenUploadModal: false,
    isOpenPreviewModal: false,
    isOpenEditModal: false,
    isOpenDeleteModal: false,
  }),
  watch: {
    photos: {
      handler(newVal) {
        this.newPhotos = cloneDeep(newVal);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      addPhoto: 'photos/add/addPhoto',
      deletePhoto: 'photos/destroy/deletePhoto',
      updatePhoto: 'photos/edit/updatePhoto',
      reorderPhotos: 'photos/list/reorderPhotos',
    }),
    async add(file) {
      try {
        this.isSaving = true;
        await this.addPhoto({
          model: _pick(this.model, ['id', 'photoable_type', 'slug']),
          file,
        });

        await this.$emit('updatePhotos');

        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η φωτογραφία αποθηκεύτηκε',
        });

        this.isOpenUploadModal = false;
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    openUploadModal() {
      this.isOpenUploadModal = true;
    },
    closeUploadModal() {
      this.isOpenUploadModal = false;
    },
    closePreviewModal(isOpen) {
      this.isOpenPreviewModal = isOpen;

      this.selectedPhoto = null;
    },
    closeEditModal() {
      this.isOpenEditModal = false;

      this.selectedPhoto = null;
    },
    async closeDeleteModal(response) {
      try {
        if (response) {
          this.isSaving = true;
          await this.deletePhoto({
            id: this.selectedPhoto.id,
          });

          await this.$emit('updatePhotos');
          this.isSaving = false;

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Η φωτογραφία διαγράφτηκε',
          });
        }

        this.isOpenDeleteModal = false;
        this.selectedPhoto = null;
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    async editPhoto(photo) {
      try {
        this.isSaving = true;
        await this.updatePhoto({
          id: this.selectedPhoto.id,
          photo: {
            ...photo,
            photoable_id: this.model.id,
            photoable_type: this.model.photoable_type,
          },
        });

        await this.$emit('updatePhotos');

        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η φωτογραφία αποθηκεύτηκε',
        });

        this.closeEditModal();
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    selectPhotoToPreview(photo) {
      this.selectedPhoto = photo;

      this.isOpenPreviewModal = true;
    },
    selectPhotoToEdit(photo) {
      this.selectedPhoto = photo;

      this.isOpenEditModal = true;
    },
    selectPhotoToDelete(photo) {
      this.selectedPhoto = photo;

      this.isOpenDeleteModal = true;
    },
    async handleReorder({ moved: { oldIndex, newIndex } }) {
      try {
        this.isSaving = true;
        const movedItem = this.photos.find((item, index) => index === oldIndex);
        const remainingItems = this.photos.filter(
          (item, index) => index !== oldIndex,
        );

        const reorderedPhotos = [
          ...remainingItems.slice(0, newIndex),
          movedItem,
          ...remainingItems.slice(newIndex),
        ];

        this.newPhotos = reorderedPhotos;

        await this.reorderPhotos({
          photos: reorderedPhotos.map(({ id }) => id),
        });

        this.$emit('updatePhotos');
      } catch (err) {
        console.log(err);
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.column {
  height: 300px;
}

.card-content {
  .columns {
    margin-bottom: 0;
  }
}
</style>
