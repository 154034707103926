<template>
  <div class="login">
    <div class="card">
      <div class="card-header">
        <img src="@/assets/logo_green.png" alt="Carespot" />
      </div>
      <form @submit.prevent="submit">
        <div class="card-content">
          <notification
            v-if="hasError"
            :has-error="hasError"
            @hideNotification="hasError = false"
          >
            Εισάγετε έγκυρα στοιχεία
          </notification>
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input
                :value="user.email"
                type="email"
                class="input"
                name="email"
                disabled
                placeholder="Εισάγετε email"
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Κωδικός</label>
            <div class="control">
              <input
                v-validate="'required'"
                v-model="user.password"
                type="password"
                class="input"
                name="password"
                placeholder="Εισάγετε κωδικό"
              />
            </div>
            <div v-show="errors.has('password')" class="help is-danger">
              Εισάγετε κωδικό
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button
            :class="[
              'button is-primary is-fullwidth',
              { 'is-loading': isSaving },
            ]"
          >
            <span>Ενεργοποίηση Χρήστη</span>
            <span class="icon is-small">
              <i class="fa fa-angle-double-right" />
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import _pick from 'lodash/pick';
import { mapMutations } from 'vuex';
import request from '@/utils/request';
import Notification from '@/views/components/Notification';

export default {
  components: {
    Notification,
  },
  data() {
    return {
      user: {},
      isSaving: false,
      hasError: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const { data } = await request.get(`/users/${to.params.uuid}/activate`);
      next(vm => {
        if (data.user.active === true) {
          vm.$router.push('/login');
        } else {
          vm.setData(data);
        }
      });
    } catch (err) {
      next(vm => vm.$router.push('/login'));
    }
  },
  methods: {
    ...mapMutations('session', ['loginUser']),
    setData(data) {
      this.user = data.user;

      if (this.user.active === true) {
        throw new Error('user is already active');
      }
    },
    async submit() {
      try {
        this.hasError = false;
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        const { data } = await request.post(
          `/users/${this.user.uuid}/activate`,
          _pick(this.user, ['email', 'password']),
        );
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$store.commit('loginUser', {
          user: data.user,
          token: data.token,
        });

        this.$router.push({ name: 'home' });
      } catch (err) {
        this.isSaving = false;
        this.hasError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  box-sizing: border-box;
  margin: 150px auto 20px;
  max-width: 350px;
  width: 90%;

  .card-header {
    padding: 1.5rem;
  }

  .input::placeholder {
    color: #bbbbbb;
  }
}
</style>
