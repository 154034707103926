<template>
  <table class="table is-bordered is-striped is-fullwidth">
    <tbody>
      <tr>
        <td colspan="8" class="has-text-right is-borderless">
          Καθαρή Αξία
        </td>
        <td class="has-text-centered">
          <strong>{{ netPrice }} &euro;</strong>
        </td>
      </tr>
      <tax
        v-for="(tax, index) in Object.keys(taxes)"
        :key="index"
        :tax="tax"
        :taxes="taxes"
      />
      <tr>
        <td colspan="8" class="has-text-right is-borderless">
          Σύνολο Προϊόντων
        </td>
        <td class="has-text-centered">
          <strong>{{ price }} &euro;</strong>
        </td>
      </tr>
      <tr v-if="hasLoyaltyDiscount">
        <td colspan="8" class="has-text-right is-borderless">
          Έκπτωση Care-δίζω ({{ loyaltyDiscountAtTheTimeOfOrder }}%)
        </td>
        <td class="has-text-centered">
          <strong>&minus;{{ order.loyalty_discount_amount }} &euro;</strong>
        </td>
      </tr>
      <tr v-if="hasCoupon">
        <td colspan="8" class="has-text-right is-borderless">
          Κουπόνι "{{ order.coupon.code }}"
        </td>
        <td class="has-text-centered">
          <strong v-if="order.coupon.type === 'percentage'">
            &minus; {{ order.coupon.value }}%
          </strong>
          <strong v-else>&minus;{{ order.coupon.value }} &euro;</strong>
        </td>
      </tr>
      <tr v-if="order.checkout_charge">
        <td colspan="8" class="has-text-right is-borderless">
          Επιβάρυνση Τρόπου Πληρωμής
        </td>
        <td class="has-text-centered">
          <strong>{{ order.checkout_charge }} &euro;</strong>
        </td>
      </tr>
      <tr v-if="hasSendCharge">
        <td colspan="8" class="has-text-right is-borderless">
          Έξοδα Αποστολής
        </td>
        <td class="has-text-centered">
          <strong>{{ order.send_charge }} &euro;</strong>
        </td>
      </tr>
      <tr v-if="order.is_gift">
        <td colspan="8" class="has-text-right is-borderless">
          Συσκευασία Δώρου
        </td>
        <td class="has-text-centered">
          <strong>{{ order.gift_cost }} &euro;</strong>
        </td>
      </tr>
      <tr>
        <td colspan="8" class="has-text-right is-borderless title is-5">
          Συνολικό Ποσό
        </td>
        <td class="has-text-centered title is-5">
          <strong>{{ totalPrice }} &euro;</strong>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { get, round } from 'lodash';
import Tax from './components/Tax';

export default {
  name: 'Logistics',

  components: {
    Tax,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },

    hasLoyalty: {
      type: Boolean,
      required: false,
      default: false,
    },

    loyalty: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  computed: {
    productsPerTax() {
      return this.order.products.reduce((acc, item) => {
        const price = item.pivot.final_price * item.pivot.count;
        const { percentage } = item.tax;

        if (percentage in acc) {
          acc[percentage] = round(acc[percentage] + price, 2);
        } else {
          acc[percentage] = price;
        }

        return acc;
      }, {});
    },

    taxes() {
      return Object.keys(this.productsPerTax).reduce((acc, item) => {
        const taxAmount =
          (this.productsPerTax[item] / (1 + item / 100)) * (item / 100);
        return {
          ...acc,
          [item]: round(taxAmount, 2),
        };
      }, {});
    },

    totalTax() {
      const { taxes } = this;

      return Object.keys(taxes).reduce(
        (acc, key) => round(acc + taxes[key], 2),
        0,
      );
    },

    hasCoupon() {
      return !!this.order.coupon;
    },

    hasSendCharge() {
      const country = get(this.order, 'charge_address.country', '');
      return country !== '';
    },

    price() {
      return round(
        this.order.products.reduce(
          (acc, item) => acc + item.pivot.final_price * item.pivot.count,
          0,
        ),
        2,
      );
    },

    netPrice() {
      return round(this.price - this.totalTax, 2);
    },

    hasLoyaltyDiscount() {
      return !!this.order.loyalty_discount_amount;
    },

    loyaltyDiscountAtTheTimeOfOrder() {
      return round((this.order.loyalty_discount_amount * 100) / this.price);
    },

    loyaltyDiscount() {
      if (this.hasLoyaltyDiscount) {
        return round((this.price * this.loyalty.discount) / 100, 2);
      }

      return 0;
    },

    totalPrice() {
      let itemsPrice = this.price;

      if (this.hasLoyaltyDiscount) {
        itemsPrice -= this.loyaltyDiscount;

        itemsPrice = round(itemsPrice, 2);
      }

      if (this.hasCoupon) {
        const { type, value } = this.order.coupon;

        if (type === 'percentage') {
          itemsPrice *= (100 - value) / 100;
        }

        if (type === 'euro') {
          itemsPrice -= value;

          itemsPrice = itemsPrice < 0 ? 0 : itemsPrice;
        }

        itemsPrice = round(itemsPrice, 2);
      }

      /* eslint-disable camelcase */
      const { gift_cost, send_charge, checkout_charge } = this.order;

      return round(itemsPrice + gift_cost + send_charge + checkout_charge, 2);
    },
  },
};
</script>

<style scoped>
.table {
  margin-top: -1px;
}
</style>
