<template>
  <span
    v-tooltip="details.title"
    :class="['badge', ' has-text-centered', details.class]"
  >
    {{ details.label }}
  </span>
</template>

<script>
export default {
  props: {
    type: String,
  },
  computed: {
    details() {
      const { type } = this;
      if (type === 'set') {
        return {
          label: 'S',
          title: 'Set',
          class: 'is-set',
        };
      }

      if (type === 'group') {
        return {
          label: 'G',
          title: 'Group',
          class: 'is-group',
        };
      }

      if (type === 'gift') {
        return {
          label: 'GF',
          title: 'Gift',
          class: 'is-gift',
        };
      }

      return {
        label: 'P',
        title: 'Product',
        class: 'is-product',
      };
    },
  },
};
</script>

<style scoped lang="scss">
.badge {
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  font-size: 1rem;
  line-height: 20px;
  height: 20px;
  width: 20px;

  &.is-product,
  &.is-gift {
    background-color: #22a684;
  }

  &.is-set {
    background-color: #3273dc;
  }

  &.is-group {
    background-color: #ffdd57;
  }
}
</style>
