/* eslint-disable */
const getters = {
  getCategory(state) {
    return {
      ...state.data,
      photoable_type: 'App\\Category',
    };
  },

  getOrders(state) {
    return state.orders;
  },

  getIsLoading(state) {
    return state.isLoading;
  },
};

export default getters;
