<template>
  <div>
    <div class="tabs hidden-print">
      <ul v-sticky sticky-offset="offset">
        <router-link
          :to="{ name: 'marketing.periodicDiscounts.list.active' }"
          tag="li"
          active-class="is-active"
        >
          <a>Ενεργές Περιοδικές Εκπτώσεις</a>
        </router-link>
        <router-link
          :to="{ name: 'marketing.periodicDiscounts.list.inactive' }"
          tag="li"
          active-class="is-active"
        >
          <a>Μη Ενεργές Περιοδικές Εκπτώσεις</a>
        </router-link>
        <router-link
          :to="{ name: 'marketing.periodicDiscounts.list.all' }"
          tag="li"
          active-class="is-active"
        >
          <a>Όλες οι Περιοδικές Εκπτώσεις</a>
        </router-link>
      </ul>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  data: () => ({
    offset: { top: 45 },
  }),
};
</script>
