<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Πληροφορίες Box Now</div>
    </div>
    <div class="card-content">
      <table class="table is-fullwidth">
        <tbody>
          <tr v-if="order.box_now.boxnowReferenceNumber">
            <td>
              <span>
                Reference Number:
              </span>
              <small class="tag">{{
                order.box_now.boxnowReferenceNumber
              }}</small>
            </td>
          </tr>
          <tr v-if="order.box_now.boxnowLockerId">
            <td>
              <span>
                Locker ID:
              </span>
              <small class="tag">{{ order.box_now.boxnowLockerId }}</small>
            </td>
          </tr>
          <tr v-if="order.box_now.boxnowLockerName">
            <td>
              <span>
                Ονομασία Locker:
              </span>
              <small class="tag">{{ order.box_now.boxnowLockerName }}</small>
            </td>
          </tr>
          <tr v-if="order.box_now.boxnowLockerAddressLine1">
            <td>
              <span>
                Δ/νση Locker:
              </span>
              <small class="tag">
                {{ order.box_now.boxnowLockerAddressLine1 }}, ΤΚ:
                {{ order.box_now.boxnowLockerPostalCode }}
              </small>
            </td>
          </tr>
          <tr v-if="order.box_now.boxnowParcelId">
            <td>
              <span>
                Parcel ID:
              </span>
              <small class="tag">{{ order.box_now.boxnowParcelId }}</small>
            </td>
          </tr>
          <tr v-if="order.box_now.boxnowVoucherLink">
            <td>
              <span>
                Voucher Link:
              </span>
              <small class="tag">
                <a
                  :href="`${order.box_now.boxnowVoucherLink}`"
                  download="sample.PDF"
                  target="blank"
                >
                  Voucher
                </a>
              </small>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BoxnowInfo',
  props: {
    order: Object,
  },
};
</script>
