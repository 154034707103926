<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ title }}

        <v-popover v-if="hasInfoSlot" trigger="click" placement="top">
          <button class="button is-small" type="button">
            <span class="icon is-small"><i class="fa fa-info"/></span>
          </button>

          <slot slot="popover" name="info" />
        </v-popover>
      </div>
      <div class="card-header-icon">
        <button
          v-tooltip="{ content: 'Κλείσιμο' }"
          v-if="isEditing"
          class="button is-small"
          type="button"
          @click="closePanel"
        >
          <span class="icon is-small"><i class="fa fa-times"/></span>
        </button>
        <button
          v-tooltip="{ content: 'Επεξεργασία' }"
          v-else
          class="button is-small"
          type="button"
          @click="isEditing = true"
        >
          <span class="icon is-small"><i class="fa fa-pencil"/></span>
        </button>
      </div>
    </div>
    <template v-if="isEditing">
      <slot />
    </template>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    title: String,
    isOpen: false,
  },
  data() {
    return {
      isEditing: this.isOpen,
    };
  },
  computed: {
    hasInfoSlot() {
      return !!this.$slots.info;
    },
  },
  methods: {
    closePanel() {
      this.isEditing = false;

      this.$emit('closePanel');
    },
  },
};
</script>

<style scoped lang="scss">
.card-header-title {
  ::v-deep .trigger {
    margin-left: 10px;
  }

  .button {
    align-self: center;
    display: flex;
    height: initial;
    padding: 0 5px;
  }
}
</style>
