<script>
import { mapGetters } from 'vuex';
import request from '@/utils/request';
import PaginatedList from '@/views/components/PaginatedList';
import ContestsHeader from './components/ContestsHeader';
import Contest from './components/Contest';

export default {
  components: {
    ContestsHeader,
    Contest,
  },
  extends: PaginatedList,
  computed: {
    ...mapGetters({
      items: 'contests/list/getData',
      paginationParams: 'contests/list/getPaginationParams',
      isLoadingContests: 'contests/list/getIsLoading',
      isLoaded: 'contests/list/getIsLoaded',
    }),
  },
  methods: {
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;

        if (response) {
          await request.delete(`/contests/${this.selectedItem.uuid}`);

          await this.navigate({
            timestamp: new Date().getTime(),
          });

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Ο διαγωνισμός διαγράφτηκε επιτυχώς!!',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    async changeItemActive(selectedItem) {
      try {
        const isActive = !selectedItem.active;

        await request.put(`/contests/${selectedItem.uuid}/active`, {
          active: !!isActive,
        });

        await this.navigate({
          timestamp: new Date().getTime(),
        });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: isActive
            ? 'Ο διαγωνισμός ενεργοποιήθηκε επιτυχώς!!'
            : 'Ο διαγωνισμός δεν είναι ενεργός πλέον',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
