<template>
  <div class="columns is-multiline">
    <div class="column is-full">
      <div class="field">
        <label class="label">
          Ονομασία *
          <span v-if="general.title.length" class="tag">{{
            general.title.length
          }}</span>
        </label>
        <div class="control">
          <input
            v-validate="'required'"
            :value="general.title"
            class="input"
            type="text"
            name="title"
            @input="updateGeneral"
          />
          <p v-show="errors.has('title')" class="help is-danger">
            Εισάγετε ονομασία
          </p>
        </div>
      </div>
    </div>

    <div v-if="showTitleEn" class="column is-full">
      <div class="field">
        <label class="label">
          Ονομασία <span class="icon is-small fa flag-icon flag-icon-gb" />
          <span v-if="hasTitleEn" class="tag">
            {{ general.title_en.length }}
          </span>
        </label>
        <div class="control">
          <input
            :value="general.title_en"
            class="input"
            type="text"
            name="title_en"
            @input="updateGeneral"
          />
        </div>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <label class="label">
          Slug *
          <span v-if="general.slug.length" class="tag">{{
            general.slug.length
          }}</span>
        </label>
        <div class="control">
          <input
            v-validate="'required|regex:^[a-z0-9]+(?:-[a-z0-9]+)*$'"
            :value="general.slug"
            class="input"
            type="text"
            name="slug"
            @input="updateGeneral"
          />
          <p v-show="errors.has('slug')" class="help is-danger">
            Εισάγετε έγκυρο slug
          </p>
        </div>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <label class="label">
          H1 (tag) *
          <span v-if="general.description.length" class="tag">{{
            general.description.length
          }}</span>
          &nbsp;<small>(μέγιστο όριο: 290 χαρακτήρες)</small>
        </label>
        <div class="control">
          <textarea
            v-validate="'required|max:290'"
            v-model.trim="general.description"
            class="textarea"
            name="description"
            @input="updateGeneral"
          />
          <p v-show="errors.has('description')" class="help is-danger">
            Εισάγετε τίτλο για το H1 tag.
          </p>
        </div>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <!-- eslint-disable -->
        <label class="label"
          >Περιεχόμενο (Η παράγραφος {p tag} που εμφανίζεται κάτω από την τον
          τίτλο {h1 tag})*</label
        >
        <div class="control">
          <editor :content="general.content" @changeContent="changeContent" />
          <p v-show="errors.has('content')" class="help is-danger">
            Εισάγετε περιεχόμενο. Θα εμφανιστεί στη σελίδα κάτω από το H1.
          </p>
        </div>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <label class="label">
          Εμφανές * <span v-if="!canActivate">(Ανεβάστε avatar πρώτα)</span>
        </label>
        <div class="control">
          <ToggleButton
            :sync="true"
            :disabled="!canActivate"
            :value="general.active"
            :color="{ checked: '#22A684', unchecked: '#ff3860' }"
            :width="30"
            :height="15"
            @change="
              e =>
                updateGeneral({
                  ...e,
                  target: {
                    value: e.value,
                    name: 'active',
                  },
                })
            "
          />
        </div>
      </div>
    </div>

    <div v-if="orders.length" class="column is-full">
      <div class="field">
        <label class="label">
          Σειρά Ταξινόμησης *
        </label>
        <div class="control">
          <div class="select is-fullwidth">
            <select v-model="general.order" name="order">
              <option v-for="item in orders" :key="item" :value="item">
                {{ item + 1 }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
import Editor from '@/views/components/Editor';

export default {
  inject: ['$validator'],

  components: {
    Editor,
  },

  props: {
    general: Object,

    canActivate: {
      type: Boolean,
      default: true,
    },

    orders: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },

    showTitleEn: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },

  computed: {
    hasTitleEn() {
      return this.general.title_en && this.general.title_en.length;
    },
  },

  methods: {
    updateGeneral(e) {
      const { name, value } = e.target;
      this.$emit('updateGeneral', {
        ...this.general,
        [name]: value,
      });
    },

    changeContent(content) {
      this.$emit('updateGeneral', {
        ...this.general,
        content,
      });
    },
  },
};
</script>

<style scoped>
.flag-icon-gb {
  margin-right: 5px;
}
</style>
