import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async addPhoto({ dispatch, commit }, { model, file }) {
    try {
      const data = new FormData();
      data.append('file', file, file.name);
      data.append('model', JSON.stringify(model));
      this.isLoading = true;
      const response = await request.post('/photos', data);

      if (response.data.error) {
        throw Error('Server-side validation failed');
      }
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
