<template>
  <multiselect
    :options="filters"
    :searchable="true"
    :internal-search="false"
    :option-height="104"
    :loading="isSearching"
    :options-limit="50"
    :reset-after="false"
    :show-labels="false"
    :multiple="true"
    :value="filter"
    track-by="id"
    label="title"
    placeholder="Επιλέξτε φίλτρο..."
    select-label="Πατήστε enter για επιλογή"
    @search-change="searchFilter"
    @select="selectFilter"
    @remove="handleRemove"
  >
    <template v-slot:option="props" class="option">
      <div class="option__desc">
        <span class="option__title">{{ props.option.title }}</span>
      </div>
    </template>
    <template v-slot:noResult>
      Δε βρέθηκαν αποτελέσματα. Προσπαθήστε ξανά!!
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import debounce from 'lodash/debounce';
import request from '@/utils/request';

export default {
  inject: ['$validator'],
  components: {
    Multiselect,
  },
  props: {
    filter: Array,
  },
  data() {
    return {
      filters: [],
      isSearching: false,
    };
  },
  methods: {
    // eslint-disable-next-line func-names
    searchFilter: debounce(async function(searchQuery) {
      if (searchQuery.length) {
        this.isSearching = true;
        const { data } = await request.get('/filters-search', {
          params: {
            term: searchQuery,
          },
        });
        this.isSearching = false;

        this.filters = data.filters;
      }
    }, 300),
    selectFilter(selectedOption) {
      this.$emit('select-filter', selectedOption);
    },
    handleRemove(selectedOption) {
      this.$emit('remove-filter', selectedOption);
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect {
  margin: 0 auto;
}
</style>
