/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setSendCharges(state, { sendCharges }) {
    state.data = sendCharges;
  },
};

export default mutations;
