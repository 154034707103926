<template>
  <div class="barcode-item columns is-multiline is-centered">
    <div v-if="hasVariations" class="column is-full">
      <BarcodeItemPhoto
        :model="barcode"
        :photo="photo"
        :photos="photos"
        :is-loading="isLoadingPhoto"
        @on-update="handleUpdatePhoto"
      />
    </div>
    <div class="column">
      <div class="columns">
        <div
          :class="['column', hasVariations ? 'is-one-quarter' : 'is-one-third']"
        >
          <label class="label">Barcode</label>
          <div class="field has-addons">
            <div class="control is-expanded">
              <input
                ref="barcode"
                :value="barcode.code"
                name="barcode"
                class="input"
                type="text"
                readonly
                @input="handleUpdateBarcode"
              />
            </div>
            <div class="control">
              <button
                v-tooltip="'Copy barcode'"
                class="button"
                @click.prevent="handleCopy"
              >
                <span class="icon"><i class="fa fa-clone"/></span>
              </button>
            </div>
          </div>
        </div>

        <div v-if="hasVariations" class="column is-one-quarter">
          <div class="columns">
            <div class="column is-half">
              <div class="is-flex stock">
                <span class="label">Stock</span>
                <span class="is-flex stock__value">{{ barcode.stock }}</span>
              </div>
            </div>

            <div class="column is-half">
              <div class="field">
                <label class="label">Εμφανές</label>
                <div class="control">
                  <ToggleButton
                    :sync="true"
                    :value="barcode.active"
                    :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                    :width="30"
                    :height="15"
                    @change="handleChangeState"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="hasExtraFields"
          :class="['column', hasVariations ? 'is-one-quarter' : 'is-one-third']"
        >
          <label class="label">Color</label>
          <div class="control">
            <multiselect
              :options="colors"
              :value="barcode.color"
              disabled
              name="colors"
              track-by="id"
              label="title"
              placeholder="Επιλέξτε χρώμα"
              selected-label="Επιλεγμένο"
              select-label="Πατήστε enter για επιλογή"
              deselect-label="Πατήστε enter για απο-επιλογή"
              @select="handleSelectColor"
              @remove="handleRemoveColor"
            />
          </div>
        </div>

        <div
          v-if="hasExtraFields"
          :class="['column', hasVariations ? 'is-one-quarter' : 'is-one-third']"
        >
          <label class="label">Size</label>
          <div class="control">
            <multiselect
              :options="sizes"
              :value="barcode.size"
              disabled
              name="sizes"
              track-by="id"
              label="title"
              placeholder="Επιλέξτε μέγεθος"
              selected-label="Επιλεγμένο"
              select-label="Πατήστε enter για επιλογή"
              deselect-label="Πατήστε enter για απο-επιλογή"
              @select="handleSelectSize"
              @remove="handleRemoveSize"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="isSuper && barcodes.length > 0" class="column is-narrow">
      <button
        class="button is-small button--delete"
        type="button"
        @click="handleDelete"
      >
        <span class="icon is-small">
          <span class="fa fa-fw fa-trash-o" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { isNull, get } from 'lodash';
import { mapGetters } from 'vuex';
import shortid from 'shortid';
import Multiselect from 'vue-multiselect';
import BarcodeItemPhoto from '../BarcodeItemPhoto';

export default {
  inject: ['$validator'],

  components: {
    Multiselect,
    BarcodeItemPhoto,
  },

  props: {
    type: String,
    barcode: Object,
    barcodes: Array,
    photos: Array,
    colors: Array,
    sizes: Array,
  },

  data() {
    return {
      isLoadingPhoto: false,
    };
  },

  computed: {
    ...mapGetters({
      isSuper: 'getIsSuper',
    }),

    name() {
      return shortid.generate();
    },

    hasExtraFields() {
      return ['group', 'product'].includes(this.type);
    },

    hasVariations() {
      return !isNull(this.barcode.color) || !isNull(this.barcode.size);
    },

    photo() {
      return get(this.barcode, 'photos[0]', {});
    },
  },

  methods: {
    handleUpdateBarcode(e) {
      this.$emit('on-update', {
        ...this.barcode,
        code: e.target.value,
      });
    },

    handleSelectColor(selectedOption) {
      this.$emit('on-update', {
        ...this.barcode,
        color: selectedOption,
      });
    },

    handleRemoveColor() {
      this.$emit('on-update', {
        ...this.barcode,
        size: {},
      });
    },

    handleSelectSize(selectedOption) {
      this.$emit('on-update', {
        ...this.barcode,
        size: selectedOption,
      });
    },

    handleRemoveSize() {
      this.$emit('on-update', {
        ...this.barcode,
        size: {},
      });
    },

    handleUpdatePhoto() {
      this.$emit('on-update-barcode-photo');
    },

    handleCopy() {
      const copyText = this.$refs.barcode;
      copyText.select();
      document.execCommand('copy');

      this.$notify({
        type: 'success',
        title: 'Επιτυχία',
        text: 'Το barcode αντιγράφτηκε στο clipboard',
      });
    },

    handleChangeState({ value }) {
      this.$emit('on-update', {
        ...this.barcode,
        active: value,
      });
    },

    handleDelete() {
      this.$emit('on-delete', this.barcode.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.barcode-item:not(:first-child) {
  border-top: 1px dashed #ccc;
}

.field {
  flex-wrap: wrap;
}

.is-narrow {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.button--delete {
  margin-bottom: 0.26rem;
}

.stock {
  flex-direction: column;
  height: 100%;

  &__value {
    align-items: center;
    flex: 1 1 100%;
  }
}
</style>
