<template>
  <tr>
    <td class="has-text-centered">{{ index + 1 }}</td>
    <td class="has-text-centered">
      <ProductPhoto :type="item.type" :photos="item.photos" />
    </td>
    <td class="has-text-centered">
      <p v-for="(barcode, index) in item.barcodes" :key="index">
        {{ barcode.code }}
      </p>
    </td>
    <td class="has-text-centered">
      {{ item.common_id }}
    </td>
    <td class="has-text-centered" style="maxWidth: 250px">
      <router-link :to="{ name: 'products.edit', params: { uuid: item.uuid } }">
        {{ item.title }}
      </router-link>
    </td>
    <td class="has-text-centered">
      {{ item.sell_price }}
    </td>
    <td class="has-text-centered">
      {{ item.discount }}
    </td>
    <td class="has-text-centered">
      {{ item.final_price }}
    </td>
    <td class="has-text-centered">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        disabled
      />
    </td>
    <td class="has-text-centered">
      <input
        :value="item.pivot.count"
        type="number"
        step="1"
        min="1"
        class="input has-text-centered"
        @input="onChangeCount"
      />
    </td>
    <td class="has-text-centered">{{ item.weight }}</td>
    <td class="has-text-centered">
      <router-link
        :to="{ name: 'products.edit', params: { uuid: item.uuid } }"
        class="button is-small"
      >
        <span class="icon is-small">
          <span class="fa fa-fw fa-pencil" />
        </span>
      </router-link>
      <button type="button" class="button is-small" @click="onDelete">
        <span class="icon is-small">
          <span class="fa fa-fw fa-trash-o" />
        </span>
      </button>
    </td>
  </tr>
</template>

<script>
import ProductPhoto from '@/views/components/ProductPhoto';

export default {
  components: {
    ProductPhoto,
  },
  props: {
    index: Number,
    item: Object,
  },
  computed: {
    hasAvatar() {
      if (!this.item.photos.length) {
        return false;
      }

      /* eslint-disable camelcase */
      return (
        this.item.photos.find(({ is_avatar }) => is_avatar === true) !==
        undefined
      );
    },
  },
  methods: {
    onDelete() {
      this.$emit('onDelete', this.item);
    },
    onChangeCount(e) {
      this.$emit('onChangeCount', this.item.id, e.target.value);
    },
  },
};
</script>
