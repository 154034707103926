<template>
  <div
    v-if="isOpen"
    :class="{
      'is-primary': toast.type === 'primary',
      'is-danger': toast.type === 'danger',
    }"
    class="notification toast"
  >
    <button class="delete" @click="closeToast" />
    {{ toast.content }}
  </div>
</template>

<script>
export default {
  name: 'Toast',
  props: {
    isOpen: {
      type: Boolean,
    },
    toast: {
      type: Object,
    },
  },
  methods: {
    closeToast() {
      this.$emit('closeToast');
    },
  },
};
</script>

<style>
.toast {
  position: fixed;
  top: 60px;
  right: 20px;
  max-width: 200px;
  z-index: 10;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
</style>
