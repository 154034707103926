<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        Προσθήκη Διαγωνισμού
      </div>
      <div class="card-header-icon">
        <div class="field has-addons is-marginless">
          <div class="control">
            <router-link
              :to="{ name: 'marketing.contests.list' }"
              class="button"
            >
              <span class="icon is-small"><i class="fa fa-list-ul"/></span
              ><span>Λίστα</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="card-content">
        <AddPhotos
          :preview="preview"
          :show-error="errors.has('image')"
          :title="'Contest Image'"
          @addPhotos="handleAddPhotos"
        />

        <div class="field">
          <label class="label">Τίτλος *</label>
          <div class="control">
            <input
              v-validate="'required'"
              v-model.trim="newContest.title"
              class="input"
              type="text"
              name="title"
            />
          </div>
          <p v-show="errors.has('title')" class="help is-danger">
            Εισάγετε ονομασία
          </p>
        </div>

        <div class="field">
          <label class="label">Slug *</label>
          <div class="control">
            <input
              v-validate="'required|regex:^[a-z0-9]+(?:-[a-z0-9]+)*$'"
              v-model.trim="newContest.slug"
              class="input"
              type="text"
              name="slug"
            />
            <div v-show="errors.has('slug')" class="help is-danger">
              Εισάγετε έγκυρο slug
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Περιγραφή *</label>
          <div class="control">
            <textarea
              v-validate="'required'"
              v-model.trim="newContest.description"
              class="textarea"
              name="description"
            />
            <p v-show="errors.has('description')" class="help is-danger">
              Εισάγετε περιγραφή
            </p>
          </div>
        </div>

        <label class="label">
          Ημερομηνία Εκκίνησης *
        </label>
        <div class="field has-addons">
          <div class="control is-expanded">
            <Datepicker
              v-validate="'required'"
              v-model="newContest.starts_at"
              :disabled-dates="disabledDates"
              :open-date="new Date()"
              name="starts_at"
              format="dd-MM-yyyy"
              language="el"
              wrapper-class="field"
              input-class="input"
              placeholder="πχ: 02-12-2017"
            />
            <p v-show="errors.has('starts_at')" class="help is-danger">
              Εισάγετε ημ/νία εκκίνησης
            </p>
          </div>
        </div>

        <label class="label">
          Ημερομηνία Λήξης *
        </label>
        <div class="field has-addons">
          <div class="control is-expanded">
            <datepicker
              v-validate="'required'"
              :disabled-date="disableDate"
              v-model="newContest.ends_at"
              :open-date="new Date()"
              name="ends_at"
              format="dd-MM-yyyy"
              language="el"
              wrapper-class="field"
              input-class="input"
              placeholder="πχ: 02-12-2017"
            />
            <p v-show="errors.has('ends_at')" class="help is-danger">
              Εισάγετε ημ/νία λήξης
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Όροι Διαγωνισμού *</label>
          <div class="control">
            <VueEditor
              v-validate="'required'"
              id="editor"
              v-model="newContest.terms"
              :editor-toolbar="toolbarOptions"
              data-vv-value-path="value"
              data-vv-name="terms"
              @input="changeContent"
            />
            <p v-show="errors.has('terms')" class="help is-danger">
              Εισάγετε Όρους
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Εμφανές</label>
          <div class="control">
            <ToggleButton
              :sync="true"
              :value="newContest.active"
              :color="{ checked: '#22A684', unchecked: '#ff3860' }"
              :width="30"
              :height="15"
              @change="updateActive"
            />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>
  </div>
</template>

<script>
import moment from 'moment';
import { isEmpty } from 'lodash';
import Datepicker from 'vuejs-datepicker';
import { VueEditor } from 'vue2-editor';

import slug from 'slug';
import request from '@/utils/request';
import AddItem from '@/views/components/AddItem';
import AddPhotos from '@/views/components/AddPhotos';
import toolbarOptions from '@/constants/toolbarOptions';

export default {
  components: {
    AddPhotos,
    VueEditor,
    Datepicker,
  },

  extends: AddItem,

  data() {
    return {
      newContest: {
        title: '',
        slug: '',
        description: '',
        image: null,
        active: false,
        terms: '',
        starts_at: moment().toDate(),
        ends_at: null,
      },
      preview: '',
      toolbarOptions,
      disabledDates: new Date(),
    };
  },

  watch: {
    // eslint-disable-next-line func-names
    'newContest.title': function(newVal) {
      this.newContest = {
        ...this.newContest,
        slug: slug(newVal.toLowerCase()),
      };
    },
  },

  methods: {
    disableDate(date) {
      if (
        moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD') <
        moment()
          .add(2, 'days')
          .format('YYYY-MM-DD')
      ) {
        return true;
      }
      return false;
    },
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (isEmpty(this.preview)) {
          this.errors.add({
            field: 'image',
            msg: 'Image is required',
          });
        }

        if (!isValid || isEmpty(this.preview)) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        const formData = new FormData();
        formData.append('title', this.newContest.title);
        formData.append('slug', this.newContest.slug);
        formData.append('description', this.newContest.description);
        formData.append('image', this.newContest.image);
        formData.append('active', this.newContest.active.toString());
        formData.append('terms', this.newContest.terms);
        formData.append(
          'starts_at',
          moment(this.newContest.starts_at).format('YYYY-MM-DD'),
        );
        formData.append(
          'ends_at',
          moment(this.newContest.ends_at).format('YYYY-MM-DD'),
        );

        const { data } = await request.post('/contests', formData);

        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$router.push({ name: 'marketing.contests.list' });
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Ο διαγωνισμός αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.newContest = {
        title: '',
        slug: '',
        description: '',
        image: null,
        active: false,
        terms: '',
        starts_at: moment().toDate(),
        ends_at: '',
      };

      this.preview = '';
      this.$validator.reset();
      this.errors.remove('image');
    },

    handleAddPhotos(image) {
      this.newContest.image = image;

      const reader = new FileReader();

      this.$validator
        .validateAll()
        .then(() => {
          reader.onload = e => {
            this.preview = e.target.result;
            this.errors.remove('image');
          };
          reader.readAsDataURL(image);
        })
        .catch(err => console.log(err));
    },

    updateActive({ value }) {
      this.newContest = {
        ...this.newContest,
        active: !!value,
      };
    },

    changeContent(terms) {
      this.newContest = {
        ...this.newContest,
        terms: terms === '<p><br></p>' ? '' : terms,
      };
    },
  },
};
</script>
