<template>
  <LoadingContainer :is-loading="isLoading">
    <form @submit.prevent="submit">
      <div class="columns is-multiline">
        <div class="column is-full">
          <SetInfo :set="model" />
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            Επεξεργασία Set
          </div>
          <div class="card-header-icon">
            <div class="field has-addons is-marginless">
              <div class="control">
                <router-link
                  :to="{ name: 'marketing.sets.list' }"
                  class="button"
                >
                  <span class="icon is-small"><i class="fa fa-list-ul"/></span>
                  <span>Λίστα</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-half">
              <div class="field">
                <label class="label">Εμφανές *</label>
                <div class="control">
                  <div class="select select is-fullwidth">
                    <select v-model="general.active" name="active">
                      <option :value="false">Όχι</option>
                      <option :value="true">Ναί</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToggleableCard :is-open="true" title="Τιμολογιακή Πολιτική">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-half">
              <div class="field">
                <label class="label">Ποσοστό Έκπτωσης *</label>
                <div class="control">
                  <input
                    v-validate="'required|excluded:0'"
                    v-model.number="general.discount"
                    class="input"
                    type="number"
                    min="0"
                    step="0.01"
                    name="discount"
                  />
                  <p v-show="errors.has('discount')" class="help is-danger">
                    Εισάγετε ποσοστό έκπτωσης
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ToggleableCard>

      <div class="card">
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </div>
    </form>
  </LoadingContainer>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import _clone from 'lodash/clone';
import _pick from 'lodash/pick';
import AddItem from '@/views/components/AddItem';
import LoadingContainer from '@/views/components/LoadingContainer';
import ToggleableCard from '@/views/components/ToggleableCard';
import SetInfo from './components/SetInfo';

export default {
  components: {
    LoadingContainer,
    SetInfo,
    ToggleableCard,
  },
  extends: AddItem,
  data() {
    return {
      general: {},
      subsets: [],
    };
  },
  computed: {
    ...mapGetters({
      model: 'sets/edit/getSet',
      isLoading: 'sets/edit/getIsLoading',
    }),
  },
  watch: {
    model: {
      handler(newVal) {
        this.general = _pick(newVal, ['discount', 'active']);

        this.subsets = _clone(newVal.subsets);
      },
      deep: true,
    },
  },
  async created() {
    await this.getSet({ uuid: this.$route.params.uuid });
  },
  methods: {
    ...mapActions({
      getSet: 'sets/edit/getSet',
      updateSet: 'sets/edit/updateSet',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateSet({
          uuid: this.model.uuid,
          set: {
            ...this.general,
            expires_at: moment(this.general.expires_at).format('DD-MM-YYYY'),
            subsets: this.subsets.map(({ id }) => id),
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το set αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = _pick(this.model, ['discount', 'active']);

      this.subsets = _clone(this.model.subsets);

      this.$validator.reset();
    },
  },
};
</script>

<style scoped>
.multiselect {
  margin: 0 auto !important;
}
</style>
