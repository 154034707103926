/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setCategories(state, { categories }) {
    state.data = categories;
  },

  updateCategoriesTree(state, { tree }) {
    state.data = {
      ...state.data,
      tree,
    };
  },
};

export default mutations;
