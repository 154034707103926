<template>
  <div>
    <div class="columns">
      <div class="column is-half">
        <div class="field">
          <label class="label">Όνομα</label>
          <div class="control">
            <input
              :value="guest.firstName"
              disabled
              class="input"
              type="text"
              name="firstName"
            />
          </div>
        </div>
      </div>

      <div class="column is-half">
        <div class="field">
          <label class="label">Επώνυμο</label>
          <div class="control">
            <input
              :value="guest.lastName"
              disabled
              class="input"
              type="text"
              name="lastName"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-half">
        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input
              :value="guest.email"
              disabled
              class="input"
              type="email"
              name="email"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-half">
        <div class="field">
          <label class="label">Κινητό</label>
          <div class="control">
            <vue-tel-input
              v-model="guest.mobile"
              enabled-country-code
              placeholder=""
              name="mobile"
              disabled
            />
          </div>
        </div>
      </div>

      <div class="column is-half">
        <div class="field">
          <label class="label">Σταθερό</label>
          <div class="control">
            <vue-tel-input
              v-model="guest.telephone"
              enabled-country-code
              placeholder=""
              name="telephone"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: Number,
    guest: Object,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-tel-input {
  border-radius: 2px;

  .dropdown {
    padding: 6px;

    &:focus {
      outline: none;
    }
  }

  &.disabled {
    background-color: whitesmoke;
    border-color: #dbdbdb;

    &:focus-within {
      box-shadow: none;
      outline: none;
    }

    & input {
      background-color: transparent;
      color: #7a7a7a;
    }

    & .dropdown,
    & .selection,
    & input {
      cursor: not-allowed;
    }
  }

  input {
    font-size: 1rem;
  }
}
</style>
