import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getPostCategories({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const { data } = await request.get('/postCategories', {
        params,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.postCategories);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },
};

export default actions;
