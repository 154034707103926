<template>
  <CustomersEdit v-bind="$props">
    <div class="card-content">
      <div class="columns">
        <div class="column is-half">
          <div class="field">
            <label class="label">Όνομα *</label>
            <div class="control">
              <input
                :value="pigeon.firstName"
                disabled
                class="input"
                type="text"
                name="firstName"
              />
            </div>
          </div>
        </div>

        <div class="column is-half">
          <div class="field">
            <label class="label">Επώνυμο *</label>
            <div class="control">
              <input
                :value="pigeon.lastName"
                disabled
                class="input"
                type="text"
                name="lastName"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-half">
          <div class="field">
            <label class="label">Email *</label>
            <div class="control">
              <input
                :value="pigeon.email"
                disabled
                class="input"
                type="email"
                name="email"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </CustomersEdit>
</template>

<script>
import _pick from 'lodash/pick';
import EditItem from '@/views/components/EditItem';
import CustomersEdit from '../CustomersEdit';

export default {
  name: 'CustomersEditPigeon',
  components: {
    CustomersEdit,
  },
  extends: EditItem,
  props: {
    customer: Object,
    tabs: Array,
    activeTab: String,
  },
  data: () => ({
    pigeon: {},
  }),
  computed: {
    isDeleted() {
      return this.customer.deleted_at !== null;
    },
  },
  created() {
    this.pigeon = _pick(this.customer.pigeon, [
      'firstName',
      'lastName',
      'email',
      'gender',
      'birthday',
      'telephone',
      'mobile',
    ]);
  },
};
</script>
