/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setQuestion(state, { question }) {
    state.data = question;
  },

  updateQuestion(state, { question }) {
    state.data = {
      ...state.data,
      ...question,
    };
  },
};

export default mutations;
