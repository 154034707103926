import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async updateCustomerAddress({ commit }, { address }) {
    try {
      await request.put(`/addresses/${address.uuid}`, address);

      const { data } = await request.get(`/customers/${address.customer_id}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit(
        'customers/edit/updateCustomer',
        { customer: data.customer },
        {
          root: true,
        },
      );
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
