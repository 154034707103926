/* eslint-disable */
const getters = {
  getSizes(state) {
    return state.data;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
};

export default getters;
