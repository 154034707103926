import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getSendMethods({ commit }) {
    try {
      const { data } = await request.get('/sendMethods');

      await commit('setSendMethods', {
        sendMethods: data.sendMethods,
      });
    } catch (err) {
      throw err;
    }
  },

  async toggleSendMethodStatus({ commit, getters }, { sendMethod, active }) {
    try {
      const { data } = await request.put(`/sendMethods/${sendMethod.id}`, {
        active,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      const sendMethods = getters.getSendMethods.map(method => ({
        ...method,
        active: method.id === sendMethod.id ? active : method.active,
      }));

      await commit('setSendMethods', { sendMethods });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
