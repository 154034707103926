<template>
  <v-jstree
    :data="items"
    :show-checkbox="true"
    :multiple="true"
    @item-click="itemClick"
  />
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import { mapGetters } from 'vuex';
import VJstree from 'vue-jstree';

export default {
  components: {
    VJstree,
  },
  props: {
    categories: Array,
  },
  data: () => ({
    items: [],
  }),
  computed: {
    ...mapGetters({
      categoriesTree: 'categories/list/getCategoriesTree',
      categoriesReverseTree: 'categories/list/getCategoriesReverseTree',
    }),
  },
  watch: {
    categories: {
      handler(newVal) {
        this.setItems(newVal);
      },
      deep: true,
    },
  },
  created() {
    this.setItems(this.categories);
  },
  methods: {
    setItems(categories) {
      const uuids = categories.map(({ uuid }) => uuid);
      const productCategories = !_isEmpty(this.categoriesReverseTree)
        ? uuids.reduce(
            (acc, uuid) => ({
              low: [...acc.low, uuid],
              mid: [...acc.mid, this.categoriesReverseTree[uuid].mid],
              top: [...acc.top, this.categoriesReverseTree[uuid].top],
            }),
            {
              low: [],
              mid: [],
              top: [],
            },
          )
        : {
            low: [],
            mid: [],
            top: [],
          };

      this.items = this.categoriesTree.map(c1 => {
        const selected1 = productCategories.top.includes(c1.id);

        return {
          ...c1,
          selected: selected1,
          disabled: true,
          opened: selected1,
          children: c1.children.map(c2 => {
            const selected2 = productCategories.mid.includes(c2.id);

            return {
              ...c2,
              selected: selected2,
              disabled: true,
              opened: selected2,
              children: c2.children.map(c3 => {
                const selected3 = productCategories.low.includes(c3.id);

                return {
                  ...c3,
                  selected: selected3,
                };
              }),
            };
          }),
        };
      });
    },
    itemClick({ model }) {
      if (model.selected) {
        this.$emit('updateCategories', {
          categories: [
            ...this.categories,
            {
              uuid: model.id,
            },
          ],
        });
      } else {
        this.$emit('updateCategories', {
          categories: this.categories.filter(({ uuid }) => uuid !== model.id),
        });
      }
    },
  },
};
</script>
