import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getCancelOrderReasons({ commit }) {
    try {
      const { data } = await request.get('/cancelOrderReasons');
      await commit('setCancelOrderReasons', {
        cancelOrderReasons: data.cancelOrderReasons,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
