import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async reorderPhotos({ dispatch, commit }, data) {
    try {
      const response = await request.put('/photos-reorder', data);

      if (response.data.error) {
        throw Error('Server-side validation failed');
      }
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
