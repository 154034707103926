<template>
  <div class="card-content">
    <FamilyProduct
      v-for="(product, productIndex) in formFamily.products"
      :key="productIndex"
      :parent-product="parentProduct"
      :product="product"
      :family="family"
      :index="productIndex"
      @change="handleChangeProduct"
      @delete="handleDeleteProduct"
    />
    <div class="actions has-text-centered">
      <button class="button" type="button" @click="handleAddProduct">
        <span class="icon is-small">
          <i class="fa fa-plus" />
        </span>
        <span>Προσθήκη Προϊόντος</span>
      </button>
    </div>
  </div>
</template>

<script>
import FamilyProduct from './FamilyProduct';

export default {
  inject: ['$validator'],

  components: {
    FamilyProduct,
  },

  props: {
    parentProduct: {
      type: Object,
      required: true,
    },

    family: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      formFamily: null,
    };
  },

  watch: {
    family: {
      handler(newVal) {
        if (newVal) {
          this.formFamily = { ...newVal };
        }
      },
      immediate: true,
    },
  },

  methods: {
    handleAddProduct() {
      this.formFamily = {
        ...this.formFamily,
        products: [
          ...this.formFamily.products,
          {
            product: null,
            attributes: [],
          },
        ],
      };

      this.$emit('update', { family: this.formFamily });
    },

    handleChangeProduct(item) {
      this.formFamily.products[item.index] = { ...item.product };

      this.$emit('update', { family: this.formFamily });
    },

    handleDeleteProduct(index) {
      const products = this.formFamily.products.filter(
        (product, productIndex) => productIndex !== index,
      );

      this.formFamily = {
        ...this.formFamily,
        products: [...products],
      };

      this.$emit('update', { family: this.formFamily });
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  border-top: 1px solid #dbdbdb;
  padding: 0.75rem;
}
</style>
