import all from './all';
import list from './list';
import edit from './edit';

const module = {
  namespaced: true,
  modules: {
    all,
    list,
    edit,
  },
};

export default module;
