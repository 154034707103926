<template>
  <div v-if="hasNestedMenu">
    <router-link v-slot="{ href, navigate, isActive }" :to="item.link" custom>
      <li :class="[isActive && 'router-link-active is-active']" role="link">
        <a :href="href" @keypress.enter="navigate" @click="navigate">{{
          item.label
        }}</a>
      </li>
    </router-link>

    <div class="nested-menu">
      <router-link
        v-slot="{ href, navigate, isActive }"
        v-for="(subitem, index) in item.children"
        :to="subitem.link"
        :key="index"
        custom
        class="nested-menu-item"
      >
        <li :class="[isActive && 'router-link-active is-active']" role="link">
          <a :href="href" @keypress.enter="navigate" @click="navigate">
            <span class="icon"><i class="fa fa-angle-right"/></span>
            <span>{{ subitem.label }}</span>
          </a>
        </li>
      </router-link>
    </div>
  </div>

  <div v-else-if="hasChildren" class="dropdown is-hoverable">
    <router-link
      v-slot="{ href, navigate, isActive }"
      :to="item.link"
      custom
      class="dropdown-trigger"
      aria-haspopup="true"
      aria-controls="dropdown-menu"
    >
      <li :class="[isActive && 'router-link-active is-active']" role="link">
        <a :href="href" @keypress.enter="navigate" @click="navigate">
          <span>{{ item.label }}</span>
          <span v-if="hasChildren" class="icon">
            <i class="fa fa-caret-right" />
          </span>
        </a>
      </li>
    </router-link>
    <div id="dropdown-menu" class="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <router-link
          v-slot="{ href, navigate, isActive }"
          v-for="(subitem, index) in item.children"
          :to="subitem.link"
          :key="index"
          custom
          class="dropdown-item"
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">{{
              subitem.label
            }}</a>
          </li>
        </router-link>
      </div>
    </div>
  </div>

  <router-link
    v-slot="{ href, navigate, isActive }"
    v-else
    :to="item.link"
    custom
  >
    <li
      :class="[isActive && 'router-link-active is-active']"
      role="link"
      @click="navigate"
    >
      <a :href="href" @keypress.enter="navigate" @click="navigate">{{
        item.label
      }}</a>
    </li>
  </router-link>
</template>

<script>
import _startsWith from 'lodash/startsWith';

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      hasNestedMenu: this.matchRoute(this.$route.path),
    };
  },
  computed: {
    hasChildren() {
      return this.item.children && this.item.children.length > 0;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.path': function(newVal) {
      this.hasNestedMenu = this.matchRoute(newVal);
    },
  },
  methods: {
    matchRoute(path) {
      const hasChildren = this.item.children && this.item.children.length > 0;
      const childrenPaths = hasChildren
        ? this.item.children.map(({ link }) => link)
        : [];

      return childrenPaths.some(childrenPath =>
        _startsWith(path, childrenPath),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-list {
  li {
    border-bottom: 1px solid rgba(#ffffff, 0.3);

    a {
      align-items: center;
      color: #ffffff;
      display: flex;
      height: 40px;
      padding: 1rem 0.75rem;
      position: relative;

      &:hover {
        background-color: transparent;
        color: #242424;
      }
    }

    .icon.is-angle {
      position: absolute;
      right: 10px;
      top: 9px;
    }

    &.is-active {
      background-color: rgba(0, 0, 0, 0.3);

      a {
        &:hover {
          color: #ffffff;
        }
      }
    }

    &.dropdown-trigger {
      a {
        justify-content: space-between;
      }
    }

    &.nested-menu-item {
      a {
        font-size: 0.8rem;
        padding: 0.75rem 1rem;
      }

      &.is-active {
        background-color: transparent;

        a {
          color: #242424;
        }
      }
    }
  }

  .nested-menu {
    position: relative;

    &:before {
      background-color: rgba(#ffffff, 0.3);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      width: 5px;
    }
  }

  .dropdown {
    width: 100%;

    li {
      width: 100%;
    }

    .dropdown-menu {
      left: 100%;
      padding-top: 0;
      top: 0;

      .dropdown-content {
        border-radius: 0 2px 2px 0;
        box-shadow: 2px 2px 3px rgba(10, 10, 10, 0.1),
          0 0 0 1px rgba(10, 10, 10, 0.1);
        padding: 0;

        .dropdown-item {
          padding: 0;

          &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }

          &:hover {
            background-color: #ffffff;
          }

          a {
            color: #4a4a4a;
            padding: 0.75rem 0.5rem;

            &:hover {
              background-color: #ffffff;
              color: #22a684;
            }
          }
        }
      }
    }
  }
}
</style>
