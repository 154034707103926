import request from '@/utils/request';
import { calculateParams } from '@/utils/calculations';

/* eslint-disable */
const actions = {
  async getColors({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get('/colors', {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.colors);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async checkForColorsAdditions() {
    try {
      const { data } = await request.get('/colors-additions');

      if (data.error) {
        throw Error('Server-side validation failed');
      }
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
