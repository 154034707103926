import list from './list';

const module = {
  namespaced: true,
  modules: {
    list,
  },
};

export default module;
