<template>
  <article class="media">
    <div class="media-content">
      <div class="content">
        <p>
          <strong>{{ note.user.name }}</strong>
          <small>{{ note.created_at | timestamp }}</small>
          <br />
          {{ note.comment }}
        </p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    note: Object,
  },
};
</script>

<style scoped>
small {
  display: inline-block;
  margin-left: 10px;
}
</style>
