import request from '@/utils/request';
import moment from 'moment';

/* eslint-disable */
const actions = {
  async fetchContest({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/contests/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setContest', {
        contest: data.contest,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async updateContest({ dispatch, commit, rootGetters }, { uuid, contest }) {
    try {
      const formData = new FormData();
      formData.append('title', contest.title);
      formData.append('slug', contest.slug);
      formData.append('description', contest.description);
      formData.append('image', contest.image);
      formData.append('active', contest.active.toString());
      formData.append('terms', contest.terms);
      formData.append(
        'starts_at',
        moment(contest.starts_at).format('YYYY-MM-DD'),
      );
      formData.append('ends_at', moment(contest.ends_at).format('YYYY-MM-DD'));

      const { data } = await request.post(`/contests/${uuid}`, formData);

      if (data.error) {
        throw Error('Server-side validation failed');
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};

export default actions;
