import edit from './edit';

const module = {
  namespaced: true,
  modules: {
    edit,
  },
};

export default module;
