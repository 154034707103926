<template>
  <div class="columns">
    <div class="column is-half">
      <label class="label">Κουπόνι</label>
      <div class="field has-addons">
        <div class="control">
          <input v-model="coupon" name="coupon" type="text" class="input" />
        </div>
        <div class="control">
          <button
            v-if="!hasCoupon"
            key="addCoupon"
            :class="{ 'is-loading': isSaving }"
            :disabled="!coupon || isSaving"
            type="submit"
            class="button is-primary"
            @click="handleClick"
          >
            Καταχώρηση
          </button>
          <button
            v-else
            key="resetCoupon"
            :class="{ 'is-loading': isSaving }"
            :disabled="isSaving"
            type="button"
            class="button"
            @click="handleReset"
          >
            Αφαίρεση
          </button>
        </div>
      </div>
      <div v-show="errors.has('coupon')" class="help is-danger">
        {{ errors.first('coupon') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  props: {
    hasCoupon: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      coupon: '',
      isSaving: false,
    };
  },

  methods: {
    ...mapActions(['getCoupon']),

    ...mapMutations(['resetCoupon']),

    async handleClick() {
      try {
        this.errors.remove('coupon');
        this.isSaving = true;

        await this.getCoupon({ code: this.coupon });

        this.$notify({
          type: 'success',
          text: 'Το κουπόνι καταχωρήθηκε επιτυχώς!!',
        });
      } catch (err) {
        this.errors.add({
          field: 'coupon',
          msg: err.response.data.message,
        });

        this.$notify({
          type: 'error',
          text: 'Παρακαλώ εισάγετε ένα έγκυρο κουπόνι!!!',
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.resetCoupon();
      this.$notify({
        type: 'success',
        text: 'Το κουπόνι αφαιρέθηκε επιτυχώς!!',
      });
    },
  },
};
</script>
