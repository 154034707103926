<template>
  <div v-sticky sticky-offset="offset">
    <nav class="level">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading print__heading-type">Τυπος</p>
          <p class="title">
            <UserType
              :item="order"
              class="is-white"
              relationship="orderable_type"
            />
          </p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Common ID</p>
          <p class="title">{{ order.common_id }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Μεθοδος Παραγγελιας</p>
          <div v-if="isConnectedToSkroutzEvent">
            <router-link
              :to="{
                name: 'orders.skroutz.edit',
                params: { code: order.smart_cart_events[0].code },
              }"
            >
              <img
                src="@/assets/skroutz_smart_cart.png"
                alt="Carespot"
                height="30"
                width="30"
              />

              <p class="title skroutz-code">
                {{ order.smart_cart_events[0].code }}
              </p>
            </router-link>
          </div>
          <div v-else-if="!!order.order_method">
            <img
              v-tooltip="{ content: order.order_method.title }"
              v-if="order.order_method.title"
              :src="order.order_method.icon"
              class="method-icon"
              height="30"
              width="30"
            />
          </div>
          <div v-else>
            ----
          </div>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Ημερομηνια</p>
          <p class="title">{{ order.created_at | timestamp }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Status</p>
          <p class="title">
            <span v-if="isPaid" class="tag is-medium is-primary">
              Πληρωμένη
            </span>
            <span v-else class="tag is-medium is-white">Απλήρωτη</span>
          </p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Κατασταση</p>
          <p class="title status">
            <span v-if="isDeleted" :class="classes">Ακυρωμένη</span>
            <span v-else-if="isUnfinished" :class="classes">Ανολοκλήρωτη</span>
            <span v-else-if="isNew" :class="classes">Νέα</span>
            <span v-else :class="classes">{{ status.title }}</span>
            <span v-if="order.is_printed" class="icon icon--print">
              <span class="fa fa-fw fa-print" />
            </span>
          </p>
        </div>
      </div>
    </nav>

    <div v-if="isDeleted" class="notification is-danger">
      {{ order.cancel_order_reason.title }}
    </div>
  </div>
</template>

<script>
import classnames from 'classnames';
import _get from 'lodash/get';
import _last from 'lodash/last';
import UserType from '@/views/components/UserType';

export default {
  components: {
    UserType,
  },
  props: {
    order: Object,
    isDeleted: Boolean,
  },
  data: () => ({
    offset: { top: 45 },
  }),
  computed: {
    isPaid() {
      return !!this.order.transaction;
    },
    isUnfinished() {
      return this.order.is_unfinished;
    },
    isNew() {
      return _get(this, 'status.slug', 'new') === 'new';
    },
    status() {
      return _last(this.order.statuses);
    },
    classes() {
      if (this.isDeleted || this.isUnfinished) {
        return 'tag is-medium is-danger';
      }

      if (this.isNew) {
        return 'tag is-medium is-warning';
      }

      return classnames('tag is-medium', {
        'is-primary': this.status.slug === 'for_delivery',
        'is-info': this.status.slug === 'confirm',
        'is-white': !['for_delivery', 'new', 'confirm'].includes(
          this.status.slug,
        ),
      });
    },
    isConnectedToSkroutzEvent() {
      return this.order.smart_cart_events.length;
    },
  },
};
</script>

<style scoped>
.type.is-white {
  background-color: white;
  border-color: transparent;
}
.tag {
  width: 100%;
}

.status {
  position: relative;
}

.skroutz-code {
  display: inline-block;
  margin-left: 10px;
}

.icon--print {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
  font-size: 0.85rem;
  position: absolute;
  right: -9px;
  top: -7px;
}
</style>
