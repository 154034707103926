<template>
  <LoadingContainer :is-loading="isLoading">
    <form novalidate @submit.prevent="submit">
      <ChargesCalculator :charges="sendCharges.gr" />

      <SumCharges :charges="charges.gr" @updateSumCharges="updateSumCharges" />

      <WeightCharges
        :charges="charges.gr"
        @updateWeightCharges="updateWeightCharges"
      />

      <BoxNowCharges
        :charges="charges.gr"
        @updateBoxNowCharges="updateBoxNowCharges"
      />

      <div class="card">
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </div>
    </form>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import AddItem from '@/views/components/AddItem';
import LoadingContainer from '@/views/components/LoadingContainer';
import ChargesCalculator from '../components/ChargesCalculator';
import SumCharges from '../components/SumCharges';
import WeightCharges from '../components/WeightCharges';
import BoxNowCharges from '../components/BoxNowCharges';

export default {
  components: {
    LoadingContainer,
    ChargesCalculator,
    SumCharges,
    WeightCharges,
    BoxNowCharges,
  },
  extends: AddItem,
  data() {
    return {
      isEditing: false,
      charges: {},
    };
  },
  computed: {
    ...mapGetters({
      sendCharges: 'getSendCharges',
      isLoading: 'getIsLoading',
    }),
  },
  watch: {
    sendCharges: {
      handler(newVal) {
        this.charges = cloneDeep(newVal);
      },
      deep: true,
    },
  },
  async created() {
    try {
      await this.getSendCharges();
    } catch (err) {
      this.$router.push('/error');
    }
  },
  methods: {
    ...mapActions(['getSendCharges', 'updateSendCharges']),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateSendCharges({
          sendCharges: this.charges,
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Οι ρυθμίσεις αποθηκεύτηκαν',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.charges = cloneDeep(this.sendCharges);

      this.$validator.reset();
    },
    updateSumCharges(charges) {
      this.charges = {
        ...this.charges,
        gr: {
          ...this.charges.gr,
          sum: charges,
        },
      };
    },
    updateWeightCharges(charges) {
      this.charges = {
        ...this.charges,
        gr: {
          ...this.charges.gr,
          weight: charges,
        },
      };
    },
    updateBoxNowCharges(charge) {
      this.charges = {
        ...this.charges,
        gr: {
          ...this.charges.gr,
          box_now: { charge },
        },
      };
    },
  },
};
</script>
