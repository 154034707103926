<template>
  <div :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background" @click="closeModal" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Προσθήκη Πελάτη</p>
        <button class="delete" @click="closeModal" />
      </header>
      <form @submit.prevent="submit">
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Όνομα *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newCustomer.firstName"
                    type="text"
                    class="input"
                    name="firstName"
                    @focus="resetAutocomplete"
                  />
                </div>
                <div v-show="errors.has('firstName')" class="help is-danger">
                  Εισάγετε όνομα
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Επώνυμο *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newCustomer.lastName"
                    type="text"
                    class="input"
                    name="lastName"
                    @focus="resetAutocomplete"
                  />
                </div>
                <div v-show="errors.has('lastName')" class="help is-danger">
                  Εισάγετε επώνυμο
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column is-half">
              <label class="label">Email *</label>
              <div class="field is-expanded">
                <div class="field has-addons">
                  <div class="control is-expanded">
                    <input
                      v-validate="'required|email'"
                      v-model.trim="newCustomer.email"
                      type="text"
                      class="input"
                      name="email"
                      @focus="resetAutocomplete"
                    />
                  </div>
                  <div class="control">
                    <button
                      v-tooltip="{ content: 'Δημιουργία Dummy Email' }"
                      class="button"
                      type="button"
                      @click="createDummyEmail"
                    >
                      <span class="icon">
                        <i class="fa fa-refresh" />
                      </span>
                    </button>
                  </div>
                </div>
                <div v-show="errors.has('email')" class="help is-danger">
                  Εισάγετε ένα έγκυρο email
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Κιν. Τηλέφωνο *</label>
                <div class="control">
                  <vue-tel-input
                    v-validate="'required'"
                    v-model="phoneNumbers.mobile"
                    enabled-country-code
                    placeholder="Εισάγετε Κινητό"
                    name="mobile"
                    input-id="add_order_customer_mobile"
                    @input="onInputMobile"
                  />
                  <p
                    v-show="
                      errors.has('mobile') || errors.has('mobile-invalid')
                    "
                    class="help is-danger"
                  >
                    Εισάγετε έγκυρο κινητό τηλέφωνο
                  </p>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Σταθ. Τηλέφωνο</label>
                <div class="control">
                  <vue-tel-input
                    v-model="phoneNumbers.telephone"
                    enabled-country-code
                    input-id="add_order_customer_telephone"
                    name="telephone"
                    placeholder="Εισάγετε Σταθερό"
                    @input="onInputTelephone"
                  />
                  <p v-show="errors.has('telephone')" class="help is-danger">
                    Εισάγετε έγκυρο σταθερό τηλέφωνο
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column is-half">
              <div class="field">
                <label class="label">Μέθοδος Αρχικής Εγγραφής *</label>
                <div class="control">
                  <div class="select select is-fullwidth">
                    <select
                      v-model.number="newCustomer.registration_method_id"
                      name="registration_method_id"
                    >
                      <option
                        v-for="method in registrationMethods"
                        :key="method.id"
                        :value="method.id"
                      >
                        {{ method.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="column is-half">
              <div class="field">
                <label class="label">Επιμέρους Τύπος Αρχικής Εγγραφής *</label>
                <div class="control">
                  <div class="select select is-fullwidth">
                    <select
                      v-model.number="newCustomer.registration_method_type_id"
                      name="registration_method_type_id"
                    >
                      <optgroup
                        v-for="method in registrationMethods"
                        v-if="method.id === newCustomer.registration_method_id"
                        :key="method.id"
                        :label="method.title"
                      >
                        <option
                          v-for="type in method.types"
                          :key="type.id"
                          :value="type.id"
                        >
                          {{ type.title }}
                        </option>
                      </optgroup>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </footer>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import get from 'lodash/get';
import randomstring from 'randomstring';
import FormSubmit from '@/views/components/FormSubmit';
import AddressMixin from '@/mixins/Address';

export default {
  components: {
    FormSubmit,
  },
  mixins: [AddressMixin],
  props: {
    isOpen: false,
  },
  data() {
    return {
      newCustomer: {
        firstName: '',
        lastName: '',
        email: '',
        registration_method_id: 4,
        registration_method_type_id: null,
        telephone: '',
        mobile: '',
      },
      isSaving: false,
      phoneNumbers: {
        mobile: '',
        telephone: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      registrationMethods: 'getRegistrationMethodsForNewCustomer',
    }),
  },

  watch: {
    // eslint-disable-next-line func-names
    'newCustomer.firstName': function(newVal) {
      this.newCustomer.firstName = newVal;
    },

    // eslint-disable-next-line func-names
    'newCustomer.lastName': function(newVal) {
      this.newCustomer.lastName = newVal;
    },

    'newCustomer.registration_method_id': {
      handler(newVal) {
        const registrationMethod = this.registrationMethods.find(
          method => method.id === newVal,
        );

        this.newCustomer.registration_method_type_id = get(
          registrationMethod,
          'types[0].id',
          null,
        );
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['addNewOrderGuest']),
    closeModal() {
      this.reset();
      this.$emit('closeModal');
    },

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid || this.errors.count()) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        await this.addNewOrderGuest({ customer: this.newCustomer });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Ο πελάτης αποθηκεύτηκε',
        });

        this.closeModal();
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      /* eslint-disable camelcase */
      const registration_method_id = 4;
      const registrationMethod = this.registrationMethods.find(
        method => method.id === registration_method_id,
      );
      const registration_method_type_id = get(
        registrationMethod,
        'types[0].id',
        null,
      );

      this.newCustomer = {
        firstName: '',
        lastName: '',
        email: '',
        registration_method_id,
        registration_method_type_id,
        telephone: '',
        mobile: '',
      };

      this.phoneNumbers = {
        mobile: '',
        telephone: '',
      };

      this.$validator.reset();
    },

    onInputTelephone(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'telephone',
          msg: 'Wrong telephone',
        });
      } else {
        this.newCustomer.telephone = number.international;
        this.errors.remove('telephone');
      }
    },

    onInputMobile(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'mobile-invalid',
          msg: 'Wrong mobile',
        });
      } else {
        this.newCustomer.mobile = number.international;
        this.errors.remove('mobile-invalid');
      }
    },

    createDummyEmail() {
      this.newCustomer.email = `${randomstring.generate(16)}@guest.gr`;
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 0.5rem;
}

.vue-tel-input {
  border: 1px solid #dbdbdb;
  border-radius: 2px;

  &:focus-within {
    border-color: #22a684;
    box-shadow: 0 0 0 0.125em rgba(34, 166, 132, 0.25);
    outline: none;
  }

  ::v-deep .dropdown {
    padding: 6px;

    &:focus {
      outline: none;
    }
  }

  ::v-deep ul {
    border-color: #dbdbdb;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;

    li.last-preferred {
      border-bottom-color: #dbdbdb;
    }
  }

  ::v-deep input {
    font-size: 1rem;
  }
}
</style>
