<script>
import Multiselect from 'vue-multiselect';
import PaginatedList from '@/views/components/PaginatedList';

export default {
  components: {
    Multiselect,
  },
  extends: PaginatedList,
  data() {
    return {
      users: [],
      items: [],
    };
  },
  methods: {
    setData(revisions, users) {
      this.users = users;
      this.setRevisionsData(revisions);
    },
    setRevisionsData(revisions) {
      this.items = revisions.data;
      this.currentPage = revisions.current_page;
      this.pagesCount = revisions.last_page;
      this.indexStartsFrom = revisions.from;
      this.total = revisions.total;
    },
    async navigate(params) {
      try {
        this.$router.push({
          name: this.$route.name,
          query: this.filterParams(params),
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
