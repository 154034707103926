<template>
  <tr>
    <td class="has-text-centered">{{ method.id }}</td>
    <td class="has-text-centered">
      {{ method.title }}
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    method: Object,
  },
};
</script>
