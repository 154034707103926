import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async addStatus(
    {
      commit,
      rootGetters: { getUser, getOrder },
    },
    { statusId },
  ) {
    try {
      await request.post(`/orders/${getOrder.id}/statuses/${statusId}`, {
        user_id: getUser.id,
      });

      const { data } = await request.get(`/orders/${getOrder.id}/statuses`);

      await commit('updateStatuses', { statuses: data.statuses });
    } catch (err) {
      throw err;
    }
  },

  async removeStatus(
    {
      commit,
      rootGetters: { getOrder },
    },
    { statusId },
  ) {
    try {
      await request.delete(`/orders/${getOrder.id}/statuses/${statusId}`);

      const { data } = await request.get(`/orders/${getOrder.id}/statuses`);

      await commit('updateStatuses', { statuses: data.statuses });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
