import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getCategories({ dispatch, commit }, params) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get('/categories', {
        params,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setCategories', {
        categories: data.categories,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async checkForCategoriesAdditions() {
    try {
      const { data } = await request.get('/categories-additions');

      if (data.error) {
        throw Error('Server-side validation failed');
      }
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
