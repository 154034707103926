import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getGroup({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/groups/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setGroup', {
        group: data.group,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async updateGroup({ dispatch, commit, rootGetters }, { uuid, group }) {
    try {
      const response1 = await request.put(`/groups/${uuid}`, group);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/products/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit(
        'products/edit/updateProduct',
        {
          product: response2.data.product,
        },
        {
          root: true,
        },
      );
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
