import get from 'lodash/get';
import menuItems from '@/constants/menuItems';
import { loadState } from '@/utils/localStorage';

const persistedState = loadState();

const state = {
  isOpen: true,
  menuItems,
  ...get(persistedState, 'sidebar', {}),
};

export default state;
