<template>
  <ToggleableCard
    title="Εξερχόμενη Επικοινωνία με SMS & Viber"
    @closePanel="closePanel"
  >
    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="field">
          <label class="label">Κινητό</label>
          <div class="select select is-fullwidth">
            <select v-validate="'required'" v-model="form.mobile" name="phone">
              <option
                v-for="(mobile, index) in mobileNumbers"
                :value="mobile.mobile"
                :key="index"
              >
                {{ mobile.mobile }} | {{ mobile.group }}
              </option>
            </select>
          </div>
        </div>

        <div class="field">
          <label class="label">Επιλέξτε μέσο</label>
          <div class="select select is-fullwidth">
            <select v-validate="'required'" v-model="form.type" name="type">
              <option :value="'sms'"> SMS </option>
              <option :value="'viber'"> Viber </option>
            </select>
          </div>
        </div>

        <div class="field">
          <label class="label">Τίτλος</label>
          <div class="control">
            <textarea
              v-validate="'required'"
              v-model="form.title"
              cols="30"
              rows="2"
              name="title"
              class="textarea"
            />
          </div>
          <p v-show="errors.has('title')" class="help is-danger">
            Εισάγετε τίτλο
          </p>
        </div>

        <div class="field">
          <label class="label">Περιεχόμενο</label>
          <div class="control">
            <textarea
              v-validate="'required'"
              v-model="form.content"
              cols="30"
              rows="8"
              name="content"
              class="textarea"
            />
          </div>
          <p v-show="errors.has('content')" class="help is-danger">
            Εισάγετε περιεχόμενο
          </p>
        </div>
      </div>
      <div class="card-footer">
        <FormSubmit
          :is-saving="isSaving"
          submit-label="Αποστολή"
          submit-icon="fa-envelope-o"
          @reset="reset"
        />
      </div>
    </form>
  </ToggleableCard>
</template>

<script>
import { mapGetters } from 'vuex';
import uniq from 'lodash/uniq';
import request from '@/utils/request';
import EditItem from '@/views/components/EditItem';
import ToggleableCard from '@/views/components/ToggleableCard';

export default {
  components: {
    ToggleableCard,
  },
  extends: EditItem,
  props: {
    customer: Object,
  },
  data() {
    return {
      form: {
        mobile: '',
        title: '',
        content: '',
        type: 'sms',
      },
      isSaving: false,
      mobileNumbers: [],
    };
  },

  computed: {
    ...mapGetters(['getCustomer']),
  },

  watch: {
    customer: {
      handler(newVal) {
        if (newVal.mobile) {
          this.mobileNumbers.push({
            mobile: newVal.mobile,
            group: 'profile',
          });
        }
        this.mobileNumbers = [
          ...this.mobileNumbers,
          ...uniq(newVal.guests.map(guest => guest.mobile)).map(mobile => ({
            mobile,
            group: 'guest',
          })),
        ];
        if (newVal.contact?.mobile) {
          this.mobileNumbers.push({
            mobile: newVal.contact.mobile,
            group: 'contact',
          });
        }

        this.form = {
          ...this.form,
          mobile: this.mobileNumbers[0].mobile,
        };
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        const { data } = await request.post(
          `/customers/${this.$route.params.uuid}/notify-sms`,
          this.form,
        );
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το μήνυμα απεστάλη',
        });

        this.closePanel();
      } catch (err) {
        console.log(err);
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    closePanel() {
      this.reset();
    },
    reset() {
      this.form = {
        mobile: this.mobileNumbers.length ? this.mobileNumbers[0].mobile : '',
        title: '',
        content: '',
        type: 'sms',
      };

      this.$validator.reset();
    },
  },
};
</script>
