<template>
  <tr>
    <td class="has-text-centered">{{ template.id }}</td>
    <td class="has-text-centered">
      {{ template.title }}
    </td>
    <td v-html="template.content" />
    <td class="has-text-centered">
      <div class="buttons is-centered">
        <router-link
          :to="{
            name: 'settings.emailTemplates.edit',
            params: { uuid: template.uuid },
          }"
          class="button is-small"
        >
          <span class="icon is-small">
            <span class="fa fa-fw fa-pencil" />
          </span>
        </router-link>
        <button class="button is-small" @click="askToDeleteItem">
          <span class="icon is-small">
            <span class="fa fa-fw fa-trash-o" />
          </span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    template: Object,
  },
  methods: {
    askToDeleteItem() {
      this.$emit('askToDeleteItem', this.template);
    },
  },
};
</script>

<style scoped>
tr td:nth-child(3) {
  white-space: pre-line;
}
</style>
