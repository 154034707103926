/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setSize(state, { size }) {
    state.data = size;
  },

  updateSize(state, { size }) {
    state.data = {
      ...state.data,
      ...size,
    };
  },
};

export default mutations;
