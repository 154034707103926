<template>
  <ToggleableCard title="Εισερχόμενη Επικοινωνία" @closePanel="closePanel">
    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="field">
          <label class="label">Τύπος Επικοινωνίας *</label>
          <div class="control">
            <div class="select select is-fullwidth">
              <select v-model.number="newContact.type_id" name="type_id">
                <option :value="null">-- Επέλεξε --</option>
                <option
                  v-for="type in contactTypes"
                  :key="type.id"
                  :value="type.id"
                >
                  {{ type.title }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div v-if="newContact.type_id">
          <div class="field">
            <label class="label">Τίτλος</label>
            <div class="control">
              <textarea
                v-validate="'required'"
                v-model="newContact.title"
                cols="30"
                rows="2"
                name="title"
                class="textarea"
              />
            </div>
            <p v-show="errors.has('title')" class="help is-danger">
              Εισάγετε τίτλο
            </p>
          </div>

          <div class="field">
            <label class="label">Περιεχόμενο</label>
            <div class="control">
              <textarea
                v-validate="'required'"
                v-model="newContact.content"
                cols="30"
                rows="8"
                name="content"
                class="textarea"
              />
            </div>
            <p v-show="errors.has('content')" class="help is-danger">
              Εισάγετε περιεχόμενο
            </p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>
  </ToggleableCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditItem from '@/views/components/EditItem';
import ToggleableCard from '@/views/components/ToggleableCard';

export default {
  components: {
    ToggleableCard,
  },
  extends: EditItem,
  props: {
    customer: Object,
  },
  data() {
    return {
      newContact: {
        title: '',
        content: '',
        type_id: null,
      },
      isSaving: false,
    };
  },
  computed: {
    ...mapGetters({
      contactTypes: 'getContactTypes',
    }),
  },
  methods: {
    ...mapActions({
      addContact: 'contacts/add/addContact',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addContact({
          ...this.newContact,
          customer_id: this.customer.id,
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η επικοινωνία καταγράφηκε',
        });

        this.closePanel();
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    closePanel() {
      this.reset();
    },
    reset() {
      this.newContact = {
        title: '',
        content: '',
        type_id: null,
      };

      this.$validator.reset();
    },
  },
};
</script>
