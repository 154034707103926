<template>
  <div>
    <div class="card hidden-print">
      <div class="card-content has-text-centered">
        <RouterLink
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'customers.edit', params: { uuid: customer.uuid } }"
          custom
          class="title is-5 is-spaced"
        >
          <h5 :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">{{
              customer.fullName
            }}</a>
          </h5>
        </RouterLink>

        <div>
          <h3 class="sum title is-3 is-marginless">
            {{ customer.sum_products }} &euro;
          </h3>
          <h6 class="sum title is-6 is-marginless">
            {{ customer.sum_total }} &euro;
          </h6>
          <p>
            Σε {{ customer.count }}
            {{ customer.count === 1 ? 'παραγγελία' : 'παραγγελίες' }}
          </p>
          <template v-if="hasOrders">
            <hr />
            <p>Τελευταία Παραγγελία</p>
            <h6 class="title is-6 is-marginless">{{ dateTime }}</h6>
          </template>
          <template v-if="hasLoyalty">
            <hr />
            <p>
              Loyalty Tier:
              <strong>{{ customer.loyalty.plan | capitalize }}</strong>
            </p>
            <h6 class="title is-6 is-marginless">
              Ενεργοί Πόντοι: {{ customer.loyalty.active_points }}
            </h6>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { last, get, size, isEmpty } from 'lodash';
import request from '@/utils/request';
import EditItem from '@/views/components/EditItem';

export default {
  extends: EditItem,
  props: {
    customer: {
      type: Object,
      default() {
        return null;
      },
    },
    customerType: String,
  },
  data() {
    return {
      notes: this.customer.notes,
    };
  },
  computed: {
    hasOrders() {
      return this.customerType === 'guest' || !this.customer
        ? false
        : size(this.customer.orders) > 0;
    },

    hasNotes() {
      return isEmpty(this.notes);
    },

    dateTime() {
      if (!this.hasOrders) {
        return null;
      }

      const lastOrder = last(this.customer.orders);

      return this.$options.filters.timestamp(lastOrder.created_at);
    },

    hasLoyalty() {
      return !isEmpty(this.customer.loyalty);
    },
  },

  methods: {
    async submit() {
      try {
        this.isSaving = true;
        const { data } = await request.put(
          `/customers/${this.customer.uuid}/notes`,
          {
            notes: this.notes,
          },
        );
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Τα σχόλια σας για τον πελάτη αποθηκεύτηκαν',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.notes = get(this.customer, 'notes');
    },
  },
};
</script>

<style scoped>
.sum {
  margin: 0.25rem auto !important;
}

.is-spaced {
  margin-bottom: 0.5rem;
}

@media print {
  .no-print {
    display: none;
  }
}
</style>
