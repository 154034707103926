<template>
  <span v-tooltip="details.title" class="pd-badge has-text-centered">
    {{ details.label }}
  </span>
</template>

<script>
export default {
  computed: {
    details() {
      return {
        label: 'PD',
        title: 'Periodic Discount',
        class: 'is-set',
      };
    },
  },
};
</script>

<style scoped lang="scss">
.pd-badge {
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  font-size: 1rem;
  line-height: 20px;
  height: 20px;
  width: 20px;
  background-color: #f80166;
}
</style>
