import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async addNote({ commit, rootGetters: { getUser, getOrder } }, { note }) {
    try {
      await request.post(`/orders/${getOrder.id}/orderNotes`, {
        user_id: getUser.id,
        comment: note,
      });

      const { data } = await request.get(`/orders/${getOrder.id}/orderNotes`);

      await commit('updateNotes', { notes: data.orderNotes });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
