import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getOrderMethods({ commit }) {
    try {
      const { data } = await request.get('/orderMethods');

      await commit('setOrderMethods', {
        orderMethods: data.orderMethods,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
