<template>
  <tr class="set">
    <td class="has-text-centered">
      {{ index + 1 }}
    </td>
    <td class="has-text-centered">
      <ProductPhoto :type="item.type" :photos="item.photos" />
    </td>
    <td class="has-text-centered">
      <p v-for="(barcode, index) in item.barcodes" :key="index">
        {{ barcode.code }}
      </p>
    </td>
    <td class="has-text-centered">
      {{ item.common_id }}
    </td>
    <td class="has-text-centered" style="maxWidth: 250px">
      <router-link :to="{ name: 'products.edit', params: { uuid: item.uuid } }">
        {{ item.title }}
      </router-link>
    </td>
    <td class="has-text-centered">
      {{ item.sell_price }}
    </td>
    <td class="has-text-centered">
      {{ item.discount }}
    </td>
    <td class="has-text-centered">
      {{ item.final_price }}
    </td>
    <td class="has-text-centered">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        disabled
      />
    </td>
    <td class="has-text-centered">{{ item.weight }}</td>
    <td class="has-text-centered">
      <router-link
        :to="{ name: 'products.edit', params: { uuid: item.uuid } }"
        class="button is-small"
      >
        <span class="icon is-small">
          <span class="fa fa-fw fa-pencil" />
        </span>
      </router-link>
    </td>
  </tr>
</template>

<script>
import ProductPhoto from '@/views/components/ProductPhoto';

export default {
  components: {
    ProductPhoto,
  },
  props: {
    index: Number,
    item: Object,
  },
  computed: {
    count() {
      return this.item.subsets.length;
    },
  },
};
</script>

<style scoped>
.table:not(:last-of-type) {
  margin-bottom: 1.5rem;
}
</style>
