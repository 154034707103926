/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setCancelOrderReason(state, { cancelOrderReason }) {
    state.data = cancelOrderReason;
  },

  updateCancelOrderReason(state, { cancelOrderReason }) {
    state.data = {
      ...state.data,
      ...cancelOrderReason,
    };
  },
};

export default mutations;
