<script>
import moment from 'moment';
import { debounce, isObject, get, startsWith } from 'lodash';
import Datepicker from 'vuejs-datepicker';

import request from '@/utils/request';
import XLSExport from '@/utils/XLSExport';
import terms from '@/constants/terms';
import LoadingContainer from '@/views/components/LoadingContainer';
import Filters from '@/views/components/Filters';
import Confirmation from '../Confirmation';
import RestoreConfirmation from '../RestoreConfirmation';
import SortableHeader from './components/SortableHeader';
import Pagination from './components/Pagination';
import NewPagination from './components/NewPagination';

const prepareItemsToExport = (items, selections, fields) =>
  items
    .filter(({ id }) => selections.includes(id))
    .map(item => {
      const obj = {};

      fields.forEach(field => {
        if (typeof field === 'string') {
          obj[terms[field] || field] = terms[item[field]] || item[field];
        } else {
          obj[terms[field.key] || field.key] = get(item, field.value);
        }
      });

      return obj;
    });

export default {
  components: {
    Confirmation,
    RestoreConfirmation,
    Datepicker,
    Pagination,
    NewPagination,
    Filters,
    SortableHeader,
    LoadingContainer,
  },
  data() {
    return {
      selectedItem: {},
      currentPage: 1,
      pagesCount: 1,
      itemsPerPage: 50,
      indexStartsFrom: 1,
      total: 0,
      isOpen: false,
      isRestoreModalOpen: false,
      sortOptions: {},
      isLoading: false,
      isUpdating: false,
      isFiltering: false,
      selectedAll: false,
      selections: [],
    };
  },
  computed: {
    hasSelections() {
      return this.selections.length > 0;
    },

    selectionsUuid() {
      return this.items
        .filter(({ id }) => this.selections.includes(id))
        .map(element => element.uuid);
    },
  },
  watch: {
    filtersOptions: {
      handler(newVal) {
        this.filters = Object.keys(newVal).reduce((acc, key) => {
          const value = newVal[key];
          if (value !== null && value !== '') {
            return {
              ...acc,
              [key]: value,
            };
          }

          return acc;
        }, {});
      },
      deep: true,
    },

    updatesOptions: {
      handler(newVal) {
        this.updates = Object.keys(newVal).reduce((acc, key) => {
          const value = newVal[key];
          if (value !== null && value !== '') {
            return {
              ...acc,
              [key]: value,
            };
          }

          return acc;
        }, {});
      },
      deep: true,
    },

    sortOptions() {
      this.search();
    },

    filters() {
      this.search();
    },
  },

  methods: {
    // eslint-disable-next-line func-names
    search: debounce(function() {
      this.navigate({
        page: 1,
        sort: this.sortOptions,
      });
    }, 500),

    filterParams(params) {
      return Object.entries(params).reduce((acc, [key, value]) => {
        if (
          ![
            'page',
            'size',
            'filters',
            'sort',
            'paginated',
            'timestamp',
            'state',
          ].includes(key)
        ) {
          return acc;
        }

        /* eslint-disable no-nested-ternary */
        return {
          ...acc,
          [key]: isObject(value) ? value : parseInt(value, 10),
          state: params.state,
        };
      }, {});
    },

    prepareFilters(filters) {
      return Object.keys(filters).reduce((acc, option) => {
        const value = filters[option];

        let newValue = value;

        if (value instanceof Object) {
          newValue = value.id;
        }

        if (startsWith(option, 'date')) {
          newValue = moment(value).format('DD-MM-YYYY');
        }

        return {
          ...acc,
          [option]: newValue,
        };
      }, {});
    },

    askToDeleteItem(selectedItem) {
      this.isOpen = !this.isOpen;
      this.selectedItem = selectedItem;
    },

    askToRestoreItem(selectedItem) {
      this.isRestoreModalOpen = !this.isRestoreModalOpen;
      this.selectedItem = selectedItem;
    },

    // eslint-disable-next-line func-names
    sort(event) {
      const { attribute, direction } = event;
      this.sortOptions = {
        attribute,
        direction,
      };
    },

    selectAll(newVal) {
      if (newVal === true) {
        this.selections = this.items.map(({ id }) => id);
      } else {
        this.selections = [];
      }
    },

    toggleSelection(event) {
      const { item, selected } = event;

      if (selected) {
        this.selections = [...this.selections, item.id];
        if (this.selections.length === this.items.length) {
          this.selectedAll = true;
        }
      } else {
        this.selections = this.selections.filter(id => id !== item.id);
        this.selectedAll = false;
      }
    },

    clearSelections() {
      this.selections = [];
      this.selectedAll = false;
      this.selectedItem = {};
    },

    async print() {
      try {
        const data = { orders: this.selectionsUuid };
        await request.put(`/orders/print`, data);

        const updatedOrders = this.items.map(item => {
          if (this.selectionsUuid.includes(item.uuid)) {
            return {
              ...item,
              is_printed: 1,
            };
          }

          return item;
        });

        const payload = {
          data: updatedOrders,
          current_page: this.paginationParams.page,
          per_page: this.paginationParams.size,
          total: this.paginationParams.count,
          from: this.paginationParams.indexStartsFrom,
        };

        this.setData(payload);

        window.print();

        this.$notify({
          type: 'success',
          title: 'Επιτυχής Καταχώρηση',
          text: 'Οι επιλεγμένες παραγγελίες καταχωρήθηκαν ως εκτυπωμένες',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία Καταχώρησης',
          text:
            'To αίτημα καταχώρησης των επιλεγμένων παραγγελιών ως εκτυπωμένες απέτυχε',
        });
      }
    },

    exportXLS(selections, fields, name) {
      const data = prepareItemsToExport(this.items, selections, fields);
      const title = `${name}_${moment.now()}.xls`;
      const xls = new XLSExport(data, title);

      xls.exportToXLS(title);
    },

    exportCSV(selections, fields, name) {
      const data = prepareItemsToExport(this.items, selections, fields);
      const title = `${name}_${moment.now()}.csv`;
      const xls = new XLSExport(data, title);

      xls.exportToCSV(title);
    },
  },
};
</script>
