import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getGeneral({ dispatch, commit }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get('/general');

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setGeneral', {
        general: data.general,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getGeneralPhotos({ dispatch, commit }) {
    try {
      const { data } = await request.get('/general/photos');

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateGeneral', {
        general: {
          photos: data.photos,
        },
      });
    } catch (err) {
      throw err;
    }
  },

  async updateGeneral({ dispatch, commit }, { general }) {
    try {
      const response1 = await request.put('/general', general);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get('/general');

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateGeneral', {
        general: response2.data.general,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
