<template>
  <CustomersEdit v-bind="$props">
    <form @submit.prevent="submit">
      <div class="card-content">
        <slot />

        <div class="columns">
          <div class="column is-full">
            <div class="field">
              <label class="label">Διαγωνισμός</label>
              <div class="control">
                <input
                  :value="participation.contest"
                  disabled
                  class="input"
                  type="text"
                  name="title"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Όνομα</label>
              <div class="control">
                <input
                  :value="participation.firstName"
                  disabled
                  class="input"
                  type="text"
                  name="firstName"
                />
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Επώνυμο</label>
              <div class="control">
                <input
                  :value="participation.lastName"
                  disabled
                  class="input"
                  type="text"
                  name="lastName"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input
                  :value="participation.email"
                  disabled
                  class="input"
                  type="email"
                  name="email"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Φύλο</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select :value="participation.gender" disabled name="gender">
                    <option :value="null">Επιλέξτε Φύλο</option>
                    <option value="male">Άνδρας</option>
                    <option value="female">Γυναίκα</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Ημ/νια Γένησης</label>
              <div class="control">
                <input
                  :value="participation.birthday"
                  disabled
                  class="input"
                  type="text"
                  name="birthday"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Κινητό</label>
              <div class="control">
                <vue-tel-input
                  v-model="participation.mobile"
                  enabled-country-code
                  placeholder=""
                  name="mobile"
                  disabled
                />
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Σταθερό</label>
              <div class="control">
                <vue-tel-input
                  v-model="participation.telephone"
                  enabled-country-code
                  placeholder=""
                  name="telephone"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </CustomersEdit>
</template>

<script>
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import EditItem from '@/views/components/EditItem';
import CustomersEdit from '../CustomersEdit';

export default {
  name: 'CustomersEditContest',
  components: {
    CustomersEdit,
  },
  extends: EditItem,
  props: {
    customer: Object,
    tabs: Array,
    activeTab: String,
  },
  data() {
    return {
      participation: {},
    };
  },
  computed: {
    isDeleted() {
      return this.customer.deleted_at !== null;
    },
  },
  created() {
    this.participation = _pick(this.customer.participation, [
      'firstName',
      'lastName',
      'email',
      'gender',
      'birthday',
      'telephone',
      'mobile',
    ]);
    this.participation.contest = _get(
      this.customer,
      'participation.contest.title',
      '',
    );
  },
};
</script>

<style scoped lang="scss">
::v-deep .vue-tel-input {
  border-radius: 2px;

  .dropdown {
    padding: 6px;
    &:focus {
      outline: none;
    }
  }

  &.disabled {
    background-color: whitesmoke;
    border-color: #dbdbdb;

    &:focus-within {
      box-shadow: none;
      outline: none;
    }

    & input {
      background-color: transparent;
      color: #7a7a7a;
    }

    & .dropdown,
    & .selection,
    & input {
      cursor: not-allowed;
    }
  }

  input {
    font-size: 1rem;
  }
}
</style>
