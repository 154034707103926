import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getTax({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/taxes/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setTax', {
        tax: data.tax,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async updateTax({ dispatch, commit }, { uuid, tax }) {
    try {
      const { data } = await request.put(`/taxes/${uuid}`, tax);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateTax', {
        tax,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
