import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getSettings({ commit }) {
    try {
      await commit('updateIsLoadingSettings', { isLoading: true });

      const { data } = await request.get('/settings');

      await commit('setAvailabilities', {
        availabilities: data.availabilities,
      });

      await commit('checkoutMethods/list/setCheckoutMethods', {
        checkoutMethods: data.checkoutMethods,
      });

      await commit('setEmailTemplates', {
        emailTemplates: data.emailTemplates,
      });

      await commit('general/edit/setGeneral', {
        general: data.general,
      });

      await commit('setOrderMethods', {
        orderMethods: data.orderMethods,
      });

      await commit('setRegistrationMethods', {
        registrationMethods: data.registrationMethods,
      });

      await commit('setSendMethods', {
        sendMethods: data.sendMethods,
      });

      await commit('setSendCharges', {
        sendCharges: data.sendCharges,
      });

      await commit('setStatuses', {
        statuses: data.statuses,
      });

      await commit('taxes/list/setTaxes', {
        taxes: data.taxes,
      });

      await commit('setUsers', {
        users: data.users,
      });

      await commit('cancelOrderReasons/list/setCancelOrderReasons', {
        cancelOrderReasons: data.cancelOrderReasons,
      });

      await commit('updateIsLoadingSettings', { isLoading: false });
    } catch (err) {
      console.log(err);
      await commit('updateIsLoadingSettings', { isLoading: false });

      throw err;
    }
  },

  async getErpStatus({ commit }) {
    try {
      const { data } = await request.get('/erp-status', {
        handlerEnabled: false,
      });

      await commit('setERPStatus', data.status);
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
