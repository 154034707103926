<template>
  <tr>
    <td class="has-text-centered">{{ reason.id }}</td>
    <td class="has-text-centered">
      {{ reason.title }}
    </td>
    <td class="has-text-centered">
      <router-link
        :to="{
          name: 'settings.cancelOrderReasons.edit',
          params: { uuid: reason.uuid },
        }"
        class="button is-small"
      >
        <span class="icon is-small">
          <span class="fa fa-fw fa-pencil" />
        </span>
      </router-link>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    reason: Object,
  },
};
</script>
