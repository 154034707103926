<template>
  <LoadingContainer :is-loading="isLoadingBrands && !isLoaded">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Μάρκες
            <span v-if="paginationParams.count > 0" class="tag">{{
              paginationParams.count
            }}</span>
          </div>
        </div>
        <div class="card-header-icon hidden-print">
          <div class="control">
            <button class="button" type="button" @click="handleERPSync">
              <span class="icon is-small">
                <i class="fa fa-cloud-download" />
              </span>
              <span>ERP Sync</span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-content">
        <Filters
          :options="options"
          :is-loading="isFiltering"
          :filters="paginationParams.filters"
          general-label="Αναζήτηση με χρήση common id, τίτλου"
          @navigate="navigate"
        />

        <NewPagination
          :items="items"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column is-full">
            <LoadingContainer
              :is-loading="isFiltering && isLoaded"
              :is-opaque="true"
              class="table-wrapper"
            >
              <table
                v-if="items.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Φώτο</th>
                    <th>Common ID</th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Τίτλος"
                        attribute="title"
                        @sort="sort"
                      />
                    </th>
                    <th>Σειρές</th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Εμφανές"
                        attribute="active"
                        @sort="sort"
                      />
                    </th>
                    <th>Ενέργειες</th>
                  </tr>
                </thead>
                <tbody>
                  <brand
                    v-for="(item, index) in items"
                    :item="item"
                    :index="paginationParams.indexStartsFrom + index"
                    :key="item.id"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν μάρκες
              </h4>
            </LoadingContainer>
          </div>
        </div>

        <NewPagination
          v-if="items.length"
          :items="items"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />
      </div>
    </div>

    <SyncModal :is-open="isSyncing" />
  </LoadingContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SyncModal from '@/views/components/SyncModal';
import PaginatedList from '@/views/components/PaginatedList';
import Brand from './components/Brand';

export default {
  components: {
    Brand,
    SyncModal,
  },
  extends: PaginatedList,
  data() {
    return {
      isSyncing: false,
      options: [
        {
          label: 'Γενική Αναζήτηση',
          title: 'general',
          type: 'string',
          value: null,
        },
        {
          label: 'Εμφανής',
          title: 'active',
          type: 'boolean',
          value: null,
        },
        {
          label: 'Έχει Φωτογραφία Avatar',
          title: 'has_avatar',
          type: 'boolean',
          value: null,
        },
        {
          label: 'Έχει Collections',
          title: 'has_collections',
          type: 'boolean',
          value: null,
        },
        {
          label: 'Έχει Προιόντα',
          title: 'has_products',
          type: 'boolean',
          value: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      items: 'brands/list/getData',
      paginationParams: 'brands/list/getPaginationParams',
      isLoadingBrands: 'brands/list/getIsLoading',
      isLoaded: 'brands/list/getIsLoaded',
    }),
  },
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.getBrands(newVal.query);
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router.replace({
              name: this.$route.name,
              query: this.filterParams({
                page: 1,
                size: 50,
                filters: {},
                sort: {},
                paginated: 1,
              }),
            });
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getBrands: 'brands/list/getBrands',
      updateBrandActiveStatus: 'brands/list/updateBrandActiveStatus',
      checkForBrandsAdditions: 'brands/list/checkForBrandsAdditions',
    }),
    async navigate(params = {}) {
      try {
        this.isFiltering = true;
        this.$router.push({
          name: this.$route.name,
          query: this.filterParams({ ...this.paginationParams, ...params }),
        });
        this.isFiltering = false;
      } catch (err) {
        this.isFiltering = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    async handleERPSync() {
      try {
        this.isSyncing = true;
        await this.checkForBrandsAdditions();

        await this.navigate({
          page: 1,
          size: 50,
          filters: {},
          sort: {},
          paginated: 1,
        });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η ενημέρωση εκτελέστηκε επιτυχώς!!',
        });
        this.isSyncing = false;
      } catch (err) {
        this.isSyncing = false;

        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
