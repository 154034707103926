<template>
  <gravatar
    :email="email"
    :alt="alt || email"
    default-img="mm"
    class="avatar"
  />
</template>

<script>
import Gravatar from 'vue-gravatar';

export default {
  components: {
    Gravatar,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  border-radius: 50%;
  box-sizing: content-box;
  display: inline-block;
}
</style>
