/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setOrder(state, { order }) {
    state.data = order;
  },

  updateOrder(state, { order }) {
    state.data = {
      ...state.data,
      ...order,
    };
  },

  updateNotes(state, { notes }) {
    state.data = {
      ...state.data,
      notes,
    };
  },

  updateStatuses(state, { statuses }) {
    state.data = {
      ...state.data,
      statuses,
    };
  },

  updateTransaction(state, { transaction }) {
    state.data = {
      ...state.data,
      transaction,
    };
  },

  updateOrderAddress(state, payload) {
    state.data = {
      ...state.data,
      ...payload,
    };
  },

  updateOrderInvoice(state, { invoice }) {
    state.data = {
      ...state.data,
      invoice: {
        ...state.data.invoice,
        ...invoice,
      },
    };
  },

  updateOrderProducts(state, { products, coupon, send_charge, statuses }) {
    state.data = {
      ...state.data,
      products,
      coupon,
      send_charge,
      statuses,
      is_printed: 0,
    };
  },

  updateOrderCustomer(state, { customer }) {
    state.data = {
      ...state.data,
      customer: {
        ...state.data.customer,
        ...customer,
      },
    };
  },

  updateOrderGift(state, { gift }) {
    state.data = {
      ...state.data,
      ...gift,
    };
  },
};

export default mutations;
