<template>
  <div>
    <div class="tabs hidden-print">
      <ul v-sticky sticky-offset="offset">
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'marketing.bundles.list.active' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @click="navigate" @keypress.enter="navigate">
              Εμφανή Bundles
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'marketing.bundles.list.inactive' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @click="navigate" @keypress.enter="navigate">
              Μη Εμφανή Bundles
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'marketing.bundles.list.all' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @click="navigate" @keypress.enter="navigate">
              Όλα τα Bundles
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'marketing.bundles.list.deleted' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @click="navigate" @keypress.enter="navigate">
              Απενεργοποιημένα Bundles
            </a>
          </li>
        </router-link>
      </ul>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  data: () => ({
    offset: { top: 45 },
  }),
};
</script>
