<template>
  <div class="customer-tab">
    <table
      v-if="customer.invoices.length"
      class="table is-bordered is-striped is-marginless is-fullwidth"
    >
      <thead>
        <tr>
          <th>Τύπος</th>
          <th>ΑΦΜ</th>
          <th>ΔΟΥ</th>
          <th>Τ. Κώδικας</th>
          <th>Νομός</th>
          <th>Πόλη</th>
          <th>Οδός</th>
          <th>Κινητό</th>
          <th class="hidden-print">Ενέργειες</th>
        </tr>
      </thead>
      <tbody>
        <item
          v-for="(item, index) in customer.invoices"
          :item="item"
          :index="index"
          :key="item.id"
          @selectItem="selectItem"
        />
      </tbody>
    </table>
    <h5 v-else class="title is-5 has-text-centered is-marginless">
      Δε βρέθηκαν τιμολόγια
    </h5>

    <invoice-edit
      :is-open="isOpen"
      :customer="customer"
      :invoice="selectedItem"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import transformations from '@/utils/transformations';
import Item from './components/Invoice';
import InvoiceEdit from './components/InvoiceEdit';

export default {
  components: {
    Item,
    InvoiceEdit,
  },
  data() {
    return {
      isOpen: false,
      selectedItem: {},
    };
  },
  computed: {
    ...mapGetters({
      customer: 'customers/edit/getCustomer',
    }),
  },
  methods: {
    selectItem(item) {
      this.selectedItem = transformations.capitalizeAddress(item);
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },
  },
};
</script>
