<script>
import LazyLoad from '@/views/components/LazyLoad';

export default {
  name: 'PaginatedListItem',
  components: {
    LazyLoad,
  },
  props: {
    item: Object,
    index: Number,
    selections: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selected: {
      get() {
        return this.selections.includes(this.item.id);
      },
      set(newValue) {
        this.$emit('toggleSelection', {
          item: this.item,
          selected: newValue,
        });
      },
    },
    isDeleted() {
      return this.item.deleted_at !== null;
    },
  },
  methods: {
    onDelete() {
      this.$emit('askToDeleteItem', this.item);
    },
    onRestore() {
      this.$emit('askToRestoreItem', this.item);
    },
    onChange() {
      this.$emit('changeItemActive', this.item);
    },
  },
};
</script>

<style lang="scss">
.checkbox-component > input:hover {
  cursor: pointer;
}

.checkbox-component > input + label > .input-box {
  border-color: #363636 !important;
  border-radius: 0 !important;
}

.checkbox-component > input + label > .input-box > .input-box-tick {
  margin-bottom: 5px;
}

.checkbox-component > input + label > .input-box > .input-box-tick > path {
  stroke: #22a684 !important;
}

.table tr.is-selected {
  background-color: #dddddd;
  color: initial;
}

img[lazy='loading'] {
  background-color: #eeeeee;
  width: 50px;
  height: 50px;
}

.table {
  img {
    display: inline-block;
    height: auto;
    max-height: 50px;
    max-width: 50px;
    width: 100%;
  }

  .method-icon {
    border: 1px solid #dddddd;
    height: auto;
    width: 30px;
  }
}
</style>
