<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Τρόποι Πληρωμής
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="table-wrapper">
        <table class="table is-bordered is-striped is-marginless is-fullwidth">
          <thead>
            <tr>
              <th>#</th>
              <th>Τίτλος</th>
              <th>Εικονίδιο</th>
              <th>Επιβάρυνση (&euro;)</th>
              <th>Ενεργός</th>
              <th>Ενέργειες</th>
            </tr>
          </thead>
          <tbody>
            <checkout-method
              v-for="method in checkoutMethods"
              :method="method"
              :key="method.id"
              @updateStatus="updateStatus"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CheckoutMethod from './components/CheckoutMethod';

export default {
  components: {
    CheckoutMethod,
  },
  data() {
    return {
      isSaving: false,
    };
  },
  computed: {
    ...mapGetters({
      checkoutMethods: 'checkoutMethods/list/getCheckoutMethods',
    }),
  },
  async created() {
    try {
      await this.getCheckoutMethods();
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    ...mapActions({
      getCheckoutMethods: 'checkoutMethods/list/getCheckoutMethods',
      toggleCheckoutMethodStatus:
        'checkoutMethods/list/toggleCheckoutMethodStatus',
    }),
    async updateStatus(selectedMethod) {
      try {
        const isActive = !selectedMethod.active;

        await this.toggleCheckoutMethodStatus({
          uuid: selectedMethod.uuid,
          active: isActive,
        });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: isActive
            ? 'Η μέθοδος ενεργοποιήθηκε επιτυχώς!!'
            : 'Η μέθοδος δεν είναι ενεργή πλέον',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
