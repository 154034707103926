<template>
  <tr>
    <td class="has-text-centered">
      <UserType :item="item" relationship="orderable_type" />
    </td>
    <td class="has-text-centered">
      <router-link :to="{ name: 'gyOrders.show', params: { uuid: item.uuid } }">
        {{ item.common_id }}
      </router-link>
    </td>
    <td class="has-text-centered">
      {{ item.created_at | timestamp }}
    </td>
    <td class="has-text-centered">
      -
    </td>
    <td class="has-text-centered hidden-print">
      <img
        v-tooltip="{ content: item.checkout_method.title }"
        v-if="item.checkout_method"
        :src="item.checkout_method.icon"
        class="method-icon"
      />
      <template v-else>-</template>
    </td>
    <td class="has-text-centered visible-print">
      {{ item.checkout_method.title }}
    </td>
    <td class="has-text-centered hidden-print">
      <img
        v-tooltip="{ content: item.send_method.title }"
        v-if="item.send_method"
        :src="item.send_method.icon"
        class="method-icon"
      />
      <template v-else>-</template>
    </td>
    <td class="has-text-centered visible-print">
      <template v-if="item.send_method">{{ item.send_method.title }}</template>
    </td>
    <td class="has-text-centered hidden-print">
      <img
        v-tooltip="{ content: item.send_method.title }"
        v-if="item.send_method"
        :src="item.order_method.icon"
        class="method-icon"
      />
      <template v-else>-</template>
    </td>
    <td class="has-text-centered visible-print">
      <template v-if="item.send_method">{{ item.send_method.title }}</template>
      <template v-else>-</template>
    </td>
    <td class="has-text-centered">{{ item.count }}</td>
    <td class="has-text-centered">{{ item.sum_total }}</td>
    <td class="has-text-centered hidden-print">
      <div class="status">
        -
      </div>
    </td>
    <td class="has-text-centered hidden-print">
      <router-link
        :to="{ name: 'gyOrders.show', params: { uuid: item.uuid } }"
        class="button is-small"
      >
        <span class="icon is-small">
          <span class="fa fa-fw fa-pencil" />
        </span>
      </router-link>
    </td>
  </tr>
</template>

<script>
import UserType from '@/views/components/UserType';

export default {
  name: 'Order',
  components: {
    UserType,
  },
  props: {
    item: Object,
  },
};
</script>

<style scoped lang="scss">
.tag {
  width: 100%;

  &.is-white {
    border: 1px solid #dbdbdb;
  }
}

tr {
  td:first-child {
    border-left: 3px solid #d4d4d4 !important;
  }

  &.is-paid {
    td:first-child {
      border-left: 3px solid #22a684 !important;
    }
  }
}

.status {
  position: relative;
}

.icon--print {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
  font-size: 0.85rem;
  position: absolute;
  right: -8px;
  top: -10px;
}
</style>
