<template>
  <LoadingContainer :is-loading="isLoadingItems">
    <div class="columns">
      <div class="column is-three-quarters">
        <form novalidate @submit.prevent="submit">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Μάρκα
                  <router-link
                    :to="{
                      name: 'products.brands.edit',
                      params: { uuid: $route.params.uuid },
                    }"
                    class="tag"
                  >
                    {{ brand.title }}
                  </router-link>
                  Προσθήκη Σειράς
                </div>
              </div>
              <div class="card-header-icon">
                <div class="field">
                  <div class="control">
                    <router-link
                      :to="{
                        name: 'products.brands.collections.list',
                        params: { uuid: $route.params.uuid },
                      }"
                      class="button"
                    >
                      <span class="icon is-small">
                        <i class="fa fa-list-ul" />
                      </span>
                      <span>Λίστα</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content">
              <AddGeneralDetails
                :general="general"
                @updateGeneral="updateGeneral"
              />
            </div>
          </div>

          <div class="card">
            <div class="card-footer">
              <FormSubmit :is-saving="isSaving" @reset="reset" />
            </div>
          </div>
        </form>
      </div>
      <div class="column">
        <ExistingCollections :items="items" :brand="brand" />

        <div class="card">
          <div class="card-header">
            <div class="card-header-title">
              Dynamic Variables
            </div>
          </div>
          <div class="card-content">
            <strong>Available Variables</strong>
            <br />
            <ul>
              <li>[collection_name]</li>
              <li>[brand_name]</li>
              <li>[discount_percentage]</li>
              <li>[count_of_products]</li>
            </ul>

            <br />

            <strong>H1 (tag) (sample)</strong>
            <br />
            <p>
              [brand_name] [collection_name]
            </p>

            <br />

            <strong>Περιεχόμενο (p tag) (sample)</strong>
            <br />
            <p>
              Ανακάλυψε τα προϊόντα [brand_name] [collection_name] και απόκτησε
              αυτά που καλύπτουν ιδανικά τις ανάγκες σου, σε μοναδικές προσφορές
              έως -[discount_percentage]%. Άμεση παράδοση, μοναδική εξυπηρέτηση
              & τεράστια ποικιλία στις καλύτερες τιμές μόνο στο Carespot, το
              online φαρμακείο που σε φροντίζει!
            </p>

            <br />

            <strong>Meta Title (sample)</strong>
            <br />
            <p>
              [brand_name] [collection_name] Έως -[discount_percentage]%
            </p>

            <br />

            <strong>Meta Description (sample)</strong>
            <br />
            <p>
              Βρες τα προϊόντα [brand_name] [collection_name] σε προσφορές έως
              -[discount_percentage]%! Άμεση παράδοση μόνο στο Carespot, το
              online φαρμακείο που σε φροντίζει.
            </p>
          </div>
        </div>
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddItem from '@/views/components/AddItem';
import AddGeneralDetails from '@/views/components/AddGeneralDetails';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import ExistingCollections from '../../components/ExistingCollections';

export default {
  components: {
    AddGeneralDetails,
    ToggleableCard,
    LoadingContainer,
    ExistingCollections,
  },
  extends: AddItem,
  data() {
    return {
      general: {
        title: '',
        slug: '',
        description: '',
        content: '',
        active: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      brand: 'brands/edit/getBrand',
      items: 'collections/all/getCollections',
      isLoadingItems: 'collections/all/getIsLoading',
    }),
  },
  async created() {
    try {
      await Promise.all([
        this.getBrand({ uuid: this.$route.params.uuid }),
        this.getCollections({ uuid: this.$route.params.uuid }),
      ]);
    } catch (err) {
      this.$router.push('/error');
    }
  },
  methods: {
    ...mapActions({
      getBrand: 'brands/edit/getBrand',
      addCollection: 'collections/add/addCollection',
      getCollections: 'collections/all/getCollections',
    }),
    async submit() {
      try {
        const [isValid] = await Promise.all(
          this.$children.map(child => child.$validator.validateAll()),
        );

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addCollection({
          ...this.general,
          brand_id: this.brand.id,
        });
        this.isSaving = false;

        this.$router.push({
          name: 'products.brands.collections.list',
          params: { uuid: this.$route.params.uuid },
        });
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η σειρά αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = {
        title: '',
        slug: '',
        description: '',
        content: '',
        active: false,
      };

      this.$children.forEach(child => child.$validator.reset());
    },
    updateGeneral(general) {
      this.general = general;
    },
  },
};
</script>
