<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Σημειώσεις Διαχειριστή
        </div>
      </div>
    </div>
    <div v-if="hasNotes" class="card-content">
      <note v-for="note in order.notes" :key="note.id" :note="note" />
    </div>

    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="field">
          <div class="control">
            <textarea
              v-validate="'required'"
              v-model="newNote"
              cols="30"
              rows="2"
              name="newNote"
              class="textarea"
            />
            <div v-show="errors.has('newNote')" class="help is-danger">
              Εισάγετε σημείωση
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import EditItem from '@/views/components/EditItem';
import Note from './components/Note';

export default {
  components: {
    Note,
  },
  extends: EditItem,
  props: {
    order: Object,
  },
  data() {
    return {
      newNote: '',
    };
  },
  computed: {
    hasNotes() {
      return this.order.notes.length > 0;
    },
  },
  methods: {
    ...mapActions(['getOrder', 'addNote']),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addNote({
          note: this.newNote,
        });
        this.reset();
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η σημείωση αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.newNote = '';

      this.$validator.reset();
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  > .card-content {
    border-bottom: 1px solid #dbdbdb;
  }
}
</style>
