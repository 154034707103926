<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Τρόποι Εγγραφής
        </div>
      </div>
    </div>
    <div class="card-content">
      <table class="table is-bordered is-striped is-marginless is-fullwidth">
        <thead>
          <tr>
            <th>#</th>
            <th>Τίτλος</th>
          </tr>
        </thead>
        <tbody>
          <registration-method
            v-for="method in registrationMethods"
            :method="method"
            :key="method.id"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import RegistrationMethod from './components/RegistrationMethod';

export default {
  components: {
    RegistrationMethod,
  },
  data() {
    return {
      isSaving: false,
    };
  },
  computed: {
    ...mapGetters({
      registrationMethods: 'getRegistrationMethods',
    }),
  },
  async mounted() {
    await this.getRegistrationMethods();
  },
  methods: {
    ...mapActions(['getRegistrationMethods']),
  },
};
</script>
