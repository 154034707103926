<template>
  <div v-sticky sticky-offset="offset">
    <nav class="level">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading print__heading-type">Τυπος</p>
          <p class="title">
            <UserType
              :item="order"
              class="is-white"
              relationship="orderable_type"
            />
          </p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Common ID</p>
          <p class="title">{{ details.id }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Ημερομηνια</p>
          <p class="title">{{ details.order.created_at | timestamp }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Status</p>
          <p class="title">
            <span class="tag is-medium is-white">
              {{ details.order.state }}
            </span>
          </p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Κατασταση</p>
          <p class="title status">
            <span class="tag is-medium is-white">
              {{ details.state }}
            </span>
          </p>
        </div>
      </div>
    </nav>

    <div v-if="isDeleted" class="notification is-danger">
      {{ details.order.cancellation_message }}
    </div>
  </div>
</template>

<script>
import UserType from '@/views/components/UserType';

export default {
  components: {
    UserType,
  },

  props: {
    order: Object,
    isDeleted: Boolean,
  },

  data: () => ({
    offset: { top: 45 },
  }),

  computed: {
    details() {
      return this.order.details;
    },
  },
};
</script>

<style scoped>
.type.is-white {
  background-color: white;
  border-color: transparent;
}
.tag {
  width: 100%;
}

.status {
  position: relative;
}

.icon--print {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
  font-size: 0.85rem;
  position: absolute;
  right: -9px;
  top: -7px;
}
</style>
