import Vue from 'vue';
import Qs from 'qs';
import Router from 'vue-router';
import { routerHistory, writeHistory } from 'vue-router-back-button';

import * as middleware from '@/utils/middleware';
import Activate from '@/views/Activate';
import Login from '@/views/Login';
import Home from '@/views/Dashboard/views/Home';
import Versions from '@/views/Dashboard/views/Versions';
import ProductsCategories from '@/views/Dashboard/views/Products/views/views/Categories';
import ProductsCategoriesAdd from '@/views/Dashboard/views/Products/views/views/Categories/views/Add';
import ProductsCategoriesEdit from '@/views/Dashboard/views/Products/views/views/Categories/views/Edit';
import ProductsCategoriesEditProducts from '@/views/Dashboard/views/Products/views/views/Categories/views/EditProducts';
import ProductsCategoriesList from '@/views/Dashboard/views/Products/views/views/Categories/views/List';
import ProductsBrands from '@/views/Dashboard/views/Products/views/views/Brands';
import ProductsBrandsAdd from '@/views/Dashboard/views/Products/views/views/Brands/views/Add';
import ProductsBrandsEdit from '@/views/Dashboard/views/Products/views/views/Brands/views/Edit';
import ProductsBrandsEditProducts from '@/views/Dashboard/views/Products/views/views/Brands/views/EditProducts';
import ProductsBrandsList from '@/views/Dashboard/views/Products/views/views/Brands/views/List';
import ProductsBrandsCollections from '@/views/Dashboard/views/Products/views/views/Brands/views/Collections';
import ProductsBrandsCollectionsAdd from '@/views/Dashboard/views/Products/views/views/Brands/views/Collections/views/Add';
import ProductsBrandsCollectionsEdit from '@/views/Dashboard/views/Products/views/views/Brands/views/Collections/views/Edit';
import ProductsBrandsCollectionsList from '@/views/Dashboard/views/Products/views/views/Brands/views/Collections/views/List';
import ProductsBrandsCategoriesEdit from '@/views/Dashboard/views/Products/views/views/Brands/views/Categories/views/Edit';
import ProductsSymptoms from '@/views/Dashboard/views/Products/views/views/Symptoms';
import ProductsSymptomsAdd from '@/views/Dashboard/views/Products/views/views/Symptoms/views/Add';
import ProductsSymptomsEdit from '@/views/Dashboard/views/Products/views/views/Symptoms/views/Edit';
import ProductsSymptomsList from '@/views/Dashboard/views/Products/views/views/Symptoms/views/List';
import ProductsSizes from '@/views/Dashboard/views/Products/views/views/Sizes';
import ProductsSizesAdd from '@/views/Dashboard/views/Products/views/views/Sizes/views/Add';
import ProductsSizesEdit from '@/views/Dashboard/views/Products/views/views/Sizes/views/Edit';
import ProductsSizesList from '@/views/Dashboard/views/Products/views/views/Sizes/views/List';
import ProductsColors from '@/views/Dashboard/views/Products/views/views/Colors';
import ProductsColorsAdd from '@/views/Dashboard/views/Products/views/views/Colors/views/Add';
import ProductsColorsEdit from '@/views/Dashboard/views/Products/views/views/Colors/views/Edit';
import ProductsColorsList from '@/views/Dashboard/views/Products/views/views/Colors/views/List';
import ProductsFilterGroups from '@/views/Dashboard/views/Products/views/views/FilterGroups';
import ProductsFilterGroupsAdd from '@/views/Dashboard/views/Products/views/views/FilterGroups/views/Add';
import ProductsFilterGroupsEdit from '@/views/Dashboard/views/Products/views/views/FilterGroups/views/Edit';
import ProductsFilterGroupsList from '@/views/Dashboard/views/Products/views/views/FilterGroups/views/List';
import ProductsFilterGroupsFilters from '@/views/Dashboard/views/Products/views/views/FilterGroups/views/Filters';
import ProductsFilterGroupsFiltersAdd from '@/views/Dashboard/views/Products/views/views/FilterGroups/views/Filters/views/Add';
import ProductsFilterGroupsFiltersEdit from '@/views/Dashboard/views/Products/views/views/FilterGroups/views/Filters/views/Edit';
import ProductsFilterGroupsFiltersList from '@/views/Dashboard/views/Products/views/views/FilterGroups/views/Filters/views/List';
import ProductsList from '@/views/Dashboard/views/Products/views/List';
import ProductsListActive from '@/views/Dashboard/views/Products/views/List/views/ProductsListActive';
import ProductsListInactive from '@/views/Dashboard/views/Products/views/List/views/ProductsListInactive';
import ProductsListAll from '@/views/Dashboard/views/Products/views/List/views/ProductsListAll';
import ProductsListDeleted from '@/views/Dashboard/views/Products/views/List/views/ProductsListDeleted';
import ProductsAdd from '@/views/Dashboard/views/Products/views/Add';
import ProductsGroupsAdd from '@/views/Dashboard/views/Products/views/GroupsAdd';
import ProductsEdit from '@/views/Dashboard/views/Products/views/Edit';
import ProductsEditGR from '@/views/Dashboard/views/Products/views/Edit/views/ProductsEditGR';
import ProductsEditEN from '@/views/Dashboard/views/Products/views/Edit/views/ProductsEditEN';
import ProductsEditLoyalty from '@/views/Dashboard/views/Products/views/Edit/views/ProductsEditLoyalty';
import ProductsEditFamily from '@/views/Dashboard/views/Products/views/Edit/views/ProductsEditFamily';
import Banners from '@/views/Dashboard/views/Content/views/Banners';
import BannersList from '@/views/Dashboard/views/Content/views/Banners/views/List';
import BannersAdd from '@/views/Dashboard/views/Content/views/Banners/views/Add';
import BannersEdit from '@/views/Dashboard/views/Content/views/Banners/views/Edit';
import ComplaintsList from '@/views/Dashboard/views/Complaints/views/List';
import ComplaintsListAll from '@/views/Dashboard/views/Complaints/views/List/views/ComplaintsListAll';
import ComplaintsAdd from '@/views/Dashboard/views/Complaints/views/Add';
import ComplaintsEdit from '@/views/Dashboard/views/Complaints/views/Edit';
import CustomersList from '@/views/Dashboard/views/Customers/views/List';
import CustomersListFull from '@/views/Dashboard/views/Customers/views/List/views/CustomersListFull';
import CustomersListEmpty from '@/views/Dashboard/views/Customers/views/List/views/CustomersListEmpty';
import CustomersListGuests from '@/views/Dashboard/views/Customers/views/List/views/CustomersListGuests';
import CustomersListAll from '@/views/Dashboard/views/Customers/views/List/views/CustomersListAll';
import CustomersListDeleted from '@/views/Dashboard/views/Customers/views/List/views/CustomersListDeleted';
import CustomersAdd from '@/views/Dashboard/views/Customers/views/Add';
import CustomersEdit from '@/views/Dashboard/views/Customers/views/Edit';
import CustomersEditOrders from '@/views/Dashboard/views/Customers/views/Edit/views/CustomersEditOrders';
import CustomersEditGYOrders from '@/views/Dashboard/views/Customers/views/Edit/views/CustomersEditGYOrders';
import CustomersEditProducts from '@/views/Dashboard/views/Customers/views/Edit/views/CustomersEditProducts';
import CustomersEditFavorites from '@/views/Dashboard/views/Customers/views/Edit/views/CustomersEditFavorites';
import CustomersEditCoupons from '@/views/Dashboard/views/Customers/views/Edit/views/CustomersEditCoupons';
import CustomersEditIncomingEmails from '@/views/Dashboard/views/Customers/views/Edit/views/CustomersEditIncomingEmails';
import CustomersEditContact from '@/views/Dashboard/views/Customers/views/Edit/views/CustomersEditContact';
import CustomersEditQuestions from '@/views/Dashboard/views/Customers/views/Edit/views/CustomersEditQuestions';
import CustomersEditAddresses from '@/views/Dashboard/views/Customers/views/Edit/views/CustomersEditAddresses';
import CustomersEditLoyalty from '@/views/Dashboard/views/Customers/views/Edit/views/CustomersEditLoyalty';
import CustomersEditInvoices from '@/views/Dashboard/views/Customers/views/Edit/views/CustomersEditInvoices';
import CustomersEditContests from '@/views/Dashboard/views/Customers/views/Edit/views/CustomersEditContests';
import CustomersEditReviews from '@/views/Dashboard/views/Customers/views/Edit/views/CustomersEditReviews';
import Posts from '@/views/Dashboard/views/Content/views/Posts';
import PostsList from '@/views/Dashboard/views/Content/views/Posts/views/List';
import PostsAdd from '@/views/Dashboard/views/Content/views/Posts/views/Add';
import PostsEdit from '@/views/Dashboard/views/Content/views/Posts/views/Edit';
import Authors from '@/views/Dashboard/views/Content/views/Authors';
import AuthorsList from '@/views/Dashboard/views/Content/views/Authors/views/List';
import AuthorsAdd from '@/views/Dashboard/views/Content/views/Authors/views/Add';
import AuthorsEdit from '@/views/Dashboard/views/Content/views/Authors/views/Edit';
import PostCategories from '@/views/Dashboard/views/Content/views/PostCategories';
import PostCategoriesList from '@/views/Dashboard/views/Content/views/PostCategories/views/List';
import PostCategoriesAdd from '@/views/Dashboard/views/Content/views/PostCategories/views/Add';
import PostCategoriesEdit from '@/views/Dashboard/views/Content/views/PostCategories/views/Edit';
import Pages from '@/views/Dashboard/views/Content/views/Pages';
import PagesList from '@/views/Dashboard/views/Content/views/Pages/views/List';
import PagesAdd from '@/views/Dashboard/views/Content/views/Pages/views/Add';
import PagesEdit from '@/views/Dashboard/views/Content/views/Pages/views/Edit';
import Videos from '@/views/Dashboard/views/Content/views/Videos';
import VideosList from '@/views/Dashboard/views/Content/views/Videos/views/List';
import VideosAdd from '@/views/Dashboard/views/Content/views/Videos/views/Add';
import VideosEdit from '@/views/Dashboard/views/Content/views/Videos/views/Edit';
import Tags from '@/views/Dashboard/views/Content/views/Tags';
import TagsList from '@/views/Dashboard/views/Content/views/Tags/views/List';
import TagsAdd from '@/views/Dashboard/views/Content/views/Tags/views/Add';
import TagsEdit from '@/views/Dashboard/views/Content/views/Tags/views/Edit';
import Reviews from '@/views/Dashboard/views/Content/views/Reviews';
import ReviewsList from '@/views/Dashboard/views/Content/views/Reviews/views/List';
import Questions from '@/views/Dashboard/views/Content/views/Questions';
import QuestionsList from '@/views/Dashboard/views/Content/views/Questions/views/List';
import QuestionsAdd from '@/views/Dashboard/views/Content/views/Questions/views/Add';
import QuestionsEdit from '@/views/Dashboard/views/Content/views/Questions/views/Edit';
import Faqs from '@/views/Dashboard/views/Content/views/Faqs';
import FaqsList from '@/views/Dashboard/views/Content/views/Faqs/views/List';
import FaqsAdd from '@/views/Dashboard/views/Content/views/Faqs/views/Add';
import FaqsEdit from '@/views/Dashboard/views/Content/views/Faqs/views/Edit';
import GYOrdersShow from '@/views/Dashboard/views/GYOrders/GYOrdersShow';
import OrdersList from '@/views/Dashboard/views/Orders/views/List';
import OrdersListAll from '@/views/Dashboard/views/Orders/views/List/views/OrdersListAll';
import OrdersListPending from '@/views/Dashboard/views/Orders/views/List/views/OrdersListPending';
import OrdersSkroutzList from '@/views/Dashboard/views/Orders/views/List/views/OrdersSkroutzList';
import OrdersListSent from '@/views/Dashboard/views/Orders/views/List/views/OrdersListSent';
import OrdersListDeleted from '@/views/Dashboard/views/Orders/views/List/views/OrdersListDeleted';
import OrdersListWithdrawal from '@/views/Dashboard/views/Orders/views/List/views/OrdersListWithdrawal';
import OrdersListUnfinished from '@/views/Dashboard/views/Orders/views/List/views/OrdersListUnfinished';
import OrdersAdd from '@/views/Dashboard/views/Orders/views/Add';
import OrdersEdit from '@/views/Dashboard/views/Orders/views/Edit';
import OrdersSkroutzEdit from '@/views/Dashboard/views/Orders/views/EditSkroutz';
import StatsOrders from '@/views/Dashboard/views/Stats/views/StatsOrders';
import StatsProducts from '@/views/Dashboard/views/Stats/views/StatsProducts';
import StatsBrands from '@/views/Dashboard/views/Stats/views/StatsBrands';
import General from '@/views/Dashboard/views/Settings/views/General';
import SendCharges from '@/views/Dashboard/views/Settings/views/SendCharges';
import SendChargesGreece from '@/views/Dashboard/views/Settings/views/SendCharges/views/SendChargesGreece';
import SendChargesCyprus from '@/views/Dashboard/views/Settings/views/SendCharges/views/SendChargesCyprus';
import Taxes from '@/views/Dashboard/views/Settings/views/Taxes';
import TaxesList from '@/views/Dashboard/views/Settings/views/Taxes/views/List';
import TaxesAdd from '@/views/Dashboard/views/Settings/views/Taxes/views/Add';
import TaxesEdit from '@/views/Dashboard/views/Settings/views/Taxes/views/Edit';
import CheckoutMethods from '@/views/Dashboard/views/Settings/views/CheckoutMethods';
import CheckoutMethodsList from '@/views/Dashboard/views/Settings/views/CheckoutMethods/views/List';
import CheckoutMethodsEdit from '@/views/Dashboard/views/Settings/views/CheckoutMethods/views/Edit';
import SendMethods from '@/views/Dashboard/views/Settings/views/SendMethods';
import RegistrationMethods from '@/views/Dashboard/views/Settings/views/RegistrationMethods';
import CancelOrderReasons from '@/views/Dashboard/views/Settings/views/CancelOrderReasons';
import CancelOrderReasonsList from '@/views/Dashboard/views/Settings/views/CancelOrderReasons/views/List';
import CancelOrderReasonsEdit from '@/views/Dashboard/views/Settings/views/CancelOrderReasons/views/Edit';
import CancelOrderReasonsAdd from '@/views/Dashboard/views/Settings/views/CancelOrderReasons/views/Add';
import EmailTemplates from '@/views/Dashboard/views/Settings/views/EmailTemplates';
import EmailTemplatesAdd from '@/views/Dashboard/views/Settings/views/EmailTemplates/views/Add';
import EmailTemplatesEdit from '@/views/Dashboard/views/Settings/views/EmailTemplates/views/Edit';
import EmailTemplatesList from '@/views/Dashboard/views/Settings/views/EmailTemplates/views/List';
import UsersList from '@/views/Dashboard/views/Users/views/List';
import UsersAdd from '@/views/Dashboard/views/Users/views/Add';
import UsersEdit from '@/views/Dashboard/views/Users/views/Edit';
import LogsUsers from '@/views/Dashboard/views/Logs/views/LogsUsers';
import LogsContent from '@/views/Dashboard/views/Logs/views/LogsContent';
import LogsProducts from '@/views/Dashboard/views/Logs/views/LogsProducts';
import LogsBrands from '@/views/Dashboard/views/Logs/views/LogsBrands';
import LogsCollections from '@/views/Dashboard/views/Logs/views/LogsCollections';
import LogsSymptoms from '@/views/Dashboard/views/Logs/views/LogsSymptoms';
import LogsOrders from '@/views/Dashboard/views/Logs/views/LogsOrders';
import LogsCustomers from '@/views/Dashboard/views/Logs/views/LogsCustomers';
import LogsSettings from '@/views/Dashboard/views/Logs/views/LogsSettings';
import LogsSettingsTaxes from '@/views/Dashboard/views/Logs/views/LogsSettings/views/LogsSettingsTaxes';
import LogsSettingsCheckoutMethods from '@/views/Dashboard/views/Logs/views/LogsSettings/views/LogsSettingsCheckoutMethods';
import LogsSettingsSendMethods from '@/views/Dashboard/views/Logs/views/LogsSettings/views/LogsSettingsSendMethods';
import LogsContentPages from '@/views/Dashboard/views/Logs/views/LogsContent/views/LogsContentPages';
import LogsContentPosts from '@/views/Dashboard/views/Logs/views/LogsContent/views/LogsContentPosts';
import LogsContentPostCategories from '@/views/Dashboard/views/Logs/views/LogsContent/views/LogsContentPostCategories';
import LogsContentAuthors from '@/views/Dashboard/views/Logs/views/LogsContent/views/LogsContentAuthors';
import LogsContentVideos from '@/views/Dashboard/views/Logs/views/LogsContent/views/LogsContentVideos';
import LogsContentTags from '@/views/Dashboard/views/Logs/views/LogsContent/views/LogsContentTags';
import LogsContentFaqs from '@/views/Dashboard/views/Logs/views/LogsContent/views/LogsContentFaqs';
import LogsContentQuestions from '@/views/Dashboard/views/Logs/views/LogsContent/views/LogsContentQuestions';
import MarketingCoupons from '@/views/Dashboard/views/Marketing/views/Coupons';
import MarketingCouponsList from '@/views/Dashboard/views/Marketing/views/Coupons/views/List';
import MarketingCouponsListActive from '@/views/Dashboard/views/Marketing/views/Coupons/views/List/views/CouponsListActive';
import MarketingCouponsListInactive from '@/views/Dashboard/views/Marketing/views/Coupons/views/List/views/CouponsListInactive';
import MarketingCouponsListAll from '@/views/Dashboard/views/Marketing/views/Coupons/views/List/views/CouponsListAll';
import MarketingCouponsListDeleted from '@/views/Dashboard/views/Marketing/views/Coupons/views/List/views/CouponsListDeleted';
import MarketingCouponsAdd from '@/views/Dashboard/views/Marketing/views/Coupons/views/Add';
import MarketingCouponsEdit from '@/views/Dashboard/views/Marketing/views/Coupons/views/Edit';
import MarketingContests from '@/views/Dashboard/views/Marketing/views/Contests';
import MarketingContestsList from '@/views/Dashboard/views/Marketing/views/Contests/views/List';
import MarketingContestsListActive from '@/views/Dashboard/views/Marketing/views/Contests/views/List/views/ContestsListActive';
import MarketingContestsListInactive from '@/views/Dashboard/views/Marketing/views/Contests/views/List/views/ContestsListInactive';
import MarketingContestsListAll from '@/views/Dashboard/views/Marketing/views/Contests/views/List/views/ContestsListAll';
import MarketingContestsListDeleted from '@/views/Dashboard/views/Marketing/views/Contests/views/List/views/ContestsListDeleted';
import MarketingContestsAdd from '@/views/Dashboard/views/Marketing/views/Contests/views/Add';
import MarketingContestsEdit from '@/views/Dashboard/views/Marketing/views/Contests/views/Edit';
import MarketingBundles from '@/views/Dashboard/views/Marketing/views/Bundles';
import MarketingBundlesList from '@/views/Dashboard/views/Marketing/views/Bundles/views/List';
import MarketingBundlesListActive from '@/views/Dashboard/views/Marketing/views/Bundles/views/List/views/BundlesListActive';
import MarketingBundlesListInactive from '@/views/Dashboard/views/Marketing/views/Bundles/views/List/views/BundlesListInactive';
import MarketingBundlesListAll from '@/views/Dashboard/views/Marketing/views/Bundles/views/List/views/BundlesListAll';
import MarketingBundlesListDeleted from '@/views/Dashboard/views/Marketing/views/Bundles/views/List/views/BundlesListDeleted';
import MarketingBundlesAdd from '@/views/Dashboard/views/Marketing/views/Bundles/views/Add';
import MarketingBundlesEdit from '@/views/Dashboard/views/Marketing/views/Bundles/views/Edit';
import MarketingSets from '@/views/Dashboard/views/Marketing/views/Sets';
import MarketingSetsList from '@/views/Dashboard/views/Marketing/views/Sets/views/List';
import MarketingSetsListActive from '@/views/Dashboard/views/Marketing/views/Sets/views/List/views/SetsListActive';
import MarketingSetsListInactive from '@/views/Dashboard/views/Marketing/views/Sets/views/List/views/SetsListInactive';
import MarketingSetsListAll from '@/views/Dashboard/views/Marketing/views/Sets/views/List/views/SetsListAll';
import MarketingSetsListDeleted from '@/views/Dashboard/views/Marketing/views/Sets/views/List/views/SetsListDeleted';
import MarketingSetsAdd from '@/views/Dashboard/views/Marketing/views/Sets/views/Add';
import MarketingSetsEdit from '@/views/Dashboard/views/Marketing/views/Sets/views/Edit';
import MarketingLoyalty from '@/views/Dashboard/views/Marketing/views/Loyalty';
import MarketingLoyaltyCategories from '@/views/Dashboard/views/Marketing/views/Loyalty/views/Categories';
import MarketingLoyaltySettings from '@/views/Dashboard/views/Marketing/views/Loyalty/views/Settings';
import MarketingWeeksDeal from '@/views/Dashboard/views/Marketing/views/WeeksDeal';
import MarketingPromos from '@/views/Dashboard/views/Marketing/views/Promos';
import MarketingPromosList from '@/views/Dashboard/views/Marketing/views/Promos/views/List';
import MarketingPromosListActive from '@/views/Dashboard/views/Marketing/views/Promos/views/List/views/PromosListActive';
import MarketingPromosListInactive from '@/views/Dashboard/views/Marketing/views/Promos/views/List/views/PromosListInactive';
import MarketingPromosListAll from '@/views/Dashboard/views/Marketing/views/Promos/views/List/views/PromosListAll';
import MarketingPromosAdd from '@/views/Dashboard/views/Marketing/views/Promos/views/Add';
import MarketingPromosEdit from '@/views/Dashboard/views/Marketing/views/Promos/views/Edit';
import PeriodicDiscounts from '@/views/Dashboard/views/Marketing/views/PeriodicDiscounts';
import PeriodicDiscountsList from '@/views/Dashboard/views/Marketing/views/PeriodicDiscounts/views/List';
import PeriodicDiscountsListActive from '@/views/Dashboard/views/Marketing/views/PeriodicDiscounts/views/List/views/PeriodicDiscountsListActive';
import PeriodicDiscountsListInactive from '@/views/Dashboard/views/Marketing/views/PeriodicDiscounts/views/List/views/PeriodicDiscountsListInactive';
import PeriodicDiscountsListAll from '@/views/Dashboard/views/Marketing/views/PeriodicDiscounts/views/List/views/PeriodicDiscountsListAll';
import PeriodicDiscountsAdd from '@/views/Dashboard/views/Marketing/views/PeriodicDiscounts/views/Add';
import PeriodicDiscountsEdit from '@/views/Dashboard/views/Marketing/views/PeriodicDiscounts/views/Edit';
import MarketingGiftActions from '@/views/Dashboard/views/Marketing/views/GiftActions/index';
import MarketingGiftActionsList from '@/views/Dashboard/views/Marketing/views/GiftActions/views/List/index';
import GiftActionsBanners from '@/views/Dashboard/views/Marketing/views/GiftActions/views/Banners/index';
import GiftActionsBannersAdd from '@/views/Dashboard/views/Marketing/views/GiftActions/views/Banners/views/Add';
import GiftActionsBannersEdit from '@/views/Dashboard/views/Marketing/views/GiftActions/views/Banners/views/Edit';
import GiftActionsBannersList from '@/views/Dashboard/views/Marketing/views/GiftActions/views/Banners/views/List';
import MarketingGiftActionsAdd from '@/views/Dashboard/views/Marketing/views/GiftActions/views/Add';
import MarketingGiftActionsEdit from '@/views/Dashboard/views/Marketing/views/GiftActions/views/Edit';
import MarketingGiftActionsEditOrders from '@/views/Dashboard/views/Marketing/views/GiftActions/views/Edit/views/GiftActionsEditOrders';
import MarketingGiftActionsEditGiftActions from '@/views/Dashboard/views/Marketing/views/GiftActions/views/Edit/views/GiftActionsEditGiftAction';
import Vouchers from '@/views/Dashboard/views/Vouchers/index';
import Error from '@/views/Error';

Vue.use(Router);
Vue.use(routerHistory);

const Dashboard = () => import('@/views/Dashboard');

const SearchTelephone = () => import('@/views/SearchTelephone');

const Products = () => import('@/views/Dashboard/views/Products');

const Complaints = () => import('@/views/Dashboard/views/Complaints');

const Customers = () => import('@/views/Dashboard/views/Customers');

const Orders = () => import('@/views/Dashboard/views/Orders');

const Marketing = () => import('@/views/Dashboard/views/Marketing');

const Content = () => import('@/views/Dashboard/views/Content');

const Settings = () => import('@/views/Dashboard/views/Settings');

const Stats = () => import('@/views/Dashboard/views/Stats');

const Users = () => import('@/views/Dashboard/views/Users');

const Logs = () => import('@/views/Dashboard/views/Logs');

const router = new Router({
  mode: 'history',
  parseQuery(query) {
    return Qs.parse(query);
  },
  stringifyQuery(query) {
    const result = Qs.stringify(query, { encode: false });

    return result ? `?${result}` : '';
  },
  scrollBehavior(to, from) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: 'smooth',
      });
    }

    // customerTab has been added into meta, by the dev, in the routes below that correspond to customer tabs. In this way we achieve fully customizable scroll behavior depending which route we visit
    // Check if customerTab property exists in the meta of each route and scroll accordingly
    if (to.meta.customerTab) {
      if (to.name === from.name && to.query !== from.query) {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      if (document.getElementsByClassName('card-content')[1]) {
        const rect = document
          .getElementsByClassName('card-content')[1]
          .getBoundingClientRect();
        return window.scrollTo({
          top: rect.top + document.documentElement.scrollTop - 50,
          behavior: 'smooth',
        });
      }
    }

    return window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  routes: [
    {
      path: '/',
      component: Dashboard,
      redirect: { name: 'home' },
      children: [
        {
          path: '/search-telephone/:telephone',
          name: 'searchTelephone',
          component: SearchTelephone,
        },
        {
          path: '/dashboard',
          name: 'home',
          component: Home,
        },
        {
          path: '/versions',
          name: 'versions',
          component: Versions,
        },
        {
          path: '/products',
          component: Products,
          children: [
            {
              path: 'categories',
              name: 'products.categories',
              component: ProductsCategories,
              redirect: { name: 'products.categories.list' },
              children: [
                {
                  path: 'add',
                  name: 'products.categories.add',
                  component: ProductsCategoriesAdd,
                },
                {
                  path: ':uuid/edit/main',
                  name: 'products.categories.edit',
                  component: ProductsCategoriesEdit,
                },
                {
                  path: ':uuid/edit/products',
                  name: 'products.categories.edit.products',
                  component: ProductsCategoriesEditProducts,
                },
                {
                  path: '',
                  name: 'products.categories.list',
                  component: ProductsCategoriesList,
                },
              ],
            },
            {
              path: 'brands',
              name: 'products.brands',
              component: ProductsBrands,
              redirect: { name: 'products.brands.list' },
              children: [
                {
                  path: ':uuid/collections',
                  name: 'products.brands.collections',
                  component: ProductsBrandsCollections,
                  redirect: { name: 'products.brands.collections.list' },
                  children: [
                    {
                      path: 'add',
                      name: 'products.brands.collections.add',
                      component: ProductsBrandsCollectionsAdd,
                    },
                    {
                      path: ':collection/edit',
                      name: 'products.brands.collections.edit',
                      component: ProductsBrandsCollectionsEdit,
                    },
                    {
                      path: '',
                      name: 'products.brands.collections.list',
                      component: ProductsBrandsCollectionsList,
                    },
                  ],
                },
                {
                  path: ':uuid/categories/:category/edit',
                  name: 'products.brands.categories.edit',
                  component: ProductsBrandsCategoriesEdit,
                },
                {
                  path: 'add',
                  name: 'products.brands.add',
                  component: ProductsBrandsAdd,
                },
                {
                  path: ':uuid/edit/main',
                  name: 'products.brands.edit',
                  component: ProductsBrandsEdit,
                },
                {
                  path: ':uuid/edit/products',
                  name: 'products.brands.edit.products',
                  component: ProductsBrandsEditProducts,
                },
                {
                  path: '',
                  name: 'products.brands.list',
                  component: ProductsBrandsList,
                },
              ],
            },
            {
              path: 'symptoms',
              name: 'products.symptoms',
              component: ProductsSymptoms,
              redirect: { name: 'products.symptoms.list' },
              children: [
                {
                  path: 'add',
                  name: 'products.symptoms.add',
                  component: ProductsSymptomsAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'products.symptoms.edit',
                  component: ProductsSymptomsEdit,
                },
                {
                  path: '',
                  name: 'products.symptoms.list',
                  component: ProductsSymptomsList,
                },
              ],
            },
            {
              path: 'sizes',
              name: 'products.sizes',
              component: ProductsSizes,
              redirect: { name: 'products.sizes.list' },
              children: [
                {
                  path: 'add',
                  name: 'products.sizes.add',
                  component: ProductsSizesAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'products.sizes.edit',
                  component: ProductsSizesEdit,
                },
                {
                  path: '',
                  name: 'products.sizes.list',
                  component: ProductsSizesList,
                },
              ],
            },
            {
              path: 'colors',
              name: 'products.colors',
              component: ProductsColors,
              redirect: { name: 'products.colors.list' },
              children: [
                {
                  path: 'add',
                  name: 'products.colors.add',
                  component: ProductsColorsAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'products.colors.edit',
                  component: ProductsColorsEdit,
                },
                {
                  path: '',
                  name: 'products.colors.list',
                  component: ProductsColorsList,
                },
              ],
            },
            {
              path: 'filterGroups',
              name: 'products.filterGroups',
              component: ProductsFilterGroups,
              redirect: { name: 'products.filterGroups.list' },
              children: [
                {
                  path: ':uuid/filters',
                  name: 'products.filterGroups.filters',
                  component: ProductsFilterGroupsFilters,
                  redirect: { name: 'products.filterGroups.filters.list' },
                  children: [
                    {
                      path: 'add',
                      name: 'products.filterGroups.filters.add',
                      component: ProductsFilterGroupsFiltersAdd,
                    },
                    {
                      path: ':filter/edit',
                      name: 'products.filterGroups.filters.edit',
                      component: ProductsFilterGroupsFiltersEdit,
                    },
                    {
                      path: '',
                      name: 'products.filterGroups.filters.list',
                      component: ProductsFilterGroupsFiltersList,
                    },
                  ],
                },
                {
                  path: 'add',
                  name: 'products.filterGroups.add',
                  component: ProductsFilterGroupsAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'products.filterGroups.edit',
                  component: ProductsFilterGroupsEdit,
                },
                {
                  path: '',
                  name: 'products.filterGroups.list',
                  component: ProductsFilterGroupsList,
                },
              ],
            },
            {
              path: 'add',
              name: 'products.add',
              component: ProductsAdd,
            },
            {
              path: 'groups/add',
              name: 'products.groups.add',
              component: ProductsGroupsAdd,
            },
            {
              path: ':uuid/edit',
              name: 'products.edit',
              component: ProductsEdit,
              redirect: { name: 'products.edit.gr' },
              children: [
                {
                  path: 'gr',
                  name: 'products.edit.gr',
                  component: ProductsEditGR,
                },
                {
                  path: 'en',
                  name: 'products.edit.en',
                  component: ProductsEditEN,
                },
                {
                  path: 'loyalty',
                  name: 'products.edit.loyalty',
                  component: ProductsEditLoyalty,
                },
                {
                  path: 'family',
                  name: 'products.edit.family',
                  component: ProductsEditFamily,
                },
              ],
            },
            {
              path: '',
              name: 'products.list',
              component: ProductsList,
              redirect: { name: 'products.list.all' },
              children: [
                {
                  path: 'active',
                  name: 'products.list.active',
                  component: ProductsListActive,
                },
                {
                  path: 'inactive',
                  name: 'products.list.inactive',
                  component: ProductsListInactive,
                },
                {
                  path: 'all',
                  name: 'products.list.all',
                  component: ProductsListAll,
                },
                {
                  path: 'deleted',
                  name: 'products.list.deleted',
                  component: ProductsListDeleted,
                },
              ],
            },
          ],
        },
        {
          path: '/complaints',
          component: Complaints,
          beforeEnter: middleware.isNotEditor,
          children: [
            {
              path: 'add',
              name: 'complaints.add',
              component: ComplaintsAdd,
            },
            {
              path: ':uuid/edit',
              name: 'complaints.edit',
              component: ComplaintsEdit,
            },
            {
              path: '',
              name: 'complaints.list',
              component: ComplaintsList,
              redirect: { name: 'complaints.list.all' },
              children: [
                {
                  path: 'all',
                  name: 'complaints.list.all',
                  component: ComplaintsListAll,
                },
              ],
            },
          ],
        },
        {
          path: '/customers',
          component: Customers,
          beforeEnter: middleware.isNotEditor,
          children: [
            {
              path: 'add',
              name: 'customers.add',
              component: CustomersAdd,
            },
            {
              path: ':uuid/edit',
              name: 'customers.edit',
              component: CustomersEdit,
              redirect: {
                name: 'customers.edit.orders',
                query: { tab: 'customer' },
              },
              children: [
                {
                  path: 'orders',
                  name: 'customers.edit.orders',
                  component: CustomersEditOrders,
                  meta: {
                    customerTab: true,
                  },
                },
                {
                  path: 'gy-orders',
                  name: 'customers.edit.gyOrders',
                  component: CustomersEditGYOrders,
                  meta: {
                    customerTab: true,
                  },
                },
                {
                  path: 'products',
                  name: 'customers.edit.products',
                  component: CustomersEditProducts,
                  meta: {
                    customerTab: true,
                  },
                },
                {
                  path: 'favorites',
                  name: 'customers.edit.favorites',
                  component: CustomersEditFavorites,
                  meta: {
                    customerTab: true,
                  },
                },
                {
                  path: 'coupons',
                  name: 'customers.edit.coupons',
                  component: CustomersEditCoupons,
                  meta: {
                    customerTab: true,
                  },
                },
                {
                  path: 'incoming-emails',
                  name: 'customers.edit.incomingEmails',
                  component: CustomersEditIncomingEmails,
                  meta: {
                    customerTab: true,
                  },
                },
                {
                  path: 'contact',
                  name: 'customers.edit.contact',
                  component: CustomersEditContact,
                  meta: {
                    customerTab: true,
                  },
                },
                {
                  path: 'questions',
                  name: 'customers.edit.questions',
                  component: CustomersEditQuestions,
                  meta: {
                    customerTab: true,
                  },
                },
                {
                  path: 'addresses',
                  name: 'customers.edit.addresses',
                  component: CustomersEditAddresses,
                  meta: {
                    customerTab: true,
                  },
                },
                {
                  path: 'loyalty',
                  name: 'customers.edit.loyalty',
                  component: CustomersEditLoyalty,
                  meta: {
                    customerTab: true,
                  },
                },
                {
                  path: 'invoices',
                  name: 'customers.edit.invoices',
                  component: CustomersEditInvoices,
                  meta: {
                    customerTab: true,
                  },
                },
                {
                  path: 'contests',
                  name: 'customers.edit.contests',
                  component: CustomersEditContests,
                  meta: {
                    customerTab: true,
                  },
                },
                {
                  path: 'reviews',
                  name: 'customers.edit.reviews',
                  component: CustomersEditReviews,
                  meta: {
                    customerTab: true,
                  },
                },
              ],
            },
            {
              path: '',
              name: 'customers.list',
              component: CustomersList,
              redirect: { name: 'customers.list.all' },
              children: [
                {
                  path: 'full-profile',
                  name: 'customers.list.full',
                  component: CustomersListFull,
                },
                {
                  path: 'empty-profile',
                  name: 'customers.list.empty',
                  component: CustomersListEmpty,
                },
                {
                  path: 'guests',
                  name: 'customers.list.guests',
                  component: CustomersListGuests,
                },
                {
                  path: 'all',
                  name: 'customers.list.all',
                  component: CustomersListAll,
                },
                {
                  path: 'deleted',
                  name: 'customers.list.deleted',
                  component: CustomersListDeleted,
                },
              ],
            },
          ],
        },
        {
          path: '/gy-orders/:uuid',
          component: GYOrdersShow,
          name: 'gyOrders.show',
        },
        {
          path: '/orders',
          component: Orders,
          beforeEnter: middleware.isNotEditor,
          children: [
            {
              path: 'add',
              name: 'orders.add',
              component: OrdersAdd,
            },
            {
              path: ':uuid/edit',
              name: 'orders.edit',
              component: OrdersEdit,
            },
            {
              path: ':code/skroutz/edit',
              name: 'orders.skroutz.edit',
              component: OrdersSkroutzEdit,
            },
            {
              path: '',
              name: 'orders.list',
              component: OrdersList,
              redirect: { name: 'orders.list.pending' },
              children: [
                {
                  path: 'pending',
                  name: 'orders.list.pending',
                  component: OrdersListPending,
                },
                {
                  path: 'skroutz',
                  name: 'orders.skroutz.list',
                  component: OrdersSkroutzList,
                },
                {
                  path: 'sent',
                  name: 'orders.list.sent',
                  component: OrdersListSent,
                },
                {
                  path: 'all',
                  name: 'orders.list.all',
                  component: OrdersListAll,
                },
                {
                  path: 'deleted',
                  name: 'orders.list.deleted',
                  component: OrdersListDeleted,
                },
                {
                  path: 'withdrawal',
                  name: 'orders.list.withdrawal',
                  component: OrdersListWithdrawal,
                },
                {
                  path: 'unfinished',
                  name: 'orders.list.unfinished',
                  component: OrdersListUnfinished,
                },
              ],
            },
          ],
        },
        {
          path: '/marketing',
          component: Marketing,
          redirect: { name: 'marketing.coupons.list' },
          children: [
            {
              path: 'contests',
              component: MarketingContests,
              children: [
                {
                  path: 'add',
                  name: 'marketing.contests.add',
                  component: MarketingContestsAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'marketing.contests.edit',
                  component: MarketingContestsEdit,
                },
                {
                  path: '',
                  name: 'marketing.contests.list',
                  component: MarketingContestsList,
                  redirect: { name: 'marketing.contests.list.active' },
                  children: [
                    {
                      path: 'active',
                      name: 'marketing.contests.list.active',
                      component: MarketingContestsListActive,
                    },
                    {
                      path: 'inactive',
                      name: 'marketing.contests.list.inactive',
                      component: MarketingContestsListInactive,
                    },
                    {
                      path: 'all',
                      name: 'marketing.contests.list.all',
                      component: MarketingContestsListAll,
                    },
                    {
                      path: 'deleted',
                      name: 'marketing.contests.list.deleted',
                      component: MarketingContestsListDeleted,
                    },
                  ],
                },
              ],
            },
            {
              path: 'coupons',
              component: MarketingCoupons,
              children: [
                {
                  path: 'add',
                  name: 'marketing.coupons.add',
                  component: MarketingCouponsAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'marketing.coupons.edit',
                  component: MarketingCouponsEdit,
                },
                {
                  path: '',
                  name: 'marketing.coupons.list',
                  component: MarketingCouponsList,
                  redirect: { name: 'marketing.coupons.list.active' },
                  children: [
                    {
                      path: 'active',
                      name: 'marketing.coupons.list.active',
                      component: MarketingCouponsListActive,
                    },
                    {
                      path: 'inactive',
                      name: 'marketing.coupons.list.inactive',
                      component: MarketingCouponsListInactive,
                    },
                    {
                      path: 'all',
                      name: 'marketing.coupons.list.all',
                      component: MarketingCouponsListAll,
                    },
                    {
                      path: 'deleted',
                      name: 'marketing.coupons.list.deleted',
                      component: MarketingCouponsListDeleted,
                    },
                  ],
                },
              ],
            },
            {
              path: 'bundles',
              component: MarketingBundles,
              children: [
                {
                  path: 'add',
                  name: 'marketing.bundles.add',
                  component: MarketingBundlesAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'marketing.bundles.edit',
                  component: MarketingBundlesEdit,
                },
                {
                  path: '',
                  name: 'marketing.bundles.list',
                  component: MarketingBundlesList,
                  redirect: { name: 'marketing.bundles.list.active' },
                  children: [
                    {
                      path: 'active',
                      name: 'marketing.bundles.list.active',
                      component: MarketingBundlesListActive,
                    },
                    {
                      path: 'inactive',
                      name: 'marketing.bundles.list.inactive',
                      component: MarketingBundlesListInactive,
                    },
                    {
                      path: 'all',
                      name: 'marketing.bundles.list.all',
                      component: MarketingBundlesListAll,
                    },
                    {
                      path: 'deleted',
                      name: 'marketing.bundles.list.deleted',
                      component: MarketingBundlesListDeleted,
                    },
                  ],
                },
              ],
            },
            {
              path: 'sets',
              component: MarketingSets,
              children: [
                {
                  path: 'add',
                  name: 'marketing.sets.add',
                  component: MarketingSetsAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'marketing.sets.edit',
                  component: MarketingSetsEdit,
                },
                {
                  path: '',
                  name: 'marketing.sets.list',
                  component: MarketingSetsList,
                  redirect: { name: 'marketing.sets.list.active' },
                  children: [
                    {
                      path: 'active',
                      name: 'marketing.sets.list.active',
                      component: MarketingSetsListActive,
                    },
                    {
                      path: 'inactive',
                      name: 'marketing.sets.list.inactive',
                      component: MarketingSetsListInactive,
                    },
                    {
                      path: 'all',
                      name: 'marketing.sets.list.all',
                      component: MarketingSetsListAll,
                    },
                    {
                      path: 'deleted',
                      name: 'marketing.sets.list.deleted',
                      component: MarketingSetsListDeleted,
                    },
                  ],
                },
              ],
            },
            {
              path: 'loyalty',
              component: MarketingLoyalty,
              children: [
                {
                  path: 'categories',
                  name: 'marketing.loyalty.categories',
                  component: MarketingLoyaltyCategories,
                },
                {
                  path: 'settings',
                  name: 'marketing.loyalty.settings',
                  component: MarketingLoyaltySettings,
                },
                {
                  path: '',
                  name: 'marketing.loyalty',
                  redirect: { name: 'marketing.loyalty.categories' },
                },
              ],
            },
            {
              path: 'weeks-deal',
              component: MarketingWeeksDeal,
              name: 'marketing.weeks-deal',
            },
            {
              path: 'gift-actions',
              component: MarketingGiftActions,
              name: 'marketing.giftActions',
              redirect: { name: 'marketing.giftActions.giftActions.list' },
              children: [
                {
                  path: 'gift-actions/add',
                  name: 'marketing.giftActions.giftActions.add',
                  component: MarketingGiftActionsAdd,
                },
                {
                  path: 'gift-actions/:id/edit',
                  name: 'marketing.giftActions.giftActions.edit',
                  component: MarketingGiftActionsEdit,
                  redirect: {
                    name: 'marketing.giftActions.giftActions.edit.giftAction',
                  },
                  children: [
                    {
                      path: 'gift-actions/orders',
                      name: 'marketing.giftActions.giftActions.edit.orders',
                      component: MarketingGiftActionsEditOrders,
                    },
                    {
                      path: '',
                      name: 'marketing.giftActions.giftActions.edit.giftAction',
                      component: MarketingGiftActionsEditGiftActions,
                    },
                  ],
                },
                {
                  path: 'gift-actions',
                  name: 'marketing.giftActions.giftActions.list',
                  component: MarketingGiftActionsList,
                },
                {
                  path: 'banners',
                  component: GiftActionsBanners,
                  name: 'marketing.giftActions.banners',
                  redirect: { name: 'marketing.giftActions.banners.list' },
                  children: [
                    {
                      path: 'add',
                      name: 'marketing.giftActions.banners.add',
                      component: GiftActionsBannersAdd,
                    },
                    {
                      path: ':uuid/edit',
                      name: 'marketing.giftActions.banners.edit',
                      component: GiftActionsBannersEdit,
                    },
                    {
                      path: '',
                      name: 'marketing.giftActions.banners.list',
                      component: GiftActionsBannersList,
                    },
                  ],
                },
              ],
            },
            {
              path: 'promos',
              component: MarketingPromos,
              children: [
                {
                  path: 'add',
                  name: 'marketing.promos.add',
                  component: MarketingPromosAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'marketing.promos.edit',
                  component: MarketingPromosEdit,
                },
                {
                  path: '',
                  name: 'marketing.promos.list',
                  component: MarketingPromosList,
                  redirect: { name: 'marketing.promos.list.active' },
                  children: [
                    {
                      path: 'active',
                      name: 'marketing.promos.list.active',
                      component: MarketingPromosListActive,
                    },
                    {
                      path: 'inactive',
                      name: 'marketing.promos.list.inactive',
                      component: MarketingPromosListInactive,
                    },
                    {
                      path: 'all',
                      name: 'marketing.promos.list.all',
                      component: MarketingPromosListAll,
                    },
                  ],
                },
              ],
            },

            {
              path: 'periodic-discounts',
              component: PeriodicDiscounts,
              children: [
                {
                  path: 'add',
                  name: 'marketing.periodicDiscounts.add',
                  component: PeriodicDiscountsAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'marketing.periodicDiscounts.edit',
                  component: PeriodicDiscountsEdit,
                },
                {
                  path: '',
                  name: 'marketing.periodicDiscounts.list',
                  component: PeriodicDiscountsList,
                  redirect: { name: 'marketing.periodicDiscounts.list.active' },
                  children: [
                    {
                      path: 'active',
                      name: 'marketing.periodicDiscounts.list.active',
                      component: PeriodicDiscountsListActive,
                    },
                    {
                      path: 'inactive',
                      name: 'marketing.periodicDiscounts.list.inactive',
                      component: PeriodicDiscountsListInactive,
                    },
                    {
                      path: 'all',
                      name: 'marketing.periodicDiscounts.list.all',
                      component: PeriodicDiscountsListAll,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: '/settings',
          component: Settings,
          redirect: { name: 'settings.general' },
          children: [
            {
              path: 'general',
              name: 'settings.general',
              component: General,
            },
            {
              path: 'send-charges',
              name: 'settings.sendCharges',
              component: SendCharges,
              redirect: { name: 'settings.sendCharges.gr' },
              children: [
                {
                  path: 'gr',
                  name: 'settings.sendCharges.gr',
                  component: SendChargesGreece,
                },
                {
                  path: 'cy',
                  name: 'settings.sendCharges.cy',
                  component: SendChargesCyprus,
                },
              ],
            },
            {
              path: 'taxes',
              component: Taxes,
              children: [
                {
                  path: 'add',
                  name: 'settings.taxes.add',
                  component: TaxesAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'settings.taxes.edit',
                  component: TaxesEdit,
                },
                {
                  path: '',
                  name: 'settings.taxes.list',
                  component: TaxesList,
                },
              ],
            },
            {
              path: 'checkout-methods',
              component: CheckoutMethods,
              children: [
                {
                  path: ':uuid/edit',
                  name: 'settings.checkoutMethods.edit',
                  component: CheckoutMethodsEdit,
                },
                {
                  path: '',
                  name: 'settings.checkoutMethods.list',
                  component: CheckoutMethodsList,
                },
              ],
            },
            {
              path: 'send-methods',
              component: SendMethods,
              name: 'settings.sendMethods',
            },
            {
              path: 'registration-methods',
              component: RegistrationMethods,
              name: 'settings.registrationMethods',
            },
            {
              path: 'email-templates',
              component: EmailTemplates,
              children: [
                {
                  path: 'add',
                  name: 'settings.emailTemplates.add',
                  component: EmailTemplatesAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'settings.emailTemplates.edit',
                  component: EmailTemplatesEdit,
                },
                {
                  path: '',
                  name: 'settings.emailTemplates.list',
                  component: EmailTemplatesList,
                },
              ],
            },
            {
              path: 'cancel-order-reasons',
              component: CancelOrderReasons,
              name: 'settings.cancelOrderReasons',
              redirect: { name: 'settings.cancelOrderReasons.list' },
              children: [
                {
                  path: 'add',
                  name: 'settings.cancelOrderReasons.add',
                  component: CancelOrderReasonsAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'settings.cancelOrderReasons.edit',
                  component: CancelOrderReasonsEdit,
                },
                {
                  path: '',
                  name: 'settings.cancelOrderReasons.list',
                  component: CancelOrderReasonsList,
                },
              ],
            },
          ],
        },
        {
          path: '/content',
          component: Content,
          redirect: { name: 'content.pages.list' },
          children: [
            {
              path: 'banners',
              component: Banners,
              children: [
                {
                  path: 'add',
                  name: 'content.banners.add',
                  component: BannersAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'content.banners.edit',
                  component: BannersEdit,
                },
                {
                  path: '',
                  name: 'content.banners.list',
                  component: BannersList,
                },
              ],
            },
            {
              path: 'posts',
              component: Posts,
              children: [
                {
                  path: 'add',
                  name: 'content.posts.add',
                  component: PostsAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'content.posts.edit',
                  component: PostsEdit,
                },
                {
                  path: '',
                  name: 'content.posts.list',
                  component: PostsList,
                },
              ],
            },
            {
              path: 'postCategories',
              component: PostCategories,
              children: [
                {
                  path: 'add',
                  name: 'content.postCategories.add',
                  component: PostCategoriesAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'content.postCategories.edit',
                  component: PostCategoriesEdit,
                },
                {
                  path: '',
                  name: 'content.postCategories.list',
                  component: PostCategoriesList,
                },
              ],
            },
            {
              path: 'authors',
              component: Authors,
              children: [
                {
                  path: 'add',
                  name: 'content.authors.add',
                  component: AuthorsAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'content.authors.edit',
                  component: AuthorsEdit,
                },
                {
                  path: '',
                  name: 'content.authors.list',
                  component: AuthorsList,
                },
              ],
            },
            {
              path: 'pages',
              component: Pages,
              children: [
                {
                  path: 'add',
                  name: 'content.pages.add',
                  component: PagesAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'content.pages.edit',
                  component: PagesEdit,
                },
                {
                  path: '',
                  name: 'content.pages.list',
                  component: PagesList,
                },
              ],
            },
            {
              path: 'questions',
              component: Questions,
              children: [
                {
                  path: 'add',
                  name: 'content.questions.add',
                  component: QuestionsAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'content.questions.edit',
                  component: QuestionsEdit,
                },
                {
                  path: '',
                  name: 'content.questions.list',
                  component: QuestionsList,
                },
              ],
            },
            {
              path: 'faqs',
              component: Faqs,
              children: [
                {
                  path: 'add',
                  name: 'content.faqs.add',
                  component: FaqsAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'content.faqs.edit',
                  component: FaqsEdit,
                },
                {
                  path: '',
                  name: 'content.faqs.list',
                  component: FaqsList,
                },
              ],
            },
            {
              path: 'videos',
              component: Videos,
              children: [
                {
                  path: 'add',
                  name: 'content.videos.add',
                  component: VideosAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'content.videos.edit',
                  component: VideosEdit,
                },
                {
                  path: '',
                  name: 'content.videos.list',
                  component: VideosList,
                },
              ],
            },
            {
              path: 'tags',
              component: Tags,
              children: [
                {
                  path: 'add',
                  name: 'content.tags.add',
                  component: TagsAdd,
                },
                {
                  path: ':uuid/edit',
                  name: 'content.tags.edit',
                  component: TagsEdit,
                },
                {
                  path: '',
                  name: 'content.tags.list',
                  component: TagsList,
                },
              ],
            },
            {
              path: 'reviews',
              component: Reviews,
              children: [
                {
                  path: '',
                  name: 'content.reviews.list',
                  component: ReviewsList,
                },
              ],
            },
          ],
        },
        {
          path: '/stats',
          component: Stats,
          redirect: { name: 'stats.orders' },
          beforeEnter: middleware.isNotEditor,
          children: [
            {
              path: 'orders',
              name: 'stats.orders',
              component: StatsOrders,
            },
            {
              path: 'products',
              name: 'stats.products',
              component: StatsProducts,
            },
            {
              path: 'brands',
              name: 'stats.brands',
              component: StatsBrands,
              beforeEnter: middleware.isSuperAdmin,
            },
          ],
        },
        {
          path: '/users',
          component: Users,
          beforeEnter: middleware.isSuperAdmin,
          children: [
            {
              path: 'add',
              name: 'users.add',
              component: UsersAdd,
            },
            {
              path: ':uuid/edit',
              name: 'users.edit',
              component: UsersEdit,
            },
            {
              path: '',
              name: 'users.list',
              component: UsersList,
            },
          ],
        },
        {
          path: '/logs',
          component: Logs,
          redirect: { name: 'logs.products' },
          beforeEnter: middleware.isSuperAdmin,
          children: [
            {
              path: 'products',
              name: 'logs.products',
              component: LogsProducts,
            },
            {
              path: 'brands',
              name: 'logs.brands',
              component: LogsBrands,
            },
            {
              path: 'collections',
              name: 'logs.collections',
              component: LogsCollections,
            },
            {
              path: 'symptoms',
              name: 'logs.symptoms',
              component: LogsSymptoms,
            },
            {
              path: 'orders',
              name: 'logs.orders',
              component: LogsOrders,
            },
            {
              path: 'customers',
              name: 'logs.customers',
              component: LogsCustomers,
            },
            {
              path: 'users',
              name: 'logs.users',
              component: LogsUsers,
            },
            {
              path: 'settings',
              name: 'logs.settings',
              component: LogsSettings,
              redirect: { name: 'logs.settings.taxes' },
              children: [
                {
                  path: 'taxes',
                  name: 'logs.settings.taxes',
                  component: LogsSettingsTaxes,
                },
                {
                  path: 'checkoutMethods',
                  name: 'logs.settings.checkoutMethods',
                  component: LogsSettingsCheckoutMethods,
                },
                {
                  path: 'sendMethods',
                  name: 'logs.settings.sendMethods',
                  component: LogsSettingsSendMethods,
                },
              ],
            },
            {
              path: 'content',
              name: 'logs.content',
              component: LogsContent,
              redirect: { name: 'logs.content.pages' },
              children: [
                {
                  path: 'pages',
                  name: 'logs.content.pages',
                  component: LogsContentPages,
                },
                {
                  path: 'posts',
                  name: 'logs.content.posts',
                  component: LogsContentPosts,
                },
                {
                  path: 'postCategories',
                  name: 'logs.content.postCategories',
                  component: LogsContentPostCategories,
                },
                {
                  path: 'authors',
                  name: 'logs.content.authors',
                  component: LogsContentAuthors,
                },
                {
                  path: 'videos',
                  name: 'logs.content.videos',
                  component: LogsContentVideos,
                },
                {
                  path: 'tags',
                  name: 'logs.content.tags',
                  component: LogsContentTags,
                },
                {
                  path: 'questions',
                  name: 'logs.content.questions',
                  component: LogsContentQuestions,
                },
                {
                  path: 'faqs',
                  name: 'logs.content.faqs',
                  component: LogsContentFaqs,
                },
              ],
            },
          ],
        },
        {
          path: '/vouchers',
          component: Vouchers,
          name: 'vouchers',
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/activate/:uuid',
      name: 'activate',
      component: Activate,
    },
    {
      path: '*',
      name: 'error',
      component: Error,
    },
  ],
});

router.beforeEach(middleware.isAuth);

router.afterEach(writeHistory);

export default router;
