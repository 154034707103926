import request from '@/utils/request';
import { calculateParams } from '@/utils/calculations';

/* eslint-disable */
const actions = {
  async getBanners({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get('/banners', {
        params: { ...newParams },
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.banners);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      console.log(err);
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },
};

export default actions;
