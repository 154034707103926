export default {
  data() {
    return {
      countryOptions: ['ΕΛΛΑΔΑ', 'ΚΥΠΡΟΣ'],
    };
  },

  methods: {
    resetAutocomplete($event) {
      $event.target.setAttribute('autocomplete', 'new-password');
    },
  },
};
