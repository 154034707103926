<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Πελάτες
        </div>
      </div>
      <div class="card-header-icon">
        <div class="field has-addons is-marginless">
          <div class="control">
            <router-link :to="{ name: 'customers.list' }" class="button">
              <span class="icon is-small"><i class="fa fa-list-ul"/></span
              ><span>Λίστα</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Όνομα *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  v-model.trim="newCustomer.firstName"
                  class="input"
                  type="text"
                  name="firstName"
                />
                <p v-show="errors.has('firstName')" class="help is-danger">
                  Εισάγετε όνομα
                </p>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Επώνυμο *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  v-model.trim="newCustomer.lastName"
                  class="input"
                  type="text"
                  name="lastName"
                />
                <p v-show="errors.has('lastName')" class="help is-danger">
                  Εισάγετε επώνυμο
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Μέθοδος Αρχικής Εγγραφής *</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select
                    v-model.number="newCustomer.registration_method_id"
                    name="registration_method_id"
                  >
                    <option
                      v-for="method in registrationMethods"
                      :key="method.id"
                      :value="method.id"
                    >
                      {{ method.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Επιμέρους Τύπος Αρχικής Εγγραφής *</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select
                    v-model.number="newCustomer.registration_method_type_id"
                    name="registration_method_type_id"
                  >
                    <optgroup
                      v-for="method in registrationMethods"
                      v-if="method.id === newCustomer.registration_method_id"
                      :key="method.id"
                      :label="method.title"
                    >
                      <option
                        v-for="type in method.types"
                        :key="type.id"
                        :value="type.id"
                      >
                        {{ type.title }}
                      </option>
                    </optgroup>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <label class="label">Email *</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <input
                  v-validate="'required|email'"
                  v-model.trim="newCustomer.email"
                  class="input"
                  type="email"
                  name="email"
                />
                <p v-show="errors.has('email:required')" class="help is-danger">
                  Εισάγετε email
                </p>
                <p v-show="errors.has('email:email')" class="help is-danger">
                  Εισάγετε έγκυρο email
                </p>
              </div>
              <div class="control">
                <button
                  v-tooltip="{ content: 'Δημιουργία Dummy Email' }"
                  class="button"
                  type="button"
                  @click="createDummyEmail"
                >
                  <span class="icon">
                    <i class="fa fa-refresh" />
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Φύλο</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select v-model="newCustomer.gender" name="gender">
                    <option :value="null">Επιλέξτε Φύλο</option>
                    <option value="male">Άνδρας</option>
                    <option value="female">Γυναίκα</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Κινητό*</label>
              <div class="control">
                <vue-tel-input
                  v-validate="'required'"
                  v-model="phoneNumbers.mobile"
                  enabled-country-code
                  input-id="add_new_customer_mobile"
                  name="mobile"
                  placeholder="Εισάγετε Κινητό"
                  @input="onInputMobile"
                />
                <p
                  v-show="errors.has('mobile') || errors.has('mobile-invalid')"
                  class="help is-danger"
                >
                  Εισάγετε έγκυρο κινητό τηλέφωνο
                </p>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Σταθερό</label>
              <div class="control">
                <vue-tel-input
                  v-model="phoneNumbers.telephone"
                  input-id="add_new_customer_telephone"
                  enabled-country-code
                  name="telephone"
                  placeholder="Εισάγετε Σταθερό"
                  @input="onInputTelephone"
                />
                <p v-show="errors.has('telephone')" class="help is-danger">
                  Εισάγετε έγκυρο σταθερό τηλέφωνο
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>
  </div>
</template>

<script>
import randomstring from 'randomstring';
import { mapActions, mapGetters } from 'vuex';
import _get from 'lodash/get';
import Datepicker from 'vuejs-datepicker';
import FormSubmit from '@/views/components/FormSubmit';
import Notification from '@/views/components/Notification';

export default {
  components: {
    Datepicker,
    Notification,
    FormSubmit,
  },
  data() {
    return {
      newCustomer: {
        firstName: '',
        lastName: '',
        email: '',
        registration_method_id: 1,
        registration_method_type_id: null,
        gender: null,
        telephone: '',
        mobile: '',
      },
      isSaving: false,
      phoneNumbers: {
        mobile: '',
        telephone: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      registrationMethods: 'getRegistrationMethodsForNewCustomer',
    }),
  },
  watch: {
    'newCustomer.registration_method_id': {
      handler(newVal) {
        const registrationMethod = this.registrationMethods.find(
          method => method.id === newVal,
        );

        this.newCustomer.registration_method_type_id = _get(
          registrationMethod,
          'types[0].id',
          null,
        );
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      addCustomer: 'customers/add/addCustomer',
    }),

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid || this.errors.count()) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addCustomer(this.newCustomer);
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Ο χρήστης αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.newCustomer = {
        firstName: '',
        lastName: '',
        email: '',
        registration_method_id: 1,
        registration_method_type_id: null,
        gender: null,
        telephone: '',
        mobile: '',
      };

      this.$validator.reset();
    },

    onInputTelephone(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'telephone',
          msg: 'Wrong telephone',
        });
      } else {
        this.newCustomer.telephone = number.international;
        this.errors.remove('telephone');
      }
    },

    onInputMobile(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'mobile-invalid',
          msg: 'Wrong mobile',
        });
      } else {
        this.newCustomer.mobile = number.international;
        this.errors.remove('mobile-invalid');
      }
    },

    createDummyEmail() {
      this.newCustomer.email = `${randomstring.generate(16)}@guest.gr`;
    },
  },
};
</script>

<style scoped lang="scss">
.vue-tel-input {
  border: 1px solid #dbdbdb;
  border-radius: 2px;

  &:focus-within {
    border-color: #22a684;
    box-shadow: 0 0 0 0.125em rgba(34, 166, 132, 0.25);
    outline: none;
  }

  ::v-deep .dropdown {
    padding: 6px;

    &:focus {
      outline: none;
    }
  }

  ::v-deep ul {
    border-color: #dbdbdb;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;

    li.last-preferred {
      border-bottom-color: #dbdbdb;
    }
  }

  ::v-deep input {
    font-size: 1rem;
  }
}
</style>
