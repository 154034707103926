import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getUsers({ commit }) {
    try {
      const { data } = await request.get('/users');

      await commit('setUsers', {
        users: data.users,
      });
    } catch (err) {
      throw err;
    }
  },

  async toggleUserStatus({ commit, getters }, { user, active }) {
    try {
      const response = await request.put(`/users/${user.uuid}/active`, {
        active,
      });

      if (response.data.error) {
        throw Error('Server-side validation failed');
      }

      const users = getters.getUsers.map(item => ({
        ...item,
        active: item.id === user.id ? active : item.active,
      }));

      await commit('setUsers', { users });
    } catch (err) {
      throw err;
    }
  },

  async updateUser({ commit, getters }, { user, data }) {
    try {
      const response = await request.put(`/users/${user.uuid}`, data);

      if (response.data.error) {
        throw Error('Server-side validation failed');
      }

      const users = getters.getUsers.map(item => (item.id === user.id ? {
        ...item,
        ...data,
      } : item));

      await commit('setUsers', { users });
    } catch (err) {
      throw err;
    }
  },

  async deleteUser({ commit, getters }, { user }) {
    try {
      const { data } = await request.delete(`/users/${user.uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      const users = getters.getUsers.filter(item => item.id !== user.id);

      await commit('setUsers', { users });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
