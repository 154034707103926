<template>
  <div :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background" @click="closeUploadModal" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Προσθήκη Φωτογραφίας</p>
        <button type="button" class="delete" @click="closeUploadModal" />
      </header>
      <section class="modal-card-body">
        <div v-if="hasPhotos" class="images">
          <ul class="images__list">
            <li v-for="photo in photos" :key="photo.id" class="images__item">
              <button
                type="button"
                class="images__btn"
                @click="handleSelect(photo)"
              >
                <photo :photo="photo" />
              </button>
            </li>
          </ul>
        </div>
        <div v-if="file" class="has-text-centered">
          <img :src="preview" />
        </div>
        <div v-else>
          <div class="image-container">
            <button
              :class="{ 'is-loading': isSaving }"
              type="button"
              class="button is-primary"
            >
              <span class="icon is-small"><i class="fa fa-image"/></span>
              <span>{{ buttonText }}</span>
            </button>
            <input
              v-validate="`mimes:${rule}`"
              ref="file"
              :id="id"
              :disabled="isSaving"
              :accept="rule"
              type="file"
              name="file"
              @change="onFileChange"
            />
            <label :for="id" />
          </div>
          <p v-show="errors.has('file')" class="help is-danger">
            Εισάγετε έγκυρη png, jpg ή jpeg εικόνα
          </p>
        </div>
      </section>
      <footer v-if="file" class="modal-card-foot">
        <div class="field is-grouped">
          <div class="control">
            <button
              :class="{ 'is-loading': isSaving }"
              :disabled="isSaving"
              type="button"
              class="button is-primary"
              @click="upload"
            >
              <span class="icon is-small"><i class="fa fa-save"/></span
              ><span>Προσθήκη</span>
            </button>
          </div>
          <div class="control">
            <button type="button" class="button" @click="reset">
              <span class="icon is-small"><i class="fa fa-undo"/></span
              ><span>Επαναφορά</span>
            </button>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import Photo from '@/views/components/Photo';
import FormSubmit from '@/views/components/FormSubmit';

export default {
  components: {
    Photo,
    FormSubmit,
  },

  props: {
    id: {
      type: String,
      required: false,
      default: 'file',
    },
    photos: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    rule: {
      type: String,
      required: false,
      default() {
        return 'image/*';
      },
    },
    isOpen: Boolean,
    isSaving: Boolean,
  },

  data() {
    return {
      file: null,
      preview: null,
    };
  },

  computed: {
    hasPhotos() {
      return this.photos.length > 0;
    },

    buttonText() {
      const types = this.rule.split(',').map(type => type.split('/')[1]);

      if (types.length === 1 && types[0] === '*') {
        return 'Επέλεξε νέα φωτογραφία';
      }

      const typeText = types.length === 1 ? types[0] : types.join(' ή ');

      return `Επέλεξε φωτογραφία τύπου ${typeText}`;
    },
  },

  watch: {
    isOpen(newVal, oldVal) {
      if (newVal !== oldVal && newVal === false) {
        this.reset();
      }
    },
  },

  methods: {
    handleSelect(photo) {
      fetch(photo.url)
        .then(response => {
          if (!response.ok) {
            throw Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          this.file = new File([blob], 'image', {
            type: blob.type,
          });

          this.createPreview(this.file);
        })
        .catch(err => console.error(err));
    },

    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      [this.file] = files;

      this.createPreview(this.file);
    },

    createPreview(file) {
      const reader = new FileReader();

      this.$validator
        .validateAll()
        .then(() => {
          reader.onload = e => {
            this.preview = e.target.result;
          };
          reader.readAsDataURL(file);
        })
        .catch(err => console.log(err));
    },

    upload() {
      this.$emit('upload', this.file);
    },

    closeUploadModal() {
      this.$emit('closeUploadModal');

      this.reset();
    },

    reset() {
      this.errors.clear();
      this.file = null;
      this.preview = null;
    },
  },
};
</script>

<style lang="scss" type="text/scss" scoped>
img {
  max-width: 100%;
}

.image-container {
  background-color: #fafafa;
  height: 200px;
  position: relative;
  padding: 40px 0 0;
  border: 2px dashed #dddddd;

  .button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  input {
    opacity: 0;
    border: 1px solid red;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  label {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:hover {
      cursor: pointer;
    }
  }
}
.images {
  &__list {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    height: 84px;
    margin-bottom: 20px;
    overflow: auto;
  }

  &__item {
    flex: 0 0 auto;
    padding-left: 5px;
    padding-right: 5px;
  }

  &__btn {
    background-color: #fff;
    border: none;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    height: 62px;
    padding: 5px;
    width: 62px;
  }
}
</style>
