<template>
  <multiselect
    v-validate="'required'"
    :options="customers"
    :searchable="true"
    :internal-search="false"
    :option-height="104"
    :show-labels="false"
    :loading="isSearching"
    :options-limit="50"
    :value="customer"
    track-by="id"
    label="fullName"
    placeholder="Αναζήτηση με χρήση email, oνοματεπώνυμου..."
    select-label="Πατήστε enter για επιλογή"
    data-vv-value-path="value"
    data-vv-name="customer"
    @search-change="searchCustomers"
    @select="selectCustomer"
  >
    <template v-slot:option="props" class="option">
      <div class="option__desc">
        <span class="option__title">{{ props.option.fullName }}</span>
        <span class="option__small">{{
          props.option.emails | primaryEmail
        }}</span>
      </div>
    </template>
    <template v-slot:noResult>
      Δε βρέθηκαν αποτελέσματα. Προσπαθήστε ξανά!!
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import _debounce from 'lodash/debounce';
import request from '@/utils/request';
import _trim from 'lodash/trim';

export default {
  inject: ['$validator'],
  components: {
    Multiselect,
  },
  props: {
    customer: Object,
  },
  data() {
    return {
      customers: [],
      isSearching: false,
    };
  },
  methods: {
    // eslint-disable-next-line func-names
    searchCustomers: _debounce(async function(searchQuery) {
      const term = _trim(searchQuery);

      if (term.length) {
        this.isSearching = true;

        const { data } = await request.get('/customers-search', {
          params: {
            term,
          },
        });
        this.isSearching = false;

        this.customers = data.customers;
      }
    }, 300),
    selectCustomer(selectedOption) {
      this.$emit('selectCustomer', selectedOption);
    },
  },
};
</script>
