<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Συναλλαγές
        </div>
      </div>
      <div class="card-header-icon">
        <button
          v-if="!order.transaction"
          class="button"
          @click="askToAddTransaction"
        >
          <span class="icon is-small"><i class="fa fa-money"/></span
          ><span>Πληρωμή</span>
        </button>
      </div>
    </div>
    <div class="card-content">
      <table v-if="order.transaction" class="table is-fullwidth">
        <thead>
          <th class="has-text-centered">Ημερομηνία</th>
          <th class="has-text-centered">Τρόπος Πληρωμής</th>
          <th class="has-text-centered">Ποσό (&euro;)</th>
          <th class="has-text-centered">Περιγραφή</th>
        </thead>
        <tbody>
          <tr>
            <td class="has-text-centered">
              {{ order.transaction.created_at | timestamp }}
            </td>
            <td class="has-text-centered">
              <img
                v-tooltip="{ content: order.checkout_method.description }"
                :src="order.checkout_method.icon"
                class="method-icon"
              />
            </td>
            <td class="has-text-centered">{{ order.sum_total }}</td>
            <td class="has-text-centered">
              {{ order.transaction.description }}
            </td>
          </tr>
        </tbody>
      </table>
      <h4 v-else class="has-text-centered">
        Δεν έχει γίνει πληρωμή
      </h4>
    </div>

    <Confirmation
      :is-open="isOpen"
      title="Επιβεβαίωση Πληρωμής"
      content="Είστε βέβαιος για την εξόφληση αυτής της παραγγελίας;"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Confirmation from '@/views/components/Confirmation';

export default {
  components: {
    Confirmation,
  },
  props: {
    order: Object,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    ...mapActions(['addTransaction']),
    askToAddTransaction() {
      this.isOpen = true;
    },
    async closeModal(response) {
      try {
        this.isOpen = false;

        if (response) {
          await this.addTransaction();
          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Η πληρωμή αποθηκεύτηκε επιτυχώς!!',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
