<template>
  <tr :class="{ 'is-selected': selected, 'hidden-print': !selected }">
    <td class="hidden-print has-text-centered">
      <checkbox v-model="selected" name="selected" />
    </td>
    <td class="has-text-centered">
      <ProductPhoto :type="item.type" :photos="item.photos" />
    </td>
    <td class="has-text-centered">{{ item.common_id }}</td>
    <td class="has-text-centered" style="maxWidth: 250px">
      <div v-if="item.subsets.length">
        <p v-for="product in item.subsets" :key="product.id">
          <router-link
            :to="{ name: 'products.edit', params: { uuid: product.uuid } }"
          >
            <i class="fa fa-angle-double-right" /> {{ product.title }}
          </router-link>
        </p>
      </div>
      <div v-else>Δε βρέθηκαν προϊόντα</div>
    </td>
    <td class="has-text-centered">{{ item.sell_price }}</td>
    <td class="has-text-centered">{{ item.discount }} &#x25;</td>
    <td class="has-text-centered">{{ item.final_price }}</td>
    <td v-if="!isDeleted" class="has-text-centered hidden-print">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        disabled
      />
    </td>
    <td class="has-text-centered visible-print">
      {{ item.active === true ? 'Ναί' : 'Όχι' }}
    </td>
    <td class="has-text-centered hidden-print">
      <div class="buttons is-centered">
        <router-link
          :to="{ name: 'products.edit', params: { uuid: item.uuid } }"
          class="button is-small"
        >
          <span class="icon is-small">
            <span class="fa fa-fw fa-pencil" />
          </span>
        </router-link>
        <button v-if="!isDeleted" class="button is-small" @click="onDelete">
          <span class="icon is-small">
            <span class="fa fa-fw fa-trash-o" />
          </span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import ProductPhoto from '@/views/components/ProductPhoto';
import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';

export default {
  components: {
    ProductPhoto,
  },
  extends: PaginatedListItem,
};
</script>
