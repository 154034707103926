import request from '@/utils/request';
import router from '@/router';

/* eslint-disable */
const actions = {
  async addCustomer({ dispatch, commit }, customer) {
    try {
      const { data } = await request.post('customers', customer);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      router.push({ name: "customers.edit", params: { uuid: data.customer.uuid } });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
