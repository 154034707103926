import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async updateBanner({ dispatch, commit }, { uuid, banner }) {
    try {
      const formData = new FormData();
      formData.append('title', banner.title);
      formData.append(
        'title_en',
        banner.title_en === null ? '' : banner.title_en,
      );
      formData.append('link', banner.link === null ? '' : banner.link);
      formData.append('active', banner.active.toString());
      formData.append('image', banner.image);

      const { data } = await request.post(
        `/gift-actions-banners/${uuid}`,
        formData,
      );

      if (data.error) {
        throw Error('Server-side validation failed');
      }
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
