/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setPage(state, { page }) {
    state.data = page;
  },

  updatePage(state, { page }) {
    state.data = {
      ...state.data,
      ...page,
    };
  },
};

export default mutations;
