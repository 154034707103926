/* eslint-disable */
const getters = {
  getCheckoutMethods(state) {
    return state.data;
  },

  getActiveCheckoutMethods(state) {
    return state.data.filter(({ active }) => active === 1);
  },
};

export default getters;
