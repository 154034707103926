<template>
  <div :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background" @click="closeModal()" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Εξερχόμενο Email</p>
        <button class="delete" @click="closeModal()" />
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">Αποδέκτης</label>
          <div class="control">
            <input :value="receiver" class="input" type="text" disabled />
          </div>
        </div>

        <div class="field">
          <label class="label">Ημερομηνία</label>
          <div class="control">
            <input
              :value="email.created_at | timestamp"
              class="input"
              type="text"
              disabled
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Τίτλος</label>
          <div class="control">
            <input :value="email.title" class="input" type="text" disabled />
          </div>
        </div>

        <div class="field">
          <label class="label">Περιεχόμενο</label>
          <div class="control">
            <textarea
              :value="email.content"
              class="textarea"
              type="text"
              disabled
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmailPreview',
  props: {
    isOpen: false,
    customer: Object,
    email: Object,
  },
  computed: {
    receiver() {
      return `${this.customer.fullName} | ${this.customer.email}`;
    },
  },
  methods: {
    closeModal(deleteResponse) {
      this.$emit('closeModal', deleteResponse);
    },
  },
};
</script>
