import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getAuthor({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/authors/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setAuthor', {
        author: data.author,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getAuthorPhotos({ dispatch, commit }, { uuid }) {
    try {
      const { data } = await request.get(`/authors/${uuid}/photos`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateAuthor', {
        author: data.author,
      });
    } catch (err) {
      throw err;
    }
  },

  async updateAuthor({ dispatch, commit }, { uuid, author }) {
    try {
      const response1 = await request.put(`/authors/${uuid}`, author);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/authors/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateAuthor', {
        author: response2.data.author,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
