import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async updatePhoto({ dispatch, commit }, { id, photo }) {
    try {
      this.isLoading = true;
      const response = await request.put(`/photos/${id}`, photo);

      if (response.data.error) {
        throw Error('Server-side validation failed');
      }
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
