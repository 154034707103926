<template>
  <div
    :class="{ 'is-danger': hasError, 'is-success': hasSuccess }"
    class="notification"
  >
    <button v-if="hasCloseBtn" class="delete" type="button" @click="dismiss" />
    <template v-if="content">
      {{ content }}
    </template>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: '',
    },
    hasCloseBtn: {
      type: Boolean,
      default: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    hasSuccess: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    dismiss() {
      this.$emit('hideNotification');
    },
  },
};
</script>
