<!-- eslint-disable -->
<template>
  <div>
    <div class="card-content">
      <LoadingContainer :is-loading="isLoadingModel || isLoadingItems">
        <div class="columns is-multiline">
          <div class="column is-full">
            <brand-info :brand="model" />
          </div>
        </div>

        <div class="columns">
          <div class="column is-three-quarters">
            <form class="has-sticky-submit" novalidate @submit.prevent="submit">
              <div class="card">
                <div class="card-header">
                  <div class="card-header-title">
                    <div class="title is-6 is-marginless">
                      Επεξεργασία Μάρκας
                      <span class="tag">
                        <strong>{{ model.title }}</strong>
                      </span>
                      <p>
                        Μόνο για την σελίδα των προϊόντων (e.g.
                        carespot.gr/brand/products)
                      </p>
                    </div>
                  </div>
                  <div class="card-header-icon">
                    <div class="field has-addons is-marginless">
                      <div class="control">
                        <router-link
                          :to="{ name: 'products.brands.list' }"
                          class="button"
                        >
                          <span class="icon is-small">
                            <i class="fa fa-list-ul" />
                          </span>
                          <span>Λίστα</span>
                        </router-link>
                      </div>
                      <div class="control">
                        <router-link
                          :to="{
                            name: 'products.brands.collections.add',
                            params: { uuid: $route.params.uuid },
                          }"
                          class="button"
                        >
                          <span class="icon is-small"
                            ><i class="fa fa-plus"/></span
                          ><span>Προσθήκη Σειράς</span>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-content">
                  <BrandsEditMenu />
                  <EditGeneralDetailsProductsPage
                    :general="general"
                    :orders="orders"
                    :can-activate="canActivate"
                    @updateGeneral="updateGeneral"
                  />
                </div>
              </div>

              <ToggleableCard title="Meta">
                <div class="card-content">
                  <EditMetaDetailsBrandProductsPage
                    :meta="meta"
                    @updateMeta="updateMeta"
                  />
                </div>
              </ToggleableCard>

              <ToggleableCard title="Social Meta">
                <div class="card-content">
                  <EditSocialMetaDetailsBrandProductsPage
                    :social-meta="socialMeta"
                    :is-read-only="true"
                    @updateSocialMeta="updateSocialMeta"
                  />
                </div>
              </ToggleableCard>

              <StickyFormFooter>
                <template v-slot:left>
                  <FormSubmit :is-saving="isSaving" @reset="reset" />
                </template>
                <template v-slot:right>
                  <a
                    v-tooltip="'Προβολή στο e-shop'"
                    :href="model.path"
                    target="_blank"
                  >
                    {{ model.title }} <i class="fa fa-arrow-right" />
                  </a>
                </template>
              </StickyFormFooter>
            </form>
          </div>
          <div class="column">
            <div class="card">
              <div class="card-header">
                <div class="card-header-title">
                  Dynamic Variables
                </div>
              </div>
              <div class="card-content">
                <strong>Available Variables</strong>
                <br />
                <ul>
                  <li>[brand_name]</li>
                  <li>[discount_percentage]</li>
                  <li>[count_of_products]</li>
                </ul>

                <br />

                <strong>H1 (tag) (sample)</strong>
                <br />
                <p>
                  Προϊόντα [brand_name]
                </p>

                <br />

                <strong>Περιεχόμενο (p tag) (sample)</strong>
                <br />
                <p>
                  Βρες ανάμεσα σε [count_of_products] προϊόντα από την κατηγορία
                  [brand_name] αυτό που σου ταιριάζει στις καλύτερες τιμές! Κάνε
                  τις αγορές σου εύκολα & γρήγορα κερδίζοντας πόντους σε κάθε
                  σου παραγγελία με το πρόγραμμα care-δίζω και επωφελήσου από τα
                  μοναδικά προνόμια.
                </p>

                <br />

                <strong>Meta Title (sample)</strong>
                <br />
                <p>
                  [brand_name] Προϊόντα Σε Προσφορές Έως -[discount_percentage]%
                </p>

                <br />

                <strong>Meta Description (sample)</strong>
                <br />
                <p>
                  Διάλεξε ανάμεσα σε [count_of_products] προϊόντα [brand_name]
                  με εκπτώσεις έως -[discount_percentage]%! Άμεση παράδοση,
                  μοναδική εξυπηρέτηση & τεράστια ποικιλία στις καλύτερες τιμές.
                </p>
              </div>
            </div>
          </div>
        </div>
      </LoadingContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { clone, get, isEmpty, pick } from 'lodash';
import slug from 'slug';
import AddItem from '@/views/components/AddItem';
import EditGeneralDetailsProductsPage from '@/views/components/EditGeneralDetailsProductsPage';
import EditRedirectionDetails from '@/views/components/EditRedirectionDetails';
import EditMetaDetailsBrandProductsPage from '@/views/components/EditMetaDetailsBrandProductsPage';
import EditSocialMetaDetailsBrandProductsPage from '@/views/components/EditSocialMetaDetailsBrandProductsPage';
import EditPhotos from '@/views/components/EditPhotos';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import StickyFormFooter from '@/views/components/StickyFormFooter';
import BrandInfo from './components/BrandInfo';
import ExistingBrands from '../../components/ExistingBrands';
import ExistingCollections from '../../components/ExistingCollections';
import BrandsEditMenu from '../../components/BrandsEditMenu';

export default {
  components: {
    BrandInfo,
    EditRedirectionDetails,
    EditMetaDetailsBrandProductsPage,
    EditSocialMetaDetailsBrandProductsPage,
    EditGeneralDetailsProductsPage,
    EditPhotos,
    ToggleableCard,
    LoadingContainer,
    StickyFormFooter,
    ExistingBrands,
    ExistingCollections,
    BrandsEditMenu,
  },

  extends: AddItem,

  data() {
    return {
      redirection: {},
      meta: {},
      socialMeta: {},
      general: {},
      oldOrder: 0,
      photos: [],
      isLoadingPhotos: false,
    };
  },

  computed: {
    ...mapGetters({
      model: 'brands/edit/getBrand',
      orders: 'brands/edit/getOrders',
      items: 'brands/all/getBrands',
      collections: 'collections/all/getCollections',
      isLoadingModel: 'brands/edit/getIsLoading',
      isLoadingItems: 'brands/all/getIsLoading',
    }),

    canActivate() {
      /* eslint-disable camelcase */
      return get(this.model, 'photos', []).some(
        ({ is_avatar }) => is_avatar === true,
      );
    },

    hasChangedTitle() {
      return this.model.title !== this.general.title;
    },

    hasModelSlug() {
      return this.model.slug;
    },
  },

  watch: {
    $route: 'fetchData',

    model: {
      handler(newVal) {
        this.general = pick(newVal, [
          'title',
          'slug',
          'description',
          'content',
          'description_for_brand_products_page',
          'content_for_brand_products_page',
          'active',
          'order',
        ]);

        this.oldOrder = this.general.order;

        this.redirection = clone(
          newVal.redirection || {
            new: null,
            old: null,
          },
        );
        this.meta = clone(newVal.meta);
        this.socialMeta = clone(newVal.social_meta);

        this.photos = clone(this.model.photos);
      },
      deep: true,
    },

    'general.title': {
      handler(newVal, oldVal) {
        if (!isEmpty(this.model) && newVal !== oldVal) {
          if (
            (!this.hasChangedTitle && !this.hasModelSlug) ||
            this.hasChangedTitle
          ) {
            const newSlug = slug(newVal.toLowerCase());
            this.general.slug = newSlug;

            const newRedirection = {
              ...this.model.redirection,
              new: `https://carespot.gr/${newSlug}`,
            };

            this.updateRedirection(newRedirection);

            const titleRegExp = new RegExp(this.model.title, 'g');
            const slugRegExp = new RegExp(this.model.slug, 'g');

            const newMeta = {
              ...this.model.meta,
              title: this.model.meta.title_for_brand_products_page.replace(
                titleRegExp,
                newVal,
              ),
              description: this.model.meta.description_for_brand_products_page.replace(
                titleRegExp,
                newVal,
              ),
              keywords: this.model.meta.keywords.replace(titleRegExp, newVal),
              schema: this.model.meta.schema.replace(slugRegExp, newSlug),
            };

            this.updateMeta(newMeta);

            const newSocialMeta = {
              ...this.model.social_meta,
              og_title: this.model.social_meta.og_title.replace(
                titleRegExp,
                newVal,
              ),
              og_description: this.model.social_meta.og_description.replace(
                titleRegExp,
                newVal,
              ),
              og_url: this.redirection.new,
              twitter_title: this.model.social_meta.twitter_title.replace(
                titleRegExp,
                newVal,
              ),
              twitter_description: this.model.social_meta.twitter_description.replace(
                titleRegExp,
                newVal,
              ),
            };

            this.updateSocialMeta(newSocialMeta);
          }

          if (!this.hasChangedTitle && this.hasModelSlug) {
            const values = pick(this.model, [
              'title',
              'slug',
              'description',
              'content',
              'description_for_brand_products_page',
              'content_for_brand_products_page',
              'active',
              'order',
            ]);

            this.general = {
              ...this.general,
              ...values,
            };

            this.oldOrder = this.general.order;

            this.redirection = clone(
              this.model.redirection || {
                new: null,
                old: null,
              },
            );
            this.meta = clone(this.model.meta);
            this.socialMeta = clone(this.model.social_meta);

            this.photos = clone(this.model.photos);
          }
        }
      },
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    ...mapActions({
      getBrand: 'brands/edit/getBrand',
      updateBrand: 'brands/edit/updateBrand',
      getBrandPhotos: 'brands/edit/getBrandPhotos',
      getBrands: 'brands/all/getBrands',
      getCollections: 'collections/all/getCollections',
    }),

    async fetchData() {
      try {
        await Promise.all([
          this.getBrand({ uuid: this.$route.params.uuid }),
          this.getBrands(),
          this.getCollections({ uuid: this.$route.params.uuid }),
        ]);
      } catch (err) {
        this.$router.push('/error');
      }
    },

    async submit() {
      try {
        const [isValid] = await Promise.all(
          this.$children.map(child => child.$validator.validateAll()),
        );

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        const order = {
          old: this.oldOrder,
          new: this.general.order,
        };

        await this.updateBrand({
          uuid: this.model.uuid,
          brand: {
            ...this.general,
            order,
            redirection: this.redirection,
            meta: this.meta,
            socialMeta: this.socialMeta,
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η μάρκα αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.general = pick(this.model, [
        'title',
        'slug',
        'description',
        'content',
        'description_for_brand_products_page',
        'content_for_brand_products_page',
        'active',
        'order',
      ]);

      this.oldOrder = this.general.order;

      this.meta = clone(this.model.meta);
      this.socialMeta = clone(this.model.social_meta);
      this.photos = clone(this.model.photos);

      this.$children.forEach(child => child.$validator.reset());
    },

    updateRedirection(redirection) {
      this.redirection = redirection;
    },

    updateMeta(meta) {
      this.meta = meta;
    },

    updateSocialMeta(socialMeta) {
      this.socialMeta = socialMeta;
    },

    updateGeneral(general) {
      this.general = general;
    },

    async updatePhotos() {
      try {
        this.isLoadingPhotos = true;
        await this.getBrandPhotos({ uuid: this.$route.params.uuid });
        this.isLoadingPhotos = false;
      } catch (err) {
        this.isLoadingPhotos = false;
      }
    },
  },
};
</script>
