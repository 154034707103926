<template>
  <stats-card title="Ανά Τρόπο Αποστολής" @update="update">
    <template v-slot:graph v-if="chartData">
      <pie-graph :chart-data="chartData" :options="options" :height="200" />
    </template>
  </stats-card>
</template>

<script>
import request from '@/utils/request';
import StatsCard from '@/views/components/StatsCard';
import PieGraph from '@/views/components/PieGraph';

export default {
  components: {
    StatsCard,
    PieGraph,
  },
  data() {
    return {
      chartData: null,
      options: {
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              return `${data.labels[tooltipItem.index]}: ${
                data.datasets[0].data[tooltipItem.index]
              }%`;
            },
          },
        },
        plugins: {
          datalabels: {
            color: '#fff',
            formatter(value) {
              return `${value}%`;
            },
          },
        },
      },
    };
  },
  methods: {
    async update() {
      const { data } = await request.get('/orders-stats/sendMethods');

      this.chartData = {
        datasets: [
          {
            data: data.data,
            backgroundColor: [
              '#ff3860',
              '#22A684',
              '#209cee',
              '#ffdd57',
              '#ccc',
            ],
          },
        ],
        labels: data.labels,
      };
    },
  },
};
</script>
