<template>
  <div v-sticky sticky-offset="offset">
    <nav class="level">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">UUID</p>
          <p class="title">{{ category.uuid }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">STATUS</p>
          <p class="title">
            <span :class="['tag is-medium', classes]">{{ status }}</span>
          </p>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    category: Object,
  },
  data: () => ({
    offset: { top: 45 },
  }),
  computed: {
    status() {
      const { category } = this;
      /* eslint-disable */
      if (category.active === true) {
        return 'Εμφανής Κατηγορία';
      } else if (category.active === false) {
        return 'Μη Εμφανής Κατηγορία';
      } else {
        return 'Κατηγορία';
      }
    },
    classes() {
      const category = this.category;
      /* eslint-disable */
      if (category.active === true) {
        return 'is-primary';
      } else if (category.active === false) {
        return 'is-warning';
      } else {
        return 'is-white';
      }
    },
  },
};
</script>
