import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getFilter({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/filters/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setFilter', {
        filter: data.filter,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async updateFilter({ dispatch, commit, rootGetters }, { uuid, newFilter }) {
    try {
      const { data } = await request.put(`/filters/${uuid}`, newFilter);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateFilter', {
        filter: newFilter,
      });

      const filters = rootGetters['filters/all/getFilters'].map(f => {
        if (uuid === f.uuid) {
          return {
            ...f,
            ...newFilter,
          };
        }

        return f;
      });

      await commit('filters/all/setData', filters, {
        root: true,
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};

export default actions;
