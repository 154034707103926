<script>
import { mapGetters, mapActions } from 'vuex';
import request from '@/utils/request';
import PaginatedList from '@/views/components/PaginatedList';
import ProductsHeader from './components/ProductsHeader';
import Product from './components/Product';

export default {
  components: {
    ProductsHeader,
    Product,
  },
  extends: PaginatedList,
  computed: {
    ...mapGetters({
      items: 'products/list/getData',
      paginationParams: 'products/list/getPaginationParams',
      isLoadingProducts: 'products/list/getIsLoading',
      isLoaded: 'products/list/getIsLoaded',
      availabilities: 'getAvailabilities',
      taxes: 'taxes/list/getTaxes',
    }),
  },
  methods: {
    ...mapActions({
      massUpdateProducts: 'products/list/massUpdateProducts',
    }),

    resetUpdateOptions() {},

    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;

        if (response) {
          await request.delete(`/products/${this.selectedItem.uuid}`);

          await this.navigate({
            timestamp: new Date().getTime(),
          });

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Το προϊόν διαγράφτηκε επιτυχώς!!',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    async closeRestoreModal(response) {
      try {
        this.isRestoreModalOpen = !this.isRestoreModalOpen;

        if (response) {
          await request.put(`/products/${this.selectedItem.uuid}/restore`);

          await this.navigate({
            timestamp: new Date().getTime(),
          });

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Η επαναφορά προϊόντος εκτελέστηκε επιτυχώς!!',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    prepareExportXLS() {
      this.exportXLS(
        this.selections,
        [
          {
            key: 'barcode',
            value: 'barcodes[0].code',
          },
          'title',
          'buy_price',
          'sell_price',
          'final_price',
          'stock',
          'discount',
          'active',
        ],
        'products',
      );
    },

    prepareExportCSV() {
      this.exportCSV(
        this.selections,
        [
          {
            key: 'barcode',
            value: 'barcodes[0].code',
          },
          'title',
          'buy_price',
          'sell_price',
          'final_price',
          'discount',
          'active',
        ],
        'products',
      );
    },

    async update(updates) {
      try {
        this.isUpdating = true;
        await this.massUpdateProducts({
          selections: this.selections,
          updates,
        });
        this.isUpdating = false;

        await this.navigate({
          timestamp: new Date().getTime(),
        });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'H μαζική ενημέρωση προιόντων εκελέστηκε',
        });
      } catch (err) {
        this.isUpdating = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
