export const MIN_COST_FOR_FREE_SHIPPING = 49;

export const PAY_ON_DELIVERY_CHARGE_FOR_GREECE = 1.5;

export const PAY_ON_DELIVERY_CHARGE_FOR_CYPRUS = 3;

export const GIFT_COST = 1;

export const BOX_NOW_CHARGE_FOR_GREECE = 2.3;

export const BOX_NOW_CHARGE_FOR_CYPRUS = 8;
