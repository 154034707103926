<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Συχνές Ερωτήσεις <span class="tag">Επεξεργασία Ερώτησης</span>
        </div>
      </div>
      <div class="card-header-icon">
        <div class="field has-addons is-marginless">
          <div class="control">
            <router-link :to="{ name: 'content.faqs.list' }" class="button">
              <span class="icon is-small"><i class="fa fa-list"/></span
              ><span>Λίστα</span>
            </router-link>
          </div>
          <div class="control">
            <button class="button" @click="askToDeleteItem">
              <span class="icon is-small"><i class="fa fa-trash"/></span>
              <span>Διαγραφή</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="field">
          <label class="label">Ερώτηση *</label>
          <div class="control">
            <textarea
              v-validate="'required'"
              v-model.trim="newFaq.title"
              class="textarea"
              type="text"
              name="title"
            />
            <p v-show="errors.has('title')" class="help is-danger">
              Εισάγετε ερώτηση
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Απάντηση *</label>
          <div class="control">
            <VueEditor
              v-validate="'required'"
              id="editor"
              v-model="newFaq.content"
              :editor-toolbar="toolbarOptions"
              data-vv-value-path="value"
              data-vv-name="content"
              @input="changeContent"
            />
            <p v-show="errors.has('content')" class="help is-danger">
              Εισάγετε απάντηση
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Προβολή *</label>
          <div class="control">
            <div class="select select is-fullwidth">
              <select
                v-validate="'required'"
                v-model.trim="newFaq.active"
                name="active"
              >
                <option :value="true">Ναί</option>
                <option :value="false">Όχι</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>

    <confirmation
      :model-name="faq.content"
      :is-open="isOpen"
      description="Είστε βέβαιος για τη διαγραφή αυτής της ερώτησης;"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import _pick from 'lodash/pick';
import { VueEditor } from 'vue2-editor';
import request from '@/utils/request';
import EditItem from '@/views/components/EditItem';
import toolbarOptions from '@/constants/toolbarOptions';

export default {
  components: {
    VueEditor,
  },
  extends: EditItem,
  data() {
    return {
      faq: {},
      newFaq: {
        title: '',
        content: '',
        active: '',
      },
      toolbarOptions,
    };
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const { data } = await request.get(`/faqs/${to.params.uuid}`);
      next(vm => vm.setData(data));
    } catch (err) {
      next(vm => vm.$router.push('/error'));
    }
  },
  methods: {
    ...mapActions({
      updateFaq: 'faqs/edit/updateFaq',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();
        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateFaq({
          ...this.newFaq,
          uuid: this.faq.uuid,
        });
        this.isSaving = false;

        this.$router.push({ name: 'content.faqs.list' });
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η απάντηση αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      const { faq } = this;
      this.newFaq = _pick(faq, ['title', 'content', 'active']);

      this.$validator.reset();
    },
    setData({ faq }) {
      this.faq = faq;
      this.newFaq = _pick(faq, ['title', 'content', 'active']);
    },
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;
        if (response) {
          await request.delete(`/faqs/${this.faq.uuid}`);
          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Η ερώτηση διαγράφτηκε',
          });
          this.$router.push({ name: 'content.faqs.list' });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    changeContent(content) {
      this.newFaq = {
        ...this.newFaq,
        content: content === '<p><br></p>' ? '' : content,
      };
    },
  },
};
</script>
