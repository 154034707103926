<template>
  <div class="customer-tab">
    <table
      v-if="customer.orders.length"
      class="table is-bordered is-striped is-marginless is-fullwidth"
    >
      <thead>
        <tr>
          <th class="has-text-centered">Τύπος</th>
          <th class="has-text-centered">Κωδικός</th>
          <th class="has-text-centered">Ημ/νια</th>
          <th class="has-text-centered">Guest Checkout</th>
          <th class="has-text-centered">Τρόπος Πληρωμής</th>
          <th class="has-text-centered">Τρόπος Αποστολής</th>
          <th class="has-text-centered">Μέθοδος Παραγγελίας</th>
          <th class="has-text-centered">Τεμάχια</th>
          <th class="has-text-centered">Αξία (&euro;)</th>
          <th class="has-text-centered hidden-print">Κατάσταση</th>
          <th class="has-text-centered hidden-print">Ενέργειες</th>
        </tr>
      </thead>
      <tbody>
        <order
          v-for="(item, index) in customer.orders"
          :item="item"
          :index="index"
          :key="item.id"
        />
      </tbody>
    </table>
    <h5 v-else class="title is-5 has-text-centered is-marginless">
      Δε βρέθηκαν παραγγελίες
    </h5>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Order from './components/Order';

export default {
  components: {
    Order,
  },
  computed: {
    ...mapGetters({
      customer: 'customers/edit/getCustomer',
    }),
  },
};
</script>
