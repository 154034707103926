/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setCategory(state, { category, orders }) {
    state.data = category;
    state.orders = orders;
  },

  updateCategory(state, { category }) {
    state.data = {
      ...state.data,
      ...category,
    };
  },

  updateCategoryPhotos(state, { photos }) {
    state.data.photos = photos;
  },
};

export default mutations;
