<template>
  <img :src="path" :width="width" :height="height" />
</template>

<script>
import isEmpty from 'lodash/isEmpty';

export default {
  props: {
    photos: Array,
    photo: Object,
    width: {
      type: Number,
      default: 50,
    },

    height: {
      type: Number,
      default: 50,
    },
  },
  computed: {
    path() {
      // fallback image
      let path = `${process.env.VUE_APP_API_URL}image/general/logo.png`;

      if (!isEmpty(this.photo)) {
        path = this.photo.default_url;
      }

      if (!isEmpty(this.photos)) {
        // eslint-disable-next-line camelcase
        const avatar = this.photos.find(({ is_avatar }) => is_avatar === true);

        if (avatar) {
          if (!avatar.url) {
            path = avatar.default_url;
          } else {
            path = avatar.url;
          }
        } else if (!this.photos[0].url) {
          path = this.photos[0].default_url;
        } else {
          path = this.photos[0].url;
        }
      }

      return path;
    },
  },
};
</script>

<style scoped>
img {
  height: auto;
  width: 100%;
}
</style>
