<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Κατάσταση</div>
    </div>
    <div class="card-content">
      <LoadingContainer
        :is-loading="isLoading"
        :is-opaque="true"
        :is-vertically-aligned="true"
      >
        <table class="table is-fullwidth">
          <tbody>
            <status
              v-for="(item, index) in statuses"
              :key="index"
              :item="item"
              :index="index"
              :order="order"
              :is-deleted="isDeleted"
            />
          </tbody>
        </table>
      </LoadingContainer>
    </div>
  </div>
</template>

<script>
import LoadingContainer from '@/views/components/LoadingContainer';
import Status from './components/Status';

export default {
  name: 'OrderStatuses',

  components: {
    LoadingContainer,
    Status,
  },

  props: {
    order: Object,
    statuses: Array,
    isDeleted: Boolean,
  },

  data: () => ({
    isLoading: false,
  }),
};
</script>
