/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setColor(state, { color }) {
    state.data = color;
  },

  updateColor(state, { color }) {
    state.data = {
      ...state.data,
      ...color,
    };
  },
};

export default mutations;
