import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getPost({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/posts/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setPost', {
        post: data.post,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getPostPhotos({ dispatch, commit }, { uuid }) {
    try {
      const { data } = await request.get(`/posts/${uuid}/photos`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updatePostPhotos', {
        photos: data.photos,
      });
    } catch (err) {
      throw err;
    }
  },

  async updatePost({ dispatch, commit }, { uuid, post }) {
    try {
      const response1 = await request.put(`/posts/${uuid}`, post);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/posts/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updatePost', {
        post: response2.data.post,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
