<template>
  <div>
    <div class="card hidden-print">
      <div class="card-content has-text-centered">
        <RouterLink
          v-slot="{ href, navigate }"
          :to="{ name: 'customers.edit', params: { uuid: customerId } }"
          custom
          class="title is-5 is-spaced"
        >
          <h5 role="link">
            <a :href="href" @click="navigate" @keypress.enter="navigate">
              {{ customer.name }}
            </a>
          </h5>
        </RouterLink>
        <div>
          <h3 class="sum title is-3 is-marginless">
            {{ customer.turnover }}
          </h3>
          <p>
            Σε {{ customer.total_orders }}
            {{ customer.total_orders === 1 ? 'παραγγελία' : 'παραγγελίες' }}
          </p>
          <hr />
          <p>Τελευταία Παραγγελία</p>
          <h6 class="title is-6 is-marginless">
            {{ customer.last_ordered_at }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      default() {
        return null;
      },
    },

    customerId: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.sum {
  margin: 0.25rem auto !important;
}

.is-spaced {
  margin-bottom: 0.5rem;
}

@media print {
  .no-print {
    display: none;
  }
}
</style>
