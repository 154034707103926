<template>
  <tr>
    <td class="has-text-centered">{{ index + 1 }}</td>
    <td class="has-text-centered">
      <img :src="product.image_url" />
    </td>
    <td class="has-text-centered print__cell-show">
      {{ product.id }}
    </td>
    <td class="has-text-centered" style="maxWidth: 250px">
      {{ product.name }}
    </td>
    <td class="has-text-centered">{{ product.price }}</td>
    <td class="has-text-centered">{{ product.discount }}</td>
    <td class="has-text-centered">{{ product.after_discount_price }}</td>
    <td class="has-text-centered">{{ product.vat }}</td>
    <td class="has-text-centered td__count">
      <p class="is-5 title">&times; {{ product.amount }}</p>
    </td>
    <td class="has-text-centered">
      <strong>{{ product.total }}</strong>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.td {
  &__count {
    max-width: 100px;
  }
}

.print__cell-show {
  display: none;

  @media print {
    display: table-cell;
  }
}
</style>
