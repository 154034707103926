<script>
import { Pie, mixins } from 'vue-chartjs';
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';

const { reactiveProp } = mixins;

export default Pie.extend({
  mixins: [reactiveProp],
  props: ['options'],
  data: () => ({
    plugins: [ChartJsPluginDataLabels],
  }),
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
});
</script>
