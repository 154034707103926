<template>
  <multiselect
    :options="products"
    :searchable="true"
    :internal-search="false"
    :option-height="104"
    :loading="isSearching"
    :options-limit="50"
    :reset-after="true"
    track-by="id"
    label="title"
    placeholder="Επιλέξτε προιόν με χρήση barcode, τίτλου..."
    select-label="Πατήστε enter για επιλογή"
    @search-change="handleSearchProducts"
    @select="handleSelectProduct"
  >
    <template v-slot:option="props" class="option">
      <Photo :photos="props.option.photos" class="option__image" />
      <div class="option__desc">
        <span class="option__title">{{ props.option.title }}</span>
        <span class="option__small">{{ props.option.barcode }}</span>
      </div>
    </template>
    <template v-slot:noResult>
      Δε βρέθηκαν αποτελέσματα. Προσπαθήστε ξανά!!
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { debounce, pick } from 'lodash';
import request from '@/utils/request';
import Photo from '@/views/components/Photo';

export default {
  components: {
    Multiselect,
    Photo,
  },

  props: {
    productId: {
      type: Number,
      required: true,
    },

    family: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      products: [],
      isSearching: false,
    };
  },

  methods: {
    // eslint-disable-next-line func-names
    handleSearchProducts: debounce(async function(searchQuery) {
      if (searchQuery.length) {
        const products = this.family.products.reduce((acc, item) => {
          if (item.product) {
            return [...acc, item.product.id];
          }

          return acc;
        }, []);

        this.isSearching = true;
        const { data } = await request.get(
          `/products/${this.productId}/families/products-search`,
          {
            params: {
              term: searchQuery,
              products,
            },
          },
        );
        this.isSearching = false;

        this.products = data.products;
      }
    }, 300),

    handleSelectProduct(selectedOption) {
      this.$emit(
        'select',
        pick(selectedOption, ['id', 'uuid', 'title', 'photos', 'slug']),
      );

      this.products = [];
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect {
  margin-bottom: 0.75rem;

  ::v-deep .multiselect__tags {
    height: 2.25em;
  }
}
</style>
