/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setBrand(state, { brand, orders }) {
    state.data = brand;
    state.orders = orders;
  },

  updateBrand(state, { brand }) {
    state.data = {
      ...state.data,
      ...brand,
    };
  },

  updateBrandPhotos(state, { photos }) {
    state.data.photos = photos;
  },

  setBrandCategory(state, { brandCategory }) {
    state.brandCategory = brandCategory;
  },

  updateBrandCategory(state, { brandCategory }) {
    state.data.brandCategory = brandCategory;
  },
};

export default mutations;
