const Items = [
  {
    link: '/dashboard',
    label: 'Κεντρική',
    authority: [1, 2, 3],
  },
  {
    label: 'Παραγγελίες',
    link: '/orders',
    authority: [1, 2],
  },
  {
    label: 'Προιόντα',
    link: '/products',
    authority: [1, 2, 3],
    children: [
      {
        link: '/products',
        label: 'Προιόντα',
        authority: [1, 2, 3],
      },
      {
        link: '/products/categories',
        label: 'Κατηγορίες',
        authority: [1, 2, 3],
      },
      {
        link: '/products/brands',
        label: 'Μάρκες',
        authority: [1, 2, 3],
      },
      {
        link: '/products/symptoms',
        label: 'Συμπτώματα',
        authority: [1, 2, 3],
      },
      {
        link: '/products/filterGroups',
        label: 'Φίλτρα',
        authority: [1, 2, 3],
      },
      {
        link: '/products/sizes',
        label: 'Μεγέθη',
        authority: [1, 2, 3],
      },
      {
        link: '/products/colors',
        label: 'Χρώματα',
        authority: [1, 2, 3],
      },
    ],
  },
  {
    label: 'Online CRM',
    link: '/customers',
    authority: [1, 2],
    children: [
      {
        label: 'Πελάτες',
        link: '/customers',
        authority: [1, 2],
      },
      {
        label: 'Παράπονα',
        link: '/complaints',
        authority: [1, 2],
      },
      {
        label: 'Χρήστες',
        link: '/users',
        authority: [1],
      },
    ],
  },
  {
    label: 'Marketing',
    link: '/marketing',
    authority: [1, 2],
    children: [
      {
        label: 'Δώρα',
        link: '/marketing/gift-actions',
        authority: [1, 2],
      },
      {
        label: 'Κουπόνια',
        link: '/marketing/coupons',
        authority: [1, 2],
      },
      {
        label: 'Διαγωνισμοί',
        link: '/marketing/contests',
        authority: [1, 2],
      },
      {
        label: 'Bundles',
        link: '/marketing/bundles',
        authority: [1, 2, 3],
      },
      {
        label: 'Sets',
        link: '/marketing/sets',
        authority: [1, 2, 3],
      },
      {
        label: 'Loyalty',
        link: '/marketing/loyalty',
        authority: [1, 2, 3],
      },
      {
        label: "Week's Deal",
        link: '/marketing/weeks-deal',
        authority: [1, 2, 3],
      },
      {
        label: 'Marketing Pages',
        link: '/marketing/promos',
        authority: [1, 2, 3],
      },
      {
        label: 'Περιοδικές Εκπτώσεις',
        link: '/marketing/periodic-discounts',
        authority: [1, 2, 3],
      },
    ],
  },
  {
    label: 'Περιεχόμενο',
    link: '/content',
    authority: [1, 2, 3],
  },
  {
    label: 'Στατιστικά',
    link: '/stats',
    authority: [1, 2],
  },
  {
    label: 'Ρυθμίσεις',
    link: '/settings',
    authority: [1, 2],
  },
  {
    label: 'Ιστορικό',
    link: '/logs',
    authority: [1],
  },
  {
    label: 'Vouchers',
    link: '/vouchers',
    authority: [1, 2, 3],
  },
];

export default Items;
