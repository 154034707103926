<template>
  <tr>
    <td class="has-text-centered">{{ index }}</td>
    <td class="has-text-centered">
      <router-link
        :to="{ name: 'products.symptoms.edit', params: { uuid: item.uuid } }"
      >
        {{ item.title }}
      </router-link>
    </td>
    <td class="has-text-centered">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        :disabled="true"
      />
    </td>
    <td class="has-text-centered">
      <router-link
        :to="{ name: 'products.symptoms.edit', params: { uuid: item.uuid } }"
        class="button is-small"
      >
        <span class="icon is-small">
          <span class="fa fa-fw fa-pencil" />
        </span>
      </router-link>
    </td>
  </tr>
</template>

<script>
import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';

export default {
  extends: PaginatedListItem,
  props: {
    item: Object,
    index: Number,
  },
};
</script>
