<template>
  <div>
    <form novalidate class="has-sticky-submit" @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Επεξεργασία Προιόντος
            </div>
          </div>
        </div>
        <div class="card-content">
          <ProductsEditMenu />

          <div class="columns is-multiline">
            <div class="column is-full">
              <div class="field">
                <label class="label">Πολλαπλασιαστής Πόντων</label>
                <div class="control">
                  <input
                    v-model="points_multiplier"
                    class="input"
                    type="number"
                    step="1"
                    min="1"
                    name="points_multiplier"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EditItem from '@/views/components/EditItem';
import ProductsEditMenu from '../components/ProductsEditMenu';

export default {
  components: {
    ProductsEditMenu,
  },

  extends: EditItem,

  data() {
    return {
      points_multiplier: 1,
      isSaving: false,
    };
  },

  computed: {
    ...mapGetters({
      model: 'products/edit/getProduct',
    }),
  },

  watch: {
    model: {
      handler(newVal) {
        if (newVal.points_multiplier) {
          this.points_multiplier = newVal.points_multiplier;
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      updateProductLoyalty: 'products/edit/updateProductLoyalty',
    }),

    async submit() {
      try {
        this.isSaving = true;
        await this.updateProductLoyalty({
          uuid: this.model.uuid,
          payload: {
            points_multiplier: this.points_multiplier,
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το προιόν αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.points_multiplier = this.model.points_multiplier;
    },
  },
};
</script>
