<template>
  <tr>
    <td class="has-text-centered">{{ index }}</td>
    <td class="has-text-centered">
      {{ item.title }}
    </td>
    <td class="has-text-centered">{{ item.percentage }} %</td>
    <td class="has-text-centered">
      <ToggleButton
        :sync="true"
        :value="item.default === 1"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        :disabled="item.default === 1"
        @change="onChange"
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onChange() {
      this.$emit('makeDefaultTax', this.item);
    },
  },
};
</script>
