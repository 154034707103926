<template>
  <span v-tooltip="{ content: type.description }" class="type">
    {{ type.label }}
  </span>
</template>

<script>
export default {
  props: {
    item: Object,
    relationship: String,
  },
  computed: {
    type() {
      return this.item[this.relationship] === 'App\\Profile'
        ? { label: 'P', description: 'Profile' }
        : { label: 'G', description: 'Guest' };
    },
  },
};
</script>

<style scoped lang="scss">
.type {
  background-color: #eeeeee;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 500;
  height: 30px;
  line-height: 28px;
  width: 30px;
}
</style>
