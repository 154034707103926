<template>
  <div class="banner">
    <div class="has-text-centered banner-content">
      <img :src="preview" />
    </div>
    <div class="banner-footer is-hidden">
      <button class="button " type="button" @click="selectPhotoToDelete">
        <span class="icon is-small"><i class="fa fa-trash"/></span>
        <span>Διαγραφή φωτογραφίας</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    preview: {
      type: String,
      required: true,
    },
  },

  methods: {
    selectPhotoToDelete() {
      this.$emit('selectPhotoToDelete', this.preview);
    },
  },
};
</script>

<style scoped lang="scss">
.banner {
  display: flex;
  flex-direction: column;

  &-content {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 4px 4px 0 0;
    flex: 0;
    margin-bottom: 1.25rem;
    padding: 0;
    position: relative;

    img {
      max-height: 500px;
    }
  }

  &-footer {
    padding: 0;
    text-align: center;

    .button {
      margin-top: 5px;
    }
  }
}

.field {
  width: 100%;
}

.control {
  flex: 1;
}
</style>
