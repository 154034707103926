<template>
  <div class="card-header">
    <div class="card-header-title">
      <div class="title is-6 is-marginless">
        {{ title }}
        <span v-if="selections.length > 0" class="tag">
          {{ selections.length }} από {{ total }}
        </span>
        <span v-else class="tag">{{ total }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import PaginatedListHeader from '@/views/components/PaginatedList/components/PaginatedListHeader';

export default {
  extends: PaginatedListHeader,
};
</script>
