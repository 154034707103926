<template>
  <div>
    <form novalidate class="has-sticky-submit" @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Επεξεργασία Προιόντος
            </div>
          </div>
        </div>
        <div class="card-content">
          <ProductsEditMenu />
          <div v-if="!hasFamily" class="has-text-centered">
            <h4 class="title is-4 has-text-centered">
              Δε βρέθηκε family
            </h4>
            <button
              v-tooltip="'Προσθήκη Family'"
              class="button"
              type="button"
              @click="handleAddFamily"
            >
              <span class="icon is-small">
                <i class="fa fa-plus" />
              </span>
              <span>Προσθήκη Family</span>
            </button>
          </div>
          <div v-else>
            <Family
              :parent-product="model"
              :family="family"
              @update="handleUpdateFamily"
            />
            <div v-if="hasModelFamily" class="actions has-text-centered">
              <button
                v-tooltip="'Διαγραφή Family'"
                class="button"
                type="button"
                @click="askToDeleteItem"
              >
                <span class="icon is-small">
                  <i class="fa fa-trash-o" />
                </span>
                <span>Διαγραφή Family</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="hasFamily" class="card">
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </div>

      <confirmation
        :is-open="isOpen"
        description="Είστε βέβαιος για την διαγραφή αυτού του family;"
        @closeModal="closeModal"
      />
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isEmpty, pick } from 'lodash';
import EditItem from '@/views/components/EditItem';
import ProductsEditMenu from '../components/ProductsEditMenu';
import Family from './components/Family';

export default {
  components: {
    ProductsEditMenu,
    Family,
  },

  extends: EditItem,

  provide() {
    return {
      $validator: this.$validator,
    };
  },

  data() {
    return {
      family: null,
    };
  },

  computed: {
    ...mapGetters({
      model: 'products/edit/getProduct',
    }),

    hasFamily() {
      return !isEmpty(this.family) && !isEmpty(this.family.products);
    },

    hasModelFamily() {
      return !isEmpty(this.model.family);
    },
  },

  watch: {
    model: {
      handler(newVal) {
        this.family = newVal.family;
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      updateProductFamily: 'products/edit/updateProductFamily',
      deleteProductFamily: 'products/edit/deleteProductFamily',
    }),

    handleAddFamily() {
      this.family = {
        id: null,
        products: [
          {
            product: pick(this.model, [
              'id',
              'uuid',
              'title',
              'photos',
              'slug',
            ]),
            attributes: [],
          },
        ],
      };
    },

    handleUpdateFamily(item) {
      this.family = { ...item.family };
    },

    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;
        if (response) {
          this.isSaving = true;

          await this.deleteProductFamily({
            uuid: this.model.uuid,
          });
          this.isSaving = false;

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Το family διαγράφτηκε επιτυχώς!!',
          });
        }
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid || this.errors.count()) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        await this.updateProductFamily({
          uuid: this.model.uuid,
          payload: {
            family: this.family,
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το προιόν αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To προιόν δεν αποθηκεύτηκε',
        });
      }
    },

    reset() {
      this.$validator.reset();

      this.family = this.model.family;
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  border-top: 1px solid #dbdbdb;
  padding: 0.75rem;
}
</style>
