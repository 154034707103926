<template>
  <div>
    <form @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            Περιοδικές Εκπτώσεις
          </div>
          <div class="card-header-icon">
            <div class="field has-addons is-marginless">
              <div class="control">
                <router-link
                  :to="{ name: 'marketing.periodic-discounts.list' }"
                  class="button"
                >
                  <span class="icon is-small"><i class="fa fa-list-ul"/></span
                  ><span>Λίστα</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-half">
              <div class="field">
                <label class="label">Τίτλος *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newPeriodicDiscount.title"
                    class="input"
                    type="text"
                    name="title"
                  />
                  <p v-show="errors.has('title')" class="help is-danger">
                    Εισάγετε τίτλο
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <label class="label">Ποσοστό Έκπτωσης *</label>
                <div class="control">
                  <input
                    v-validate="'required|min_value:0'"
                    v-model.trim.number="newPeriodicDiscount.discount"
                    class="input"
                    type="number"
                    min="0"
                    step="0.01"
                    name="discount"
                  />
                  <p v-show="errors.has('discount')" class="help is-danger">
                    Εισάγετε ποσοστό έκπτωσης
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <label class="label">Ενεργό *</label>
                <div class="control">
                  <div class="select select is-fullwidth">
                    <select
                      v-model.number="newPeriodicDiscount.active"
                      name="active"
                    >
                      <option :value="false">Όχι</option>
                      <option :value="true">Ναί</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <label class="label">Sold Limit *</label>
                <div class="control">
                  <input
                    v-validate="'required|excluded:0'"
                    v-model.trim.number="newPeriodicDiscount.sold_limit"
                    class="input"
                    type="number"
                    name="sold_limit"
                    min="0"
                    step="1"
                  />
                  <p v-show="errors.has('sold_limit')" class="help is-danger">
                    Εισάγετε Τεμάχια
                  </p>
                </div>
              </div>
            </div>

            <div class="column is-full">
              <label class="label">
                Ημερομηνία Εκκίνησης *
              </label>
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input
                    v-model="displayDateStart"
                    class="input"
                    name="date_start"
                    disabled
                  />
                  <p v-show="errors.has('date_start')" class="help is-danger">
                    Εισάγετε ημ/νία εκκίνησης
                  </p>
                </div>
              </div>
            </div>

            <div class="column is-one-quarter">
              <div class="field has-addons">
                <div class="control is-expanded">
                  <DatePicker
                    id="date"
                    v-model="newPeriodicDiscount.date_start"
                    name="date_start"
                    type="datetime"
                    title-format="DD-MM-YYYY"
                    inline
                  />
                  <p v-show="errors.has('date_start')" class="help is-danger">
                    Εισάγετε ημ/νία εκκίνησης
                  </p>
                </div>
              </div>
            </div>

            <div class="column is-full">
              <label class="label">
                Ημερομηνία Λήξης *
              </label>
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input
                    v-model="displayDateEnd"
                    class="input"
                    name="date_end"
                    disabled
                  />
                  <p v-show="errors.has('date_end')" class="help is-danger">
                    Εισάγετε ημ/νία λήξης
                  </p>
                </div>
              </div>
            </div>

            <div class="column is-one-quarter">
              <div class="field has-addons">
                <div class="control is-expanded">
                  <DatePicker
                    id="date"
                    v-model="newPeriodicDiscount.date_end"
                    name="date_end"
                    type="datetime"
                    title-format="DD-MM-YYYY"
                    inline
                  />
                  <p v-show="errors.has('date_end')" class="help is-danger">
                    Εισάγετε ημ/νία λήξης
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-content">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import request from '@/utils/request';
import AddItem from '@/views/components/AddItem';

import ProductsSearch from '@/views/components/ProductsSearch';

export default {
  components: {
    ProductsSearch,
    DatePicker,
  },

  extends: AddItem,

  data() {
    return {
      newPeriodicDiscount: {
        title: '',
        active: false,
        sold_limit: 0,
        discount: null,
        date_start: moment().toDate(),
        date_end: null,
      },
      disabledDates: new Date(),
    };
  },

  computed: {
    displayDateStart() {
      return this.newPeriodicDiscount.date_start
        ? this.formatDate(this.newPeriodicDiscount.date_start)
        : 'Επιλέξτε Ημερομηνία και ώρα παρακάτω';
    },

    displayDateEnd() {
      return this.newPeriodicDiscount.date_end
        ? this.formatDate(this.newPeriodicDiscount.date_end)
        : 'Επιλέξτε Ημερομηνία και ώρα παρακάτω';
    },
  },

  methods: {
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY-HH:mm');
    },

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        const { data } = await request.post('/periodic-discounts', {
          ...this.newPeriodicDiscount,
        });
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$router.push({ name: 'marketing.periodicDiscounts.list' });
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η Περιοδική Έκπτωση αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    disableDate(date) {
      if (
        moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD') <
        moment()
          .add(2, 'days')
          .format('YYYY-MM-DD')
      ) {
        return true;
      }
      return false;
    },

    reset() {
      this.newPeriodicDiscount = {
        title: '',
        active: false,
        sold_limit: 0,
        discount: null,
        date_start: moment().toDate(),
        date_end: null,
      };

      this.$validator.reset();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .mx-datepicker {
  width: 100% !important;
}

::v-deep .mx-datepicker-main {
  border-radius: 12px;

  .mx-datepicker-body {
    border-radius: 12px;
  }

  .mx-calendar {
    border-radius: 12px;
    width: 100% !important;

    .mx-calendar-content .cell.active {
      background-color: #16c995 !important;
      text-align: center;
      border-radius: 12px;
    }
    .mx-calendar-content .cell {
      text-align: center;
      border-radius: 12px;
    }

    & .cell.disabled {
      background-color: #111 !important;
    }
  }
}
</style>
