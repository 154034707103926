/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setSymptom(state, { symptom }) {
    state.data = symptom;
  },

  updateSymptom(state, { symptom }) {
    state.data = {
      ...state.data,
      ...symptom,
    };
  },
};

export default mutations;
