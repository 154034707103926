/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setGeneral(state, { general }) {
    state.data = general;
  },

  updateGeneral(state, { general }) {
    state.data = {
      ...state.data,
      ...general,
    };
  },
};

export default mutations;
