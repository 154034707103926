import { isEmpty, get, round } from 'lodash';

/* eslint-disable */
const getters = {
  getNewOrder(state) {
    return state.data;
  },

  getCustomer(state, { getNewOrder }) {
    return getNewOrder.customer;
  },

  getCustomerEmails(state, { getCustomer }) {
    return get(getCustomer, 'emails', []);
  },

  getHasCustomer(state, { getCustomer }) {
    return getCustomer !== null;
  },

  getCustomerType(state, { getNewOrder }) {
    return getNewOrder.customer_type;
  },

  getIsProfile(state, { getCustomerType }) {
    return getCustomerType === 'profile';
  },

  getIsGuest(state, { getCustomerType }) {
    return getCustomerType === 'guest';
  },

  getCustomerEmail(state, { getNewOrder }) {
    return getNewOrder.email;
  },

  getCanHaveProfileType(state, { getCustomerEmails, getCustomerEmail }) {
    return get(getCustomerEmails, 'profile', []).includes(getCustomerEmail);
  },

  getItemsCount(state, { getNewOrder }) {
    return Object.values(getNewOrder.products).reduce(
      (acc, item) => acc + item.pivot.count,
      0,
    );
  },

  getItemsTotalPrice(state, { getNewOrder }) {
    return round(
      getNewOrder.products.reduce(
        (acc, item) => acc + item.final_price * item.pivot.count,
        0,
      ),
      2,
    );
  },

  getIsPharmacy(state, { getNewOrder }) {
    return (
      !isEmpty(getNewOrder.send_method) &&
      getNewOrder.send_method.slug === 'pharmacy'
    );
  },

  getIsBoxnow(state, { getNewOrder }) {
    return (
      !isEmpty(getNewOrder.send_method) &&
      getNewOrder.send_method.slug === 'box_now'
    );
  },

  getIsPayOnDelivery(state, { getNewOrder }) {
    return (
      !isEmpty(getNewOrder.checkout_method) &&
      getNewOrder.checkout_method.slug === 'pay_on_delivery'
    );
  },
};

export default getters;
