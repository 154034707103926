import request from '@/utils/request';
import { calculateParams } from '@/utils/calculations';

/* eslint-disable */
const actions = {
  async fetchGiftActionsOrders({ dispatch, commit, getters }, route = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(
        getters['getPaginationParams'],
        route.query,
      );

      await commit('setPaginationParams', newParams);

      const url = `/giftActions/${route.params.id}/orders`;

      const { data } = await request.get(url, {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.orders);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },
};

export default actions;
