<template>
  <tr>
    <td class="has-text-centered">{{ item.content }}</td>
    <td class="has-text-centered hidden-print">
      <router-link
        :to="{ name: 'content.questions.edit', params: { uuid: item.uuid } }"
        class="button is-small"
      >
        <span class="icon is-small"><i class="fa fa-pencil"/></span>
      </router-link>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    item: Object,
  },
};
</script>
