import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getComplaint({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/complaints/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setComplaint', {
        complaint: data.complaint,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async updateComplaint({ dispatch, commit }, { uuid, complaint }) {
    try {
      const response1 = await request.put(`/complaints/${uuid}`, complaint);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/complaints/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateComplaint', {
        complaint: response2.data.complaint,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
