import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getProduct({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/products/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setProduct', {
        product: data.product,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getProductPhotos({ dispatch, commit }, { uuid }) {
    try {
      const { data } = await request.get(`/products/${uuid}/photos`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateProductPhotos', {
        photos: data.photos,
      });
    } catch (err) {
      throw err;
    }
  },

  async updateProduct({ dispatch, commit }, { uuid, product }) {
    try {
      const response1 = await request.put(`/products/${uuid}`, product);

      if (response1.data.error) {
        const err = Error('Server-side validation failed');
        err.messages = response1.data.errors;
        throw err;
      }

      const response2 = await request.get(`/products/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateProduct', {
        product: response2.data.product,
      });
    } catch (err) {
      throw err;
    }
  },

  async updateEnProduct({ dispatch, commit }, { uuid, product }) {
    try {
      const response1 = await request.put(`/products/${uuid}/en`, product);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/products/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateProduct', {
        product: response2.data.product,
      });
    } catch (err) {
      throw err;
    }
  },

  async updateProductLoyalty({ dispatch, commit }, { uuid, payload }) {
    try {
      const response1 = await request.put(`/products/${uuid}/loyalty`, payload);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/products/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateProduct', {
        product: response2.data.product,
      });
    } catch (err) {
      throw err;
    }
  },

  async updateProductFamily({ dispatch, commit }, { uuid, payload }) {
    try {
      const response1 = await request.put(
        `/products/${uuid}/families`,
        payload,
      );

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/products/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateProduct', {
        product: response2.data.product,
      });
    } catch (err) {
      throw err;
    }
  },

  async deleteProductFamily({ dispatch, commit }, { uuid, payload }) {
    try {
      const response1 = await request.delete(
        `/products/${uuid}/families`,
        payload,
      );

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/products/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateProduct', {
        product: response2.data.product,
      });
    } catch (err) {
      throw err;
    }
  },

  async checkForProductUpdates({ dispatch, commit }, { uuid }) {
    try {
      const response1 = await request.get(`/product-updates/${uuid}`);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/products/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateProduct', {
        product: response2.data.product,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
