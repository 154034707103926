<template>
  <VueEditor
    v-validate="'required'"
    id="editor"
    v-model="value"
    :editor-toolbar="toolbarOptions"
    data-vv-value-path="value"
    data-vv-name="content"
    @input="changeContent"
  />
</template>

<script>
import { VueEditor } from 'vue2-editor';
import toolbarOptions from '@/constants/toolbarOptions';

export default {
  inject: ['$validator'],
  components: {
    VueEditor,
  },
  props: {
    content: '',
  },
  data() {
    return {
      value: this.content,
      toolbarOptions,
    };
  },
  watch: {
    content(newVal) {
      this.value = newVal;
    },
  },
  methods: {
    changeContent(content) {
      const newContent = content === '<p><br></p>' ? '' : content;
      this.$emit('changeContent', newContent);
    },
  },
};
</script>
