<template>
  <div class="customer-tab">
    <table
      v-if="customer.contacts.length"
      class="table is-bordered is-striped is-marginless is-fullwidth"
    >
      <thead>
        <tr>
          <th>Ημ/νια</th>
          <th>Περιεχόμενο</th>
          <th>Tύπος</th>
          <th class="hidden-print">Ενέργειες</th>
        </tr>
      </thead>
      <tbody>
        <contact
          v-for="(item, index) in customer.contacts"
          :item="item"
          :index="index"
          :key="item.id"
          :contact-types="contactTypes"
          @selectItem="selectItem"
        />
      </tbody>
    </table>
    <h5 v-else class="title is-5 has-text-centered is-marginless">
      Δε βρέθηκαν αρχεία επικοινωνίας
    </h5>

    <contact-preview
      :is-open="isOpen"
      :customer="customer"
      :contact="selectedContact"
      :contact-types="contactTypes"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Contact from './components/Contact';
import ContactPreview from './components/ContactPreview';

export default {
  components: {
    Contact,
    ContactPreview,
  },
  data() {
    return {
      isOpen: false,
      selectedContact: {},
    };
  },
  computed: {
    ...mapGetters({
      customer: 'customers/edit/getCustomer',
      contactTypes: 'getContactTypes',
    }),
  },
  methods: {
    selectItem(item) {
      this.selectedContact = item;
      this.isOpen = true;
    },
    closeModal() {
      this.selectedContact = {};
      this.isOpen = false;
    },
  },
};
</script>
