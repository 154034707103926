import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async addBanner({ dispatch, commit, rootGetters }, banner) {
    try {
      const formData = new FormData();
      formData.append('title', banner.title);
      formData.append('title_en', banner.title_en);
      formData.append('link', banner.link);
      formData.append('active', banner.active);
      formData.append('image', banner.image);

      const { data } = await request.post('/banners', formData);

      if (data.error) {
        throw Error('Server-side validation failed');
      }
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
