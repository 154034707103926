<template>
  <div>
    <nav class="level">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">STATUS</p>
          <p class="title">
            <span :class="['tag is-medium', classes]">{{ status }}</span>
          </p>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    bundle: Object,
  },
  computed: {
    status() {
      const { bundle } = this;
      /* eslint-disable */
      if (bundle.deleted_at !== null) {
        return 'Απενεργοποιημένo Bundle';
      } else if (bundle.active === true) {
        return 'Εμφανές Bundle';
      } else if (bundle.active === false) {
        return 'Μη Εμφανές Bundle';
      } else {
        return 'Bundle';
      }
    },
    classes() {
      const bundle = this.bundle;
      /* eslint-disable */
      if (bundle.deleted_at !== null) {
        return 'is-danger';
      } else if (bundle.active === true) {
        return 'is-primary';
      } else if (bundle.active === false) {
        return 'is-warning';
      } else {
        return 'is-white';
      }
    },
  },
};
</script>
