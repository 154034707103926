import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getAvailabilities({ commit }) {
    try {
      const { data } = await request.get('/availabilities');
      await commit('setAvailabilities', { availabilities: data.availabilities });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
