<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Τρόποι Αποστολής
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="table-wrapper">
        <table class="table is-bordered is-striped is-marginless is-fullwidth">
          <thead>
            <tr>
              <th>#</th>
              <th>Τίτλος</th>
              <th>Εικονίδιο</th>
              <th>Ενεργός</th>
            </tr>
          </thead>
          <tbody>
            <send-method
              v-for="method in sendMethods"
              :method="method"
              :key="method.id"
              @updateStatus="updateStatus"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SendMethod from './components/SendMethod';

export default {
  components: {
    SendMethod,
  },
  data() {
    return {
      isSaving: false,
    };
  },
  computed: {
    ...mapGetters({
      sendMethods: 'getSendMethods',
    }),
  },
  async mounted() {
    await this.getSendMethods();
  },
  methods: {
    ...mapActions(['getSendMethods', 'toggleSendMethodStatus']),
    async updateStatus(selectedMethod) {
      try {
        const isActive = !selectedMethod.active;

        await this.toggleSendMethodStatus({
          sendMethod: selectedMethod,
          active: isActive,
        });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: isActive
            ? 'Η μέθοδος ενεργοποιήθηκε επιτυχώς!!'
            : 'Η μέθοδος δεν είναι ενεργή πλέον',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table th {
  text-align: center;
}
</style>
