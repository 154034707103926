<template>
  <div class="attribute">
    <div class="container">
      <div class="columns">
        <div class="column is-half">
          <div class="field">
            <label class="label">Ιδιότητα *</label>
            <div class="control">
              <input
                v-validate="'required'"
                v-model="formAttribute.key"
                :name="`key ${index}`"
                type="text"
                class="input"
                @input="handleInput"
              />
              <p v-show="errors.has(`key ${index}`)" class="help is-danger">
                Εισάγετε Ιδιότητα
              </p>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="field">
            <label class="label">Τιμή *</label>
            <div class="control">
              <input
                v-validate="'required'"
                v-model="formAttribute.value"
                :name="`value ${index}`"
                type="text"
                class="input"
                @input="handleInput"
              />
              <p v-show="errors.has(`value ${index}`)" class="help is-danger">
                Εισάγετε Τιμή
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="button is-small" @click.prevent="handleDelete">
      <span class="icon is-small">
        <span class="fa fa-fw fa-trash-o" />
      </span>
    </button>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],

  props: {
    attribute: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      formAttribute: {
        key: '',
        value: '',
      },
    };
  },

  watch: {
    attribute: {
      handler(newVal) {
        if (newVal) {
          this.formAttribute = { ...newVal };
        }
      },
      immediate: true,
    },
  },

  methods: {
    async handleInput() {
      this.$emit('change', {
        index: this.index,
        attribute: this.formAttribute,
      });
    },

    handleDelete() {
      this.$emit('delete', this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.attribute {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  .button {
    margin-left: 1rem;
    margin-top: 1.85rem;
  }
}
</style>
