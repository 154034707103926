<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div>{{ title }}</div>
        <small v-if="hasOptions" class="has-text-grey">{{
          boundariesText
        }}</small>
      </div>
      <div v-if="hasOptions" class="card-header-icon is-hidden-mobile">
        <slot name="options">
          <div class="field has-addons">
            <div
              v-tooltip="'Γκρούπαρε τα στοιχεία ανά:'"
              v-for="(item, index) in groupTypeOptions"
              :key="index"
              :value="item.value"
              class="control"
            >
              <a
                :class="['button', { 'is-primary': isActive(item.value) }]"
                @click.prevent="selectGroupType(item.value)"
              >
                {{ item.text }}
              </a>
            </div>
          </div>

          <div class="field has-addons">
            <div class="control">
              <a
                v-tooltip="'Προηγούμενη Περίοδος'"
                class="button"
                @click.prevent="selectPrev"
              >
                <span class="icon"><i class="fa fa-angle-double-left"/></span>
              </a>
            </div>
            <div class="control">
              <div class="select">
                <select
                  v-tooltip="'Επέλεξε περίοδο:'"
                  v-model="periodType"
                  name="periodType"
                >
                  <option
                    v-for="(item, index) in periodTypeOptions"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.text }} ({{ getOptionText(item.value) }})
                  </option>
                </select>
              </div>
            </div>
            <div class="control">
              <a
                v-tooltip="'Επόμενη Περίοδος'"
                :disabled="isDisabled()"
                class="button"
                @click.prevent="selectNext"
              >
                <span class="icon"><i class="fa fa-angle-double-right"/></span>
              </a>
            </div>
          </div>

          <div class="field has-addons">
            <div class="control">
              <a v-tooltip="'Σήμερα'" class="button" @click.prevent="selectNow">
                {{ nowText }}
              </a>
            </div>
          </div>
        </slot>
      </div>
    </div>
    <div class="card-content">
      <slot name="graph">
        <vue-loading
          :size="{ width: '50px', height: '50px' }"
          type="spin"
          color="#22A684"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import VueLoading from 'vue-loading-template';

export default {
  components: {
    VueLoading,
  },
  props: {
    hasOptions: false,
    title: String,
  },
  data() {
    return {
      startDate: moment().format('YYYY-MM-DD'),
      boundariesText: '',
      nowText: '',
      defaultGroupTypeOptions: [
        {
          value: 'day',
          text: 'Ημέρα',
        },
        {
          value: 'week',
          text: 'Εβδομάδα',
        },
        {
          value: 'month',
          text: 'Μήνας',
        },
      ],
      periodTypeOptions: [
        {
          value: 'week',
          text: 'Εβδομάδα',
        },
        {
          value: 'month',
          text: 'Μήνας',
        },
        {
          value: 'year',
          text: 'Έτος',
        },
      ],
      groupType: 'day',
      periodType: 'month',
    };
  },
  computed: {
    groupTypeOptions() {
      const { periodType } = this;

      if (['day', 'week'].includes(periodType)) {
        return this.defaultGroupTypeOptions.filter(
          item => item.value === 'day',
        );
      }

      if (periodType === 'month') {
        return this.defaultGroupTypeOptions.filter(item =>
          ['day', 'week'].includes(item.value),
        );
      }

      return this.defaultGroupTypeOptions;
    },
  },
  watch: {
    periodType(newValue) {
      if (newValue === 'year') {
        this.groupType = 'month';
      } else if (newValue === 'month') {
        this.groupType = 'week';
      } else {
        this.groupType = 'day';
      }

      this.update();
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    isActive(type) {
      return this.groupType === type;
    },
    isDisabled() {
      const { periodType } = this;
      const { startDate } = this;
      const now = moment().format('YYYY-MM-DD');

      if (periodType === 'week') {
        return moment(startDate, 'YYYY-MM-DD').isSame(now, 'week');
      }

      if (periodType === 'month') {
        return moment(startDate, 'YYYY-MM-DD').isSame(now, 'month');
      }

      if (periodType === 'year') {
        return moment(startDate, 'YYYY-MM-DD').isSame(now, 'year');
      }

      return false;
    },
    selectPrev() {
      const { periodType } = this;
      const { startDate } = this;

      if (periodType === 'week') {
        this.startDate = moment(startDate, 'YYYY-MM-DD')
          .subtract(1, 'week')
          .format('YYYY-MM-DD');
      }

      if (periodType === 'month') {
        this.startDate = moment(startDate, 'YYYY-MM-DD')
          .subtract(1, 'month')
          .format('YYYY-MM-DD');
      }

      if (periodType === 'year') {
        this.startDate = moment(startDate, 'YYYY-MM-DD')
          .subtract(1, 'year')
          .format('YYYY-MM-DD');
      }

      this.update();
    },
    selectNext() {
      const { periodType } = this;
      const { startDate } = this;

      if (periodType === 'week') {
        this.startDate = moment(startDate, 'YYYY-MM-DD')
          .add(1, 'week')
          .format('YYYY-MM-DD');
      }

      if (periodType === 'month') {
        this.startDate = moment(startDate, 'YYYY-MM-DD')
          .add(1, 'month')
          .format('YYYY-MM-DD');
      }

      if (periodType === 'year') {
        this.startDate = moment(startDate, 'YYYY-MM-DD')
          .add(1, 'year')
          .format('YYYY-MM-DD');
      }

      this.update();
    },
    selectNow() {
      this.startDate = moment().format('YYYY-MM-DD');

      this.update();
    },
    getPeriodBoundaries(periodType) {
      const { startDate } = this;

      if (periodType === 'day') {
        return {
          start: startDate,
          end: startDate,
        };
      }

      if (periodType === 'week') {
        return {
          start: moment(startDate, 'YYYY-MM-DD')
            .startOf('isoWeek')
            .format('YYYY-MM-DD'),
          end: moment(startDate, 'YYYY-MM-DD')
            .endOf('isoWeek')
            .format('YYYY-MM-DD'),
        };
      }

      if (periodType === 'month') {
        return {
          start: moment(startDate, 'YYYY-MM-DD')
            .startOf('month')
            .format('YYYY-MM-DD'),
          end: moment(startDate, 'YYYY-MM-DD')
            .endOf('month')
            .format('YYYY-MM-DD'),
        };
      }

      if (periodType === 'year') {
        return {
          start: moment(startDate, 'YYYY-MM-DD')
            .startOf('year')
            .format('YYYY-MM-DD'),
          end: moment(startDate, 'YYYY-MM-DD')
            .endOf('year')
            .format('YYYY-MM-DD'),
        };
      }

      return {
        start: startDate,
        end: startDate,
      };
    },
    getBoundariesText({ start, end }) {
      if (start === end) {
        return moment(start, 'YYYY-MM-DD').format('DD-MM-YYYY');
      }

      return `${moment(start, 'YYYY-MM-DD').format('DD-MM-YYYY')} έως ${moment(
        end,
        'YYYY-MM-DD',
      ).format('DD-MM-YYYY')}`;
    },
    getNowText() {
      const { periodType } = this;
      const startDate = moment().format('YYYY-MM-DD');

      if (periodType === 'week') {
        return moment(startDate, 'YYYY-MM-DD').format('YYYY-W');
      }

      if (periodType === 'month') {
        return moment(startDate, 'YYYY-MM-DD').format('YYYY-MM');
      }

      if (periodType === 'year') {
        return moment(startDate, 'YYYY-MM-DD').format('YYYY');
      }

      return moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
    },
    getOptionText(periodType) {
      const { startDate } = this;

      if (periodType === 'week') {
        return moment(startDate, 'YYYY-MM-DD').format('YYYY-W');
      }

      if (periodType === 'month') {
        return moment(startDate, 'YYYY-MM-DD').format('YYYY-MM');
      }

      if (periodType === 'year') {
        return moment(startDate, 'YYYY-MM-DD').format('YYYY');
      }

      return startDate;
    },
    selectGroupType(type) {
      if (type !== this.groupType) {
        this.groupType = type;

        this.update();
      }
    },
    update() {
      const period = this.getPeriodBoundaries(this.periodType);
      this.boundariesText = this.getBoundariesText(period);
      this.nowText = this.getNowText();

      this.$emit('update', {
        group: this.groupType,
        period,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.field {
  margin-bottom: 0;
  margin-left: 1rem;
}

.card-header {
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.card-header-title {
  display: block;
}
</style>
