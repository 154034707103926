<template>
  <div>
    <div v-show="file">
      <img :src="preview" />
      <div class="field is-grouped is-grouped-centered">
        <div class="control">
          <button
            :class="{ 'is-loading': isSaving }"
            type="button"
            class="button is-primary"
            @click="upload"
          >
            Αποθήκευση
          </button>
        </div>
        <div class="control">
          <button type="button" class="button" @click="reset">
            Ακύρωση
          </button>
        </div>
      </div>
    </div>
    <div v-show="!file">
      <div class="image-container">
        <button
          :class="{ 'is-loading': isSaving }"
          type="button"
          class="button is-primary"
        >
          <span class="icon is-small"><i class="fa fa-image"/></span>
          <span>Επέλεξε νέα φωτογραφία</span>
        </button>
        <input
          v-validate="'mimes:image/*'"
          id="file"
          ref="file"
          :disabled="isSaving"
          type="file"
          accept="image/*"
          name="file"
          @change="onFileChange"
        />
        <label for="file" />
      </div>
      <p v-show="errors.has('file')" class="help is-danger">
        Εισάγετε έγκυρη png, jpg ή jpeg εικόνα
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isSaving: {
      type: Boolean,
    },
  },
  data() {
    return {
      file: null,
      preview: null,
    };
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      [this.file] = files;

      this.createPreview(this.file);
    },
    createPreview(file) {
      const reader = new FileReader();

      this.$validator
        .validateAll()
        .then(() => {
          reader.onload = e => {
            this.preview = e.target.result;
          };
          reader.readAsDataURL(file);
        })
        .catch(err => console.log(err));
    },
    upload() {
      this.$emit('upload', this.file);
    },
    reset() {
      this.errors.clear();
      this.file = null;
      this.preview = null;
      this.$refs.file.value = null;
    },
  },
};
</script>

<style lang="scss" type="text/scss" scoped>
img {
  max-width: 100%;
}

.image-container {
  background-color: #fafafa;
  height: 200px;
  position: relative;
  padding: 40px 0 0;
  border: 2px dashed #dddddd;

  .button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  input {
    opacity: 0;
    border: 1px solid red;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  label {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
