<template>
  <div v-sticky sticky-offset="offset">
    <nav class="level">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Skroutz Code</p>

          <img
            src="@/assets/skroutz_smart_cart.png"
            alt="Carespot"
            height="30"
            width="30"
          />

          <p class="title skroutz-code">
            {{ skroutzSmartCart.code }}
          </p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div v-if="skroutzSmartCart.carespot_order_common_id">
          <p class="heading">Common ID</p>
          <router-link
            :to="{
              name: 'orders.edit',
              params: { uuid: '130kv3' },
            }"
          >
            <p class="title skroutz-code">
              {{ skroutzSmartCart.carespot_order_common_id }}
            </p>
          </router-link>
        </div>
        <div v-if="!skroutzSmartCart.carespot_order_common_id">
          <p class="heading">Common ID</p>
          <p class="title skroutz-code">
            ---
          </p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Ημερομηνια</p>
          <p class="title">{{ skroutzSmartCart.created_at | timestamp }}</p>
        </div>
      </div>
      <div
        v-if="skroutzSmartCart.state === 'open'"
        class="level-item has-text-centered"
      >
        <div>
          <p class="heading">Αποδοχη εως</p>
          <p class="title">
            <span class="tag is-medium is-white">
              {{ skroutzSmartCart.expires_at | timestamp }}
            </span>
          </p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Status</p>
          <p class="title">
            <span class="tag is-medium is-white">
              {{ skroutzSmartCart.state }}
            </span>
          </p>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  filters: {
    timestamp(value, from = 'YYYY-MM-DD HH:mm:ss', to = 'DD-MM-YYYY HH:mm') {
      return moment.utc(value, from).format(to);
    },
  },

  props: {
    skroutzSmartCart: Object,
  },
  data: () => ({
    offset: { top: 45 },
  }),
};
</script>

<style scoped>
.type.is-white {
  background-color: white;
  border-color: transparent;
}
.tag {
  width: 100%;
}

.status {
  position: relative;
}

.icon--print {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
  font-size: 0.85rem;
  position: absolute;
  right: -9px;
  top: -7px;
}
.skroutz-code {
  display: inline-block;
  margin-left: 10px;
}
</style>
