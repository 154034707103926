<template>
  <div class="control">
    <div class="select is-fullwidth">
      <select name="variation" @change="handleChange">
        <VariationsListItem
          v-for="variation in items"
          :key="variation.cart_id"
          :item="variation"
          :variation-type="variationType"
          :selected-variation="selectedVariation"
        />
      </select>
    </div>
  </div>
</template>

<script>
import VariationsListItem from '../VariationsListItem';

export default {
  components: {
    VariationsListItem,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },

    variationType: {
      type: String,
      required: true,
    },

    selectedVariation: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleChange({ target }) {
      const { value } = target;

      const item = this.items.find(variation => variation.cart_id === value);
      this.$emit('on-select', item);
    },
  },
};
</script>
