import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getBrands({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const { data } = await request.get('/brands', {
        params,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.brands);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },
  async getBrandCategories({ commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });
      const { data } = await request.get(`/brands/${uuid}/categories`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }
      await commit('setBrandCategories', {
        brandCategories: data.categories,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });
      throw err;
    }
  },
};

export default actions;
