/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setFilter(state, { filter }) {
    state.data = filter;
  },

  updateFilter(state, { filter }) {
    state.data = {
      ...state.data,
      ...filter,
    };
  },
};

export default mutations;
