/* eslint-disable */
const getters = {
  getPost(state) {
    return {
      ...state.data,
      photoable_type: 'App\\Post',
    };
  },
  getIsLoading(state) {
    return state.isLoading;
  },
};

export default getters;
