<template>
  <multiselect
    :options="products"
    :searchable="true"
    :internal-search="false"
    :option-height="104"
    :loading="isSearching"
    :options-limit="50"
    :reset-after="true"
    track-by="id"
    label="title"
    placeholder="Επιλέξτε προιόν με χρήση barcode, τίτλου..."
    select-label="Πατήστε enter για επιλογή"
    @search-change="searchProducts"
    @select="selectProduct"
  >
    <template v-slot:option="props" class="option">
      <Photo :photos="props.option.photos" class="option__image" />
      <div class="option__desc">
        <span class="option__title">{{ props.option.title }}</span>
        <span class="option__small">{{ props.option.barcode }}</span>
      </div>
    </template>
    <template v-slot:noResult>
      Δε βρέθηκαν αποτελέσματα. Προσπαθήστε ξανά!!
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import _debounce from 'lodash/debounce';
import request from '@/utils/request';
import Photo from '@/views/components/Photo';

export default {
  inject: ['$validator'],
  components: {
    Multiselect,
    Photo,
  },
  props: {
    group: Object,
  },
  data() {
    return {
      products: [],
      isSearching: false,
    };
  },
  methods: {
    // eslint-disable-next-line func-names
    searchProducts: _debounce(async function(searchQuery) {
      if (searchQuery.length) {
        const products = this.group.subgroups.map(({ id }) => id);

        this.isSearching = true;
        const { data } = await request.get('/products-search', {
          params: {
            term: searchQuery,
            products,
          },
        });
        this.isSearching = false;

        this.products = data.products;
      }
    }, 300),
    selectProduct(selectedOption) {
      this.$emit('selectProduct', selectedOption);

      this.products = [];
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect {
  margin: 2rem auto;
}
</style>
