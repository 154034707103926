import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getSymptom({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/symptoms/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setSymptom', {
        symptom: data.symptom,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getSymptomPhotos({ dispatch, commit }, { uuid }) {
    try {
      const { data } = await request.get(`/symptoms/${uuid}/photos`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateSymptom', {
        symptom: data.symptom,
      });
    } catch (err) {
      throw err;
    }
  },

  async updateSymptom({ dispatch, commit }, { uuid, symptom }) {
    try {
      const response1 = await request.put(`/symptoms/${uuid}`, symptom);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/symptoms/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateSymptom', {
        symptom: response2.data.symptom,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
