<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        Σημειώσεις Πελάτη
      </div>
      <div class="card-header-icon">
        <button
          v-tooltip="{ content: 'Προβολή' }"
          v-if="isEditing"
          class="button is-small"
          @click="handleEditing"
        >
          <span class="icon is-small"><i class="fa fa-eye"/></span>
        </button>
        <button
          v-tooltip="{ content: 'Επεξεργασία' }"
          v-else
          class="button is-small"
          @click="isEditing = !isEditing"
        >
          <span class="icon is-small"><i class="fa fa-pencil"/></span>
        </button>
      </div>
    </div>
    <div v-if="isEditing">
      <form @submit.prevent="submit">
        <div class="card-content">
          <div class="field">
            <label class="label">Περιεχόμενο </label>
            <div class="control">
              <textarea
                v-model="newNotes"
                name="notes"
                class="textarea"
                cols="30"
                rows="5"
              />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </form>
    </div>
    <div v-else class="card-content">
      <div class="field">
        <label class="label">Περιεχόμενο</label>
        <div class="control">
          {{ newNotes }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request';
import EditItem from '@/views/components/EditItem';

export default {
  extends: EditItem,

  props: {
    customer: Object,
  },

  data() {
    return {
      copyNotes: '',
      newNotes: '',
      isSaving: false,
      isEditing: false,
    };
  },

  created() {
    this.copyNotes = this.customer.notes;
    this.newNotes = this.copyNotes;
  },

  methods: {
    async submit() {
      try {
        this.isSaving = true;

        const { data } = await request.put(
          `/customers/${this.customer.uuid}/notes`,
          {
            notes: this.newNotes,
          },
        );

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Οι πληροφορίες πελάτη αποθηκεύτηκαν',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      } finally {
        this.isEditing = false;
        this.isSaving = false;
      }
    },

    reset() {
      this.newNotes = this.copyNotes;
    },

    handleEditing() {
      this.isEditing = !this.isEditing;
      this.reset();
    },
  },
};
</script>

<style scoped lang="scss">
.label {
  display: inline-block;
}
</style>
