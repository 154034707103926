<template>
  <ToggleableCard
    title="Εξερχόμενη Επικοινωνία με email"
    @closePanel="closePanel"
  >
    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="field">
          <label class="label">Email</label>
          <div class="select select is-fullwidth">
            <select v-validate="'required'" v-model="form.email" name="email">
              <option
                v-for="(email, index) in emails"
                :value="email.email"
                :key="index"
              >
                {{ email.email }} | {{ email.group }}
              </option>
            </select>
          </div>
        </div>

        <div class="field">
          <label class="label">Τίτλος</label>
          <div class="control">
            <textarea
              v-validate="'required'"
              v-model="form.title"
              cols="30"
              rows="2"
              name="title"
              class="textarea"
            />
          </div>
          <p v-show="errors.has('title')" class="help is-danger">
            Εισάγετε τίτλο
          </p>
        </div>

        <div class="field">
          <label class="label">Περιεχόμενο</label>
          <div class="control">
            <textarea
              v-validate="'required'"
              v-model="form.content"
              cols="30"
              rows="8"
              name="content"
              class="textarea"
            />
          </div>
          <p v-show="errors.has('content')" class="help is-danger">
            Εισάγετε περιεχόμενο
          </p>
        </div>
      </div>
      <div class="card-footer">
        <FormSubmit
          :is-saving="isSaving"
          submit-label="Αποστολή"
          submit-icon="fa-envelope-o"
          @reset="reset"
        />
      </div>
    </form>
  </ToggleableCard>
</template>

<script>
import { mapActions } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import EditItem from '@/views/components/EditItem';
import ToggleableCard from '@/views/components/ToggleableCard';

export default {
  components: {
    ToggleableCard,
  },
  extends: EditItem,
  props: {
    customer: Object,
  },
  data() {
    return {
      form: {
        email: '',
        title: '',
        content: '',
      },
      isSaving: false,
      emails: [],
    };
  },

  watch: {
    'customer.emails': {
      handler(newVal) {
        this.emails = Object.entries(newVal).reduce((acc, [key, value]) => {
          if (!isEmpty(value)) {
            if (isObject(value)) {
              return [...acc, ...value.map(email => ({ email, group: key }))];
            }

            return [...acc, { email: value, group: key }];
          }

          return acc;
        }, []);

        this.form = {
          ...this.form,
          email: this.emails[0].email,
        };
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['sendEmail']),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.sendEmail({ email: this.form });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το email απεστάλη',
        });

        this.closePanel();
      } catch (err) {
        console.log(err);
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    closePanel() {
      this.reset();
    },
    reset() {
      this.form = {
        email: this.emails.length ? this.emails[0].email : '',
        title: '',
        content: '',
      };

      this.$validator.reset();
    },
  },
};
</script>
