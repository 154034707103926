<script>
import Confirmation from '../Confirmation';
import FormSubmit from '../FormSubmit';

export default {
  components: {
    Confirmation,
    FormSubmit,
  },
  data() {
    return {
      isOpen: false,
      isSaving: false,
    };
  },
  methods: {
    askToDeleteItem() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
