import store from '@/store';

export const isAuth = (to, from, next) => {
  const { name } = to;

  if (['activate', 'login'].includes(name)) {
    return next();
  }

  const { token } = store.state.session;

  if (token) {
    return next();
  }

  return next({ path: '/login' });
};

export const isSuperAdmin = (to, from, next) => {
  const { user } = store.state.session;

  if (user.authority === 1) {
    return next();
  }

  return next('/');
};

export const isNotEditor = (to, from, next) => {
  const { user } = store.state.session;

  if ([1, 2].includes(user.authority)) {
    return next();
  }

  return next('/');
};

export const isActive = (to, from, next) => {
  const { user } = store.state.session;

  if (user.active === 1) {
    return next('/login');
  }

  return next();
};
