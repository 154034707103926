<template>
  <div class="notification is-warning">
    {{ notes }}
  </div>
</template>

<script>
export default {
  props: {
    notes: String,
  },
};
</script>
