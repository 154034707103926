import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getCollections({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`brands/${uuid}/collections`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setCollections', {
        collections: data.collections,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },
};

export default actions;
