<template>
  <tr>
    <td class="has-text-centered">
      <ProductPhoto :type="product.type" :photos="product.photos" />
    </td>
    <td class="has-text-centered" style="maxWidth: 250px">
      <router-link
        :to="{ name: 'products.edit', params: { uuid: product.uuid } }"
      >
        {{ product.title }}
      </router-link>
      <p v-for="(barcode, index) in product.barcodes" :key="index">
        {{ barcode.code }}
      </p>
    </td>
  </tr>
</template>

<script>
import ProductPhoto from '@/views/components/ProductPhoto';

export default {
  components: {
    ProductPhoto,
  },

  props: {
    product: Object,
  },
};
</script>
