import _get from 'lodash/get';

/* eslint-disable */
const mutations = {
  updateCustomerType(state, { customer_type }) {
    state.data = {
      ...state.data,
      customer_type,
      charge_address:
        customer_type === 'guest'
          ? null
          : _get(state.customer, 'addresses[0]', null),
    };
  },

  updateCustomerEmail(state, { email }) {
    state.data = {
      ...state.data,
      email,
      customer_type: 'guest',
      charge_address: null,
    };
  },

  addNewOrderCustomer(state, customer) {
    state.data = {
      ...state.data,
      customer,
      customer_type: 'guest',
      charge_address: null,
      email: '',
    };
  },

  resetOrderCustomer(state) {
    state.data = {
      ...state.data,
      customer_type: 'guest',
      customer: null,
      email: '',
    };
  },

  copyNewOrder(state, { order }) {
    state.data = {
      ...state.data,
      ...order,
    };
  },

  startNewOrderForACustomer(state, { customer }) {
    state.data = {
      ...state.data,
      checkout_method_id: null,
      send_method_id: null,
      order_method_id: null,
      products: [],
      customer,
      charge_address: customer.addresses[0],
      coupon: null,
    };
  },

  toggleCountry(state, isCyprus) {
    state.isSendingCountryCyprus = isCyprus;
  },

  resetNewOrder(state) {
    state.data = {
      ...state.data,
      email: '',
      customer_type: 'guest',
      customer: null,
      checkout_method_id: null,
      send_method_id: null,
      order_method_id: null,
      products: [],
      customer_notes: null,
      customer_notes_courier: null,
      is_gift: 0,
      gift_wishes: null,
      gift_cost: 0,
      checkout_charge: 0,
      send_charge: 0,
      charge_address: null,
      send_address: null,
      invoice: null,
      coupon: null,
    };
  },

  updateNewOrder(state, options) {
    state.data = {
      ...state.data,
      ...options,
    };
  },

  setCoupon(state, coupon) {
    state.data = {
      ...state.data,
      coupon,
    };
  },

  resetCoupon(state) {
    state.data = {
      ...state.data,
      coupon: null,
    };
  },
};

export default mutations;
