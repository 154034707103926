<template>
  <LoadingContainer
    :is-loading="isLoadingPostCategories && !isLoadedPostCategories"
  >
    <div class="card">
      <PaginatedListHeaderTemplate
        :total="paginationParams.count"
        :selections="selections"
        :has-selections="hasSelections"
        title="Κατηγορίες Άρθρων"
      >
        <template v-slot:options>
          <router-link
            :to="{ name: 'content.postCategories.add' }"
            class="button"
          >
            <span class="icon is-small"><i class="fa fa-plus"/></span
            ><span>Προσθήκη</span>
          </router-link>
        </template>
      </PaginatedListHeaderTemplate>
      <div class="card-content">
        <Filters
          :options="options"
          :is-loading="isFiltering"
          :filters="paginationParams.filters"
          general-label="Αναζήτηση με χρήση τίτλου"
          @navigate="navigate"
        />

        <NewPagination
          :items="postCategories"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column is-full">
            <LoadingContainer
              :is-loading="isFiltering && isLoadedPostCategories"
              :is-opaque="true"
              class="table-wrapper"
            >
              <table
                v-if="postCategories.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Τίτλος"
                        attribute="title"
                        @sort="sort"
                      />
                    </th>
                    <th class="hidden-print">Άρθρα</th>
                    <th class="hidden-print">Ενέργειες</th>
                  </tr>
                </thead>
                <tbody>
                  <postCategory
                    v-for="(item, index) in postCategories"
                    :item="item"
                    :index="paginationParams.indexStartsFrom + index"
                    :key="item.id"
                    :selections="selections"
                    @askToDeleteItem="askToDeleteItem"
                    @toggleSelection="toggleSelection"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν κατηγορίες
              </h4>
            </LoadingContainer>
          </div>
        </div>

        <NewPagination
          v-if="postCategories.length"
          :items="postCategories"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />
      </div>

      <confirmation
        :model-name="selectedItem.title"
        :is-open="isOpen"
        description="Είστε βέβαιος για τη διαγραφή αυτής της κατηγορίας;"
        @closeModal="closeModal"
      />
    </div>
  </LoadingContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/utils/request';
import PaginatedList from '@/views/components/PaginatedList';
import PaginatedListHeaderTemplate from '@/views/components/PaginatedList/components/PaginatedListHeaderTemplate';
import PostCategory from './components/PostCategory';

export default {
  components: {
    PostCategory,
    PaginatedListHeaderTemplate,
  },
  extends: PaginatedList,
  metaInfo: {
    title: 'Κατηγορίες Άρθρων',
  },
  data: () => ({
    options: [
      {
        label: 'Γενική Αναζήτηση',
        title: 'general',
        type: 'string',
        value: null,
      },
      {
        label: 'Έχει Άρθρα',
        title: 'has_posts',
        type: 'boolean',
        value: null,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      postCategories: 'postCategories/list/getData',
      paginationParams: 'postCategories/list/getPaginationParams',
      isLoadingPostCategories: 'postCategories/list/getIsLoading',
      isLoadedPostCategories: 'postCategories/list/getIsLoaded',
    }),
  },
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.getPostCategories(newVal.query);
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router.replace({
              name: this.$route.name,
              query: this.filterParams({
                page: 1,
                size: 50,
                filters: {},
                sort: {},
                paginated: 1,
              }),
            });
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getPostCategories: 'postCategories/list/getPostCategories',
    }),
    async navigate(params = {}) {
      try {
        this.$router.push({
          name: this.$route.name,
          query: this.filterParams({ ...this.paginationParams, ...params }),
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;

        if (response) {
          await request.delete(`/postCategories/${this.selectedItem.uuid}`);

          await this.navigate({
            timestamp: new Date().getTime(),
          });

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Η κατηγορία διαγράφτηκε',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    prepareExportXLS() {
      this.exportXLS(this.selections, ['title'], 'categories');
    },
    prepareExportCSV() {
      this.exportCSV(this.selections, ['title'], 'categories');
    },
  },
};
</script>
