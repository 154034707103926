/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setVideo(state, { video }) {
    state.data = video;
  },

  updateVideo(state, { video }) {
    state.data = {
      ...state.data,
      ...video,
    };
  },
};

export default mutations;
