<template>
  <div :class="['dashboard', { 'has-sidebar': showSidebar }]">
    <navbar />
    <notifications position="bottom right" />
    <main>
      <sidebar :show-sidebar="showSidebar" />
      <div class="app-main">
        <div sticky-container class="container is-fluid">
          <slot />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';

export default {
  components: {
    Navbar,
    Sidebar,
  },
  computed: {
    showSidebar() {
      return this.$store.state.sidebar.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  .app-main {
    padding-bottom: 20px;
    padding-top: calc(3.25em + 10px);
    transition: all 0.5s ease-in-out;
  }

  &.has-sidebar {
    .app-main {
      margin-left: 140px;
    }
  }
}
</style>
