/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setData(state, data) {
    state.data = data;
  },
  setBrandCategories(state, { brandCategories }) {
    state.brandCategories = brandCategories;
  },
};

export default mutations;
