<template>
  <table class="table is-bordered is-striped is-fullwidth">
    <tbody>
      <tr>
        <td colspan="8" class="has-text-right is-borderless">
          Καθαρή Αξία
        </td>
        <td class="has-text-centered">
          <strong>{{ detailsOrder.net_value }}</strong>
        </td>
      </tr>
      <tr v-if="hasTax">
        <td colspan="8" class="has-text-right is-borderless" />
        <td class="has-text-centered">
          <strong>+ {{ detailsOrder.tax }}</strong>
        </td>
      </tr>
      <tr v-if="hasCoupon">
        <td colspan="8" class="has-text-right is-borderless">
          Κουπόνι "{{ detailsOrder.coupon }}"
        </td>
        <td class="has-text-centered">
          <strong>{{ detailsOrder.coupon_discount }}</strong>
        </td>
      </tr>
      <tr v-if="hasCheckoutCharges">
        <td colspan="8" class="has-text-right is-borderless">
          Επιβάρυνση Τρόπου Πληρωμής
        </td>
        <td class="has-text-centered">
          <strong>{{ checkoutCharges }}</strong>
        </td>
      </tr>
      <tr v-if="order.is_gift">
        <td colspan="8" class="has-text-right is-borderless">
          Συσκευασία Δώρου
        </td>
        <td class="has-text-centered">
          <strong>{{ detailsOrder.gift_delivery_fee }}</strong>
        </td>
      </tr>
      <tr>
        <td colspan="8" class="has-text-right is-borderless title is-5">
          Συνολικό Ποσό
        </td>
        <td class="has-text-centered title is-5">
          <strong>{{ detailsOrder.total_amount }}</strong>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'Logistics',

  props: {
    order: Object,
  },

  computed: {
    detailsOrder() {
      return this.order.details.order;
    },

    hasTax() {
      return !!this.detailsOrder.tax;
    },

    hasCoupon() {
      return !!this.detailsOrder.coupon;
    },

    hasCheckoutCharges() {
      return !!this.detailsOrder.cash_on_delivery_fee;
    },

    checkoutCharges() {
      return this.hasCheckoutCharges
        ? this.detailsOrder.cash_on_delivery_fee
        : 0;
    },
  },
};
</script>

<style scoped>
.table {
  margin-top: -1px;
}
</style>
