<template>
  <LoadingContainer :is-loading="isLoading">
    <OrderSkroutzPrint
      :skroutz-smart-cart="skroutzSmartCart"
      class="visible-print"
    />

    <div v-if="skroutzSmartCart" class="hidden-print">
      <div class="columns is-multiline">
        <div class="column is-full">
          <SkroutzSmartCartInfo :skroutz-smart-cart="skroutzSmartCart" />
        </div>
      </div>

      <div class="columns is-multiline">
        <div class="column">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                Παραγγελία Skroutz
              </div>
              <div class="card-header-icon">
                <div class="buttons">
                  <button class="button" @click="print">
                    <span class="icon is-small">
                      <i class="fa fa-print" />
                    </span>
                    <span>Εκτύπωση</span>
                  </button>
                  <RouterLink
                    :to="{ name: 'orders.skroutz.list' }"
                    class="button ml-3"
                  >
                    <span class="icon is-small"><i class="fa fa-list"/></span
                    ><span>Λίστα</span>
                  </RouterLink>
                  <span v-if="skroutzSmartCart.state === 'open'">
                    <button
                      :class="{ 'is-loading': isSaving }"
                      type="button"
                      class="button is-primary ml-3"
                      @click="openAcceptModal"
                    >
                      Αποδοχή
                    </button>

                    <button
                      :class="{ 'is-loading': isRejecting }"
                      type="button"
                      class="button is-danger ml-3"
                      @click="openRejectModal"
                    >
                      Απόρριψη
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <div class="card-content">
              <div class="table-wrapper">
                <table class="table is-bordered is-striped is-fullwidth">
                  <thead>
                    <th>#</th>
                    <th>Φωτό</th>
                    <th>Skroutz ID</th>
                    <th>Τελική Τιμή (&euro;)</th>
                    <th>Σύνολο (&euro;)</th>
                    <th class="table__count">Ποσότητα</th>
                    <th class="table__count">Stock</th>
                  </thead>
                  <tbody>
                    <ProductSkroutz
                      v-for="(product, index) in skroutzSmartCart.line_items"
                      :key="index"
                      :product="product"
                      :index="index"
                    />
                    <tr>
                      <td
                        colspan="6"
                        class="has-text-right is-borderless title is-5"
                      >
                        Συνολικό Ποσό
                      </td>
                      <td class="has-text-centered title is-5">
                        <strong>{{ totalPrice }} &euro;</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <CustomerNotes
            v-if="skroutzSmartCart.comments"
            :notes="skroutzSmartCart.comments"
          />

          <!-- User Info -->
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Στοιχεία Χρέωσης
                </div>
              </div>
            </div>
            <div class="card-content">
              <div class="columns columns__address">
                <div class="column">
                  <div class="field">
                    <label class="label">Όνομα</label>
                    <div class="control">
                      {{ skroutzSmartCart.customer.first_name }}
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Επώνυμο</label>
                    <div class="control">
                      {{ skroutzSmartCart.customer.last_name }}
                    </div>
                  </div>
                </div>

                <div class="column">
                  <div class="field">
                    <label class="label">ID</label>
                    <div class="control">
                      {{ skroutzSmartCart.customer.id }}
                    </div>
                  </div>
                </div>

                <div class="column">
                  <div class="field">
                    <label class="label">Οδός</label>
                    <div class="control">
                      {{ skroutzSmartCart.customer.address.street_name }}
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Αριθμός</label>
                    <div class="control">
                      {{ skroutzSmartCart.customer.address.street_number }}
                    </div>
                  </div>
                </div>

                <div class="column">
                  <div class="field">
                    <label class="label">Τ. Κώδικας</label>
                    <div class="control">
                      {{ skroutzSmartCart.customer.address.zip }}
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Πόλη</label>
                    <div class="control">
                      {{ skroutzSmartCart.customer.address.city }}
                    </div>
                  </div>
                </div>
                <div class="field column">
                  <label class="label">Νομός</label>
                  <div class="control">
                    {{ skroutzSmartCart.customer.address.region }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--EOF User Info -->

          <!-- Courier Info -->
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Στοιχεία Courier
                </div>
              </div>
            </div>
            <div class="card-content">
              <div class="columns columns__address">
                <div class="column">
                  <div class="field">
                    <label class="label">Όνομα</label>
                    <div class="control">
                      {{ skroutzSmartCart.courier }}
                    </div>
                  </div>

                  <div class="field">
                    <div class="label">Courier voucher</div>
                    <div>
                      <a
                        :href="skroutzSmartCart.courier_voucher"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="control"
                      >
                        {{ skroutzSmartCart.courier_voucher }}
                      </a>
                    </div>
                  </div>
                </div>

                <div class="column">
                  <div class="field">
                    <label class="label">ID</label>
                    <div class="control">
                      {{ skroutzSmartCart.customer.id }}
                    </div>
                  </div>
                  <div class="tracking-codes-container">
                    <div
                      v-for="(code, // eslint-disable-next-line vue/html-indent
                      index) in skroutzSmartCart.courier_tracking_codes"
                      :key="index"
                      class="field courier-column"
                    >
                      <label class="label">Tracking code</label>
                      <div class="control">
                        {{ code }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- EOF Courier Info -->

          <!-- Order Timeline -->
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Χρονικό Πλαίσιο Παραγγελίας
                </div>
              </div>
            </div>
            <div class="card-content">
              <div class="columns columns__address">
                <div class="column">
                  <div class="field">
                    <label class="label">Αποστολή μέχρι</label>
                    <div class="control">
                      {{ skroutzSmartCart.dispatch_until | timestamp }}
                    </div>
                  </div>
                </div>

                <div class="column">
                  <div class="field">
                    <label class="label">Αποδοχή έως</label>
                    <div class="control">
                      {{ skroutzSmartCart.expires_at | timestamp }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--EOF Order Timeline -->

          <!-- Handle Order -->
          <div v-if="skroutzSmartCart.state == 'open'">
            <!-- Accept Modal -->
            <div :class="['modal', { 'is-active': isAcceptOpen }]">
              <div class="modal-background" @click="closeAcceptModal" />
              <div class="modal-card">
                <header class="modal-card-head">
                  <p class="modal-card-title">Επιβεβαίωση Παραγγελίας</p>
                  <button class="delete" @click="closeAcceptModal" />
                </header>
                <form @submit.prevent="submitApprove">
                  <section class="modal-card-body">
                    <div class="field">
                      <label class="label">Αριθμός Πακέτων *</label>
                      <div class="control">
                        <div class="is-fullwidth">
                          <input
                            v-validate="'required'"
                            v-model="acceptOrderForm.number_of_parcels"
                            type="number"
                            min="1"
                            step="1"
                            class="input"
                            name="noOfParcels"
                          />
                          <div
                            v-show="errors.has('noOfParcels')"
                            class="help is-danger"
                          >
                            Εισάγετε Αριθμό Πακέτων
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="field">
                      <label class="label">
                        Τοποθεσία Παραλαβής *
                      </label>
                      <div class="control">
                        <div class="select is-fullwidth">
                          <select
                            v-validate="'required'"
                            v-model.number="acceptOrderForm.pickup_location"
                            name="pickupLocation"
                          >
                            <option
                              v-for="(option, index) in pickupLocations"
                              :key="index"
                              :value="option.id"
                            >
                              {{ option.label }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div
                        v-show="errors.has('pickupLocation')"
                        class="help is-danger"
                      >
                        Επιλέξτε Τοποθεσία Παραλαβής
                      </div>
                    </div>

                    <div class="field">
                      <label class="label">
                        Παράθυρο Παραλαβής *
                      </label>
                      <div class="control">
                        <div class="select is-fullwidth">
                          <select
                            v-validate="'required'"
                            v-model.number="acceptOrderForm.pickup_window"
                            name="pickupWindow"
                          >
                            <option
                              v-for="(option, index) in pickupWindows"
                              :key="index"
                              :value="option.id"
                            >
                              {{ option.label }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div
                        v-show="errors.has('pickupWindow')"
                        class="help is-danger"
                      >
                        Επιλέξτε Παράθυρο Παραλαβής
                      </div>
                    </div>

                    <div class="field">
                      <label class="label">
                        Κινητό τηλέφωνο (copy/paste here from Skroutz)
                      </label>
                      <div class="control">
                        <div class="is-fullwidth">
                          <vue-tel-input
                            v-model="acceptOrderForm.mobile"
                            enabled-country-code
                            input-id="add_new_customer_mobile"
                            name="mobile"
                            placeholder="Εισάγετε Κινητό"
                            @input="onInputMobile"
                          />
                          <p
                            v-show="
                              errors.has('mobile') ||
                                errors.has('mobile-invalid')
                            "
                            class="help is-danger"
                          >
                            Εισάγετε έγκυρο κινητό τηλέφωνο
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <footer class="modal-card-foot">
                    <div class="field is-grouped">
                      <div class="control">
                        <button
                          :class="{ 'is-loading': isSaving }"
                          type="submit"
                          class="button is-primary"
                        >
                          Αποδοχή
                        </button>
                      </div>
                    </div>
                  </footer>
                </form>
              </div>
            </div>
            <!-- EOF Accept Modal -->

            <!-- Reject Modal -->
            <div :class="['modal', { 'is-active': isRejectOpen }]">
              <div class="modal-background" @click="closeRejectModal" />
              <div class="modal-card">
                <header class="modal-card-head">
                  <p class="modal-card-title">Ακύρωση Παραγγελίας</p>
                  <button class="delete" @click="closeRejectModal" />
                </header>
                <form data-vv-scope="rejectForm" @submit.prevent="submitReject">
                  <section class="modal-card-body">
                    <div class="field">
                      <label class="label">
                        Αρχικός Λόγος Απόρριψης
                      </label>
                      <div class="control">
                        <div class="select is-fullwidth">
                          <select v-model="reject_reason" name="pickupLocation">
                            <option
                              v-for="(option, index) in initialRejectReasons"
                              :key="index"
                              :value="option.id"
                            >
                              {{ option.label }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="reject_reason === 'rejection_reason_other'"
                      class="field"
                    >
                      <label class="label">
                        Λόγος Απόρριψης *
                      </label>
                      <div class="control">
                        <div class="is-fullwidth">
                          <textarea
                            v-validate="'required'"
                            v-model="rejection_reason_other"
                            class="textarea"
                            name="otherReason"
                          />
                          <div
                            v-show="errors.has('otherReason')"
                            class="help is-danger"
                          >
                            Εισάγετε λόγο
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <!-- ItemComponent -->
                      <div
                        v-for="(product, index) in skroutzSmartCart.line_items"
                        :key="index"
                        class="item-reasons"
                      >
                        <p>{{ product.product_name }}</p>
                        <div class="columns ">
                          <div class="column">
                            <div class="field">
                              <label class="label">
                                Λόγος Σχετικός με τα Αντικείμενα *
                              </label>
                              <div class="control">
                                <div class="select is-fullwidth">
                                  <select
                                    v-model.number="line_items[index].reason_id"
                                    name="reasonId"
                                  >
                                    <option
                                      v-for="(option, index) in rejectReasons"
                                      :key="index"
                                      :value="option.id"
                                    >
                                      {{ option.label }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            v-if="line_items[index].reason_id === 4"
                            class="column"
                          >
                            <div class="field">
                              <label class="label">
                                Ποσότητα *
                              </label>
                              <div class="control">
                                <div class="select is-fullwidth">
                                  <select
                                    v-model="
                                      line_items[index].available_quantity
                                    "
                                    name="availableQuantity"
                                  >
                                    <option
                                      v-for="(option, index) in Array.from(
                                        { length: 10 },
                                        (_, index) => index + 1,
                                      )"
                                      :key="index"
                                      :value="option"
                                    >
                                      {{ option }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--EOF ItemComponent -->
                    </div>
                  </section>
                  <footer class="modal-card-foot">
                    <div class="field is-grouped">
                      <div class="control">
                        <button
                          :class="{ 'is-loading': isRejecting }"
                          type="submit"
                          class="button is-danger ml-3"
                        >
                          Απόρριψη
                        </button>
                      </div>
                    </div>
                  </footer>
                </form>
              </div>
            </div>
            <!-- EOF Reject Modal -->
          </div>
          <!-- EOF Handle Order -->
        </div>
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import moment from 'moment';

import request from '@/utils/request';
import LoadingContainer from '@/views/components/LoadingContainer';
import OrderSkroutzPrint from '@/views/Dashboard/views/Orders/components/OrderSkroutzPrint/';
import ProductSkroutz from './components/ProductSkroutz';
import CustomerNotes from './components/CustomerNotes';
import SkroutzSmartCartInfo from './components/SkroutzSmartCartInfo';
import ProductsSearch from '../../components/ProductsSearch';

export default {
  filters: {
    timestamp(value, from = 'YYYY-MM-DD HH:mm:ss', to = 'DD-MM-YYYY HH:mm') {
      return moment.utc(value, from).format(to);
    },
  },

  components: {
    ProductSkroutz,
    CustomerNotes,
    SkroutzSmartCartInfo,
    ProductsSearch,
    LoadingContainer,
    OrderSkroutzPrint,
  },

  data() {
    return {
      isAcceptOpen: false,
      isRejectOpen: false,
      isSaving: false,
      isRejecting: false,
      skroutzSmartCart: null,
      acceptOrderForm: {
        number_of_parcels: 1,
        pickup_location: '',
        pickup_window: null,
        mobile: null,
      },
      reject_reason: 'rejection_reason_items',
      rejection_reason_other: '',
      initialRejectReasons: [
        {
          id: 'rejection_reason_items',
          label: 'Αντικείμενα',
        },
        {
          id: 'rejection_reason_other',
          label: 'Άλλος',
        },
      ],
      rejectReasons: [],
      line_items: [],
    };
  },

  computed: {
    totalPrice() {
      /* eslint-disable camelcase */
      return Number.parseFloat(
        this.skroutzSmartCart?.line_items.reduce(
          (acc, item) => acc + item.total_price,
          0,
        ),
      ).toFixed(2);
    },
    pickupWindows() {
      return this.skroutzSmartCart?.accept_options?.pickup_window;
    },
    pickupLocations() {
      return this.skroutzSmartCart?.accept_options?.pickup_location;
    },
  },

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(
        `/orders-skroutz/${this.$route.params.code}`,
      );

      this.skroutzSmartCart = {
        carespot_order_common_id: data.carespot_order?.common_id,
        ...data.order,
        accept_options: {
          number_of_parcels: [1],
          pickup_location: [],
          pickup_window: [],
          ...(data.order.accept_options || {}),
        },
        reject_options: {
          line_item_rejection_reasons: [],
          ...(data.order.reject_options || {}),
        },
      };
      this.rejectReasons = this.skroutzSmartCart.reject_options.line_item_rejection_reasons;
      this.line_items = this.skroutzSmartCart.line_items.map(item => ({
        id: item.id,
        reason_id: 1,
      }));
      this.isLoading = false;
    } catch (err) {
      this.$router.push('/error');
    }
  },

  methods: {
    async submitApprove() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }
        this.isSaving = true;

        await request.patch(
          `/smart-cart-events/${this.$route.params.code}/approve`,
          this.acceptOrderForm,
        );
        this.isSaving = false;

        this.closeAcceptModal();

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η παραγγελία αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    async submitReject() {
      try {
        const isValid = await this.$validator.validateAll('rejectForm');

        if (!isValid) {
          throw Error('Client-side validation failed');
        }
        this.isRejecting = true;

        await request.patch(
          `/smart-cart-events/${this.$route.params.code}/reject`,
          this.reject_reason === 'rejection_reason_other'
            ? {
                rejection_reason_other: this.rejection_reason_other,
                line_items: [],
              }
            : { line_items: this.line_items, rejection_reason_other: '' },
        );
        this.isRejecting = false;

        this.closeRejectModal();

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η παραγγελία αποθηκεύτηκε',
        });
      } catch (err) {
        this.isRejecting = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    openAcceptModal() {
      this.isAcceptOpen = true;
    },

    closeAcceptModal() {
      this.isAcceptOpen = false;
      this.resetAcceptForm();
    },

    openRejectModal() {
      this.isRejectOpen = true;
    },

    closeRejectModal() {
      this.isRejectOpen = false;
      this.resetRejectForm();
    },

    resetAcceptForm() {
      this.acceptOrderForm = {
        number_of_parcels: 1,
        pickup_location: '',
        pickup_window: null,
        mobile: null,
      };
    },

    resetRejectForm() {
      this.reject_reason = 'rejection_reason_items';
      this.rejection_reason_other = '';
      // reason id & available quantity are stored into line items []
      this.line_items = this.line_items.map(item => {
        // eslint-disable-next-line no-param-reassign
        item.reason_id = 1;
        // eslint-disable-next-line no-param-reassign
        delete item.available_quantity;
        return item;
      });
    },

    onInputMobile(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'mobile-invalid',
          msg: 'Wrong mobile',
        });
      } else {
        this.acceptOrderForm.mobile = number.international;
        this.errors.remove('mobile-invalid');
      }
    },

    print() {
      window.print();
    },
  },
};
</script>

<style scoped lang="scss">
.tracking-codes-container {
  display: flex;
  flex-wrap: wrap;
}

.courier-column {
  flex: 50%;
}

.table-wrapper {
  overflow-x: auto;
}

.table {
  margin: -1px 0 0;

  &__barcode {
    min-width: 170px;
  }

  &__count {
    max-width: 100px;
  }
}

.columns__address {
  margin-bottom: 0;
}

.item-reasons {
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
