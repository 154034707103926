<template>
  <LoadingContainer :is-loading="isLoadingModel">
    <form novalidate @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Επεξεργασία Μεθόδου Πληρωμής
              <span class="tag">{{ model.title }}</span>
            </div>
          </div>
          <div class="card-header-icon">
            <div class="field has-addons is-marginless">
              <div class="control">
                <router-link
                  :to="{ name: 'settings.checkoutMethods.list' }"
                  class="button"
                >
                  <span class="icon is-small"><i class="fa fa-list"/></span
                  ><span>Λίστα</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="card-content">
          <div class="field">
            <label class="label">Εμφανές *</label>
            <div class="control">
              <div class="select select is-fullwidth">
                <select v-model.number="newModel.active" name="active">
                  <option :value="false">Όχι</option>
                  <option :value="true">Ναί</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Επιβάρυνση *</label>
            <div class="control">
              <input
                v-model.number="newModel.charge"
                type="number"
                min="0"
                step="0.01"
                class="input"
                name="charge"
              />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </div>
    </form>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _pick from 'lodash/pick';
import AddItem from '@/views/components/AddItem';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';

export default {
  components: {
    ToggleableCard,
    LoadingContainer,
  },
  extends: AddItem,
  data() {
    return {
      newModel: {},
    };
  },
  computed: {
    ...mapGetters({
      model: 'checkoutMethods/edit/getCheckoutMethod',
      isLoadingModel: 'checkoutMethods/edit/getIsLoading',
    }),
  },
  watch: {
    model: {
      handler(newVal) {
        this.newModel = _pick(newVal, ['charge', 'active']);
      },
      deep: true,
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    ...mapActions({
      getCheckoutMethod: 'checkoutMethods/edit/getCheckoutMethod',
      updateCheckoutMethod: 'checkoutMethods/edit/updateCheckoutMethod',
    }),
    async fetchData() {
      try {
        await this.getCheckoutMethod({ uuid: this.$route.params.uuid });
      } catch (err) {
        this.$router.push('/error');
      }
    },
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateCheckoutMethod({
          checkoutMethod: this.newModel,
          uuid: this.model.uuid,
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Tα στοιχεία αποθηκεύτηκαν',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.newModel = _pick(this.model, ['charge', 'active']);

      this.$validator.reset();
    },
  },
};
</script>
