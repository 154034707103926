<template>
  <tr>
    <td>
      <ToggleButton
        :sync="true"
        :value="isChecked"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        disabled
      />
    </td>
    <td>
      <span>
        {{ item.title }}
      </span>
      <div v-if="isChecked">
        <div>
          <small>{{ statusValue }}</small>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'Status',

  props: {
    index: Number,
    item: Object,
    order: Object,
    isDeleted: Boolean,
  },

  computed: {
    shippingDetails() {
      return this.order.details.shipping_details;
    },

    statusValue() {
      let itemValue;

      switch (this.item.slug) {
        case 'new':
          itemValue = this.order.details.order.created_at;
          break;
        case 'confirm':
          itemValue = this.shippingDetails.confirmed_at;
          break;
        case 'check':
          itemValue = this.shippingDetails.stock_control;
          break;
        case 'pick':
          itemValue = this.shippingDetails.collected_at;
          break;
        case 'voucher':
          itemValue = this.shippingDetails.voucher;
          break;
        case 'invoice':
          itemValue = this.shippingDetails.pricing_at;
          break;
        case 'for_delivery':
          itemValue = this.shippingDetails.sent_at;
          break;
        case 'canceled':
          itemValue = this.order.details.order.cancellation_date;
          break;
        default:
      }

      return itemValue || '-';
    },

    isChecked() {
      return this.statusValue !== '-';
    },
  },
};
</script>
