/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setData(state, payload) {
    state.isLoaded = true;
    state.data = payload;
  },
};

export default mutations;
