import _get from 'lodash/get';

/* eslint-disable */
const getters = {
  getOrderStatuses(state, getters) {
    const statuses = _get(getters.getOrder, 'statuses', []).reduce(
      (acc, item) => {
        acc[item.id] = item;

        return acc;
      },
      {},
    );

    return getters.getStatuses.map(status =>
      Object.keys(statuses).includes(status.id.toString())
        ? {
            ...status,
            ...statuses[status.id],
            checked: true,
          }
        : {
            ...status,
            checked: false,
          },
    );
  },
};

export default getters;
