<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Χρέωση βάσει Βάρους Παραγγελίας
        </div>
      </div>
      <div class="card-header-icon">
        <div v-if="isEditing" class="field has-addons is-marginless">
          <div class="control">
            <button
              type="button"
              class="button"
              @click="isEditing = !isEditing"
            >
              <span class="icon is-small"><i class="fa fa-eye"/></span
              ><span>Προβολή</span>
            </button>
          </div>
          <div class="control">
            <button type="button" class="button" @click="addCharge">
              <span class="icon is-small"><i class="fa fa-plus"/></span
              ><span>Προσθήκη</span>
            </button>
          </div>
        </div>
        <button
          v-else
          type="button"
          class="button"
          @click="isEditing = !isEditing"
        >
          <span class="icon is-small"><i class="fa fa-pencil"/></span
          ><span>Επεξεργασία</span>
        </button>
      </div>
    </div>
    <div class="card-content">
      <table class="table is-bordered is-striped is-fullwidth">
        <thead>
          <tr>
            <th>Operator</th>
            <th>Βάρος Παραγγελίας</th>
            <th>Επιπλέον Χρέωση ανά Kg</th>
            <th v-if="isEditing">Ενέργειες</th>
          </tr>
        </thead>
        <tbody v-if="charges.weight.length">
          <Charge
            v-for="(charge, index) in charges.weight"
            :charge="charge"
            :is-editing="isEditing"
            :key="index"
            @updateItem="updateItem"
            @deleteItem="deleteItem"
          />
        </tbody>
        <tbody v-else>
          <tr>
            <td class="has-text-centered" colspan="4">Δε βρέθηκαν χρεώσεις</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import shortid from 'shortid';
import Charge from './components/Charge';

export default {
  components: {
    Charge,
  },
  props: {
    charges: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
    };
  },
  methods: {
    addCharge() {
      const charges = [
        ...this.charges.weight,
        {
          id: shortid.generate(),
          operator: '>=',
          limit: 0,
          charge: 0,
          per_kilo_amount: 1,
          per_kilo_charge: 0,
        },
      ];

      this.$emit('updateWeightCharges', charges);
    },
    deleteItem(item) {
      const charges = this.charges.weight.filter(({ id }) => id !== item.id);

      this.$emit('updateWeightCharges', charges);
    },
    updateItem(item) {
      const charges = this.charges.weight.map(charge =>
        item.id === charge.id
          ? {
              ...charge,
              ...item,
            }
          : charge,
      );

      this.$emit('updateWeightCharges', charges);
    },
  },
};
</script>
