<template>
  <CustomersEdit v-bind="$props">
    <div class="card-content">
      <slot />

      <div class="columns">
        <div class="column is-half">
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input
                v-if="emails.length === 1"
                :value="`${emails[0].email} | ${emails[0].group}`"
                class="input"
                disabled
              />
              <div v-else class="select select is-fullwidth">
                <select name="emails">
                  <option v-for="(email, index) in emails" :key="index">
                    {{ email.email }} | {{ email.group }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-half">
          <div class="field">
            <label class="label">Όνομα</label>
            <div class="control">
              <input
                :value="customer.firstName"
                disabled
                class="input"
                type="text"
                name="firstName"
              />
            </div>
          </div>
        </div>

        <div class="column is-half">
          <div class="field">
            <label class="label">Επώνυμο</label>
            <div class="control">
              <input
                :value="customer.lastName"
                disabled
                class="input"
                type="text"
                name="lastName"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-half">
          <div class="field">
            <label class="label">Μέθοδος Αρχικής Εγγραφής</label>
            <div class="control">
              <div class="select select is-fullwidth">
                <select
                  :value="customer.registration_method_id"
                  disabled
                  name="registration_method_id"
                >
                  <option
                    v-for="method in registrationMethods"
                    :key="method.id"
                    :value="method.id"
                  >
                    {{ method.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-half">
          <div class="field">
            <label class="label">Επιμέρους Τύπος Αρχικής Εγγραφής</label>
            <div class="control">
              <div class="select select is-fullwidth">
                <select
                  :value="customer.registration_method_type_id"
                  disabled
                  name="registration_method_type_id"
                >
                  <optgroup
                    v-for="method in registrationMethods"
                    v-if="method.id === customer.registration_method_id"
                    :key="method.id"
                    :label="method.title"
                  >
                    <option
                      v-for="type in method.types"
                      :key="type.id"
                      :value="type.id"
                    >
                      {{ type.title }}
                    </option>
                  </optgroup>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-half">
          <div class="field">
            <label class="label">Newsletter Email</label>
            <div class="control newsletter">
              <ToggleButton
                :value="!!customer.newsletter_email"
                :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                :width="30"
                :height="15"
                disabled
              />
              <input
                v-if="customer.newsletter_email"
                :value="customer.newsletter_email"
                class="input is-static"
                type="email"
                name="newsletter_email"
                readonly
              />
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-half">
          <div class="field">
            <label class="label">Φύλο</label>
            <div class="control">
              <div class="select select is-fullwidth">
                <select :value="customer.gender" name="gender" disabled>
                  <option :value="null">Επιλέξτε Φύλο</option>
                  <option value="male">Άνδρας</option>
                  <option value="female">Γυναίκα</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-half">
          <div class="field">
            <label class="label">Ημ/νια Γένησης</label>
            <div class="control">
              <input
                :value="customer.birthday"
                disabled
                class="input"
                type="text"
                name="birthday"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-half">
          <div class="field">
            <label class="label">Κινητό</label>
            <div class="control">
              <vue-tel-input
                v-model="customer.mobile"
                enabled-country-code
                placeholder=""
                name="mobile"
                disabled
              />
            </div>
          </div>
        </div>

        <div class="column is-half">
          <div class="field">
            <label class="label">Σταθερό</label>
            <div class="control">
              <vue-tel-input
                v-model="customer.telephone"
                enabled-country-code
                placeholder=""
                name="telephone"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </CustomersEdit>
</template>

<script>
import { mapGetters } from 'vuex';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import EditItem from '@/views/components/EditItem';
import CustomersEdit from '../CustomersEdit';

export default {
  name: 'CustomersEditCustomer',
  components: {
    CustomersEdit,
  },
  extends: EditItem,
  props: {
    customer: Object,
    tabs: Array,
    activeTab: String,
  },
  computed: {
    ...mapGetters({
      registrationMethods: 'getRegistrationMethods',
    }),
    isDeleted() {
      return this.customer.deleted_at !== null;
    },
    emails() {
      return Object.entries(this.customer.emails).reduce(
        (acc, [key, value]) => {
          if (!_isEmpty(value)) {
            if (_isObject(value)) {
              return [...acc, ...value.map(email => ({ email, group: key }))];
            }

            return [...acc, { email: value, group: key }];
          }

          return acc;
        },
        [],
      );
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-tel-input {
  border-radius: 2px;

  .dropdown {
    padding: 6px;

    &:focus {
      outline: none;
    }
  }

  &.disabled {
    background-color: whitesmoke;
    border-color: #dbdbdb;

    &:focus-within {
      box-shadow: none;
      outline: none;
    }

    & input {
      background-color: transparent;
      color: #7a7a7a;
    }

    & .dropdown,
    & .selection,
    & input {
      cursor: not-allowed;
    }
  }

  input {
    font-size: 1rem;
  }
}

.newsletter {
  align-items: center;
  display: flex;

  .is-static {
    border: 1px solid #dbdbdb;
    color: #7a7a7a;
    display: inline-block;
    margin-left: 10px;
    padding: 0 5px;
  }
}
</style>
