import createLogger from 'vuex/dist/logger';
import { saveState } from '@/utils/localStorage';

/* eslint-disable */
const localStoragePlugin = store => {
  store.subscribe((mutation, { session, newOrder }) =>
    saveState({
      session,
      // newOrder,
    }),
  );
};

export default (process.env.NODE_ENV !== 'production'
  ? [createLogger(), localStoragePlugin]
  : [localStoragePlugin]);
