<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Στοιχεία Παραλήπτη
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Ονοματεπώνυμο</label>
              <div class="control">
                {{ address.name }}
              </div>
            </div>

            <div class="field">
              <label class="label">Διεύθυνση</label>
              <div class="control">
                {{ address.address }}
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Κιν. Τηλέφωνο</label>
              <div class="control">
                {{ address.mobile }}
              </div>
            </div>

            <div class="field">
              <label class="label">Σταθ. Τηλέφωνο</label>
              <div class="control">
                {{ address.phone }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: Object,
  },

  computed: {
    address() {
      return this.order.details.shipping_details;
    },
  },
};
</script>
