var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v(" Meta title * "),(
            _vm.meta.title_for_category_products_page &&
              _vm.meta.title_for_category_products_page.length
          )?_c('span',{staticClass:"tag"},[_vm._v(_vm._s(_vm.meta.title_for_category_products_page.length))]):_vm._e()]),_c('div',{staticClass:"control"},[_c('textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"textarea",attrs:{"disabled":!_vm.isSuper,"name":"meta_title_for_category_products_page"},domProps:{"value":_vm.meta.title_for_category_products_page},on:{"input":_vm.updateMeta}})]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('meta_title_for_category_products_page')),expression:"errors.has('meta_title_for_category_products_page')"}],staticClass:"help is-danger"},[_vm._v(" Εισάγετε title ")])])]),_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v(" Meta Description * "),(
            _vm.meta.description_for_category_products_page &&
              _vm.meta.description_for_category_products_page.length
          )?_c('span',{staticClass:"tag"},[_vm._v(_vm._s(_vm.meta.description_for_category_products_page.length))]):_vm._e(),_vm._v("  "),_c('small',[_vm._v("(μέγιστο όριο: 290 χαρακτήρες)")])]),_c('div',{staticClass:"control"},[_c('textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:290'),expression:"'required|max:290'"}],staticClass:"textarea",attrs:{"disabled":!_vm.isSuper,"name":"meta_description_for_category_products_page"},domProps:{"value":_vm.meta.description_for_category_products_page},on:{"input":_vm.updateMeta}})]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.errors.has('meta_description_for_category_products_page:required')
        ),expression:"\n          errors.has('meta_description_for_category_products_page:required')\n        "}],staticClass:"help is-danger"},[_vm._v(" Εισάγετε description ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('meta_description_for_category_products_page:max')),expression:"errors.has('meta_description_for_category_products_page:max')"}],staticClass:"help is-danger"},[_vm._v(" Εισάγετε μέχρι 290 χαρακτήρες ")])])]),_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Meta Keywords *")]),_c('div',{staticClass:"control"},[_c('textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"textarea",attrs:{"disabled":!_vm.isSuper,"name":"meta_keywords"},domProps:{"value":_vm.meta.keywords},on:{"input":_vm.updateMeta}})]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('meta_keywords')),expression:"errors.has('meta_keywords')"}],staticClass:"help is-danger"},[_vm._v(" Εισάγετε Keywords ")])])]),_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Schema *")]),_c('div',{staticClass:"control"},[_c('codemirror',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"disabled":!_vm.isSuper,"value":_vm.meta.schema,"data-vv-value-path":"value","data-vv-name":"meta_schema"},on:{"input":_vm.updateSchema}})],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('meta_schema')),expression:"errors.has('meta_schema')"}],staticClass:"help is-danger"},[_vm._v(" Εισάγετε Schema ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }