<script>
import { mapGetters } from 'vuex';
import request from '@/utils/request';
import PaginatedList from '@/views/components/PaginatedList';
import PeriodicDiscountHeader from './components/PeriodicDiscountHeader';
import PeriodicDiscount from './components/PeriodicDiscount';

export default {
  components: {
    PeriodicDiscountHeader,
    PeriodicDiscount,
  },
  extends: PaginatedList,

  computed: {
    ...mapGetters({
      items: 'periodicDiscounts/list/getData',
      paginationParams: 'periodicDiscounts/list/getPaginationParams',
      isLoadingPeriodicDiscounts: 'periodicDiscounts/list/getIsLoading',
      isLoaded: 'periodicDiscounts/list/getIsLoaded',
    }),
  },
  methods: {
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;

        if (response) {
          await request.delete(`/periodic-discounts/${this.selectedItem.uuid}`);

          await this.navigate({
            timestamp: new Date().getTime(),
          });

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Η Περιοδική Έκπτωση διαγράφτηκε επιτυχώς!!',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
