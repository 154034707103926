<template>
  <tr :class="{ 'is-selected': selected, 'hidden-print': !selected }">
    <td class="has-text-centered hidden-print">
      <checkbox v-model="selected" name="selected" />
    </td>
    <td class="has-text-centered">
      <router-link
        :to="{ name: 'customers.edit', params: { uuid: item.uuid } }"
      >
        {{ item.fullName }}
      </router-link>
    </td>
    <td class="has-text-centered">
      <router-link
        v-tooltip="{ content: emails, classes: 'tooltip' }"
        :to="{ name: 'customers.edit', params: { uuid: item.uuid } }"
      >
        {{ item.emails | primaryEmail }}
      </router-link>
    </td>
    <td class="has-text-centered">
      {{ genderDescription }}
    </td>
    <td class="has-text-centered">
      {{ item.birthday }}
    </td>
    <td class="has-text-centered">
      {{ item.count }}
    </td>
    <td class="has-text-centered">
      {{ item.sum_products }}
    </td>
    <td class="has-text-centered hidden-print">
      <div class="buttons is-centered">
        <router-link
          :to="{ name: 'customers.edit', params: { uuid: item.uuid } }"
          class="button is-small"
        >
          <span class="icon is-small">
            <span class="fa fa-fw fa-pencil" />
          </span>
        </router-link>
        <button v-if="!isDeleted" class="button is-small" @click="onDelete">
          <span class="icon is-small">
            <span class="fa fa-fw fa-trash-o" />
          </span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';

export default {
  extends: PaginatedListItem,
  computed: {
    genderDescription() {
      const { gender } = this.item;

      if (gender) {
        return gender === 'male' ? 'Άνδρας' : 'Γυναίκα';
      }

      return '';
    },
    emails() {
      const emails = Object.entries(this.item.emails).reduce(
        (acc, [key, value]) => {
          if (!_isEmpty(value)) {
            return { ...acc, [key]: value };
          }

          return acc;
        },
        {},
      );

      return JSON.stringify(emails);
    },
  },
};
</script>

<style lang="scss">
.tooltip {
  max-width: 300px;
  text-align: center;
  word-break: break-word;
}
</style>
