var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tabs"},[_c('ul',[_c('router-link',{attrs:{"to":{ name: 'settings.sendCharges.gr' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('span',[_vm._v("Ελλάδα")]),_c('span',{staticClass:"icon fa flag-icon flag-icon-gr"})])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'settings.sendCharges.cy' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('span',[_vm._v("Κύπρος")]),_c('span',{staticClass:"icon fa flag-icon flag-icon-cy"})])])]}}])})],1)]),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }