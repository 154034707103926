<template>
  <ExistingItems :items="items" :to="to" title="Σειρές Μάρκας">
    <template v-slot:item="props" v-if="items.length">
      <router-link
        :to="{
          name: 'products.brands.collections.edit',
          params: { uuid: props.item.brand.uuid, collection: props.item.uuid },
        }"
      >
        <Photo :photos="props.item.photos" />
        <span>{{ props.item.title }}</span>
      </router-link>
    </template>

    <p v-else>Δε βρέθηκαν αποτελέσματα</p>
  </ExistingItems>
</template>

<script>
import Photo from '@/views/components/Photo';
import ExistingItems from '@/views/components/ExistingItems';

export default {
  components: {
    Photo,
    ExistingItems,
  },
  props: {
    items: Array,
    brand: Object,
  },
  computed: {
    to() {
      return this.items.length > 0
        ? {
            name: 'products.brands.collections.list',
            params: { uuid: this.brand.uuid },
          }
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  align-items: center;
  display: flex;
}

img {
  height: 30px;
  margin-right: 10px;
  width: 30px;
}
</style>
