import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async updateFaq({ dispatch, commit }, faq) {
    try {
      const { data } = await request.put(`/faqs/${faq.uuid}`, faq);

      if (data.error) {
        throw Error('Server-side validation failed');
      }
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
