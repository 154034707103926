import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async updateOrderProducts({ commit, rootGetters: { getOrder } }, payload) {
    try {
      const response1 = await request.put(
        `/orders/${getOrder.uuid}/products`,
        payload,
      );

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(
        `/customers/${getOrder.customer.uuid}`,
      );

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateOrderProducts', {
        statuses: response1.data.order.statuses,
        products: response1.data.products,
        coupon: response1.data.coupon,
        send_charge: response1.data.send_charge,
      });

      await commit('updateOrderCustomer', {
        customer: response2.data.customer,
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};

export default actions;
