import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getTag({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/tags/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setTag', {
        tag: data.tag,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getTagPhotos({ dispatch, commit }, { uuid }) {
    try {
      const { data } = await request.get(`/tags/${uuid}/photos`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateTag', {
        tag: data.tag,
      });
    } catch (err) {
      throw err;
    }
  },

  async updateTag({ dispatch, commit }, { uuid, tag }) {
    try {
      const response1 = await request.put(`/tags/${uuid}`, tag);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/tags/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setTag', {
        tag: response2.data.tag,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
