<template>
  <div class="customer-tab">
    <table
      v-if="customer.incoming_emails.length"
      class="table is-bordered is-striped is-marginless is-fullwidth"
    >
      <thead>
        <tr>
          <th>Ημ/νια</th>
          <th>Περιεχόμενο</th>
          <th class="hidden-print">Ενέργειες</th>
        </tr>
      </thead>
      <tbody>
        <email
          v-for="(item, index) in customer.incoming_emails"
          :item="item"
          :index="index"
          :key="item.id"
          @selectItem="selectItem"
        />
      </tbody>
    </table>
    <h5 v-else class="title is-5 has-text-centered is-marginless">
      Δε βρέθηκαν emails
    </h5>

    <email-preview
      :is-open="isOpen"
      :customer="customer"
      :email="selectedEmail"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Email from './components/Email';
import EmailPreview from './components/EmailPreview';

export default {
  components: {
    Email,
    EmailPreview,
  },
  data() {
    return {
      isOpen: false,
      selectedEmail: {},
    };
  },
  computed: {
    ...mapGetters({
      customer: 'customers/edit/getCustomer',
    }),
  },
  methods: {
    selectItem(item) {
      this.selectedEmail = item;
      this.isOpen = true;
    },
    closeModal() {
      this.selectedEmail = {};
      this.isOpen = false;
    },
  },
};
</script>
