<template>
  <div :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background" @click="closeModal" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Επιβεβαίωση Ακύρωσης</p>
        <button class="delete" @click="closeModal" />
      </header>
      <form @submit.prevent="submit">
        <section class="modal-card-body">
          <div class="field">
            <label class="label">Λογος Ακυρωσης *</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select
                  v-model.number="cancel_reason_id"
                  name="cancel_reason_id"
                >
                  <option
                    v-for="reason in cancelOrderReasons"
                    :key="reason.id"
                    :value="reason.id"
                  >
                    {{ reason.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">
              Αιτιολογία Ακύρωσης (Προσοχή, θα σταλλεί στον πελάτη!!)
            </label>
            <div class="control">
              <textarea
                v-model.trim="cancel_description"
                rows="2"
                type="text"
                class="textarea"
                name="cancel_description"
              />
            </div>
          </div>

          <div class="field">
            <div class="control">
              <checkbox v-model="inform_client" name="inform_client" />
              <label class="label">Ενημέρωση Πελάτη</label>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </footer>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FormSubmit from '@/views/components/FormSubmit';

export default {
  name: 'Confirmation',
  components: {
    FormSubmit,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cancel_reason_id: 1,
      cancel_description: '',
      inform_client: false,
      isSaving: false,
    };
  },
  computed: {
    ...mapGetters({
      cancelOrderReasons: 'cancelOrderReasons/list/getCancelOrderReasons',
    }),
  },
  methods: {
    ...mapActions(['cancelOrder']),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.cancelOrder({
          cancel_reason_id: this.cancel_reason_id,
          cancel_description: this.cancel_description,
          inform_client: this.inform_client,
        });
        this.isSaving = false;

        this.closeModal();

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η παραγγελία ακυρώθηκε επιτυχώς',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.cancel_reason_id = 1;
      this.cancel_description = '';
      this.inform_client = false;

      this.$validator.reset();
    },
    closeModal() {
      this.reset();

      this.$emit('closeModal');
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox-component,
.label {
  display: inline-block;
}

.checkbox-component + .label {
  margin-left: 5px;
}
</style>
