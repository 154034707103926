import request from '@/utils/request';
import router from '@/router';

/* eslint-disable */
const actions = {
  async addAuthor({ dispatch, commit }, author) {
    try {
      const { data } = await request.post('authors', author);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      router.push({
        name: 'content.authors.edit',
        params: { uuid: data.author.uuid },
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
