<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Vouchers
          </div>
        </div>
      </div>
      <form @submit.prevent="submit">
        <div class="card-content">
          <div class="field">
            <label class="label">Voucher No *</label>
            <div class="control">
              <input
                v-validate="'required'"
                v-model.trim="form.voucher"
                class="input"
                type="text"
                name="voucher"
              />
              <p v-show="errors.has('voucher')" class="help is-danger">
                Εισάγετε voucher
              </p>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <FormSubmit
            :is-saving="isSaving"
            submit-label="Αναζήτηση"
            @reset="reset"
          />
        </div>
      </form>
    </div>

    <div v-if="hasHistory" class="card">
      <div class="card-content">
        <table class="table is-bordered is-striped is-marginless is-fullwidth">
          <thead>
            <tr>
              <th class="has-text-centered">#</th>
              <th class="has-text-centered">Ημ/νια</th>
              <th class="has-text-centered">Ενέργεια</th>
              <th class="has-text-centered">Τοποθεσία</th>
              <th class="has-text-centered">Λεπτομέρειες</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in history" :key="index">
              <td class="has-text-centered">{{ index + 1 }}</td>
              <td class="has-text-centered">
                {{ item.checkpoint_date_time | date }}
              </td>
              <td class="has-text-centered">
                {{ item.checkpoint_action }}
              </td>
              <td class="has-text-centered">
                {{ item.checkpoint_location }}
              </td>
              <td class="has-text-centered">
                {{ item.checkpoint_notes }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { get } from 'lodash';
import request from '@/utils/request';
import FormSubmit from '@/views/components/FormSubmit';

export default {
  components: {
    FormSubmit,
  },

  filters: {
    date(val) {
      return moment(val).format('DD-MM-YYYY HH:mm:ss');
    },
  },

  data() {
    return {
      form: {
        voucher: '',
      },
      isSaving: false,
      history: [],
    };
  },

  computed: {
    hasHistory() {
      return this.history.length > 0;
    },
  },

  methods: {
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        const { data } = await request.get(`/vouchers/${this.form.voucher}`);
        this.isSaving = false;

        if (data.ACSExecution_HasError === false) {
          this.history = get(
            data,
            'ACSOutputResponce.ACSTableOutput.Table_Data',
            [],
          );
        }

        if (data.error) {
          throw Error('Server-side validation failed');
        }
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.form = {
        voucher: '',
      };
      this.$validator.reset();
    },
  },
};
</script>
