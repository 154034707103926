<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-one-quarter-widescreen is-half-tablet">
        <router-link :to="{ name: 'orders.list' }">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                Παραγγελίες&nbsp;
                <small>(30 ημέρες)</small>
              </div>
              <div class="card-header-icon">{{ orders }}</div>
            </div>
            <div class="card-content has-text-centered">
              <span class="icon is-large">
                <i class="fa fa-shopping-basket" />
              </span>
            </div>
          </div>
        </router-link>
      </div>

      <div class="column is-one-quarter-widescreen is-half-tablet">
        <div class="card">
          <div class="card-header">
            <div class="card-header-title">
              Πωλήσεις&nbsp;
              <small>(30 ημέρες)</small>
            </div>
            <div class="card-header-icon">{{ sales }}</div>
          </div>
          <div class="card-content has-text-centered">
            <span class="icon is-large"><i class="fa fa-euro"/></span>
          </div>
        </div>
      </div>

      <div class="column is-one-quarter-widescreen is-half-tablet">
        <router-link :to="{ name: 'customers.list' }">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                Μέλη
              </div>
              <div class="card-header-icon">{{ customers }}</div>
            </div>
            <div class="card-content has-text-centered">
              <span class="icon is-large"><i class="fa fa-users"/></span>
            </div>
          </div>
        </router-link>
      </div>

      <div class="column is-one-quarter-widescreen is-half-tablet">
        <router-link :to="{ name: 'products.list' }">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                Προιόντα
              </div>
              <div class="card-header-icon">{{ products }}</div>
            </div>
            <div class="card-content has-text-centered">
              <span class="icon is-large"><i class="fa fa-cubes"/></span>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="columns is-multiline">
      <div class="column is-half">
        <orders-number />
      </div>
      <div class="column is-half">
        <orders-profit />
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request';
import OrdersNumber from '@/views/components/OrdersNumber';
import OrdersProfit from '@/views/components/OrdersProfit';

export default {
  components: {
    OrdersNumber,
    OrdersProfit,
  },
  data() {
    return {
      orders: 0,
      sales: 0,
      customers: 0,
      products: 0,
    };
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const { data } = await request.get('/dashboard');

      next(vm => vm.setData(data));
    } catch (err) {
      next(vm => vm.$router.push('/error'));
    }
  },
  methods: {
    setData({ orders, sales, customers, products }) {
      this.orders = new Intl.NumberFormat('de-DE').format(orders);
      this.sales = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(sales);
      this.customers = new Intl.NumberFormat('de-DE').format(customers);
      this.products = new Intl.NumberFormat('de-DE').format(products);
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  margin-bottom: 0;
}

.card-header-icon {
  color: #22a684;
  font-weight: 700;
}

.icon {
  &.is-large {
    height: 10rem;
    width: 10rem;

    .fa {
      color: #a0a0a0;
      font-size: 6rem;
    }
  }
}
</style>
