<template>
  <div class="login">
    <div class="card">
      <div class="card-header">
        <img src="@/assets/logo_green.png" alt="Carespot" />
      </div>
      <form @submit.prevent="submit">
        <div class="card-content">
          <notification
            v-if="hasError"
            :has-error="hasError"
            @hideNotification="hasError = false"
          >
            Εισάγετε έγκυρα στοιχεία
          </notification>

          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input
                v-validate="'required|email'"
                v-model="user.email"
                type="email"
                class="input"
                name="email"
                placeholder="Εισάγετε email"
              />
            </div>
            <div v-show="errors.has('email:required')" class="help is-danger">
              Εισάγετε email
            </div>
            <div v-show="errors.has('email:email')" class="help is-danger">
              Εισάγετε έγκυρο email
            </div>
          </div>

          <div class="field">
            <label class="label">Κωδικός</label>
            <div class="control">
              <input
                v-validate="'required|min:8'"
                v-model="user.password"
                type="password"
                class="input"
                name="password"
                placeholder="Εισάγετε κωδικό"
              />
            </div>
            <div
              v-show="errors.has('password:required')"
              class="help is-danger"
            >
              Εισάγετε κωδικό
            </div>
            <div v-show="errors.has('password:min')" class="help is-danger">
              Εισάγετε 8 ή περισσότερους χαρακτήρες
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button
            :class="[
              'button is-primary is-fullwidth',
              { 'is-loading': isSaving },
            ]"
          >
            <span>Σύνδεση Χρήστη</span>
            <span class="icon is-small">
              <i class="fa fa-angle-double-right" />
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Notification from '@/views/components/Notification';

export default {
  components: {
    Notification,
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      isSaving: false,
      hasError: false,
    };
  },
  methods: {
    ...mapActions(['loginUser']),
    async submit() {
      try {
        this.hasError = false;
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.loginUser({ user: this.user });
        this.isSaving = false;
      } catch (err) {
        console.log(err);
        this.isSaving = false;
        this.hasError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  box-sizing: border-box;
  margin: 150px auto 20px;
  max-width: 350px;
  width: 90%;

  .card-header {
    padding: 1.5rem;
  }

  .input::placeholder {
    color: #bbbbbb;
  }
}
</style>
