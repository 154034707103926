import transformations from '@/utils/transformations';

/* eslint-disable camelcase */
export default {
  setPlace(place) {
    const finalPlace = {
      street: '',
      streetNumber: '',
      postcode: '',
      city: '',
      region: '',
      country: 'ΕΛΛΑΔΑ',
    };

    const { address_components } = place;

    if (address_components) {
      address_components.forEach(({ types, long_name }) => {
        const [type] = types;

        switch (type) {
          case 'route':
            finalPlace.street = long_name;
            break;
          case 'street_number':
            finalPlace.streetNumber = long_name;
            break;
          case 'postal_code':
            finalPlace.postcode = long_name;
            break;
          case 'administrative_area_level_5':
          case 'locality':
            finalPlace.city = long_name;
            break;
          case 'administrative_area_level_1':
          case 'administrative_area_level_3':
            finalPlace.region = long_name;
            break;
          case 'country':
            finalPlace.country = long_name;
            break;
          default:
            break;
        }
      });
    }

    return transformations.capitalizeAddress(finalPlace);
  },
};
