import { render, staticRenderFns } from "./FamilyProduct.vue?vue&type=template&id=cf398b98&scoped=true"
import script from "./FamilyProduct.vue?vue&type=script&lang=js"
export * from "./FamilyProduct.vue?vue&type=script&lang=js"
import style0 from "./FamilyProduct.vue?vue&type=style&index=0&id=cf398b98&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf398b98",
  null
  
)

export default component.exports