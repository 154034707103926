import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getColor({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/colors/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setColor', {
        color: data.color,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async updateColor({ dispatch, commit, rootGetters }, { uuid, color }) {
    try {
      const { data } = await request.put(`/colors/${uuid}`, color);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateColor', {
        color,
      });

      const colors = rootGetters['colors/list/getColors'].map(s => {
        if (uuid === s.uuid) {
          return {
            ...s,
            ...color,
          };
        }

        return s;
      });

      await commit(
        'colors/list/setColors',
        {
          colors,
        },
        {
          root: true,
        },
      );
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
