import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getCheckoutMethod({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/checkoutMethods/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setCheckoutMethod', {
        checkoutMethod: data.checkoutMethod,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async updateCheckoutMethod({ dispatch, commit, rootGetters }, { uuid, checkoutMethod }) {
    try {
      const response = await request.put(`/checkoutMethods/${uuid}`, checkoutMethod);

      if (response.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateCheckoutMethod', {
        checkoutMethod,
      });

      const checkoutMethods = rootGetters['checkoutMethods/list/getCheckoutMethods'].map(method => {
        if (uuid === method.uuid) {
          return {
            ...method,
            ...checkoutMethod,
          };
        }

        return method;
      });

      await commit('checkoutMethods/list/setCheckoutMethods', {
        checkoutMethods
      }, {
        root: true,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
