<template>
  <div class="card-header">
    <div class="card-header-title">
      <div class="title is-6 is-marginless">
        {{ title }}
        <span v-if="selections.length > 0" class="tag">
          {{ selections.length }} από {{ total }}
        </span>
        <span v-else class="tag">{{ total }}</span>
      </div>
    </div>
    <div class="card-header-icon hidden-print">
      <div class="field has-addons is-marginless">
        <div class="control">
          <div class="dropdown is-right is-hoverable">
            <div class="dropdown-trigger">
              <button
                class="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu1"
              >
                <span class="icon is-small"><i class="fa fa-plus"/></span>
                <span>Προσθήκη</span>
                <span class="icon is-small">
                  <i class="fa fa-angle-down" aria-hidden="true" />
                </span>
              </button>
            </div>
            <div id="dropdown-menu1" class="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <router-link
                  v-slot="{ href, navigate }"
                  :to="{ name: 'products.groups.add' }"
                  class="dropdown-item"
                  custom
                >
                  <div role="link">
                    <a
                      :href="href"
                      @keypress.enter="navigate"
                      @click="navigate"
                    >
                      Προσθήκη Group
                    </a>
                  </div>
                </router-link>
                <router-link
                  v-slot="{ href, navigate }"
                  :to="{ name: 'marketing.sets.add' }"
                  class="dropdown-item"
                  custom
                >
                  <div role="link">
                    <a
                      :href="href"
                      @keypress.enter="navigate"
                      @click="navigate"
                    >
                      Προσθήκη Set
                    </a>
                  </div>
                </router-link>
                <router-link
                  v-slot="{ href, navigate }"
                  :to="{ name: 'marketing.bundles.add' }"
                  class="dropdown-item"
                  custom
                >
                  <div role="link">
                    <a
                      :href="href"
                      @keypress.enter="navigate"
                      @click="navigate"
                    >
                      Προσθήκη Bundle
                    </a>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="control">
          <div
            :class="['dropdown is-right', { 'is-hoverable': hasSelections }]"
          >
            <div class="dropdown-trigger">
              <button
                :disabled="!hasSelections"
                class="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu2"
              >
                <span class="icon is-small"><i class="fa fa-wrench"/></span>
                <span>Επιλογές</span>
                <span class="icon is-small">
                  <i class="fa fa-angle-down" aria-hidden="true" />
                </span>
              </button>
            </div>
            <div id="dropdown-menu2" class="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <div class="dropdown-item" @click="openUpdatesModal">
                  <span class="icon is-small"><i class="fa fa-refresh"/></span>
                  <span>Mass Update</span>
                </div>
                <div class="dropdown-item" @click="print">
                  <span class="icon is-small"><i class="fa fa-print"/></span>
                  <span>Εκτύπωση</span>
                </div>
                <div class="dropdown-item" @click="prepareExportXLS">
                  <span class="icon is-small">
                    <i class="fa fa-file-excel-o" />
                  </span>
                  <span>Εξαγωγή σε XLS</span>
                </div>
                <div class="dropdown-item" @click="prepareExportCSV">
                  <span class="icon is-small">
                    <i class="fa fa-file-text-o" />
                  </span>
                  <span>Εξαγωγή σε CSV</span>
                </div>
                <div
                  v-if="canDeleteItems || canRestoreItems"
                  class="dropdown-divider"
                />
                <div
                  v-if="canDeleteItems"
                  class="dropdown-item"
                  @click="askToDeleteItems"
                >
                  <span class="icon is-small"><i class="fa fa-trash-o"/></span>
                  <span>Απενεργοποίηση</span>
                </div>
                <div
                  v-if="canRestoreItems"
                  class="dropdown-item"
                  @click="askToRestoreItems"
                >
                  <span class="icon is-small"><i class="fa fa-repeat"/></span>
                  <span>Επαναφορά</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Confirmation
      :model-name="modelName"
      :is-open="isOpen"
      description="Είστε βέβαιος για την απενεργοποίηση αυτών των προιόντων;"
      @closeModal="closeModal"
    />

    <RestoreConfirmation
      :model-name="modelName"
      :is-open="isRestoreModalOpen"
      description="Είστε βέβαιος για την επαναφορά αυτών των προιόντων;"
      @closeRestoreModal="closeRestoreModal"
    />

    <UpdatesModal
      :is-open="isUpdateModalOpen"
      :is-updating="isUpdating"
      @update="update"
      @closeModal="closeUpdatesModal"
    />
  </div>
</template>

<script>
import PaginatedListHeader from '@/views/components/PaginatedList/components/PaginatedListHeader';
import request from '@/utils/request';
import UpdatesModal from './components/UpdatesModal';

export default {
  components: {
    UpdatesModal,
  },
  extends: PaginatedListHeader,
  props: {
    isUpdating: Boolean,
  },
  data() {
    return {
      isUpdateModalOpen: false,
    };
  },
  computed: {
    modelName() {
      const count = this.selections.length;

      return count === 1 ? '1 προιόν' : `${count} προιόντα`;
    },
  },
  watch: {
    isUpdating(newVal, oldVal) {
      // Close the modal if updating process finished
      if (newVal === false && oldVal === true) {
        this.isUpdateModalOpen = false;
      }
    },
  },
  methods: {
    openUpdatesModal() {
      this.isUpdateModalOpen = true;
    },

    closeUpdatesModal() {
      this.isUpdateModalOpen = false;
    },

    update(updates) {
      this.$emit('update', updates);
    },

    async closeModal(isOk) {
      try {
        this.isOpen = false;

        if (isOk) {
          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Τα προϊόντα διαγράφτηκαν επιτυχώς!!',
          });

          await request.delete('/products', {
            params: {
              products: this.selections,
            },
          });

          this.$emit('navigate', {
            timestamp: new Date().getTime(),
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    async closeRestoreModal(isOk) {
      try {
        this.isRestoreModalOpen = false;

        if (isOk) {
          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Τα προϊόντα επαναφέρθηκαν επιτυχώς!!',
          });

          await request.put('/products-restore', { products: this.selections });

          this.$emit('navigate', {
            timestamp: new Date().getTime(),
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
