export default {
  title: 'Τίτλος',
  content: 'Περιεχόμενο',
  discount: 'Έκπτωση',
  buy_price: 'Τιμή Αγοράς',
  final_price: 'Τελική Τιμή',
  sell_price: 'Τιμή Πώλησης',
  weight: 'Βάρος',
  created_at: 'Δημιουργία',
  updated_at: 'Ενημέρωση',
  code: 'Κωδικός',
  barcode: 'Barcode',
  items: 'Αριθμός Αντικειμένων',
  amount: 'Συνολικό Ποσό',
  send_method: 'Μέθοδος Αποστολής',
  checkout_method: 'Μέθοδος Πληρωμής',
  fullName: 'Ον/μο',
  age: 'Ηλικία',
  gender: 'Φύλο',
  customer_fullName: 'Ον/μο Πελάτη',
  customer_email: 'Email Πελάτη',
  male: 'Άνδρας',
  female: 'Γυναίκα',
  email: 'Email',
  firstName: 'Όνομα',
  lastName: 'Επώνυμο',
  date: 'Ημ/νια',
  date_start: 'Ημ/νια',
  date_end: 'Ημ/νια',
  age_start: 'Ηλικία',
  age_end: 'Ηλικία',
  availability_id: 'Διαθεσιμότητα',
  discount_start: 'Έκπτωση',
  discount_end: 'Έκπτωση',
  final_price_start: 'Τελική Τιμή',
  final_price_end: 'Τελική Τιμή',
  tax_id: 'ΦΠΑ',
  0: 'Όχι',
  1: 'Ναί',
  active: 'Προβολή',
  general: 'Γενική Αναζήτηση',
  true: 'Ναί',
  false: 'Όχι',
  send_method_id: 'Μέθοδος Αποστολής',
  checkout_method_id: 'Μέθοδος Πληρωμής',
  order_method_id: 'Μέθοδος Παραγγελίας',
  is_gift: 'Συσκευασία Δώρου',
  sum_start: 'Αξία',
  sum_end: 'Αξία',
  count_start: 'Τεμάχια',
  count_end: 'Τεμάχια',
  status_id: 'Κατάσταση',
  hasAnswer: 'Έχει απαντηθεί',
  stock: 'Stock',
};
