<template>
  <div class="hidden-print">
    <div class="columns">
      <div class="column">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <div class="select">
                <select
                  :disabled="!items.length"
                  v-model.number="selectedItemsPerPage"
                >
                  <option
                    v-for="(item, index) in itemsPerPageOptions"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div v-show="items.length" class="level-right">
            <nav class="pagination is-centered">
              <a
                :disabled="currentPage === 1"
                class="pagination-previous"
                @click="stepStart"
              >
                <span class="icon is-small">
                  <i class="fa fa-angle-double-left" />
                </span>
              </a>
              <a
                :disabled="currentPage === 1"
                class="pagination-previous"
                @click="stepDown"
              >
                <span class="icon is-small">
                  <i class="fa fa-angle-left" />
                </span>
              </a>
              <input
                :value="currentPage"
                :max="pagesCount"
                type="number"
                class="input"
                min="1"
                @change="handlePageChange"
              />
              <a
                :disabled="currentPage === pagesCount"
                class="pagination-next"
                @click="stepUp"
              >
                <span class="icon is-small">
                  <i class="fa fa-angle-right" />
                </span>
              </a>
              <a
                :disabled="currentPage === pagesCount"
                class="pagination-next"
                @click="stepEnd"
              >
                <span class="icon is-small">
                  <i class="fa fa-angle-double-right" />
                </span>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterItem from './components/FilterItem';

export default {
  components: {
    FilterItem,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    total: {
      type: [Number, String],
      default: 0,
    },
    currentPage: {
      type: [Number, String],
      default: 1,
    },
    pagesCount: {
      type: [Number, String],
      default: 1,
    },
    itemsPerPage: {
      type: [Number, String],
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItemsPerPage: this.itemsPerPage,
      showFilterOptions: false,
      showUpdateOptions: false,
      itemsPerPageOptions: [50, 100, 200, 400, 800],
    };
  },
  watch: {
    selectedItemsPerPage(newVal, oldVal) {
      // We check if it was set already
      if (oldVal) {
        this.navigate(1);
      }
    },
    itemsPerPage(newVal, oldVal) {
      // We check if it was set already
      if (newVal !== oldVal) {
        this.selectedItemsPerPage = newVal;
      }
    },
  },
  methods: {
    handlePageChange(e) {
      this.navigate(e.target.value);
    },
    stepStart() {
      this.navigate(1);
    },
    stepUp() {
      if (this.currentPage !== this.pagesCount) {
        this.navigate(this.currentPage + 1);
      }
    },
    stepDown() {
      if (this.currentPage !== 1) {
        this.navigate(this.currentPage - 1);
      }
    },
    stepEnd() {
      this.navigate(this.pagesCount);
    },
    navigate(page) {
      this.$emit('navigate', {
        page,
        size: this.selectedItemsPerPage,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.pagination {
  .input {
    order: 2;
    text-align: center;
    width: auto;
  }
}
</style>
