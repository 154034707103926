import request from '@/utils/request';
import router from '@/router';

/* eslint-disable */
const actions = {
  async loginUser({ dispatch, commit }, { user }) {
    try {
      const { data } = await request.post('/login', user);

      await commit('loginUser', {
        user: data.user,
        token: data.token,
      });

      return router.push({ name: 'home' });
    } catch (err) {
      throw err;
    }
  },

  async logoutUser({ dispatch, commit }) {
    try {
      await request.post('/logout');

      await commit('logoutUser');

      localStorage.removeItem('carespot');

      router.push('/login');
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
