<template>
  <tr>
    <td class="has-text-centered">{{ index }}</td>
    <td v-if="revision.user" class="has-text-centered">
      <router-link
        :to="{ name: 'users.edit', params: { uuid: revision.user.uuid } }"
      >
        {{ revision.user.name }}
      </router-link>
    </td>
    <td v-else class="has-text-centered">-</td>
    <td class="has-text-centered" v-html="description" />
    <td v-if="revision.revisionable" class="has-text-centered">
      <router-link
        :to="{
          name: 'customers.edit',
          params: { uuid: revision.revisionable.uuid },
        }"
      >
        {{ revision.revisionable.title }}
      </router-link>
    </td>
    <td v-else class="has-text-centered">-</td>
    <td class="has-text-centered">{{ revision.created_at | timestamp }}</td>
  </tr>
</template>

<script>
/* eslint-disable camelcase */

export default {
  props: {
    revision: Object,
    index: Number,
  },
  computed: {
    description() {
      let description = '';
      const { key, old_value, new_value } = this.revision;

      if (key === 'created_at' && old_value === null) {
        description = 'Εγγραφή συμπτώματος';
      } else {
        description = `Αλλάχτηκε το πεδίο <strong>${key}</strong> απο <strong>${old_value}</strong> σε <strong>${new_value}</strong>`;
      }

      return description;
    },
  },
};
</script>
