<template>
  <tr>
    <td class="has-text-centered">{{ index }}</td>
    <td>
      <router-link
        :to="{ name: 'content.questions.edit', params: { uuid: item.uuid } }"
      >
        {{ item.content }}
      </router-link>
    </td>
    <td class="has-text-centered">
      <span v-tooltip="'Έχει απαντηθεί'" v-if="hasAnswer" class="icon">
        <i class="fa fa-check" />
      </span>
      <span v-tooltip="'Δεν έχει απαντηθεί'" v-else class="icon">
        <i class="fa fa-times" />
      </span>
    </td>
    <td class="has-text-centered">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        :disabled="!hasAnswer"
        @change="onChange"
      />
    </td>
    <td class="has-text-centered">
      <div class="buttons is-centered">
        <router-link
          :to="{ name: 'content.questions.edit', params: { uuid: item.uuid } }"
          class="button is-small"
        >
          <span class="icon is-small">
            <i class="fa fa-fw fa-pencil" />
          </span>
        </router-link>
        <button class="button is-small" @click="onDelete">
          <span class="icon is-small">
            <i class="fa fa-fw fa-trash-o" />
          </span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';

export default {
  extends: PaginatedListItem,
  computed: {
    hasAnswer() {
      return this.item.answer !== null;
    },
  },
};
</script>

<style scoped>
.fa-check {
  color: #22a684;
}

.fa-times {
  color: #ff3860;
}
</style>
