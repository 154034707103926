<template>
  <tr>
    <td>
      {{ item.content }}
    </td>
    <td class="has-text-centered">
      <StarRating
        v-tooltip="`Αστέρια: ${item.stars}`"
        v-if="item.stars"
        :rating="item.stars"
        :increment="0.1"
        :star-size="16"
        :show-rating="false"
        read-only
      />
    </td>
    <td class="has-text-centered">
      <router-link
        v-tooltip="item.reviewable.title"
        v-if="item.reviewable_type === 'App\\Product'"
        :to="{ name: 'products.edit', params: { uuid: item.reviewable.uuid } }"
      >
        Προιόν
      </router-link>
      <router-link
        v-tooltip="item.reviewable.title"
        v-else
        :to="{
          name: 'content.posts.edit',
          params: { uuid: item.reviewable.uuid },
        }"
      >
        Άρθρο
      </router-link>
    </td>
    <td class="has-text-centered">{{ item.created_at | timestamp }}</td>
    <td class="has-text-centered hidden-print">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        disabled
      />
    </td>
  </tr>
</template>

<script>
import StarRating from 'vue-star-rating';

export default {
  components: {
    StarRating,
  },
  props: {
    item: Object,
  },
};
</script>

<style scoped>
.vue-star-rating {
  justify-content: center;
}
</style>
