<script>
import { mapGetters } from 'vuex';
import request from '@/utils/request';
import PaginatedList from '@/views/components/PaginatedList';
import CustomersHeader from './components/CustomersHeader';
import Customer from './components/Customer';

export default {
  components: {
    CustomersHeader,
    Customer,
  },
  extends: PaginatedList,
  data() {
    return {
      taxes: [],
      availabilities: [],
    };
  },
  computed: {
    ...mapGetters({
      items: 'customers/list/getData',
      paginationParams: 'customers/list/getPaginationParams',
      isLoadingCustomers: 'customers/list/getIsLoading',
      isLoaded: 'customers/list/getIsLoaded',
    }),
  },
  methods: {
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;

        if (response) {
          await request.delete(`/customers/${this.selectedItem.uuid}`);

          await this.navigate({
            timestamp: new Date().getTime(),
          });

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Ο πελάτης διαγράφτηκε επιτυχώς!!',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    prepareExportXLS() {
      this.exportXLS(
        this.selections,
        ['email', 'fullName', 'gender', 'age'],
        'customers',
      );
    },
    prepareExportCSV() {
      this.exportCSV(
        this.selections,
        ['email', 'fullName', 'gender', 'age'],
        'customers',
      );
    },
  },
};
</script>
