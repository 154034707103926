<template>
  <tr>
    <td class="has-text-centered">{{ index }}</td>
    <td class="has-text-centered">
      <Photo :photos="item.photos" />
    </td>
    <td class="has-text-centered">
      <router-link
        :to="{
          name: 'products.brands.collections.edit',
          params: { uuid: brand.uuid, collection: item.uuid },
        }"
      >
        {{ item.title }}
      </router-link>
    </td>
    <td class="has-text-centered">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        :disabled="true"
      />
    </td>
    <td class="has-text-centered">
      <div class="buttons is-centered">
        <router-link
          :to="{
            name: 'products.brands.collections.edit',
            params: { uuid: brand.uuid, collection: item.uuid },
          }"
          class="button is-small"
        >
          <span class="icon is-small">
            <i class="fa fa-fw fa-pencil" />
          </span>
        </router-link>
        <button v-if="isSuperAdmin" class="button is-small" @click="onDelete">
          <span class="icon is-small">
            <span class="fa fa-fw fa-trash-o" />
          </span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import Photo from '@/views/components/Photo';
import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';

export default {
  components: {
    Photo,
  },
  extends: PaginatedListItem,
  props: {
    brand: Object,
    item: Object,
    index: Number,
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: 'getIsSuper',
    }),
  },
};
</script>
