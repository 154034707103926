<template>
  <tr>
    <td class="has-text-centered">
      {{ item.created_at | timestamp }}
    </td>
    <td>
      <h6 class="is-6">
        <strong>{{ item.title }}</strong>
      </h6>
      <div>{{ item.content }}</div>
    </td>
    <td class="has-text-centered hidden-print">
      <button class="button is-small" @click="selectItem">
        <span class="icon is-small">
          <span class="fa fa-fw fa-eye" />
        </span>
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'Email',
  props: {
    item: Object,
  },
  methods: {
    selectItem() {
      this.$emit('selectItem', this.item);
    },
  },
};
</script>
