<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Email Επικοινωνίας
        </div>
      </div>
      <div class="card-header-icon">
        <div class="field">
          <div class="control">
            <router-link
              :to="{ name: 'settings.emailTemplates.add' }"
              class="button"
            >
              <span class="icon is-small"><i class="fa fa-plus"/></span
              ><span>Προσθήκη</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="table-wrapper">
        <table class="table is-bordered is-striped is-marginless is-fullwidth">
          <thead>
            <tr>
              <th>#</th>
              <th>Τίτλος</th>
              <th>Περιεχόμενο</th>
              <th>Ενέργειες</th>
            </tr>
          </thead>
          <tbody>
            <email-template
              v-for="template in emailTemplates"
              :template="template"
              :key="template.id"
              @askToDeleteItem="askToDeleteItem"
            />
          </tbody>
        </table>
      </div>
    </div>

    <confirmation
      :model-name="selectedEmailTemplate.title"
      :is-open="isOpen"
      description="Είστε βέβαιος για τη διαγραφή αυτού του email;"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Confirmation from '@/views/components/Confirmation';
import EmailTemplate from './components/Template';

export default {
  components: {
    Confirmation,
    EmailTemplate,
  },
  data() {
    return {
      isOpen: false,
      isSaving: false,
      selectedEmailTemplate: {},
    };
  },
  computed: {
    ...mapGetters({
      emailTemplates: 'getEmailTemplates',
    }),
  },
  methods: {
    ...mapActions(['deleteEmailTemplate']),
    askToDeleteItem(item) {
      this.selectedEmailTemplate = item;

      this.isOpen = true;
    },
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;
        if (response) {
          await this.deleteEmailTemplate({
            emailTemplate: this.selectedEmailTemplate,
          });

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'To email διαγράφτηκε επιτυχώς!!',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
