/* eslint-disable */
const getters = {
  getIsLoadingSettings(state) {
    return state.isLoading;
  },
  getERPStatus(state) {
    return state.erpStatus;
  },
};

export default getters;
