<template>
  <tr>
    <td class="has-text-centered ">
      {{ item.event_type }}
    </td>
    <td class="has-text-centered">
      <router-link
        :to="{ name: 'orders.skroutz.edit', params: { code: item.code } }"
      >
        {{ item.code }}
      </router-link>
    </td>
    <td class="has-text-centered">
      {{ item.event_time | timestamp }}
    </td>
    <td class="has-text-centered">
      {{ item.details.expires_at | timestamp }}
    </td>
    <td class="has-text-centered">
      <div>
        {{ fullName }}
      </div>
    </td>
    <td class="has-text-centered">{{ orderTotalQuantity }}</td>
    <td class="has-text-centered">{{ orderTotalCost }}</td>
    <td class="has-text-centered">{{ orderState }}</td>
    <td class="has-text-centered hidden-print">
      <router-link
        :to="{ name: 'orders.skroutz.edit', params: { code: item.code } }"
        class="button is-small"
      >
        <span class="icon is-small">
          <span class="fa fa-fw fa-pencil" />
        </span>
      </router-link>
    </td>
  </tr>
</template>

<script>
import { round } from 'lodash';
import moment from 'moment';

import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';
import UserType from '@/views/components/UserType';
import OrderMixin from '@/mixins/Order';

export default {
  name: 'Order',

  filters: {
    timestamp(value, from = 'YYYY-MM-DD HH:mm:ss', to = 'DD-MM-YYYY HH:mm') {
      return moment.utc(value, from).format(to);
    },
  },

  components: {
    UserType,
  },

  extends: PaginatedListItem,

  mixins: [OrderMixin],

  computed: {
    fullName() {
      // eslint-disable-next-line
      return `${this.item?.details?.customer?.first_name} ${this.item?.details?.customer?.last_name}`;
    },

    orderTotalCost() {
      return round(
        // eslint-disable-next-line
        (this.item?.details?.line_items || []).reduce(
          (prev, curr) => prev + curr.total_price,
          0,
        ),
        2,
      );
    },

    orderTotalQuantity() {
      // eslint-disable-next-line
      return (this.item?.details?.line_items || []).reduce(
        (prev, curr) => prev + curr.quantity,
        0,
      );
    },

    orderState() {
      return this.item?.details?.state;
    },
  },
};
</script>

<style scoped lang="scss">
.tag {
  width: 100%;

  &.is-white {
    border: 1px solid #dbdbdb;
  }
}

tr {
  td:first-child {
    border-left: 3px solid #d4d4d4 !important;
  }
}

.status {
  position: relative;
}

.icon--print {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
  font-size: 0.85rem;
  position: absolute;
  right: -8px;
  top: -10px;
}
</style>
