<template>
  <div class="columns is-multiline">
    <div class="column is-full">
      <label class="label">Παλαιό URL</label>
      <div class="field has-addons">
        <div class="control is-expanded">
          <input
            v-validate="'url'"
            ref="old"
            :value="redirection.old"
            type="text"
            class="input"
            name="old"
            @input="updateRedirection"
          />
        </div>
        <div class="control">
          <button
            v-tooltip="'Copy URL'"
            class="button"
            @click.prevent="handleCopy('old')"
          >
            <span class="icon"><i class="fa fa-clone"/></span>
          </button>
        </div>
        <p v-show="errors.has('old')" class="help is-danger">
          Εισάγετε έγκυρο url
        </p>
      </div>
    </div>

    <div class="column is-full">
      <label class="label">Νέο URL</label>
      <div class="field has-addons">
        <div class="control is-expanded">
          <input
            v-validate="'url'"
            ref="new"
            :value="redirection.new"
            :readonly="!isEditable"
            type="text"
            class="input"
            name="new"
            @input="updateRedirection"
          />
        </div>
        <div class="control">
          <button
            v-tooltip="'Copy URL'"
            class="button"
            @click.prevent="handleCopy('new')"
          >
            <span class="icon"><i class="fa fa-clone"/></span>
          </button>
        </div>
        <p v-show="errors.has('new')" class="help is-danger">
          Εισάγετε έγκυρο url
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    redirection: Object,
    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    updateRedirection(e) {
      const { name, value } = e.target;

      this.$emit('updateRedirection', {
        ...this.redirection,
        [name]: value,
      });
    },

    handleCopy(ref) {
      const copyText = this.$refs[ref];
      copyText.select();
      document.execCommand('copy');

      this.$notify({
        type: 'success',
        title: 'Επιτυχία',
        text: 'Το URL αντιγράφτηκε στο clipboard',
      });
    },
  },
};
</script>
