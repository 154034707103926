import { round, get, isEmpty, omit } from 'lodash';
import request from '@/utils/request';
import router from '@/router';

/* eslint-disable */
const actions = {
  async addNewOrder({ commit, rootGetters: { getUser, getNewOrder } }) {
    try {
      if (!isEmpty(getNewOrder.invoice)) {
        getNewOrder.invoice = {
          ...omit(getNewOrder.invoice, ['vat_office']),
        };
      }

      const { data } = await request.post('/orders', {
        ...getNewOrder,
        user_id: getUser.id,
        coupon_code: get(getNewOrder.coupon, 'code', null),
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('resetNewOrder');

      router.push({ name: 'orders.edit', params: { uuid: data.order.uuid } });
    } catch (err) {
      throw err;
    }
  },

  async addNewOrderGuest({ commit }, { customer }) {
    try {
      const { data } = await request.post('/guests', customer);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateCustomerType', 'guest');

      await commit('addNewOrderCustomer', data.guest.customer);

      await commit('updateCustomerEmail', { email: data.guest.email });
    } catch (err) {
      throw err;
    }
  },

  async getCoupon({ dispatch, commit, getters }, { code }) {
    try {
      const order = getters.getNewOrder;

      const itemsPrice = round(
        order.products.reduce(
          (acc, item) => acc + item.final_price * item.pivot.count,
          0,
        ),
        2,
      );

      const { data } = await request.post(`/coupons/check/${code}`, {
        price: itemsPrice,
        products: order.products.map(product => ({
          brand_id: product.brand.uuid,
        })),
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setCoupon', data.coupon);
    } catch (err) {
      await commit('resetCoupon');
      throw err;
    }
  },
};

export default actions;
