<template>
  <tr v-if="isEditing">
    <td class="has-text-centered">
      <div class="select">
        <select :value="charge.operator" name="operator" @change="updateItem">
          <option
            v-for="(operator, index) in operators"
            :key="index"
            :value="operator.value"
          >
            {{ operator.label }}
          </option>
        </select>
      </div>
    </td>
    <td class="has-text-centered">
      <div class="field has-addons">
        <div class="control">
          <input
            :value="charge.limit"
            type="number"
            min="0"
            step="0.01"
            class="input has-text-centered"
            name="limit"
            @change="updateItem"
          />
        </div>
        <div class="control">
          <a class="button is-static">
            &euro;
          </a>
        </div>
      </div>
    </td>
    <td class="has-text-centered">
      <div class="field has-addons">
        <div class="control">
          <input
            :value="charge.charge"
            type="number"
            min="0"
            step="0.01"
            class="input has-text-centered"
            name="charge"
            @change="updateItem"
          />
        </div>
        <div class="control">
          <a class="button is-static">
            &euro;
          </a>
        </div>
      </div>
    </td>
    <td v-if="isEditing" class="has-text-centered">
      <button type="button" class="button is-small" @click="deleteItem">
        <span class="icon is-small">
          <span class="fa fa-fw fa-trash-o" />
        </span>
      </button>
    </td>
  </tr>
  <tr v-else>
    <td class="has-text-centered">{{ operatorLabel }}</td>
    <td class="has-text-centered">{{ charge.limit }} &euro;</td>
    <td class="has-text-centered">{{ charge.charge }} &euro;</td>
  </tr>
</template>

<script>
import OPERATORS from '@/constants/operators';

export default {
  props: {
    charge: Object,
    isEditing: Boolean,
  },
  data: () => ({
    operators: OPERATORS,
  }),
  computed: {
    operatorLabel() {
      const operator = this.operators.find(
        ({ value }) => value === this.charge.operator,
      );

      return operator.label;
    },
  },
  methods: {
    updateItem(e) {
      const { name, value } = e.target;

      this.$emit('updateItem', {
        ...this.charge,
        [name]: Number(value),
      });
    },
    deleteItem() {
      this.$emit('deleteItem', this.charge);
    },
  },
};
</script>

<style lang="scss" scoped>
.field.has-addons {
  justify-content: center;
}
</style>
