import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getCancelOrderReason({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/cancelOrderReasons/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setCancelOrderReason', {
        cancelOrderReason: data.cancelOrderReason,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async updateCancelOrderReason(
    { dispatch, commit, rootGetters },
    { uuid, cancelOrderReason },
  ) {
    try {
      const response = await request.put(
        `/cancelOrderReasons/${uuid}`,
        cancelOrderReason,
      );

      if (response.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateCancelOrderReason', {
        cancelOrderReason,
      });

      const cancelOrderReasons = rootGetters[
        'cancelOrderReasons/list/getCancelOrderReasons'
      ].map(reason => {
        if (uuid === reason.uuid) {
          return {
            ...reason,
            ...cancelOrderReason,
          };
        }

        return reason;
      });

      await commit(
        'cancelOrderReasons/list/setCancelOrderReasons',
        {
          cancelOrderReasons,
        },
        {
          root: true,
        },
      );
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
