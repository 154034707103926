import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getSize({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/sizes/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setSize', {
        size: data.size,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async updateSize({ dispatch, commit, rootGetters }, { uuid, size }) {
    try {
      const { data } = await request.put(`/sizes/${uuid}`, size);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateSize', {
        size,
      });

      const sizes = rootGetters['sizes/list/getSizes'].map(s => {
        if (uuid === s.uuid) {
          return {
            ...s,
            ...size,
          };
        }

        return s;
      });

      await commit('sizes/list/setSizes', {
        sizes,
      }, {
        root: true,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
