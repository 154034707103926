<template>
  <div class="operators-wrapper">
    <div>
      <h2 class="help__title">Βοήθεια</h2>
    </div>
    <div class="help__text">
      <p>
        Στο πεδίο 'Παράδειμα Operator' βλέπετε τις μορφές που μπορεί να έχει
        ένας βασικός operator αναλογώς αν θα οριστεί σε "product" ή "brand" ή
        "collection". Η περίπτωση του να έχει πολλαπλά βασικά*
        προιόντα/μάρκες/κλπ περιγράφεται ως Multiple Products.
      </p>
      <ol>
        <!-- <li></li> -->
        <li>
          <p>
            Αντιγράψτε τον επιθυμητό operator απο το πεδίο 'Παράδειγμα Operator'
            στο πεδίο 'Operator(s)' παρακάτω.
          </p>
        </li>
        <li>
          <p>
            Bρείτε το uuid του επιθυμητού βασικού* προϊόντος/μάρκας/κλπ και
            εισάγετε το διπλα στο "id" αντικαθιστώντας την υπάρχουσα τιμη.
          </p>
        </li>
        <li>
          <p>
            Πλέον έχετε ορίσει το βασικό προϊόν. Προσθέστε τα σχετικά δώρα στο
            πεδίο Σχετικά Προιόντα χρησιμοποιώντας το common id.
          </p>
        </li>
        <p>
          *Βασικό προϊόν είναι το προϊόν που φέρει κάποιο δώρο. Αντίστοιχα
          βασική μάρκα κλπ.
        </p>
      </ol>
    </div>
    <div class="column is-full">
      <div class="field">
        <label class="label">Παράδειγμα Operator </label>
        <div class="control">
          <codemirror
            :value="operatorExample"
            :options="editorOptions"
            data-vv-value-path="value"
            data-vv-name="operator"
            class="help-mirror"
          />
        </div>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <label class="label">Operator(s) *</label>
        <div class="control">
          <codemirror
            :value="operators"
            data-vv-value-path="value"
            data-vv-name="operator"
            @input="updateOperators"
          />
        </div>
        <p v-show="errors.has('operator')" class="help is-danger">
          Εισάγετε Operator(s)
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    operators: String,
  },

  data() {
    return {
      operatorExample: `//Product
      {
        "models": [{
        "type": "product",
        "id": "wmkyij"
      }]
    }

//Multiple Products
    {
        "models": [
          {
          "type": "product",
          "id": "wmkyii"
          },

          {
          "type": "product",
          "id": "defabc"
          },
        ]
    }
    

//Brand
    {
        "models": [{
        "type": "brand",
        "id": "wmkyii"
      }]
    }

//Collection
    {
        "models": [{
        "type": "collection",
        "id": "wnkyij"
      }]
    }
    `,
      editorOptions: {
        readOnly: true,
      },
    };
  },

  methods: {
    updateOperators(operators) {
      this.$emit('updateOperators', operators);
    },
  },
};
</script>

<style scoped lang="scss">
.operators-wrapper {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
}

.help {
  &__title {
    font-weight: 700;
    padding-left: 7px;
    margin: 3px;
  }

  &__text {
    padding: 0px 25px 10px 25px;
    font-size: 14px;
    font-weight: 400;
    margin: 0px;
  }
}

ol {
  line-height: 25px;
}

::v-deep .help-mirror .CodeMirror-lines {
  background-color: whitesmoke;
}
</style>
