<template>
  <multiselect
    :options="products"
    :searchable="true"
    :internal-search="false"
    :option-height="104"
    :loading="isSearching"
    :reset-after="true"
    track-by="id"
    label="title"
    placeholder="Επιλέξτε προιόν με χρήση barcode, τίτλου..."
    select-label="Πατήστε enter για επιλογή"
    @search-change="handleSearchChange"
    @select="handleSelectProduct"
  >
    <template v-slot:option="props" class="option">
      <Photo :photos="props.option.photos" class="option__image" />
      <div class="option__desc">
        <span class="option__title">{{ props.option.title }}</span>
        <span class="option__small">{{ props.option.barcode }}</span>
      </div>
    </template>
    <template v-slot:noResult>
      Δε βρέθηκαν αποτελέσματα. Προσπαθήστε ξανά!!
    </template>
    <template v-slot:afterList>
      <div v-observe-visibility="handleScrollBottom" />
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import debounce from 'lodash/debounce';
import request from '@/utils/request';
import Photo from '@/views/components/Photo';

export default {
  inject: ['$validator'],

  components: {
    Multiselect,
    Photo,
  },

  props: {
    order: Object,
  },

  data() {
    return {
      products: [],
      isSearching: false,
      page: 1,
      searchQuery: '',
      noMoreResults: false,
    };
  },

  computed: {
    hasResults() {
      return !!this.products.length;
    },
  },

  watch: {
    searchQuery: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.products = [];
          this.page = 1;
        }
      },
    },
  },

  methods: {
    // eslint-disable-next-line func-names
    handleSearchChange: debounce(function(searchQuery) {
      if (searchQuery.length) {
        this.searchQuery = searchQuery;
        this.searchProducts();
      }
    }, 300),

    async searchProducts() {
      const products = this.order.products.reduce(
        (acc, { id, pivot }) => (pivot.code === null ? [...acc, id] : acc),
        [],
      );

      this.isSearching = true;
      const { data } = await request.get('/products-search', {
        params: {
          term: this.searchQuery,
          page: this.page,
          products,
        },
      });
      this.isSearching = false;

      if (data.products.length) {
        this.noMoreResults = false;
        this.products = this.products.concat(data.products);
      } else {
        this.noMoreResults = true;
      }
    },

    handleSelectProduct(selectedOption) {
      this.$emit('selectProduct', selectedOption);

      this.products = [];
    },

    handleScrollBottom(reached) {
      if (
        reached &&
        this.searchQuery &&
        this.hasResults &&
        !this.noMoreResults
      ) {
        this.page += 1;
        this.searchProducts();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect {
  margin: 2rem auto;
}
</style>
