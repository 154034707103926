import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getLoyalty({ commit }) {
    try {
      const { data } = await request.get('/loyalty');

      await commit('setLoyalty', data.loyalty);
    } catch (err) {
      throw err;
    }
  },

  async updateLoyalty({ commit }, data) {
    try {
      await request.put('/loyalty', data);

      await commit('setLoyalty', data.loyalty);
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
