<template>
  <tr :class="[{ 'is-active': !isUnderReview && !isExpired }]">
    <td class="has-text-centered">
      <p class="is-marginless">{{ item.count }}</p>
      <small v-if="isUnderReview && !isExpired">
        Υπό επεξεργασία
      </small>

      <small v-if="isExpired">Ανενεργοί</small>
    </td>
    <td class="has-text-centered">{{ item.description }}</td>
    <td class="has-text-centered">
      <router-link
        v-if="item.pointable_type === 'App\\Product'"
        :to="{
          name: 'products.edit',
          params: { uuid: item.pointable.uuid },
        }"
      >
        Προιόν
      </router-link>

      <router-link
        v-if="item.pointable_type === 'App\\Post'"
        :to="{
          name: 'content.posts.edit',
          params: { uuid: item.pointable.uuid },
        }"
      >
        Άρθρο
      </router-link>

      <router-link
        v-if="item.pointable_type === 'App\\Order' && item.pointable !== null"
        :to="{
          name: 'orders.edit',
          params: { uuid: item.pointable.uuid },
        }"
      >
        {{ item.pointable.common_id }}
      </router-link>
    </td>
    <td class="has-text-centered">{{ item.created_at | timestamp }}</td>
    <td class="has-text-centered">{{ item.expire_at | timestamp }}</td>
  </tr>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isUnderReview() {
      return !this.item.activated_at;
    },

    isExpired() {
      return moment(this.item.expire_at).isBefore();
    },
  },
};
</script>

<style lang="scss" scoped>
tr {
  td:first-child {
    border-left: 3px solid #d4d4d4 !important;
  }

  &.is-active {
    td:first-child {
      border-left: 3px solid #22a684 !important;
    }
  }
}
</style>
