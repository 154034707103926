import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async createOrderInvoice({ commit }, { invoice }) {
    try {
      const { data } = await request.post('/invoices', invoice);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateOrderInvoice', { invoice: data.invoice });
    } catch (err) {
      throw err;
    }
  },

  async updateOrderInvoice({ commit }, { invoice }) {
    try {
      const { data } = await request.put(`/invoices/${invoice.uuid}`, invoice);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateOrderInvoice', { invoice });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
