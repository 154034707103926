import all from './all';
import list from './list';

const module = {
  namespaced: true,
  modules: {
    all,
    list,
  },
};

export default module;
