<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        Πληροφορίες Δώρου
      </div>
    </div>
    <div class="card-content">
      <div class="field">
        <div class="control">
          <checkbox :checked="order.is_gift" class="disabled" />
          <label>Συσκευασία Δώρου</label>
        </div>
      </div>
      <div v-if="order.is_gift" class="field">
        <label class="label">Ευχητήριο Μήνυμα</label>
        <div class="control">
          {{ order.details.order.gift_message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: Object,
  },
};
</script>

<style scoped lang="scss">
.checkbox-component,
.label {
  display: inline-block;
}

::v-deep .checkbox-component {
  margin-right: 10px;
  vertical-align: middle;

  &.disabled input {
    pointer-events: none;

    & + label {
      opacity: 0.6;
      pointer-events: none;

      .input-box {
        background: #ededed;
        border: 1px solid #dbdbdb;
        color: #35495e;
      }
    }
  }
}
</style>
