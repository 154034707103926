<template>
  <LoadingContainer :is-loading="isLoadingCollections && !isLoaded">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Μάρκα
            <router-link
              :to="{
                name: 'products.brands.edit',
                params: { uuid: $route.params.uuid },
              }"
              class="tag"
            >
              {{ brand.title }}
            </router-link>
            Σειρές <span v-if="total > 0" class="tag">{{ total }}</span>
          </div>
        </div>
        <div class="card-header-icon">
          <div class="field">
            <div class="control">
              <router-link
                :to="{
                  name: 'products.brands.collections.add',
                  params: { uuid: $route.params.uuid },
                }"
                class="button"
              >
                <span class="icon is-small"><i class="fa fa-plus"/></span
                ><span>Προσθήκη</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <Filters
          :options="options"
          :is-loading="isFiltering"
          :filters="paginationParams.filters"
          general-label="Αναζήτηση με χρήση τίτλου"
          @navigate="navigate"
        />

        <NewPagination
          :items="items"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column">
            <LoadingContainer
              :is-loading="isFiltering && isLoaded"
              :is-opaque="true"
            >
              <table
                v-if="items.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Φώτο</th>
                    <th>
                      <sortable-header
                        :sort-options="sortOptions"
                        label="Τίτλος"
                        attribute="title"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="sortOptions"
                        label="Προβολή"
                        attribute="active"
                        @sort="sort"
                      />
                    </th>
                    <th>Ενέργειες</th>
                  </tr>
                </thead>
                <tbody>
                  <collection
                    v-for="(item, index) in items"
                    :brand="brand"
                    :item="item"
                    :index="indexStartsFrom + index"
                    :key="item.id"
                    @askToDeleteItem="askToDeleteItem"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν σειρές
              </h4>
            </LoadingContainer>
          </div>
        </div>
      </div>
    </div>

    <confirmation
      :model-name="selectedItem.title"
      :is-open="isOpen"
      description="Είστε βέβαιος για τη διαγραφή αυτής της σειράς;"
      @closeModal="closeModal"
    />
  </LoadingContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PaginatedList from '@/views/components/PaginatedList';
import request from '@/utils/request';
import Collection from './components/Collection';

export default {
  components: {
    Collection,
  },
  extends: PaginatedList,
  data() {
    return {
      isSyncing: false,
      options: [
        {
          label: 'Γενική Αναζήτηση',
          title: 'general',
          type: 'string',
          value: null,
        },
        {
          label: 'Εμφανής',
          title: 'active',
          type: 'boolean',
          value: null,
        },
        {
          label: 'Έχει Φωτογραφία Avatar',
          title: 'has_avatar',
          type: 'boolean',
          value: null,
        },
        {
          label: 'Έχει Products',
          title: 'has_products',
          type: 'boolean',
          value: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      items: 'collections/list/getData',
      paginationParams: 'collections/list/getPaginationParams',
      isLoadingCollections: 'collections/list/getIsLoading',
      isLoaded: 'collections/list/getIsLoaded',
      brand: 'brands/edit/getBrand',
    }),
  },
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await Promise.all([
              this.getBrand({ uuid: newVal.params.uuid }),
              this.getCollections({
                uuid: newVal.params.uuid,
                params: newVal.query,
              }),
            ]);
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router.replace({
              name: this.$route.name,
              query: this.filterParams({
                page: 1,
                size: 50,
                filters: {},
                sort: {},
                paginated: 1,
              }),
            });
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getBrand: 'brands/edit/getBrand',
      getCollections: 'collections/list/getCollections',
      updateCollectionActiveStatus:
        'collections/list/updateCollectionActiveStatus',
    }),
    async navigate(params = {}) {
      try {
        this.isFiltering = true;
        this.$router.push({
          name: this.$route.name,
          query: this.filterParams({ ...this.paginationParams, ...params }),
        });
        this.isFiltering = false;
      } catch (err) {
        this.isFiltering = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;

        if (response) {
          await request.delete(`/collections/${this.selectedItem.uuid}`);

          await this.navigate({
            timestamp: new Date().getTime(),
          });

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Το άρθρο διαγράφτηκε επιτυχώς!!',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
