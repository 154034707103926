<template>
  <tr>
    <td colspan="8" class="has-text-right is-borderless">
      Φ.Π.Α. ({{ tax }}%)
    </td>
    <td class="has-text-centered">
      <strong>{{ taxes[tax] }} &euro;</strong>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    tax: String,
    taxes: Object,
  },
};
</script>
