<template>
  <LoadingContainer :is-loading="isLoadingProducts && !isLoaded">
    <div class="card">
      <productsHeader
        :total="paginationParams.count"
        :selections="selections"
        :has-selections="hasSelections"
        :is-updating="isUpdating"
        title="Εμφανή Προϊόντα"
        @navigate="navigate"
        @update="update"
        @print="print"
        @prepareExportXLS="prepareExportXLS"
        @prepareExportCSV="prepareExportCSV"
      />
      <div class="card-content">
        <Filters
          :options="options"
          :is-loading="isFiltering"
          :filters="paginationParams.filters"
          general-label="Αναζήτηση με χρήση barcode, τίτλου, erp id, common id"
          @navigate="navigate"
        />

        <NewPagination
          :items="items"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column is-full">
            <LoadingContainer
              :is-loading="isFiltering && isLoaded"
              :is-opaque="true"
              class="table-wrapper"
            >
              <table
                v-if="items.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th class="hidden-print">
                      <checkbox
                        v-model="selectedAll"
                        name="selected"
                        @input="selectAll"
                      />
                    </th>
                    <th class="hidden-print">Φώτο</th>
                    <th>Barcodes</th>
                    <th>Common ID</th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Τίτλος"
                        attribute="title"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Stock"
                        attribute="stock"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Π.Λ. Τιμή (&euro;)"
                        attribute="sell_price"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Έκπτωση (&#x25;)"
                        attribute="discount"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Τελική Τιμή (&euro;)"
                        attribute="final_price"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <SortableHeader
                        :sort-options="paginationParams.sort"
                        label="Προβολές"
                        attribute="views"
                        @sort="sort"
                      />
                    </th>
                    <th class="hidden-print">
                      <SortableHeader
                        :sort-options="paginationParams.sort"
                        label="Πωλήσεις"
                        attribute="purchases"
                        @sort="sort"
                      />
                    </th>
                    <th>Εμφανές</th>
                    <th class="hidden-print">Ενέργειες</th>
                  </tr>
                </thead>
                <tbody>
                  <product
                    v-for="(item, index) in items"
                    :item="item"
                    :index="paginationParams.indexStartsFrom + index"
                    :key="item.id"
                    :selections="selections"
                    @askToDeleteItem="askToDeleteItem"
                    @toggleSelection="toggleSelection"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν εμφανή προιόντα
              </h4>
            </LoadingContainer>
          </div>
        </div>

        <NewPagination
          v-if="items.length"
          :items="items"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />
      </div>
    </div>

    <confirmation
      :model-name="selectedItem.title"
      :is-open="isOpen"
      description="Είστε βέβαιος για την απενεργοποίηση αυτού του προιόντος;"
      @closeModal="closeModal"
    />
  </LoadingContainer>
</template>

<script>
import { mapActions } from 'vuex';
import ProductsList from '../../components/ProductsList';

export default {
  extends: ProductsList,
  metaInfo: {
    title: 'Εμφανή Προιόντα',
  },
  data: () => ({
    options: [
      {
        label: 'Γενική Αναζήτηση',
        title: 'general',
        type: 'string',
        value: null,
      },
      {
        label: 'Brand',
        title: 'brand_id',
        type: 'search',
        value: null,
      },
      {
        label: 'Category',
        title: 'category_id',
        type: 'search',
        value: null,
      },
      {
        label: 'Τύπος',
        title: 'type',
        type: 'options',
        options: 'types',
        optionsValue: 'value',
        value: null,
      },
      {
        label: 'Είναι Καινούριο',
        title: 'is_new',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Έχει Barcode',
        title: 'has_barcode',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Έχει Χρώματα',
        title: 'has_colors',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Έχει Μεγέθη',
        title: 'has_sizes',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Έχει Meta',
        title: 'has_meta',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Έχει Φωτογραφία Avatar',
        title: 'has_avatar',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Έχει Οικογένεια',
        title: 'has_family',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Διαθεσιμότητα',
        title: 'availability_id',
        type: 'options',
        options: 'availabilities',
        optionsValue: 'id',
        value: null,
      },
      {
        label: 'ΦΠΑ',
        title: 'tax_id',
        type: 'options',
        options: 'taxes',
        optionsValue: 'id',
        value: null,
      },
      {
        label: 'Έκπτωση',
        title: 'discount',
        type: 'range',
        value: {
          start: 0,
          end: 0,
        },
      },
      {
        label: 'Π.Λ. Τιμή',
        title: 'sell_price',
        type: 'range',
        value: {
          start: 0,
          end: 0,
        },
      },
      {
        label: 'Τελική Τιμή',
        title: 'final_price',
        type: 'range',
        value: {
          start: 0,
          end: 0,
        },
      },
      {
        label: 'Stock',
        title: 'stock',
        type: 'range',
        value: {
          start: 0,
          end: 0,
        },
      },
      {
        label: 'Βάρος',
        title: 'weight',
        type: 'range',
        value: {
          start: 0,
          end: 0,
        },
      },
      {
        label: 'ERP ABC Color',
        title: 'erp_abc_color',
        type: 'options',
        options: 'erp_abc_colors',
        optionsValue: 'value',
        value: null,
      },
      {
        label: 'Μόνιμα Διαθέσιμο',
        title: 'is_available_forever',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Πωλήσεις',
        title: 'purchases',
        type: 'range',
        value: {
          start: 0,
          end: 0,
        },
      },
    ],
  }),
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.getActiveProducts(newVal.query);
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router.replace({
              name: this.$route.name,
              query: this.filterParams({
                page: 1,
                size: 50,
                filters: {},
                sort: {},
              }),
            });
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getActiveProducts: 'products/list/getActiveProducts',
    }),
    async navigate(params = {}) {
      try {
        this.isFiltering = true;
        this.$router.push({
          name: this.$route.name,
          query: this.filterParams({ ...this.paginationParams, ...params }),
        });
        this.isFiltering = false;
      } catch (err) {
        this.isFiltering = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
