/* eslint-disable */
const getters = {
  getSymptom(state) {
    return {
      ...state.data,
      photoable_type: 'App\\Symptom',
    };
  },
  getIsLoading(state) {
    return state.isLoading;
  },
};

export default getters;
