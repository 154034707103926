<template>
  <LoadingContainer :is-loading="isLoadingSymptoms && !isLoaded">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Συμπτώματα
            <span v-if="paginationParams.count > 0" class="tag">{{
              paginationParams.count
            }}</span>
          </div>
        </div>
        <div class="card-header-icon">
          <div class="field has-addons is-marginless">
            <div class="control">
              <router-link
                :to="{ name: 'products.symptoms.add' }"
                class="button"
              >
                <span class="icon is-small"><i class="fa fa-plus"/></span
                ><span>Προσθήκη</span>
              </router-link>
            </div>
            <div class="control">
              <button class="button" type="button" @click="handleERPSync">
                <span class="icon is-small">
                  <i class="fa fa-cloud-download" />
                </span>
                <span>ERP Sync</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <Filters
          :options="options"
          :is-loading="isFiltering"
          :filters="paginationParams.filters"
          general-label="Αναζήτηση με χρήση τίτλου"
          @navigate="navigate"
        />

        <NewPagination
          :items="symptoms"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column is-full">
            <LoadingContainer
              :is-loading="isFiltering && isLoaded"
              :is-opaque="true"
              class="table-wrapper"
            >
              <table
                v-if="symptoms.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Τίτλος"
                        attribute="title"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Προβολή"
                        attribute="active"
                        @sort="sort"
                      />
                    </th>
                    <th>Ενέργειες</th>
                  </tr>
                </thead>
                <tbody>
                  <symptom
                    v-for="(item, index) in symptoms"
                    :item="item"
                    :index="paginationParams.indexStartsFrom + index"
                    :key="item.id"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν συμπτώματα
              </h4>
            </LoadingContainer>
          </div>
        </div>

        <NewPagination
          v-if="symptoms.length"
          :items="symptoms"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />
      </div>
    </div>

    <SyncModal :is-open="isSyncing" />
  </LoadingContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SyncModal from '@/views/components/SyncModal';
import PaginatedList from '@/views/components/PaginatedList';
import Symptom from './components/Symptom';

export default {
  components: {
    SyncModal,
    Symptom,
  },
  extends: PaginatedList,
  data() {
    return {
      isSyncing: false,
      options: [
        {
          label: 'Γενική Αναζήτηση',
          title: 'general',
          type: 'string',
          value: null,
        },
        {
          label: 'Εμφανές',
          title: 'active',
          type: 'boolean',
          value: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      symptoms: 'symptoms/list/getData',
      paginationParams: 'symptoms/list/getPaginationParams',
      isLoadingSymptoms: 'symptoms/list/getIsLoading',
      isLoaded: 'symptoms/list/getIsLoaded',
    }),
  },
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.getSymptoms(newVal.query);
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router.replace({
              name: this.$route.name,
              query: this.filterParams({
                page: 1,
                size: 50,
                filters: {},
                sort: {},
                paginated: 1,
              }),
            });
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getSymptoms: 'symptoms/list/getSymptoms',
      updateSymptomActiveStatus: 'symptoms/list/updateSymptomActiveStatus',
      checkForSymptomsAdditions: 'symptoms/list/checkForSymptomsAdditions',
    }),
    async navigate(params = {}) {
      try {
        this.isFiltering = true;
        this.$router.push({
          name: this.$route.name,
          query: this.filterParams({ ...this.paginationParams, ...params }),
        });
        this.isFiltering = false;
      } catch (err) {
        this.isFiltering = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    async handleERPSync() {
      try {
        this.isSyncing = true;
        await this.checkForSymptomsAdditions();

        await this.navigate({
          page: 1,
          size: 50,
          filters: {},
          sort: {},
          paginated: 1,
        });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η ενημέρωση εκτελέστηκε επιτυχώς!!',
        });
        this.isSyncing = false;
      } catch (err) {
        this.isSyncing = false;

        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
