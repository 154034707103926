<template>
  <form @submit.prevent="submit">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Authors <span class="tag">Προσθήκη author</span>
          </div>
        </div>
        <div class="card-header-icon">
          <router-link :to="{ name: 'content.authors.list' }" class="button">
            <span class="icon is-small"><i class="fa fa-list"/></span
            ><span>Λίστα</span>
          </router-link>
        </div>
      </div>
      <div class="card-content">
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="field">
              <label class="label">Oνοματεπώνυμο *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  v-model.trim="general.name"
                  class="input"
                  type="text"
                  name="name"
                />
                <p v-show="errors.has('name')" class="help is-danger">
                  Εισάγετε ονοματεπώνυμο
                </p>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Ειδικότητα *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  v-model.trim="general.specialty"
                  class="input"
                  type="text"
                  name="specialty"
                />
                <p v-show="errors.has('specialty')" class="help is-danger">
                  Εισάγετε ειδικότητα
                </p>
              </div>
            </div>
          </div>
          <div class="column is-full">
            <div class="field">
              <label class="label">Βιογραφικό</label>
              <div class="control">
                <textarea
                  v-model.trim="general.bio"
                  name="bio"
                  class="textarea"
                />
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Facebook</label>
              <div class="control">
                <input
                  v-validate="'url'"
                  v-model.trim="general.facebook"
                  class="input"
                  type="text"
                  name="facebook"
                />
                <p v-show="errors.has('facebook')" class="help is-danger">
                  Εισάγετε έγκυρο url
                </p>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Twitter</label>
              <div class="control">
                <input
                  v-validate="'url'"
                  v-model.trim="general.twitter"
                  class="input"
                  type="text"
                  name="twitter"
                />
                <p v-show="errors.has('twitter')" class="help is-danger">
                  Εισάγετε έγκυρο url
                </p>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Instagram</label>
              <div class="control">
                <input
                  v-validate="'url'"
                  v-model.trim="general.instagram"
                  class="input"
                  type="text"
                  name="instagram"
                />
                <p v-show="errors.has('instagram')" class="help is-danger">
                  Εισάγετε έγκυρο url
                </p>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Linkedin</label>
              <div class="control">
                <input
                  v-validate="'url'"
                  v-model.trim="general.linkedin"
                  class="input"
                  type="text"
                  name="linkedin"
                />
                <p v-show="errors.has('linkedin')" class="help is-danger">
                  Εισάγετε έγκυρο url
                </p>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Webpage</label>
              <div class="control">
                <input
                  v-validate="'url'"
                  v-model.trim="general.webpage"
                  class="input"
                  type="text"
                  name="webpage"
                />
                <p v-show="errors.has('webpage')" class="help is-danger">
                  Εισάγετε έγκυρο url
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import LoadingContainer from '@/views/components/LoadingContainer';
import ToggleableCard from '@/views/components/ToggleableCard';
import AddItem from '@/views/components/AddItem';

export default {
  components: {
    LoadingContainer,
    ToggleableCard,
  },
  extends: AddItem,
  data() {
    return {
      general: {
        name: '',
        specialty: '',
        bio: '',
        facebook: '',
        twitter: '',
        instagram: '',
        linkedin: '',
        webpage: '',
      },
      isSaving: false,
    };
  },
  methods: {
    ...mapActions({
      addAuthor: 'authors/add/addAuthor',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addAuthor(this.general);
        this.isSaving = false;

        this.$router.push({ name: 'content.authors.list' });
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'O author αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = {
        name: '',
        specialty: '',
        bio: '',
        facebook: '',
        twitter: '',
        instagram: '',
        linkedin: '',
        webpage: '',
      };

      this.$validator.reset();
    },
  },
};
</script>
