<template>
  <div class="customer-tab">
    <table
      v-if="favorites.length"
      class="table is-bordered is-striped is-marginless is-fullwidth"
    >
      <thead>
        <tr>
          <th class="has-text-centered">Φώτο</th>
          <th class="has-text-centered">Barcodes</th>
          <th class="has-text-centered">Common ID</th>
          <th class="has-text-centered">Τίτλος</th>
          <th class="has-text-centered">Π.Λ. Τιμή (&euro;)</th>
          <th class="has-text-centered">Έκπτωση (&#x25;)</th>
          <th class="has-text-centered">Τελική Τιμή (&euro;)</th>
          <th class="has-text-centered">Προβολή</th>
          <th class="has-text-centered">Ενέργειες</th>
        </tr>
      </thead>
      <tbody>
        <Favorite
          v-for="(item, index) in favorites"
          :item="item"
          :index="index"
          :key="item.id"
        />
      </tbody>
    </table>
    <h5 v-else class="title is-5 has-text-centered is-marginless">
      Δε βρέθηκαν αγαπημένα
    </h5>
  </div>
</template>

<script>
import _get from 'lodash/get';
import { mapGetters } from 'vuex';
import Favorite from './components/Favorite';

export default {
  components: {
    Favorite,
  },
  computed: {
    ...mapGetters({
      customer: 'customers/edit/getCustomer',
    }),
    favorites() {
      return _get(this.customer.profile, 'favorites', []);
    },
  },
};
</script>
