<template>
  <tr>
    <td class="has-text-centered">{{ index }}</td>
    <td class="has-text-centered">
      {{ item.title }}
    </td>
  </tr>
</template>

<script>
import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';

export default {
  extends: PaginatedListItem,
  props: {
    item: Object,
    index: Number,
  },
};
</script>
