<template>
  <LoadingContainer :is-loading="isLoading">
    <div class="columns is-multiline">
      <div class="column is-full">
        <product-info :product="product" />
      </div>
    </div>

    <router-view />
  </LoadingContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoadingContainer from '@/views/components/LoadingContainer';
import ProductInfo from './components/ProductInfo';

export default {
  components: {
    ProductInfo,
    LoadingContainer,
  },
  data() {
    return {
      uuid: this.$route.params.uuid,
    };
  },
  computed: {
    ...mapGetters({
      isLoadingProduct: 'products/edit/getIsLoading',
      product: 'products/edit/getProduct',
      isLoadingTags: 'tags/all/getIsLoading',
      isLoadingCategories: 'categories/list/getIsLoading',
      isLoadingColors: 'colors/list/getIsLoading',
      isLoadingSizes: 'sizes/list/getIsLoading',
      isLoadingFilterGroups: 'filterGroups/all/getIsLoading',
      isLoadingSymptoms: 'symptoms/all/getIsLoading',
    }),
    isLoading() {
      return (
        this.isLoadingProduct ||
        this.isLoadingTags ||
        this.isLoadingCategories ||
        this.isLoadingColors ||
        this.isLoadingSizes ||
        this.isLoadingFilterGroups ||
        this.isLoadingSymptoms
      );
    },
  },
  watch: {
    $route: 'fetchData',
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    ...mapActions({
      getProduct: 'products/edit/getProduct',
      getTags: 'tags/all/getTags',
      getPromos: 'promos/all/getPromos',
      getCategories: 'categories/list/getCategories',
      getColors: 'colors/all/getColors',
      getSizes: 'sizes/all/getSizes',
      getSymptoms: 'symptoms/all/getSymptoms',
      getFilterGroups: 'filterGroups/all/getFilterGroups',
      getCollections: 'collections/all/getCollections',
    }),
    async fetchData() {
      try {
        await Promise.all([
          this.getProduct({ uuid: this.$route.params.uuid }),
          this.getTags(),
          this.getPromos(),
          this.getCategories(),
          this.getColors(),
          this.getSizes(),
          this.getSymptoms(),
          this.getFilterGroups(),
        ]);

        if (this.product.brand_id) {
          await this.getCollections({ uuid: this.product.brand.uuid });
        }
      } catch (err) {
        console.log(err);
        // this.$router.push('/error');
      }
    },
  },
};
</script>
