<template>
  <form @submit.prevent="submit">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          Marketing Pages
        </div>
        <div class="card-header-icon">
          <div class="field has-addons is-marginless">
            <div class="control">
              <router-link
                :to="{ name: 'marketing.promos.list' }"
                class="button"
              >
                <span class="icon is-small"><i class="fa fa-list-ul"/></span
                ><span>Λίστα</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="field">
              <label class="label">Τίτλος *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  v-model.trim="newPromo.title"
                  class="input"
                  type="text"
                  name="title"
                />
                <p v-show="errors.has('title')" class="help is-danger">
                  Εισάγετε τίτλο
                </p>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Slug *</label>
              <div class="control">
                <input
                  v-validate="'regex:^[a-z0-9]+(?:-[a-z0-9]+)*$'"
                  v-model.trim="newPromo.slug"
                  class="input"
                  type="text"
                  name="slug"
                />
                <p v-show="errors.has('slug')" class="help is-danger">
                  Εισάγετε έγκυρο slug
                </p>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Ενεργό *</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select v-model.number="newPromo.active" name="active">
                    <option :value="false">Όχι</option>
                    <option :value="true">Ναί</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Crawlable *</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select v-model.number="newPromo.crawlable" name="active">
                    <option :value="false">Όχι</option>
                    <option :value="true">Ναί</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ToggleableCard :is-open="true" title="Σχετικά Προϊόντα">
      <div class="card-content">
        <div class="field">
          <label class="label">Σχετικά Προιόντα</label>
          <div class="control">
            <ProductsSearch
              :products="newPromo.products"
              @selectProduct="handleSelectProduct"
              @removeProduct="handleRemoveProduct"
            />
          </div>
        </div>
      </div>
    </ToggleableCard>

    <div class="card">
      <div class="card-content">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </div>
  </form>
</template>

<script>
import slug from 'slug';
import request from '@/utils/request';
import AddItem from '@/views/components/AddItem';
import ToggleableCard from '@/views/components/ToggleableCard';
import ProductsSearch from '@/views/components/ProductsSearch';

export default {
  components: {
    ToggleableCard,
    ProductsSearch,
  },

  extends: AddItem,

  data() {
    return {
      newPromo: {
        title: '',
        slug: '',
        products: [],
        active: false,
        crawlable: false,
      },
    };
  },

  watch: {
    'newPromo.title': {
      handler(newVal, oldVal) {
        // When title is already set and a super admin changes it
        // then we need to update slugs, urls and titles in various places
        if (oldVal !== undefined && newVal !== oldVal) {
          const newSlug = slug(newVal.toLowerCase());
          this.newPromo.slug = newSlug;
        }
      },
    },
  },

  methods: {
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        const { data } = await request.post('/promos', {
          ...this.newPromo,
          products: this.newPromo.products.map(({ id }) => id),
        });
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$router.push({ name: 'marketing.promos.list' });
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το Marketing Page αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.newPromo = {
        slug: '',
        active: false,
        crawlable: false,
      };

      this.$validator.reset();
    },

    handleSelectProduct(product) {
      this.newPromo = {
        ...this.newPromo,
        products: [...(this.newPromo.products || []), product],
      };
    },

    handleRemoveProduct(product) {
      this.newPromo = {
        ...this.newPromo,
        products: this.newPromo.products.filter(({ id }) => id !== product.id),
      };
    },
  },
};
</script>
