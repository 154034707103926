<template>
  <layout>
    <div class="box error has-text-centered">
      <span class="icon"><i class="fa fa-bug"/></span>
      <h1 class="title is-1 is-spaced">404</h1>
      <h4 class="subtitle is-4">Αδυναμία Σύνδεσης</h4>
      <p>Λυπούμαστε, αλλά δεν μπορέσαμε να βρούμε τη σελίδα που αναζητήσατε.</p>
      <p>Παρακαλούμε, προσπαθήστε ξανά!!</p>
      <router-link
        to="/dashboard"
        class="button is-primary is-large is-outlined"
      >
        Κεντρική Σελίδα
      </router-link>
    </div>
  </layout>
</template>

<script>
import Layout from '@/views/components/Layout';

export default {
  components: {
    layout: Layout,
  },
};
</script>

<style lang="scss" scoped>
.error {
  .is-1 {
    font-size: 60px;
  }

  .icon {
    height: auto;
    width: auto;

    .fa {
      font-size: 200px;
    }
  }

  .button {
    margin-top: 30px;
  }
}
</style>
