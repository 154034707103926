import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getCustomer({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/customers/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setCustomer', {
        customer: data.customer,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async updateCustomer({ dispatch, commit }, { uuid, customer }) {
    try {
      const response1 = await request.put(`/customers/${uuid}`, customer);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateCustomer', {
        customer,
      });
    } catch (err) {
      throw err;
    }
  },

  async activateCustomer({ dispatch, commit }, { customer }) {
    try {
      const { data } = await request.put(`/customers/${customer.uuid}/restore`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateCustomer', {
        customer: {
          ...customer,
          deleted_at: null,
        },
      });
    } catch (err) {
      throw err;
    }
  },

  async subscribeCustomer({ dispatch, commit }, { uuid, email }) {
    try {
      const { data } = await request.put(`/customers/${uuid}/subscribe`, {
        email,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateCustomer', {
        customer: data.customer,
      });
    } catch (err) {
      throw err;
    }
  },

  async unsubscribeCustomer({ dispatch, commit }, { uuid, email }) {
    try {
      const { data } = await request.put(`/customers/${uuid}/unsubscribe`, {
        email,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateCustomer', {
        customer: data.customer,
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};

export default actions;
