<template>
  <CustomersEdit v-bind="$props">
    <div class="card-content">
      <slot />

      <template v-if="hasNewsletter">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Ενεργό Newsletter</label>
              <div class="control">
                <ToggleButton
                  :value="shouldHaveNewsletter"
                  :sync="true"
                  :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                  :width="30"
                  :height="15"
                  @change="handleChange"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Όνομα</label>
              <div class="control">
                <input
                  :value="newsletter.firstName"
                  disabled
                  class="input"
                  type="text"
                  name="firstName"
                />
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Επώνυμο</label>
              <div class="control">
                <input
                  :value="newsletter.lastName"
                  disabled
                  class="input"
                  type="text"
                  name="lastName"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input
                  :value="newsletter.email"
                  disabled
                  class="input"
                  type="email"
                  name="email"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Φύλο</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select :value="newsletter.gender" name="gender" disabled>
                    <option :value="null">Επιλέξτε Φύλο</option>
                    <option value="male">Άνδρας</option>
                    <option value="female">Γυναίκα</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Ημ/νια Γένησης</label>
              <div class="control">
                <input
                  :value="newsletter.birthday"
                  disabled
                  class="input"
                  type="text"
                  name="birthday"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Κινητό</label>
              <div class="control">
                <vue-tel-input
                  v-model="newsletter.mobile"
                  enabled-country-code
                  placeholder=""
                  name="mobile"
                  disabled
                />
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Σταθερό</label>
              <div class="control">
                <vue-tel-input
                  v-model="newsletter.telephone"
                  enabled-country-code
                  placeholder=""
                  name="telephone"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <Confirmation
      :is-open="isOpen"
      title="Επιβεβαίωση Απεγγραφής"
      description="Είστε βέβαιος για το unsubscribe αυτού του πελάτη απο το newsletter;"
      @closeModal="closeModal"
    />
  </CustomersEdit>
</template>

<script>
import { mapActions } from 'vuex';
import _pick from 'lodash/pick';
import EditItem from '@/views/components/EditItem';
import Confirmation from '@/views/components/Confirmation';
import CustomersEdit from '../CustomersEdit';

export default {
  name: 'CustomersEditNewsletter',
  components: {
    Confirmation,
    CustomersEdit,
  },
  extends: EditItem,
  props: {
    customer: Object,
    tabs: Array,
    activeTab: String,
  },
  data() {
    return {
      newsletter: {},
      shouldHaveNewsletter: false,
      isOpen: false,
      isLoading: false,
    };
  },
  computed: {
    isDeleted() {
      return this.customer.deleted_at !== null;
    },
    hasNewsletter() {
      return this.customer.newsletter !== null;
    },
  },
  created() {
    this.newsletter = _pick(this.customer.newsletter, [
      'firstName',
      'lastName',
      'email',
      'gender',
      'birthday',
      'telephone',
      'mobile',
    ]);
    this.shouldHaveNewsletter = this.customer.newsletter_email !== null;
  },
  methods: {
    ...mapActions({
      unsubscribeCustomer: 'customers/edit/unsubscribeCustomer',
    }),
    handleChange({ value }) {
      this.shouldHaveNewsletter = value;

      if (!value) {
        this.isOpen = true;
      }
    },
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;

        if (response) {
          this.isLoading = true;
          await this.unsubscribeCustomer({
            uuid: this.customer.uuid,
            email: this.newsletter.email,
          });
          this.isLoading = false;

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Ο πελάτης έγινε unsubscribe απο το newsletters επιτυχώς!!',
          });
        } else {
          this.shouldHaveNewsletter = !this.shouldHaveNewsletter;
        }
      } catch (err) {
        console.log(err);
        this.isLoading = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-tel-input {
  border-radius: 2px;

  .dropdown {
    padding: 6px;

    &:focus {
      outline: none;
    }
  }

  &.disabled {
    background-color: whitesmoke;
    border-color: #dbdbdb;

    &:focus-within {
      box-shadow: none;
      outline: none;
    }

    & input {
      background-color: transparent;
      color: #7a7a7a;
    }

    & .dropdown,
    & .selection,
    & input {
      cursor: not-allowed;
    }
  }

  input {
    font-size: 1rem;
  }
}
</style>
