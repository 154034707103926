<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        Πληροφορίες Δώρου
      </div>
    </div>
    <div class="card-content">
      <div class="field">
        <div class="control">
          <checkbox v-model="order.is_gift" name="inform_client" />
          <label>Συσκευασία Δώρου</label>
        </div>
      </div>

      <div v-if="order.is_gift" class="field">
        <label class="label">Ευχητήριο Μήνυμα</label>
        <div class="control">
          <textarea
            v-model="order.gift_wishes"
            name="gift_wishes"
            class="textarea"
            cols="30"
            rows="5"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { GIFT_COST } from '@/constants/costs';

export default {
  props: {
    order: Object,
  },
  watch: {
    // eslint-disable-next-line func-names
    'order.is_gift': function(newValue) {
      this.updateNewOrder({
        is_gift: !!newValue,
        gift_wishes: newValue ? this.order.gift_wishes : null,
        gift_cost: newValue ? GIFT_COST : 0,
      });
    },
    // eslint-disable-next-line func-names
    'order.gift_wishes': function(newValue) {
      this.updateNewOrder({
        gift_wishes: newValue,
      });
    },
  },
  methods: {
    ...mapMutations(['updateNewOrder']),
  },
};
</script>

<style scoped lang="scss">
.checkbox-component,
.label {
  display: inline-block;
}

.checkbox-component {
  margin-right: 10px;
  vertical-align: middle;
}
</style>
