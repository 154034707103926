<template>
  <LoadingContainer :is-loading="isLoadingSizes && !isLoaded">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Μεγέθη
            <span v-if="paginationParams.count > 0" class="tag">{{
              paginationParams.count
            }}</span>
          </div>
        </div>
        <div class="card-header-icon hidden-print">
          <div class="control">
            <button class="button" type="button" @click="handleERPSync">
              <span class="icon is-small">
                <i class="fa fa-cloud-download" />
              </span>
              <span>ERP Sync</span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-content">
        <Filters
          :options="options"
          :is-loading="isFiltering"
          :filters="paginationParams.filters"
          general-label="Αναζήτηση με χρήση τίτλου"
          @navigate="navigate"
        />

        <NewPagination
          :items="sizes"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column">
            <LoadingContainer
              :is-loading="isFiltering && isLoaded"
              :is-opaque="true"
            >
              <table
                v-if="sizes.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Τίτλος</th>
                  </tr>
                </thead>
                <tbody>
                  <size
                    v-for="(item, index) in sizes"
                    :item="item"
                    :index="paginationParams.indexStartsFrom + index"
                    :key="item.id"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν μεγέθη
              </h4>
            </LoadingContainer>
          </div>
        </div>

        <NewPagination
          v-if="sizes.length"
          :items="sizes"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />
      </div>
    </div>

    <SyncModal :is-open="isSyncing" />
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SyncModal from '@/views/components/SyncModal';
import PaginatedList from '@/views/components/PaginatedList';
import Size from './components/Size';

export default {
  components: {
    Size,
    SyncModal,
  },
  extends: PaginatedList,
  data: () => ({
    isSyncing: false,
    options: [
      {
        label: 'Γενική Αναζήτηση',
        title: 'general',
        type: 'string',
        value: null,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      sizes: 'sizes/list/getData',
      paginationParams: 'sizes/list/getPaginationParams',
      isLoadingSizes: 'sizes/list/getIsLoading',
      isLoaded: 'sizes/list/getIsLoaded',
    }),
  },
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.getSizes(newVal.query);
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router.replace({
              name: this.$route.name,
              query: this.filterParams({
                page: 1,
                size: 50,
                filters: {},
                sort: {},
                paginated: 1,
              }),
            });
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getSizes: 'sizes/list/getSizes',
      checkForSizesAdditions: 'sizes/list/checkForSizesAdditions',
    }),
    async navigate(params = {}) {
      try {
        this.isFiltering = true;
        this.$router.push({
          name: this.$route.name,
          query: this.filterParams({ ...this.paginationParams, ...params }),
        });
        this.isFiltering = false;
      } catch (err) {
        this.isFiltering = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    async handleERPSync() {
      try {
        this.isSyncing = true;
        await this.checkForSizesAdditions();

        await this.navigate({
          page: 1,
          size: 50,
          filters: {},
          sort: {},
          paginated: 1,
        });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η ενημέρωση εκτελέστηκε επιτυχώς!!',
        });
        this.isSyncing = false;
      } catch (err) {
        this.isSyncing = false;

        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
