<template>
  <div class="columns is-multiline">
    <div class="column is-full">
      <div class="field">
        <label class="label">
          Meta Title *
          <span v-if="meta.title.length" class="tag">{{
            meta.title.length
          }}</span>
        </label>
        <div class="control">
          <textarea
            v-validate="'required'"
            :value="meta.title"
            :disabled="!isSuper"
            class="textarea"
            name="meta_title"
            @input="updateMeta"
          />
        </div>
        <p v-show="errors.has('meta_title')" class="help is-danger">
          Εισάγετε title
        </p>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <label class="label">
          Meta Description *
          <span v-if="meta.description.length" class="tag">{{
            meta.description.length
          }}</span>
          &nbsp;<small>(μέγιστο όριο: 290 χαρακτήρες)</small>
        </label>
        <div class="control">
          <textarea
            v-validate="'required|max:290'"
            :value="meta.description"
            :disabled="!isSuper"
            class="textarea"
            name="meta_description"
            @input="updateMeta"
          />
        </div>
        <p
          v-show="errors.has('meta_description:required')"
          class="help is-danger"
        >
          Εισάγετε description
        </p>
        <p v-show="errors.has('meta_description:max')" class="help is-danger">
          Εισάγετε μέχρι 290 χαρακτήρες
        </p>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <label class="label">Meta Keywords *</label>
        <div class="control">
          <textarea
            v-validate="'required'"
            :value="meta.keywords"
            :disabled="!isSuper"
            class="textarea"
            name="meta_keywords"
            @input="updateMeta"
          />
        </div>
        <p v-show="errors.has('meta_keywords')" class="help is-danger">
          Εισάγετε Keywords
        </p>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <label class="label">Schema *</label>
        <div class="control">
          <codemirror
            v-validate="'required'"
            :disabled="!isSuper"
            :value="meta.schema"
            data-vv-value-path="value"
            data-vv-name="meta_schema"
            @input="updateSchema"
          />
        </div>
        <p v-show="errors.has('meta_schema')" class="help is-danger">
          Εισάγετε Schema
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  inject: ['$validator'],
  props: {
    meta: Object,
  },
  computed: {
    ...mapGetters({
      isSuper: 'getIsSuper',
    }),
  },
  methods: {
    updateMeta(e) {
      const { name, value } = e.target;

      this.$emit('updateMeta', {
        ...this.meta,
        [name.split('_')[1]]: value,
      });
    },
    updateSchema(schema) {
      this.$emit('updateMeta', {
        ...this.meta,
        schema,
      });
    },
  },
};
</script>
