import edit from './edit';
import add from './add';

const module = {
  namespaced: true,
  modules: {
    edit,
    add,
  },
};

export default module;
