const state = {
  isLoading: false,
  data: {
    tree: [],
    reverseTree: {},
    perType: [],
  },
};

export default state;
