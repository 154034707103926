import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async updateOrCreateProfile({ dispatch, commit }, { profile, customer_id }) {
    try {
      const response1 = await request.post('/profiles', profile);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/customers/${customer_id}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('customers/edit/updateCustomer', {
        customer: response2.data.customer,
      }, {
        root: true,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
