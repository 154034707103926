<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Σημειώσεις Πελάτη
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="field">
        <label class="label">Σχολια για Courier</label>
        <div class="control">
          <textarea
            v-validate="'max:99'"
            v-model="order.customer_notes_courier"
            name="customer_notes_courier"
            class="textarea"
            cols="30"
            rows="2"
          />
        </div>
        <div
          v-show="errors.has('customer_notes_courier')"
          class="help is-danger"
        >
          Εισάγετε έως 99 χαρακτήρες
        </div>
      </div>

      <div class="field">
        <label class="label">Σχολια για παραγγελία</label>
        <div class="control">
          <textarea
            v-model="order.customer_notes"
            name="customer_notes"
            class="textarea"
            cols="30"
            rows="2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  props: {
    order: Object,
  },
  watch: {
    'order.customer_notes_courier': {
      handler(newValue) {
        this.updateNewOrder({
          customer_notes_courier: newValue,
        });
      },
    },
    'order.customer_notes': {
      handler(newValue) {
        this.updateNewOrder({
          customer_notes: newValue,
        });
      },
    },
  },
  methods: {
    ...mapMutations(['updateNewOrder']),
  },
};
</script>
