<template>
  <LoadingContainer :is-loading="isLoadingItems">
    <div class="columns">
      <div class="column is-three-quarters">
        <form novalidate @submit.prevent="submit">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Ομάδα Φίλτρων
                  <router-link
                    :to="{
                      name: 'products.filterGroups.edit',
                      params: { uuid: $route.params.uuid },
                    }"
                    class="tag"
                  >
                    {{ filterGroup.title }}
                  </router-link>
                  Προσθήκη Φίλτρου
                </div>
              </div>
              <div class="card-header-icon">
                <div class="field">
                  <div class="control">
                    <router-link
                      :to="{
                        name: 'products.filterGroups.filters.list',
                        params: { uuid: $route.params.uuid },
                      }"
                      class="button"
                    >
                      <span class="icon is-small">
                        <i class="fa fa-list-ul" />
                      </span>
                      <span>Λίστα</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content">
              <div class="field">
                <label class="label">Ονομασία *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newFilter.title"
                    class="input"
                    type="text"
                    name="title"
                  />
                  <p v-show="errors.has('title')" class="help is-danger">
                    Εισάγετε ονομασία
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">
                  Ονομασία
                  <span class="icon is-small fa flag-icon flag-icon-gb" />
                </label>
                <div class="control">
                  <input
                    v-model.trim="newFilter.title_en"
                    class="input"
                    type="text"
                    name="title_en"
                  />
                </div>
              </div>
            </div>
            <div class="card-footer">
              <FormSubmit :is-saving="isSaving" @reset="reset" />
            </div>
          </div>
        </form>
      </div>
      <div class="column">
        <ExistingFilters :items="items" :filter-group="filterGroup" />
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddItem from '@/views/components/AddItem';
import EditGeneralDetails from '@/views/components/EditGeneralDetails';
import EditMetaDetails from '@/views/components/EditMetaDetails';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import ExistingFilters from '../../components/ExistingFilters';

export default {
  components: {
    EditMetaDetails,
    EditGeneralDetails,
    ToggleableCard,
    LoadingContainer,
    ExistingFilters,
  },
  extends: AddItem,
  data() {
    return {
      newFilter: {
        title: '',
        title_en: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      filterGroup: 'filterGroups/edit/getFilterGroup',
      items: 'filters/list/getFilters',
      isLoadingItems: 'filters/list/getIsLoading',
    }),
  },
  async created() {
    try {
      await Promise.all([
        this.getFilterGroup({ uuid: this.$route.params.uuid }),
        this.getFilters({ uuid: this.$route.params.uuid }),
      ]);
    } catch (err) {
      this.$router.push('/error');
    }
  },
  methods: {
    ...mapActions({
      getFilterGroup: 'filterGroups/edit/getFilterGroup',
      addFilter: 'filters/add/addFilter',
      getFilters: 'filters/list/getFilters',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addFilter({
          ...this.newFilter,
          filter_group_id: this.filterGroup.id,
        });
        this.isSaving = false;

        this.$router.push({
          name: 'products.filterGroups.filters.list',
          params: { uuid: this.$route.params.uuid },
        });
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η σειρά αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.newFilter = {
        title: '',
        title_en: '',
      };

      this.$validator.reset();
    },
  },
};
</script>

<style scoped>
.flag-icon-gb {
  margin-right: 5px;
}
</style>
