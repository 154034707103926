<template>
  <div class="customer-tab">
    <table
      v-if="reviews.length"
      class="table is-bordered is-striped is-marginless is-fullwidth"
    >
      <thead>
        <tr>
          <th>Σχόλιο</th>
          <th class="has-text-centered">Stars</th>
          <th class="has-text-centered">Τύπος</th>
          <th class="has-text-centered">Ημ/νια Δημιουργίας</th>
          <th class="has-text-centered">Προβολή</th>
        </tr>
      </thead>
      <tbody>
        <item
          v-for="(item, index) in reviews"
          :item="item"
          :index="index"
          :key="item.id"
        />
      </tbody>
    </table>
    <h5 v-else class="title is-5 has-text-centered is-marginless">
      Δε βρέθηκαν σχόλια
    </h5>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _get from 'lodash/get';
import Item from './components/Review';

export default {
  components: {
    Item,
  },
  computed: {
    ...mapGetters({
      customer: 'customers/edit/getCustomer',
    }),
    reviews() {
      return _get(this.customer.profile, 'reviews', []);
    },
  },
};
</script>
