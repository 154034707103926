import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async addContact({ dispatch, commit }, contact) {
    try {
      const { data } = await request.post('contacts', contact);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit(
        'customers/edit/addCustomerContact',
        {
          contact: data.contact,
        },
        {
          root: true,
        },
      );
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
