import Vue from 'vue';
import Vuex from 'vuex';
import settings from './settings';
import session from './session';
import sidebar from './sidebar';
import order from './order';
import orderNotes from './orderNotes';
import orderGift from './orderGift';
import orderStatuses from './orderStatuses';
import orderTransactions from './orderTransactions';
import orderAddress from './orderAddress';
import orderInvoice from './orderInvoice';
import orderProducts from './orderProducts';
import statuses from './statuses';
import checkoutMethods from './checkoutMethods';
import sendMethods from './sendMethods';
import orderMethods from './orderMethods';
import registrationMethods from './registrationMethods';
import availabilities from './availabilities';
import taxes from './taxes';
import users from './users';
import complaints from './complaints';
import customers from './customers';
import customerEmails from './customerEmails';
import customerAddresses from './customerAddresses';
import customerInvoices from './customerInvoices';
import emailTemplates from './emailTemplates';
import orders from './orders';
import newOrder from './newOrder';
import pages from './pages';
import posts from './posts';
import postCategories from './postCategories';
import skroutzSmartCart from './skroutzSmartCart';
import banners from './banners';
import brands from './brands';
import symptoms from './symptoms';
import collections from './collections';
import questions from './questions';
import filterGroups from './filterGroups';
import filters from './filters';
import categories from './categories';
import tags from './tags';
import products from './products';
import contacts from './contacts';
import colors from './colors';
import sizes from './sizes';
import general from './general';
import sendCharges from './sendCharges';
import photos from './photos';
import faqs from './faqs';
import videos from './videos';
import bundles from './bundles';
import sets from './sets';
import groups from './groups';
import profiles from './profiles';
import our from './our';
import contests from './contests';
import coupons from './coupons';
import authors from './authors';
import reviews from './reviews';
import loyalty from './loyalty';
import promos from './promos';
import periodicDiscounts from './periodicDiscounts';
import giftActions from './giftActions';
import giftActionsBanners from './giftActionsBanners';
import cancelOrderReasons from './cancelOrderReasons';
import plugins from './plugins';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    settings,
    session,
    sidebar,
    order,
    orderNotes,
    orderGift,
    orderStatuses,
    orderTransactions,
    orderAddress,
    orderInvoice,
    orderProducts,
    statuses,
    checkoutMethods,
    sendMethods,
    orderMethods,
    registrationMethods,
    availabilities,
    taxes,
    users,
    complaints,
    customers,
    customerEmails,
    customerAddresses,
    customerInvoices,
    emailTemplates,
    orders,
    newOrder,
    pages,
    posts,
    postCategories,
    skroutzSmartCart,
    banners,
    brands,
    symptoms,
    collections,
    questions,
    filterGroups,
    filters,
    categories,
    tags,
    products,
    contacts,
    sizes,
    colors,
    general,
    sendCharges,
    photos,
    faqs,
    videos,
    bundles,
    sets,
    groups,
    profiles,
    our,
    contests,
    coupons,
    authors,
    reviews,
    loyalty,
    promos,
    periodicDiscounts,
    cancelOrderReasons,
    giftActions,
    giftActionsBanners,
  },
  plugins,
});

export default store;
