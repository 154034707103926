import request from '@/utils/request';
import { calculateParams } from '@/utils/calculations';

/* eslint-disable */
const actions = {
  async getOrders({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get('/orders', {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.orders);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getWithdrawOrders({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get('/orders-withdrew', {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.orders);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getPendingOrders({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get('/orders-pending', {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.orders);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getUnfinishedOrders({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get('/orders-unfinished', {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.orders);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getSentOrders({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get('/orders-sent', {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.orders);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getDeletedOrders({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get('/orders-deleted', {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.orders);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async massDeleteOrders({ rootGetters: { getUser } }, { data }) {
    try {
      const response = await request.delete('/orders', {
        data: {
          ...data,
          user_id: getUser.id,
        },
      });

      if (response.data.error) {
        throw Error('Server-side validation failed');
      }
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
