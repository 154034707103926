<template>
  <option :value="item.cart_id" :selected="isSelected">
    {{ title }}
  </option>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },

    variationType: {
      type: String,
      required: true,
    },

    selectedVariation: {
      type: Object,
      required: true,
    },
  },

  computed: {
    title() {
      if (this.variationType === 'color') {
        return this.item.color_title;
      }

      return this.item.size_title;
    },

    isSelected() {
      return this.selectedVariation.cart_id === this.item.cart_id;
    },
  },
};
</script>
