import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getStatuses({ commit }) {
    try {
      const { data } = await request.get('/statuses');

      await commit('setSteps', {
        statuses: data.statuses,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
