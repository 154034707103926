<script>
import FormSubmit from '../FormSubmit';

export default {
  components: {
    FormSubmit,
  },
  data() {
    return {
      isSaving: false,
    };
  },
};
</script>
