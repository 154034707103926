<template>
  <form @submit.prevent="submit">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          {{ titleDescription }}
        </div>
        <div class="card-header-icon">
          <div class="field has-addons is-marginless">
            <div class="control">
              <router-link
                :to="{ name: 'marketing.coupons.list' }"
                class="button"
              >
                <span class="icon is-small"><i class="fa fa-list-ul"/></span
                ><span>Λίστα</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="field">
              <label class="label">Κωδικός *</label>
              <div class="control">
                <input
                  v-model.trim="form.code"
                  class="input"
                  type="text"
                  name="code"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Τίτλος</label>
              <div class="control">
                <input
                  v-model.trim="form.title"
                  class="input"
                  type="text"
                  name="title"
                />
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Ελάχιστη Αξία Παραγγελίας *</label>
              <div class="control">
                <input
                  v-model.number="form.min_price"
                  class="input"
                  type="number"
                  name="min_price"
                  min="0"
                />
              </div>
            </div>
          </div>
          <div class="column is-half">
            <label class="label">Ποσό Κουπονιού *</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <input
                  v-model.number="form.value"
                  class="input"
                  type="number"
                  min="0"
                  name="min_price"
                />
              </div>
              <div class="control">
                <div class="select">
                  <select v-model="form.type" name="type">
                    <option value="euro">&euro;</option>
                    <option value="percentage">&#x25;</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <label class="label">Ημερομηνία Έναρξης</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <Datepicker
                  v-model="form.date_start"
                  name="date_start"
                  format="dd-MM-yyyy"
                  language="el"
                  wrapper-class="field"
                  input-class="input"
                  placeholder="πχ: 02-12-2017"
                />
              </div>
              <div v-if="form.date_start" class="control">
                <button
                  class="button"
                  type="button"
                  @click="clearDate('date_start')"
                >
                  <span class="icon"><i class="fa fa-times"/></span>
                </button>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <label class="label">Ημερομηνία Λήξης</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <Datepicker
                  v-model="form.date_end"
                  name="date_end"
                  format="dd-MM-yyyy"
                  language="el"
                  wrapper-class="field"
                  input-class="input"
                  placeholder="πχ: 02-12-2017"
                />
              </div>
              <div v-if="form.date_end" class="control">
                <button
                  class="button"
                  type="button"
                  @click="clearDate('date_end')"
                >
                  <span class="icon"><i class="fa fa-times"/></span>
                </button>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Ενεργό *</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select v-model.number="form.active" name="active">
                    <option :value="false">Όχι</option>
                    <option :value="true">Ναί</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Μίας Χρήσης *</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select v-model.number="form.one_timer" name="active">
                    <option :value="false">Όχι</option>
                    <option :value="true">Ναί</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ToggleableCard :is-open="true" title="Συνδέσεις">
      <div class="card-content">
        <div class="columns is-multiline">
          <div class="column is-full">
            <label class="label">Brands</label>
            <BrandsSearch
              :selections="form.brands"
              @on-select="selectBrand"
              @on-remove="removeBrand"
            />
          </div>
        </div>
      </div>
    </ToggleableCard>

    <div class="card">
      <div class="card-content">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </div>
  </form>
</template>

<script>
import moment from 'moment';
import pick from 'lodash/pick';
import Datepicker from 'vuejs-datepicker';
import AddItem from '@/views/components/AddItem';
import request from '@/utils/request';
import ToggleableCard from '@/views/components/ToggleableCard';
import BrandsSearch from '../components/BrandsSearch';

export default {
  components: {
    Datepicker,
    ToggleableCard,
    BrandsSearch,
  },

  extends: AddItem,

  data() {
    return {
      coupon: {},
      form: {},
    };
  },

  computed: {
    isDeleted() {
      return this.coupon.deleted_at !== null;
    },

    titleDescription() {
      const { coupon } = this;
      /* eslint-disable */
      if (coupon.deleted_at !== null) {
        return 'Ακυρωμένο Κουπόνι';
      } else if (coupon.active === true) {
        return 'Ενεργό Κουπόνι';
      } else if (coupon.active === false) {
        return 'Μη Ενεργό Κουπόνι';
      } else {
        return 'Κουπόνι';
      }
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const { data } = await request.get(`/coupons/${to.params.uuid}`);

      next(vm => vm.setData(data));
    } catch (err) {
      next(vm => vm.$router.push({ name: 'error' }));
    }
  },

  methods: {
    setData(data) {
      this.coupon = data.coupon;
      this.form = pick(this.coupon, [
        'code',
        'title',
        'type',
        'value',
        'date_start',
        'date_end',
        'active',
        'one_timer',
        'min_price',
        'brands',
      ]);
    },

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        const { data } = await request.put(`/coupons/${this.coupon.uuid}`, {
          ...this.form,
          brands: this.form.brands.map(brand => brand.id),
          date_start:
            this.form.date_start &&
            moment(this.form.date_start).format('DD-MM-YYYY'),
          date_end:
            this.form.date_end &&
            moment(this.form.date_end).format('DD-MM-YYYY'),
        });
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το κουπόνι αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.form = pick(this.coupon, [
        'code',
        'title',
        'type',
        'value',
        'date_start',
        'date_end',
        'active',
        'one_timer',
        'min_price',
        'brands',
      ]);

      this.$validator.reset();
    },

    clearDate(date) {
      this.form[date] = null;
    },

    selectBrand(brand) {
      this.form = {
        ...this.form,
        brands: [...this.form.brands, brand],
      };
    },

    removeBrand(brand) {
      this.form = {
        ...this.form,
        brands: this.form.brands.filter(({ id }) => id !== brand.id),
      };
    },
  },
};
</script>
