import _get from 'lodash/get';

/* eslint-disable */
const getters = {
  getRegistrationMethods(state) {
    return state.data;
  },
  getRegistrationMethodsForNewCustomer(state, getters) {
    return getters.getRegistrationMethods.filter(
      method => !['guest-checkout', 'newsletter'].includes(method.slug),
    );
  },
  getRegistrationMethodsForNewGuest(state, getters) {
    return getters.getRegistrationMethods.filter(
      method => method.slug === 'guest-checkout',
    );
  },
  getContactTypes(state, getters) {
    const registrationMethod = getters.getRegistrationMethods.find(
      method => method.slug === 'contact',
    );

    return _get(registrationMethod, 'types', []);
  },
};

export default getters;
