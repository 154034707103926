import request from '@/utils/request';
import { calculateParams } from '@/utils/calculations';

/* eslint-disable */
const actions = {
  async getFilters({ dispatch, commit, getters }, { uuid, params = {} }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get(`filterGroups/${uuid}/filters`, {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.filters);

      await commit(
        'filterGroups/edit/setFilterGroup',
        {
          filterGroup: data.filterGroup,
        },
        {
          root: true,
        },
      );

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },
};

export default actions;
