<template>
  <PaginatedListHeaderTemplate
    :title="title"
    :total="total"
    :selections="selections"
    :has-selections="hasSelections"
  >
    <template v-slot:options>
      <div class="field has-addons is-marginless">
        <div class="control">
          <router-link :to="{ name: 'customers.add' }" class="button">
            <span class="icon is-small"><i class="fa fa-plus"/></span
            ><span>Προσθήκη</span>
          </router-link>
        </div>
        <div class="control">
          <div
            :class="['dropdown is-right', { 'is-hoverable': hasSelections }]"
          >
            <div class="dropdown-trigger">
              <button
                :disabled="!hasSelections"
                class="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
              >
                <span class="icon is-small"><i class="fa fa-wrench"/></span>
                <span>Επιλογές</span>
                <span class="icon is-small">
                  <i class="fa fa-angle-down" aria-hidden="true" />
                </span>
              </button>
            </div>
            <div id="dropdown-menu" class="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <div class="dropdown-item" @click="print">
                  <span class="icon is-small"><i class="fa fa-print"/></span>
                  <span>Εκτύπωση</span>
                </div>
                <div class="dropdown-item" @click="prepareExportXLS">
                  <span class="icon is-small">
                    <i class="fa fa-file-excel-o" />
                  </span>
                  <span>Εξαγωγή σε XLS</span>
                </div>
                <div class="dropdown-item" @click="prepareExportCSV">
                  <span class="icon is-small">
                    <i class="fa fa-file-text-o" />
                  </span>
                  <span>Εξαγωγή σε CSV</span>
                </div>
                <div v-if="canDeleteItems" class="dropdown-divider" />
                <div
                  v-if="canDeleteItems"
                  class="dropdown-item"
                  @click="askToDeleteItems"
                >
                  <span class="icon is-small"><i class="fa fa-trash-o"/></span>
                  <span>Διαγραφή</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:confirmation>
      <confirmation
        :model-name="modelName"
        :is-open="isOpen"
        description="Είστε βέβαιος για τη διαγραφή αυτών των πελατών;"
        @closeModal="closeModal"
      />
    </template>
  </PaginatedListHeaderTemplate>
</template>

<script>
import PaginatedListHeader from '@/views/components/PaginatedList/components/PaginatedListHeader';
import request from '@/utils/request';

export default {
  extends: PaginatedListHeader,
  computed: {
    modelName() {
      const count = this.selections.length;

      return count === 1 ? '1 πελάτης' : `${count} πελάτες`;
    },
  },
  methods: {
    async closeModal(response) {
      try {
        this.isOpen = false;

        if (response) {
          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Οι πελάτες διαγράφτηκαν επιτυχώς!!',
          });
          await request.delete('/customers', {
            params: {
              customers: this.selections,
            },
          });
          this.$emit('navigate');
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
