/* eslint-disable */
const getters = {
  getIsOpen(state) {
    return state.isOpen;
  },

  filteredMenuItems(state, getters) {
    return state.menuItems.filter(({ authority }) => (
      authority.includes(getters.getUser.authority)
    ));
  },
};

export default getters;
