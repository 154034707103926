<template>
  <multiselect
    v-validate="'required'"
    :options="customers"
    :searchable="true"
    :internal-search="false"
    :option-height="104"
    :loading="isSearching"
    :option-limit="50"
    :reset-after="true"
    :value="customer"
    track-by="id"
    label="fullName"
    placeholder="Αναζήτηση με χρήση email, oνοματεπώνυμου..."
    deselect-label=""
    select-label=""
    selected-label="Επιλεγμένο"
    data-vv-value-path="value"
    data-vv-name="customer"
    @search-change="searchCustomers"
    @select="selectCustomer"
  >
    <template v-slot:option="{ option }" class="option">
      <div class="option__desc">
        <span v-if="hasFullName(option.fullName)" class="option__title">
          {{ option.fullName }}
        </span>
        <template v-if="option.emails.profile.length > 0">
          <span
            v-for="(email, index) in option.emails.profile"
            :key="`profile-${index}`"
            class="option__small"
          >
            {{ email }}
            <span v-tooltip="{ content: 'Profile' }" class="tag">P</span>
          </span>
        </template>
        <template v-if="option.emails.guests.length > 0">
          <span
            v-for="(email, index) in option.emails.guests"
            :key="`guests-${index}`"
            class="option__small"
          >
            {{ email }}
          </span>
        </template>
        <template v-if="option.emails.contacts.length > 0">
          <span
            v-for="(email, index) in option.emails.contacts"
            :key="`contacts-${index}`"
            class="option__small"
          >
            {{ email }}
          </span>
        </template>
        <template v-if="option.emails.contests.length > 0">
          <span
            v-for="(email, index) in option.emails.contests"
            :key="`contests-${index}`"
            class="option__small"
          >
            {{ email }}
          </span>
        </template>
        <template v-if="option.emails.newsletters.length > 0">
          <span
            v-for="(email, index) in option.emails.newsletters"
            :key="`newsletters-${index}`"
            class="option__small"
          >
            {{ email }}
          </span>
        </template>
        <template v-if="option.emails.questions.length > 0">
          <span
            v-for="(email, index) in option.emails.questions"
            :key="`questions-${index}`"
            class="option__small"
          >
            {{ email }}
          </span>
        </template>
      </div>
    </template>
    <template v-slot:noResult>
      Δε βρέθηκαν αποτελέσματα. Προσπαθήστε ξανά!!
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import _debounce from 'lodash/debounce';
import _trim from 'lodash/trim';
import request from '@/utils/request';

export default {
  inject: ['$validator'],
  components: {
    Multiselect,
  },
  props: {
    customer: Object,
    customerType: String,
  },
  data() {
    return {
      customers: [],
      isSearching: false,
    };
  },
  methods: {
    // eslint-disable-next-line func-names
    searchCustomers: _debounce(async function(searchQuery) {
      if (searchQuery.length) {
        this.isSearching = true;
        const { data } = await request.get('/customers-search', {
          params: {
            term: searchQuery,
            type: this.customerType,
          },
        });
        this.isSearching = false;

        this.customers = data.customers;
      }
    }, 300),
    selectCustomer(selectedOption) {
      this.$emit('selectCustomer', selectedOption);

      this.customers = [];
    },
    hasFullName(fullName) {
      return !!_trim(fullName);
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect {
  height: auto;
  margin: 0 auto;

  .option__desc {
    flex: 1;
  }

  .option__title {
    margin-left: 0;
    white-space: nowrap;
  }

  .option__small {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 0;
    white-space: nowrap;
  }
}
</style>
