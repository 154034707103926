<template>
  <div>
    <CustomersMenu :tabs="tabs" :active-tab="activeTab" />

    <slot />
  </div>
</template>

<script>
import CustomersMenu from './components/CustomersMenu';

export default {
  components: {
    CustomersMenu,
  },
  props: {
    tabs: Array,
    activeTab: String,
  },
};
</script>
