// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import bugsnag from 'bugsnag-js';
import bugsnagVue from 'bugsnag-vue';
import moment from 'moment';
import VueProgressBar from 'vue-progressbar';
import VeeValidate, { Validator } from 'vee-validate';
import el from 'vee-validate/dist/locale/el';
import VueNotifications from 'vue-notification';
import VueForm from 'vue-form';
import ToggleButton from 'vue-js-toggle-button';
import { Checkbox, Radio } from 'vue-checkbox-radio';
import VueLazyload from 'vue-lazyload';
import VTooltip from 'v-tooltip';
import Meta from 'vue-meta';
import VueCodemirror from 'vue-codemirror';
import vClickOutside from 'v-click-outside';
import Sticky from 'vue-sticky-directive';
import VueTextareaAutosize from 'vue-textarea-autosize';
import _capitalize from 'lodash/capitalize';
import _isEmpty from 'lodash/isEmpty';
import VueTelInput from 'vue-tel-input';
import VueObserveVisibility from 'vue-observe-visibility';

import 'codemirror/mode/javascript/javascript';
import 'codemirror/lib/codemirror.css';
/* eslint-disable */

import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'flag-icon-css/css/flag-icon.min.css';

const bugsnagClient = bugsnag(process.env.VUE_APP_BUGSNAG_API_KEY);
bugsnagClient.use(bugsnagVue(Vue));

import * as VueGoogleMaps from 'vue2-google-maps';

import App from './App';
import router from './router';
import store from './store';
import './assets/customersTab.css';

Validator.extend('requiredProducts', {
  validate: (_, { subsetsLength }) => !!subsetsLength,
});

Vue.use(Meta);
Vue.use(VTooltip);
Vue.use(VueProgressBar, {
  color: '#FFFFFF',
  failedColor: '#ff3860',
  height: '2px',
});
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'static/logo_green.png',
  loading: 'static/logo_green.png',
  attempt: 1,
});
Vue.use(VueLazyload);
Vue.use(VueForm);
Validator.localize('el', el);
Vue.use(VeeValidate, {
  locale: 'el',
});
Vue.use(VueNotifications);
Vue.use(ToggleButton);
Vue.component('checkbox', Checkbox);
Vue.component('radio', Radio);
Vue.config.productionTip = false;
Vue.use(VueCodemirror, {
  options: {
    mode: 'text/javascript',
    lineNumbers: true,
    line: true,
    viewportMargin: 5,
    tabSize: 2,
  },
});
Vue.use(vClickOutside);
Vue.use(Sticky);
Vue.use(VueTextareaAutosize);

const chars = {
  ά: 'α',
  έ: 'ε',
  ή: 'η',
  ί: 'ι',
  ϊ: 'ι',
  ΐ: 'ι',
  ύ: 'υ',
  ό: 'ο',
  ώ: 'ω',
  Ά: 'Α',
  Έ: 'Ε',
  Ή: 'Η',
  Ί: 'Ι',
  Ϊ: 'Ι',
  Ϊ́: 'Ι',
  Ύ: 'Υ',
  Ό: 'Ο',
  Ώ: 'Ω',
};

Vue.filter(
  'timestamp',
  (value, from = 'YYYY-MM-DD HH:mm:ss', to = 'DD-MM-YYYY HH:mm') =>
    value
      ? moment
          .utc(value, from)
          .local()
          .format(to)
      : null,
);

Vue.filter('capitalize', value => _capitalize(value));

Vue.filter('primaryEmail', emails => {
  if (_isEmpty(emails)) {
    return '';
  }

  if (!_isEmpty(emails.profile)) {
    return emails.profile[0];
  }

  if (!_isEmpty(emails.guests)) {
    return emails.guests[0];
  }

  if (!_isEmpty(emails.newsletters)) {
    return emails.newsletters[0];
  }

  if (!_isEmpty(emails.contests)) {
    return emails.contests[0];
  }

  if (!_isEmpty(emails.contacts)) {
    return emails.contacts[0];
  }

  if (emails.ours) {
    return emails.ours;
  }

  return null;
});

Vue.filter('capitalizeGreek', val =>
  val
    .toLowerCase()
    .split('')
    .map(char => chars[char] || char)
    .join('')
    .toUpperCase(),
);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: 'places',
    region: 'GR',
    language: 'el',
  },
});

Vue.use(VueTelInput, {
  onlyCountries: ['gr', 'cy'],
  mode: 'national',
  defaultCountry: 'gr',
  autocomplete: 'new-password',
}); // Define default global options here

Vue.use(VueObserveVisibility);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
