/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setComplaint(state, { complaint }) {
    state.data = complaint;
  },

  updateComplaint(state, { complaint }) {
    state.data = {
      ...state.data,
      ...complaint,
    };
  },
};

export default mutations;
