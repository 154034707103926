<template>
  <CustomersEdit v-bind="$props">
    <form @submit.prevent="submit">
      <div class="card-content">
        <slot />

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Όνομα</label>
              <div class="control">
                <input
                  v-model.trim="newOur.firstName"
                  class="input"
                  type="text"
                  name="firstName"
                />
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Επώνυμο</label>
              <div class="control">
                <input
                  v-model.trim="newOur.lastName"
                  class="input"
                  type="text"
                  name="lastName"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input
                  v-validate="'email'"
                  v-model.trim="newOur.email"
                  class="input"
                  type="email"
                  name="email"
                />
                <p v-show="errors.has('email:email')" class="help is-danger">
                  Εισάγετε έγκυρο email
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Φύλο</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select v-model="newOur.gender" name="gender">
                    <option :value="null">Επιλέξτε Φύλο</option>
                    <option value="male">Άνδρας</option>
                    <option value="female">Γυναίκα</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Ημ/νια Γένησης</label>
              <div class="control">
                <Datepicker
                  v-model="newOur.birthday"
                  name="birthday"
                  format="dd-MM-yyyy"
                  language="el"
                  wrapper-class="field"
                  input-class="input"
                  placeholder="πχ: 02-12-2017"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Κινητό</label>
              <div class="control">
                <vue-tel-input
                  v-model="phoneNumbers.mobile"
                  enabled-country-code
                  placeholder="Εισάγετε Κινητό"
                  name="mobile"
                  input-id="edit_customer_our_mobile"
                  @input="onInputMobile"
                />
                <p
                  v-show="errors.has('mobile') || errors.has('mobile-invalid')"
                  class="help is-danger"
                >
                  Εισάγετε έγκυρο κινητό τηλέφωνο
                </p>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Σταθερό</label>
              <div class="control">
                <vue-tel-input
                  v-model="phoneNumbers.telephone"
                  enabled-country-code
                  input-id="edit_customer_our_telephone"
                  name="telephone"
                  placeholder="Εισάγετε Σταθερό"
                  @input="onInputTelephone"
                />
                <p v-show="errors.has('telephone')" class="help is-danger">
                  Εισάγετε έγκυρο σταθερό τηλέφωνο
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>
  </CustomersEdit>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import _pick from 'lodash/pick';
import Datepicker from 'vuejs-datepicker';
import EditItem from '@/views/components/EditItem';
import CustomersEdit from '../CustomersEdit';

export default {
  name: 'CustomersEditOur',
  components: {
    CustomersEdit,
    Datepicker,
  },
  extends: EditItem,
  props: {
    customer: Object,
    tabs: Array,
    activeTab: String,
  },
  data() {
    return {
      newOur: {},
      phoneNumbers: {
        mobile: this.customer.our.mobile,
        telephone: this.customer.our.telephone,
      },
    };
  },
  computed: {
    isDeleted() {
      return this.customer.deleted_at !== null;
    },
  },
  created() {
    this.newOur = _pick(this.customer.our, [
      'firstName',
      'lastName',
      'email',
      'gender',
      'birthday',
      'telephone',
      'mobile',
    ]);
  },
  methods: {
    ...mapActions({
      updateOur: 'our/edit/updateOur',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid || this.errors.count()) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateOur({
          uuid: this.customer.our.uuid,
          our: {
            ...this.newOur,
            customer_id: this.customer.id,
            birthday: this.newOur.birthday
              ? moment(this.newOur.birthday).format('DD-MM-YYYY')
              : null,
          },
          customer_id: this.customer.uuid,
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Ο πελάτης αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.errors.clear();
      this.newOur = _pick(this.customer.our, [
        'firstName',
        'lastName',
        'email',
        'gender',
        'birthday',
        'telephone',
        'mobile',
      ]);
    },
    onInputTelephone(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'telephone',
          msg: 'Wrong telephone',
        });
      } else {
        this.errors.remove('telephone');
        this.newOur.telephone = number.international;
      }
    },
    onInputMobile(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'mobile-invalid',
          msg: 'Wrong mobile',
        });
      } else {
        this.errors.remove('mobile-invalid');
        this.newOur.mobile = number.international;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.vue-tel-input {
  border: 1px solid #dbdbdb;
  border-radius: 2px;

  &:focus-within {
    border-color: #22a684;
    box-shadow: 0 0 0 0.125em rgba(34, 166, 132, 0.25);
    outline: none;
  }

  ::v-deep .dropdown {
    padding: 6px;

    &:focus {
      outline: none;
    }
  }

  ::v-deep ul {
    border-color: #dbdbdb;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;

    li.last-preferred {
      border-bottom-color: #dbdbdb;
    }
  }

  ::v-deep input {
    font-size: 1rem;
  }
}
</style>
