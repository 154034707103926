var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs is-boxed"},[_c('ul',[_c('RouterLink',{attrs:{"to":{ name: 'products.categories.edit', params: { uuid: _vm.uuid } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_c('span',[_vm._v("Main page")])])])]}}])}),_c('RouterLink',{attrs:{"to":{ name: 'products.categories.edit.products', params: { uuid: _vm.uuid } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_c('span',[_vm._v("Products page")])])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }