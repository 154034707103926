import _get from 'lodash/get';

/* eslint-disable */
const getters = {
  getAuthor(state) {
    return {
      ...state.data,
      photoable_type: 'App\\Author',
    };
  },
  getPosts(state, { getAuthor }) {
    return _get(getAuthor, 'posts', []);
  },
  getIsLoading(state) {
    return state.isLoading;
  },
};

export default getters;
