<template>
  <LoadingContainer :is-loading="isLoadingModel || isLoadingItems">
    <div class="columns">
      <div class="column is-three-quarters">
        <form novalidate @submit.prevent="submit">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Μάρκα
                  <router-link
                    :to="{
                      name: 'products.brands.edit',
                      params: { uuid: $route.params.uuid },
                    }"
                    class="tag"
                  >
                    {{ brand.title }}
                  </router-link>
                  Επεξεργασία Μάρκας-Κατηγορίας
                  <span class="tag">
                    <strong>{{ category.title }}</strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="card-content">
              <EditGeneralDetailsBrandCategoryPage
                :general="general"
                @updateGeneral="updateGeneral"
              />
            </div>
          </div>

          <ToggleableCard title="Meta">
            <div class="card-content">
              <EditMetaDetails :meta="meta" @updateMeta="updateMeta" />
            </div>
          </ToggleableCard>

          <ToggleableCard title="Social Meta">
            <div class="card-content">
              <EditSocialMetaDetails
                :social-meta="socialMeta"
                :is-read-only="true"
                @updateSocialMeta="updateSocialMeta"
              />
            </div>
          </ToggleableCard>

          <div class="card">
            <div class="card-footer">
              <FormSubmit :is-saving="isSaving" @reset="reset" />
            </div>
          </div>
        </form>
      </div>
      <div class="column">
        <ExistingCategories :categories="brandCategories" :brand="brand" />
        <div class="card">
          <div class="card-header">
            <div class="card-header-title">
              Dynamic Variables
            </div>
          </div>
          <div class="card-content">
            <strong>Available Variables</strong>
            <br />
            <ul>
              <li>[brand_name]</li>
              <li>[category_name]</li>
              <li>[discount_percentage]</li>
              <li>[count_of_products]</li>
            </ul>

            <br />

            <strong>H1 (tag) (sample)</strong>
            <br />
            <p>
              [brand_name] [category_name]
            </p>

            <br />

            <strong>Περιεχόμενο (p tag) (sample)</strong>
            <br />
            <p>
              Βρες ανάμεσα σε [count_of_products] προϊόντα από την κατηγορία
              [brand_name] [category_name] αυτό που σου ταιριάζει στις καλύτερες
              τιμές! Κάνε τις αγορές σου εύκολα & γρήγορα κερδίζοντας πόντους σε
              κάθε σου παραγγελία με το πρόγραμμα care-δίζω και επωφελήσου από
              τα μοναδικά προνόμια.
            </p>

            <br />

            <strong>Meta Title (sample)</strong>
            <br />
            <p>
              [brand_name] [category_name] Έως -[discount_percentage]%
            </p>

            <br />

            <strong>Meta Description (sample)</strong>
            <br />
            <p>
              Βρες [brand_name] [category_name] σε προσφορές έως
              -[discount_percentage]%! Άμεση παράδοση & μοναδική εξυπηρέτηση
              μόνο στο Carespot, το φαρμακείο που σε φροντίζει.
            </p>
          </div>
        </div>
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _clone from 'lodash/clone';
import _pick from 'lodash/pick';
import AddItem from '@/views/components/AddItem';
import EditGeneralDetailsBrandCategoryPage from '@/views/components/EditGeneralDetailsBrandCategoryPage';
import EditMetaDetails from '@/views/components/EditMetaDetails';
import EditSocialMetaDetails from '@/views/components/EditSocialMetaDetails';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import ExistingCategories from '@/views/Dashboard/views/Products/views/views/Brands/components/ExistingCategories';

export default {
  components: {
    EditMetaDetails,
    EditSocialMetaDetails,
    EditGeneralDetailsBrandCategoryPage,
    ToggleableCard,
    LoadingContainer,
    ExistingCategories,
  },
  extends: AddItem,
  data() {
    return {
      meta: {},
      socialMeta: {},
      general: {},
    };
  },
  computed: {
    ...mapGetters({
      brand: 'brands/edit/getBrand',
      category: 'categories/edit/getCategory',
      model: 'brands/edit/getBrandCategory',
      isLoadingModel: 'categories/edit/getIsLoading',
      isLoadingItems: 'categories/list/getIsLoading',
      brandCategories: 'brands/all/getBrandCategoriesTree',
    }),
  },
  watch: {
    $route: 'fetchData',
    model: {
      handler(newVal, oldVal) {
        // Update info only when changing model
        if (newVal.id !== oldVal.id) {
          this.general = _pick(newVal, ['description', 'content']);
          this.meta = _clone(this.model.meta);
          this.socialMeta = _clone(this.model.social_meta);
        }
      },
      deep: true,
    },
  },
  async created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      getBrand: 'brands/edit/getBrand',
      getCategory: 'categories/edit/getCategory',
      getCategories: 'categories/list/getCategories',
      getBrandCategories: 'brands/all/getBrandCategories',
      getBrandCategory: 'brands/edit/getBrandCategory',
      updateBrandCategory: 'brands/edit/updateBrandCategory',
    }),
    async fetchData() {
      try {
        await Promise.all([
          this.getBrand({ uuid: this.$route.params.uuid }),
          this.getCategory({ uuid: this.$route.params.category }),
          this.getCategories({ uuid: this.$route.params.uuid }),
          this.getBrandCategories({ uuid: this.$route.params.uuid }),
          this.getBrandCategory({
            brand_uuid: this.$route.params.uuid,
            category_uuid: this.$route.params.category,
          }),
        ]);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'Δεν υπάρχει ο συνδυασμός Brand - Category',
        });
        this.$router.push('/error');
      }
    },
    async submit() {
      try {
        const [isValid] = await Promise.all(
          this.$children.map(child => child.$validator.validateAll()),
        );

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateBrandCategory({
          uuid: this.model.uuid,
          brandCategory: {
            ...this.general,
            brand_id: this.brand.id,
            category_id: this.category.id,
            meta: this.meta,
            socialMeta: this.socialMeta,
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text:
            'Η σελίδας της κατηγορίας του συγκεκριμένου συνδυασμού Brand-Category αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = _pick(this.model, ['description', 'content']);

      this.meta = _clone(this.model.meta);
      this.socialMeta = _clone(this.model.social_meta);

      this.$children.forEach(child => child.$validator.reset());
    },
    updateMeta(meta) {
      this.meta = meta;
    },
    updateSocialMeta(socialMeta) {
      this.socialMeta = socialMeta;
    },
    updateGeneral(general) {
      this.general = general;
    },
  },
};
</script>
