import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getTaxes({ dispatch, commit }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get('/taxes');

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setTaxes', {
        taxes: data.taxes,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async setDefaultTax({ commit, getters }, { tax }) {
    try {
      const { data } = await request.put(`/taxes/${tax.uuid}/default`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      const id = tax.id;
      const taxes = getters.getTaxes.map(tax => ({
        ...tax,
        default: tax.id === id ? 1 : 0,
      }));

      await commit('setTaxes', { taxes });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
