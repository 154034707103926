<template>
  <ExistingItems :items="items" title="Υφιστάμενα Μεγέθη">
    <template v-slot:item="props">
      <router-link
        :to="{ name: 'products.sizes.edit', params: { uuid: props.item.uuid } }"
        :disabled="props.item.id === item.id"
      >
        {{ props.item.title }}
      </router-link>
    </template>
  </ExistingItems>
</template>

<script>
import ExistingItems from '@/views/components/ExistingItems';

export default {
  components: {
    ExistingItems,
  },
  props: {
    items: Array,
    item: Object,
  },
};
</script>
