<template>
  <tr>
    <td class="has-text-centered">{{ item.contest.title }}</td>
    <td class="has-text-centered hidden-print">
      <router-link
        :to="{
          name: 'marketing.contests.edit',
          params: { uuid: item.contest.uuid },
        }"
        class="button is-small"
      >
        <span class="icon is-small"><i class="fa fa-pencil"/></span>
      </router-link>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    item: Object,
  },
};
</script>
