<template>
  <tr :class="{ 'is-selected': selected, 'hidden-print': !selected }">
    <td class="has-text-centered">
      <router-link
        :to="{ name: 'complaints.edit', params: { uuid: item.uuid } }"
      >
        {{ item.customer.fullName }}
      </router-link>
    </td>
    <td class="has-text-centered">
      <i v-if="isResolved" class="fa fa-check" aria-hidden="true" />
      <i v-else class="fa fa-times" aria-hidden="true" />
    </td>
    <td class="has-text-centered">
      {{ item.created_at | timestamp }}
    </td>
    <td class="has-text-centered hidden-print">
      <div class="buttons is-centered">
        <router-link
          :to="{ name: 'complaints.edit', params: { uuid: item.uuid } }"
          class="button is-small"
        >
          <span class="icon is-small">
            <span class="fa fa-fw fa-pencil" />
          </span>
        </router-link>
      </div>
    </td>
  </tr>
</template>

<script>
import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';

export default {
  extends: PaginatedListItem,
  computed: {
    isResolved() {
      return this.item.resolved;
    },
  },
};
</script>

<style lang="scss">
.fa-check {
  color: #22a684;
}

.fa-times {
  color: #ff3860;
}
</style>
