var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{class:[{ 'is-active': !_vm.isUnderReview && !_vm.isExpired }]},[_c('td',{staticClass:"has-text-centered"},[_c('p',{staticClass:"is-marginless"},[_vm._v(_vm._s(_vm.item.count))]),(_vm.isUnderReview && !_vm.isExpired)?_c('small',[_vm._v(" Υπό επεξεργασία ")]):_vm._e(),(_vm.isExpired)?_c('small',[_vm._v("Ανενεργοί")]):_vm._e()]),_c('td',{staticClass:"has-text-centered"},[_vm._v(_vm._s(_vm.item.description))]),_c('td',{staticClass:"has-text-centered"},[(_vm.item.pointable_type === 'App\\Product')?_c('router-link',{attrs:{"to":{
        name: 'products.edit',
        params: { uuid: _vm.item.pointable.uuid },
      }}},[_vm._v(" Προιόν ")]):_vm._e(),(_vm.item.pointable_type === 'App\\Post')?_c('router-link',{attrs:{"to":{
        name: 'content.posts.edit',
        params: { uuid: _vm.item.pointable.uuid },
      }}},[_vm._v(" Άρθρο ")]):_vm._e(),(_vm.item.pointable_type === 'App\\Order' && _vm.item.pointable !== null)?_c('router-link',{attrs:{"to":{
        name: 'orders.edit',
        params: { uuid: _vm.item.pointable.uuid },
      }}},[_vm._v(" "+_vm._s(_vm.item.pointable.common_id)+" ")]):_vm._e()],1),_c('td',{staticClass:"has-text-centered"},[_vm._v(_vm._s(_vm._f("timestamp")(_vm.item.created_at)))]),_c('td',{staticClass:"has-text-centered"},[_vm._v(_vm._s(_vm._f("timestamp")(_vm.item.expire_at)))])])}
var staticRenderFns = []

export { render, staticRenderFns }