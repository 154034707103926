<template>
  <span class="tag is-primary">
    {{ key }}<span v-html="symbol" /> {{ value }}&nbsp;<button
      class="delete is-small"
      @click="dropFilter"
    />
  </span>
</template>

<script>
import _endsWith from 'lodash/endsWith';
import _startsWith from 'lodash/startsWith';
import moment from 'moment';
import terms from '@/constants/terms';

export default {
  props: {
    filter: Object,
  },
  computed: {
    key() {
      const { key } = this.filter;

      return terms[key] || key;
    },
    value() {
      const { key, value } = this.filter;

      let newValue = value;

      if (value instanceof Object) {
        newValue = value.id;
      }

      if (_startsWith(key, 'date')) {
        newValue = moment(value).format('DD-MM-YYYY');
      }

      if (_endsWith(key, 'id')) {
        return value;
      }

      return terms[newValue] || newValue;
    },
    symbol() {
      const { key } = this.filter;
      let symbol = '&nbsp;&equiv;&nbsp;';

      if (_endsWith(key, 'start')) {
        symbol = '&nbsp;&ge;&nbsp;';
      }

      if (_endsWith(key, 'end')) {
        symbol = '&nbsp;&le;&nbsp;';
      }

      return symbol;
    },
  },
  methods: {
    dropFilter() {
      this.$emit('dropFilter', this.filter.key);
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  margin-right: 0.5rem;
}
</style>
