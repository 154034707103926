<template>
  <div v-sticky sticky-offset="offset">
    <nav class="level">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">UUID</p>
          <p class="title">{{ collection.uuid }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">STATUS</p>
          <p class="title">
            <span :class="['tag is-medium', classes]">{{ status }}</span>
          </p>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    collection: Object,
  },
  data: () => ({
    offset: { top: 45 },
  }),
  computed: {
    status() {
      const { collection } = this;
      /* eslint-disable */
      if (collection.active === true) {
        return 'Εμφανής Σειρά';
      } else if (collection.active === false) {
        return 'Μη Εμφανής Σειρά';
      } else {
        return 'Σειρά';
      }
    },
    classes() {
      const collection = this.collection;
      /* eslint-disable */
      if (collection.active === true) {
        return 'is-primary';
      } else if (collection.active === false) {
        return 'is-warning';
      } else {
        return 'is-white';
      }
    },
  },
};
</script>
