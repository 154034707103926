<template>
  <div class="columns is-multiline">
    <div class="column is-full">
      <orders-number />
    </div>
    <div class="column is-full">
      <orders-profit />
    </div>
    <div class="column is-half">
      <checkout-methods />
    </div>
    <div class="column is-half">
      <send-methods />
    </div>
  </div>
</template>

<script>
import OrdersNumber from '@/views/components/OrdersNumber';
import OrdersProfit from '@/views/components/OrdersProfit';
import CheckoutMethods from './components/CheckoutMethods';
import SendMethods from './components/SendMethods';

export default {
  components: {
    OrdersNumber,
    OrdersProfit,
    CheckoutMethods,
    SendMethods,
  },
};
</script>
