<template>
  <tr>
    <td class="has-text-centered">{{ index }}</td>
    <td>
      <router-link
        :to="{
          name: 'content.postCategories.edit',
          params: { uuid: item.uuid },
        }"
      >
        {{ item.title }}
      </router-link>
    </td>
    <td class="has-text-centered hidden-print">
      <span class="button is-small is-static">{{ item.posts.length }}</span>
    </td>
    <td class="has-text-centered hidden-print">
      <div class="buttons is-centered">
        <router-link
          :to="{
            name: 'content.postCategories.edit',
            params: { uuid: item.uuid },
          }"
          class="button is-small"
        >
          <span class="icon is-small">
            <span class="fa fa-fw fa-pencil" />
          </span>
        </router-link>
        <button class="button is-small" @click="onDelete">
          <span class="icon is-small">
            <span class="fa fa-fw fa-trash-o" />
          </span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';

export default {
  extends: PaginatedListItem,
};
</script>
