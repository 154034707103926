/* eslint-disable */
const getters = {
  getOrder(state) {
    return state.data;
  },

  getIsLoading(state) {
    return state.isLoading;
  },

  getNotes(state) {
    return state.data.notes;
  },
};

export default getters;
