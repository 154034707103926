<template>
  <tr>
    <td class="has-text-centered">{{ index + 1 }}</td>
    <td>{{ item.content }}</td>
    <td class="has-text-centered">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        disabled
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    index: Number,
    item: Object,
  },
};
</script>
