<template>
  <LoadingContainer :is-loading="hasLoadedOrders && !isLoadedOrders">
    <div class="card">
      <div
        v-for="selectedOrder in filteredItems"
        :key="selectedOrder.id"
        class="visible-print"
      >
        <OrderPrint :order="selectedOrder" />
      </div>
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Παραγγελίες Skroutz
          </div>
        </div>
      </div>

      <div class="tabs hidden-print">
        <ul>
          <li :class="['tab ', { 'is-active': isSkroutzOrderStateOpen }]">
            <a @click="selectTab('open')">
              Νέες Παραγγελίες
              <span class="tag">{{ counters.open }}</span>
            </a>
          </li>
          <li :class="['tab', { 'is-active': !isSkroutzOrderStateOpen }]">
            <a @click="selectTab('all')">
              Όλες οι Παραγγελίες
              <span v-if="isSkroutzOrderStateOpen" class="tag">{{
                counters.all
              }}</span>
              <span v-else class="tag">{{ properCounter }}</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="card-content hidden-print">
        <Filters
          :options="isSkroutzOrderStateOpen ? [options[0]] : options"
          :is-loading="isFiltering"
          :filters="paginationParams.filters"
          general-label="Αναζητήστε βάσει κωδικού"
          @navigate="navigate"
        />

        <NewPagination
          :items="items"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column is-full">
            <LoadingContainer
              :is-loading="isFiltering && isLoadedOrders"
              :is-opaque="true"
              class="table-wrapper"
            >
              <table
                v-if="items.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>Τύπος</th>
                    <th>Κωδικός</th>
                    <th>Ημ/νια</th>
                    <th>Αποδοχή έως</th>
                    <th>Πελάτης</th>
                    <th>Τεμάχια</th>
                    <th>
                      <SortableHeader
                        :sort-options="paginationParams.sort"
                        label="Αξία (&euro;)"
                        attribute="amount"
                        @sort="sort"
                      />
                    </th>
                    <th class="hidden-print">Κατάσταση</th>
                    <th class="hidden-print">Ενέργειες</th>
                  </tr>
                </thead>
                <tbody>
                  <SkroutzEventItem
                    v-for="(item, index) in items"
                    :item="item"
                    :index="indexStartsFrom + index"
                    :key="item.id"
                    :selections="selections"
                    @askToDeleteItem="askToDeleteItem"
                    @toggleSelection="toggleSelection"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν παραγγελίες
              </h4>
            </LoadingContainer>
          </div>
        </div>

        <NewPagination
          v-if="items.length"
          :items="items"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />
      </div>
    </div>

    <Confirmation
      :model-name="selectedItem.common_id"
      :is-open="isOpen"
      description="Είστε βέβαιος για την ακύρωση αυτής της παραγγελίας;"
      @closeModal="closeModal"
    />
  </LoadingContainer>
</template>

<script>
import request from '@/utils/request';
import { mapActions, mapGetters } from 'vuex';
import OrdersList from '../../components/OrdersList';
import SkroutzEventItem from '../../components/OrdersList/components/SkroutzEventItem';

export default {
  components: { SkroutzEventItem },
  extends: OrdersList,
  metaInfo: {
    title: 'Εκκρεμμείς Παραγγελίες',
  },

  data: () => ({
    counters: {},
    options: [
      {
        label: 'Γενική Αναζήτηση',
        title: 'general',
        type: 'string',
        value: null,
      },
      {
        label: 'Περιέχει προϊόν',
        title: 'product_id',
        type: 'search',
        value: null,
      },
      {
        label: 'State Παραγγελίας',
        title: 'skroutzOrderState',
        type: 'options',
        options: 'skroutzOrdersStates',
        optionsValue: 'value',
        value: null,
      },
    ],
    currentState: '',
  }),
  computed: {
    ...mapGetters({
      items: 'skroutzSmartCart/list/getData',
      paginationParams: 'skroutzSmartCart/list/getPaginationParams',
    }),

    filteredItems() {
      return this.items.filter(({ id }) => this.selections.includes(id));
    },

    properCounter() {
      return this.counters[this.$route.query.state];
    },

    isSkroutzOrderStateOpen() {
      return this.$route.query.state === 'open';
    },
  },

  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.getSkroutzOrders(newVal.query);
            const { data } = await request.get('/orders-skroutz/counters');
            this.counters = { ...data.counters };
          } else {
            // Initial loading without query params
            this.$router.replace({
              name: this.$route.name,
              query: {
                ...this.filterParams({
                  page: 1,
                  size: 50,
                  filters: {},
                  sort: {},
                  state: 'open',
                }),
              },
            });
          }
        } catch (err) {
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        } finally {
          this.isFiltering = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getSkroutzOrders: 'skroutzSmartCart/list/getSkroutzOrders',
    }),

    selectTab(state) {
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: { ...this.$route.query, state, filters: {} },
      });
    },

    async navigate(params = {}) {
      let stateValue;
      // if nav is triggered because of setting a filter
      if (params?.filters?.skroutzOrderState) {
        stateValue = params?.filters?.skroutzOrderState;
      }
      // if nav is triggered because of pagination
      else if (params.size) {
        stateValue = this.$route.query.state;
      }
      // if nav is triggered because of dropping a filter/ changing tabs
      else if (!this.isSkroutzOrderStateOpen) {
        stateValue = 'all';
      } else {
        stateValue = 'open';
      }

      try {
        this.isFiltering = true;
        this.$router
          .push({
            name: this.$route.name,
            query: {
              ...this.filterParams({
                ...this.paginationParams,
                ...params,
              }),
              state: stateValue,
            },
          })
          .catch(() => {});
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      } finally {
        this.isFiltering = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tabs {
  margin-bottom: 0;
}

.tag {
  margin-left: 10px;
}

.tab {
  position: relative;

  &.is-main {
    background-color: whitesmoke;
    font-weight: 700;
  }
}
</style>
