<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Λόγοι Διαγραφής Παραγγελίας
        </div>
      </div>
      <div class="card-header-icon">
        <div class="field">
          <div class="control">
            <router-link
              :to="{ name: 'settings.cancelOrderReasons.add' }"
              class="button"
            >
              <span class="icon is-small"><i class="fa fa-plus"/></span
              ><span>Προσθήκη</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="table-wrapper">
        <table class="table is-bordered is-striped is-marginless is-fullwidth">
          <thead>
            <tr>
              <th>#</th>
              <th>Τίτλος</th>
              <th>Ενέργειες</th>
            </tr>
          </thead>
          <tbody>
            <cancel-order-reason
              v-for="reason in cancelOrderReasons"
              :reason="reason"
              :key="reason.id"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CancelOrderReason from './components/CancelOrderReason';

export default {
  components: {
    CancelOrderReason,
  },
  data() {
    return {
      isSaving: false,
    };
  },
  computed: {
    ...mapGetters({
      cancelOrderReasons: 'cancelOrderReasons/list/getCancelOrderReasons',
    }),
  },
  async mounted() {
    await this.getCancelOrderReasons();
  },
  methods: {
    ...mapActions({
      getCancelOrderReasons: 'cancelOrderReasons/list/getCancelOrderReasons',
    }),
  },
};
</script>
