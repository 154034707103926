<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Στοιχεία Τιμολόγησης
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Τύπος Παραστατικού</label>
              <div class="control">
                {{ order.details.order.pricing_type }}
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Ονοματεπώνυμο</label>
              <div class="control">
                {{ pricingDetails.name }}
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                {{ pricingDetails.email }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: Object,
  },

  computed: {
    pricingDetails() {
      return this.order.details.pricing_details;
    },
  },
};
</script>
