import moment from 'moment';
import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getOrder({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/orders/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setOrder', {
        order: {
          ...data.order,
        },
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async cancelOrder(
    { dispatch, commit, rootGetters },
    { cancel_reason_id, cancel_description, inform_client },
  ) {
    try {
      const { data } = await request.delete(
        `/orders/${rootGetters.getOrder.uuid}`,
        {
          data: {
            cancel_reason_id,
            cancel_description,
            inform_client,
            user_id: rootGetters.getUser.id,
          },
        },
      );

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      // Get the updated customer info
      const response2 = await request.get(
        `/customers/${rootGetters.getOrder.customer.uuid}`,
      );

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      // Set the updated customer info
      await commit('updateOrderCustomer', {
        customer: response2.data.customer,
      });

      // Get the updated order statuses
      const response3 = await request.get(
        `/orders/${rootGetters.getOrder.id}/statuses`,
      );

      // Set the updated cancel_order_reason
      const cancel_order_reason = rootGetters[
        'cancelOrderReasons/list/getCancelOrderReasons'
      ].find(c => cancel_reason_id === c.id);

      await commit('setOrder', {
        order: {
          ...rootGetters.getOrder,
          cancel_order_reason,
          cancel_reason_id,
          cancel_description,
          statuses: response3.data.statuses,
          deleted_at: moment.now(),
        },
      });
    } catch (err) {
      throw err;
    }
  },

  async activateOrder({ dispatch, commit }, { order }) {
    try {
      const { data } = await request.put(`/orders/${order.uuid}/restore`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/customers/${order.customer.uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateOrderCustomer', {
        customer: response2.data.customer,
      });

      const response3 = await request.get(`/orders/${order.id}/statuses`);

      await commit('setOrder', {
        order: {
          ...order,
          statuses: response3.data.statuses,
          deleted_at: null,
        },
      });
    } catch (err) {
      throw err;
    }
  },

  async updateOrderMethods(
    { dispatch, commit },
    {
      order: {
        uuid,
        send_method_id,
        checkout_method_id,
        order_method_id,
        customer_notes_courier,
        customer_notes,
        checkout_charge,
        send_charge,
      },
    },
  ) {
    try {
      const response1 = await request.put(`/orders/${uuid}/methods`, {
        send_method_id,
        checkout_method_id,
        order_method_id,
        customer_notes_courier,
        customer_notes,
        checkout_charge,
        send_charge,
      });

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const { data } = await request.get(`/orders/${uuid}`);

      await commit('setOrder', {
        order: data.order,
      });
    } catch (err) {
      throw err;
    }
  },

  async detachOrderInvoice({ dispatch, commit }, { invoice: { uuid } }) {
    try {
      const { data } = await request.delete(`/invoices/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateOrder', {
        order: {
          invoice: null,
        },
      });
    } catch (err) {
      throw err;
    }
  },

  async attachOrderInvoice({ dispatch, commit }, { order: { uuid }, invoice }) {
    try {
      const { data } = await request.post(`/orders/${uuid}/invoices`, invoice);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateOrder', {
        order: {
          invoice: data.invoice,
        },
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
