import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getRegistrationMethods({ commit }) {
    try {
      const { data } = await request.get('/registrationMethods');

      await commit('setRegistrationMethods', {
        registrationMethods: data.registrationMethods,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
