<template>
  <div class="hidden-print">
    <slot name="generalSearch" />

    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <div class="select">
            <select
              :disabled="!items.length"
              v-model.number="selectedItemsPerPage"
            >
              <option
                v-for="(item, index) in itemsPerPageOptions"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
        </div>

        <div v-if="!showFilterOptions" class="level-item">
          <div
            v-if="hasFiltersOptions && hasPaginationFilters"
            class="field is-marginless"
          >
            <div class="control">
              <button
                :class="[
                  'button is-outlined is-primary',
                  { 'is-loading': isLoading },
                ]"
                @click="showFilterOptions = !showFilterOptions"
              >
                <span>Προβολή Φίλτρων</span>
                <span class="icon is-small"><i class="fa fa-search"/></span>
              </button>
            </div>
          </div>
        </div>

        <div v-if="hasFilters" class="level-item">
          <div class="field is-marginless">
            <div class="control">
              <button
                class="button is-outlined is-danger"
                @click="clearFilters"
              >
                <span>Διαγραφή Φίλτρων</span>
                <span class="icon is-small"><i class="fa fa-close"/></span>
              </button>
            </div>
          </div>
        </div>

        <div v-if="!showUpdateOptions" class="level-item">
          <div v-if="hasUpdatesOptions" class="field is-marginless">
            <div class="control">
              <button
                :class="[
                  'button is-outlined is-primary',
                  { 'is-loading': isUpdating },
                ]"
                @click="showUpdateOptions = !showUpdateOptions"
              >
                <span>Μαζική Ενημέρωση</span>
                <span class="icon is-small"><i class="fa fa-save"/></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-show="items.length" class="level-right">
        <nav class="pagination is-centered">
          <a
            :disabled="currentPage === 1"
            class="pagination-previous"
            @click="stepDown"
          >
            <span class="icon is-small">
              <i class="fa fa-angle-double-left" />
            </span>
          </a>
          <a
            :disabled="currentPage === pagesCount"
            class="pagination-next"
            @click="stepUp"
          >
            <span class="icon is-small">
              <i class="fa fa-angle-double-right" />
            </span>
          </a>
          <ul class="pagination-list">
            <pagination-link
              v-for="(page, index) in curatedPages"
              :page="page"
              :current-page="currentPage"
              :pages-count="pagesCount"
              :key="index"
              @navigate="navigate"
            />
          </ul>
        </nav>
      </div>
    </div>

    <article v-show="showUpdateOptions">
      <div class="message">
        <div class="message-header">
          <p>Επιλογές Μαζικής Ανανέωσης Στοιχείων</p>
          <button class="delete" @click="showUpdateOptions = false" />
        </div>
        <div class="message-body">
          <slot name="updateOptions" />
        </div>
      </div>
    </article>

    <div v-show="showFilterOptions">
      <article class="message is-primary">
        <div class="message-header">
          <p>Φίλτρα</p>
          <button class="delete" @click="showFilterOptions = false" />
        </div>
        <div class="message-body">
          <slot />
        </div>
      </article>
    </div>
    <div v-if="hasFilters" :style="{ marginBottom: '20px' }">
      <filterItem
        v-for="key in Object.keys(filters)"
        :key="key"
        :filter="{ key, value: filters[key] }"
        @dropFilter="dropFilter"
      />
    </div>
  </div>
</template>

<script>
import PaginationLink from './components/PaginationLink';
import FilterItem from './components/FilterItem';

export default {
  components: {
    PaginationLink,
    FilterItem,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    total: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pagesCount: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 0,
    },
    filtersOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    updatesOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    updates: {
      type: Object,
      default() {
        return {};
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItemsPerPage: this.itemsPerPage,
      showFilterOptions: false,
      showUpdateOptions: false,
      itemsPerPageOptions: [50, 100, 200, 400, 800],
    };
  },
  computed: {
    curatedPages() {
      const pages = [];
      const options = [
        1,
        this.currentPage - 1,
        this.currentPage,
        this.currentPage + 1,
        this.pagesCount,
      ];
      // eslint-disable-next-line
      for (let i = 1, limit = this.pagesCount; i <= limit; i++) {
        // eslint-disable-next-line
        if (options.includes(i) && !pages.includes(i)) {
          pages.push(i);
        } else {
          pages.push(0);
        }
      }
      return pages.reduce(
        (acc, item) =>
          item === 0 && acc[acc.length - 1] === 0 ? acc : acc.concat(item),
        [],
      );
    },
    hasPaginationFilters() {
      return this.$slots.default || this.$slots.generalSearch;
    },
    hasFilters() {
      return Object.keys(this.filters).length > 0;
    },
    hasFiltersOptions() {
      return this.showFilters && Object.keys(this.filtersOptions).length > 0;
    },
    hasUpdates() {
      return Object.keys(this.updates).length > 0;
    },
    hasUpdatesOptions() {
      return Object.keys(this.updatesOptions).length > 0;
    },
  },
  watch: {
    selectedItemsPerPage(newVal, oldVal) {
      // We check if it was set already
      if (oldVal) {
        this.navigate(1);
      }
    },
  },
  created() {
    this.selectedItemsPerPage = this.itemsPerPage;
  },
  methods: {
    stepUp() {
      if (this.currentPage !== this.pagesCount) {
        this.navigate(this.currentPage + 1);
      }
    },
    stepDown() {
      if (this.currentPage !== 1) {
        this.navigate(this.currentPage - 1);
      }
    },
    navigate(page) {
      this.$emit('navigate', {
        page,
        size: this.selectedItemsPerPage,
        filters: this.filters,
      });
    },
    clearFilters() {
      this.$emit('clearFilters');
    },
    dropFilter(key) {
      this.$emit('dropFilter', key);
    },
  },
};
</script>

<style scoped>
.message {
  margin-bottom: 1rem;
}
</style>
