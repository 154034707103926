<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Πληροφορίες
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Μέθοδος Πληρωμής</label>
              <div class="control">
                {{ order.checkout_method.title }}
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label">Μέθοδος Αποστολής</label>
              <div class="control">
                {{ order.details.shipping_details.type }}
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label">Μέθοδος Παραγγελίας</label>
              <div class="control">------</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: Object,
  },
};
</script>
