import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getBanners({ dispatch, commit, getters }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const { data } = await request.get('/banners', {
        params: { paginated: 0 },
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.banners);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      console.log(err);
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },
};

export default actions;
