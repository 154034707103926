<template>
  <LoadingContainer :is-loading="isLoadingBundles && !isLoaded">
    <div class="card">
      <bundlesHeader
        :total="paginationParams.count"
        :selections="selections"
        :has-selections="hasSelections"
        :can-delete-items="false"
        title="Απενεργοποιημένα Bundles"
        @navigate="navigate"
      />
      <div class="card-content">
        <Filters
          :options="options"
          :is-loading="isFiltering"
          :filters="paginationParams.filters"
          general-label="Αναζήτηση με χρήση barcode, common id, τίτλου"
          @navigate="navigate"
        />

        <NewPagination
          :items="items"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column is-full">
            <LoadingContainer
              :is-loading="isFiltering && isLoaded"
              :is-opaque="true"
              class="table-wrapper"
            >
              <table
                v-if="items.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th class="hidden-print">
                      <checkbox
                        v-model="selectedAll"
                        name="selected"
                        @input="selectAll"
                      />
                    </th>
                    <th>Προιόντα</th>
                    <th>Έκπτωση (&#x25;)</th>
                    <th>Ημ/νία Λήξης</th>
                    <th class="hidden-print">Ενέργειες</th>
                  </tr>
                </thead>
                <tbody>
                  <bundle
                    v-for="(item, index) in items"
                    :item="item"
                    :index="paginationParams.indexStartsFrom + index"
                    :key="item.id"
                    :selections="selections"
                    @askToDeleteItem="askToDeleteItem"
                    @toggleSelection="toggleSelection"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν απενεργοποιημένα bundles
              </h4>
            </LoadingContainer>
          </div>
        </div>

        <NewPagination
          v-if="items.length"
          :items="items"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />
      </div>
    </div>

    <confirmation
      :is-open="isOpen"
      description="Είστε βέβαιος για τη διαγραφή αυτού του bundle;"
      @closeModal="closeModal"
    />
  </LoadingContainer>
</template>

<script>
import { mapActions } from 'vuex';
import BundlesList from '../../components/BundlesList';

export default {
  extends: BundlesList,
  metaInfo: {
    title: 'Διαγραμμένα Bundles',
  },
  data: () => ({
    options: [
      {
        label: 'Γενική Αναζήτηση',
        title: 'general',
        type: 'string',
        value: null,
      },
    ],
  }),
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.getDeletedBundles(newVal.query);
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router.replace({
              name: this.$route.name,
              query: this.filterParams({
                page: 1,
                size: 50,
                filters: {},
                sort: {},
              }),
            });
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getDeletedBundles: 'bundles/list/getDeletedBundles',
    }),
    async navigate(params = {}) {
      try {
        this.isFiltering = true;
        this.$router.push({
          name: this.$route.name,
          query: this.filterParams({ ...this.paginationParams, ...params }),
        });
        this.isFiltering = false;
      } catch (err) {
        this.isFiltering = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
