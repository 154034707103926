<template>
  <tr
    :class="{
      'is-selected': selected,
      'hidden-print': !selected,
      'is-paid': isPaid && !isDeleted,
    }"
  >
    <td class="has-text-centered hidden-print">
      <checkbox v-model="selected" name="selected" />
    </td>
    <td class="has-text-centered hidden-print">
      <UserType :item="item" relationship="orderable_type" />
    </td>
    <td class="has-text-centered">
      <router-link :to="{ name: 'orders.edit', params: { uuid: item.uuid } }">
        {{ item.common_id }}
      </router-link>
    </td>
    <td class="has-text-centered">
      {{ item.created_at | timestamp }}
    </td>
    <td class="has-text-centered">
      <div>
        <router-link
          :to="{
            name: 'customers.edit',
            params: { uuid: item.orderable.customer.uuid },
          }"
        >
          {{ item.orderable.customer.fullName }}
        </router-link>
      </div>
      <div>
        <small>{{ item.orderable.customer.email }}</small>
      </div>
    </td>
    <td class="has-text-centered hidden-print">
      <img
        v-tooltip="{ content: checkoutMethodTitle }"
        v-if="checkoutMethodTitle"
        :src="item.checkout_method.icon"
        class="method-icon"
      />
    </td>
    <td class="has-text-centered visible-print">
      {{ checkoutMethodTitle }}
    </td>
    <td class="has-text-centered hidden-print">
      <img
        v-tooltip="{ content: sendMethodTitle }"
        v-if="sendMethodTitle"
        :src="item.send_method.icon"
        class="method-icon"
      />
    </td>
    <td class="has-text-centered visible-print">
      {{ sendMethodTitle }}
    </td>
    <td class="has-text-centered hidden-print">
      <img
        v-tooltip="{ content: orderMethodTitle }"
        v-if="orderMethodTitle"
        :src="item.order_method.icon"
        class="method-icon"
      />
    </td>
    <td class="has-text-centered visible-print">
      {{ orderMethodTitle }}
    </td>
    <td class="has-text-centered">{{ item.count }}</td>
    <td class="has-text-centered">{{ item.sum_total }}</td>
    <td class="has-text-centered hidden-print">
      <div class="status">
        <span
          v-tooltip="{ content: item.cancel_reason }"
          v-if="isDeleted"
          :class="classes"
        >
          Ακυρωμένη
        </span>
        <span v-else-if="isUnfinished" :class="classes">Ανολοκλήρωτη</span>
        <span v-else-if="isNew" :class="classes">Νέα</span>
        <span v-else :class="classes">{{ status.title }}</span>
        <span v-if="item.is_printed" class="icon icon--print">
          <span class="fa fa-fw fa-print" />
        </span>
      </div>
    </td>
    <td class="has-text-centered hidden-print">
      <router-link
        :to="{ name: 'orders.edit', params: { uuid: item.uuid } }"
        class="button is-small"
      >
        <span class="icon is-small">
          <span class="fa fa-fw fa-pencil" />
        </span>
      </router-link>
    </td>
  </tr>
</template>

<script>
import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';
import UserType from '@/views/components/UserType';
import OrderMixin from '@/mixins/Order';

export default {
  name: 'Order',
  components: {
    UserType,
  },
  extends: PaginatedListItem,
  mixins: [OrderMixin],
};
</script>

<style scoped lang="scss">
.tag {
  width: 100%;

  &.is-white {
    border: 1px solid #dbdbdb;
  }
}

tr {
  td:first-child {
    border-left: 3px solid #d4d4d4 !important;
  }

  &.is-paid {
    td:first-child {
      border-left: 3px solid #22a684 !important;
    }
  }
}

.status {
  position: relative;
}

.icon--print {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
  font-size: 0.85rem;
  position: absolute;
  right: -8px;
  top: -10px;
}
</style>
