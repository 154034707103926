<template>
  <CustomersEdit v-bind="$props">
    <div class="card-content">
      <slot />

      <div class="columns">
        <div class="column is-half">
          <div class="field">
            <label class="label">Όνομα</label>
            <div class="control">
              <input
                :value="question.firstName"
                disabled
                class="input"
                type="text"
                name="firstName"
              />
            </div>
          </div>
        </div>

        <div class="column is-half">
          <div class="field">
            <label class="label">Επώνυμο</label>
            <div class="control">
              <input
                :value="question.lastName"
                disabled
                class="input"
                type="text"
                name="lastName"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-half">
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input
                :value="question.email"
                disabled
                class="input"
                type="email"
                name="email"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-full">
          <div class="field">
            <label class="label">
              Ερώτηση&nbsp;
              <template v-if="question.content">
                <span v-if="question.active === true" class="tag is-success">
                  Δημοσιευμένη
                </span>
                <span v-else class="tag is-danger">Μη Δημοσιευμένη</span>
              </template>
            </label>
            <div class="control">
              <textarea
                :value="question.content"
                disabled
                class="textarea"
                name="content"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="question.content" class="columns">
        <div class="column is-full">
          <div class="field">
            <label class="label">Απάντηση</label>
            <div class="control">
              <textarea
                :value="question.answer"
                disabled
                class="textarea"
                name="content"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </CustomersEdit>
</template>

<script>
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import EditItem from '@/views/components/EditItem';
import CustomersEdit from '../CustomersEdit';

export default {
  name: 'CustomersEditQuestion',
  components: {
    CustomersEdit,
  },
  extends: EditItem,
  props: {
    customer: Object,
    tabs: Array,
    activeTab: String,
  },
  data() {
    return {
      question: {},
    };
  },
  computed: {
    isDeleted() {
      return this.customer.deleted_at !== null;
    },
  },
  created() {
    this.question = _pick(this.customer.question, [
      'firstName',
      'lastName',
      'email',
      'title',
      'content',
      'active',
    ]);
    this.question.answer = _get(this.customer.question, 'answer.content', '');
  },
};
</script>
