<template>
  <tr>
    <td class="has-text-centered">{{ method.id }}</td>
    <td class="has-text-centered">
      {{ method.title }}
    </td>
    <td class="has-text-centered">
      <img
        v-tooltip="{ content: method.title }"
        :src="method.icon"
        class="checkout-method-icon"
      />
    </td>
    <td class="has-text-centered">
      {{ method.charge }}
    </td>
    <td class="has-text-centered">
      <ToggleButton
        :sync="true"
        :value="method.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        @change="onChange"
      />
    </td>
    <td class="has-text-centered">
      <router-link
        :to="{
          name: 'settings.checkoutMethods.edit',
          params: { uuid: method.uuid },
        }"
        class="button is-small"
      >
        <span class="icon is-small">
          <span class="fa fa-fw fa-pencil" />
        </span>
      </router-link>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    method: Object,
  },
  methods: {
    onChange() {
      this.$emit('updateStatus', this.method);
    },
  },
};
</script>

<style scoped>
.checkout-method-icon {
  border: 1px solid #dddddd;
  height: 30px;
  width: 30px;
}
</style>
