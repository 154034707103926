import pick from 'lodash/pick';

const getters = {
  getLoyalty(state) {
    return state.data;
  },
  getCategories(state, { getLoyalty }) {
    return pick(getLoyalty, ['categories', 'limits']);
  },
  getSettings(state, { getLoyalty }) {
    return getLoyalty.settings;
  },
};

export default getters;
