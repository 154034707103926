<template>
  <multiselect
    :options="categories"
    :searchable="true"
    :internal-search="false"
    :option-height="104"
    :loading="isSearching"
    :options-limit="50"
    :reset-after="false"
    :show-labels="false"
    :multiple="true"
    :value="category"
    track-by="id"
    label="title"
    placeholder="Επιλέξτε category..."
    select-label="Πατήστε enter για επιλογή"
    @search-change="searchCategories"
    @select="selectCategory"
    @remove="handleRemove"
  >
    <template v-slot:option="props" class="option">
      <div class="option__desc">
        <span class="option__title">
          {{ props.option.title }} ({{ props.option.type }})
        </span>
        <span v-if="props.option.type === 'low'" class="option__small">
          {{ props.option.parent.parent.title }} &raquo;
          {{ props.option.parent.title }} &raquo; {{ props.option.title }}
        </span>
        <span v-else-if="props.option.type === 'mid'" class="option__small">
          {{ props.option.parent.title }} &raquo;
          {{ props.option.title }}
        </span>
        <span v-else class="option__small">{{ props.option.title }}</span>
      </div>
    </template>
    <template v-slot:noResult>
      Δε βρέθηκαν αποτελέσματα. Προσπαθήστε ξανά!!
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import _debounce from 'lodash/debounce';
import request from '@/utils/request';

export default {
  components: {
    Multiselect,
  },
  props: {
    category: {
      type: [Object, Array],
      validator(value) {
        // Check if value is an array or an object
        return Array.isArray(value) || typeof value === 'object';
      },
    },
  },
  data() {
    return {
      categories: [],
      isSearching: false,
    };
  },
  methods: {
    // eslint-disable-next-line func-names
    searchCategories: _debounce(async function(searchQuery) {
      if (searchQuery.length) {
        this.isSearching = true;
        const { data } = await request.get('/categories-search', {
          params: {
            term: searchQuery,
          },
        });
        this.isSearching = false;

        this.categories = data.categories;
      }
    }, 300),
    selectCategory(selectedOption) {
      this.$emit('select-category', selectedOption);
    },
    handleRemove(selectedOption) {
      this.$emit('remove-category', selectedOption);
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect {
  margin: 0 auto;
}

.option {
  &__title {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }

  &__small {
    font-size: 0.8rem;
    font-weight: 300;
    margin-top: 0.5rem;
  }
}
</style>
