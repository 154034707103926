<template>
  <multiselect
    :options="options"
    :internal-search="false"
    :option-height="104"
    :options-limit="50"
    :reset-after="false"
    :show-labels="false"
    :multiple="true"
    v-model="selectedOption"
    label="title"
    @select="selectOption"
    @remove="handleRemove"
  >
    <template v-slot:options="props" class="option">
      <option :value="null">Επέλεξε</option>
      <option
        v-for="item in props"
        :key="item[option.optionsValue]"
        :value="item[option.optionsValue]"
      >
        {{ item.title }}
      </option>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  inject: ['$validator'],
  components: {
    Multiselect,
  },
  props: {
    option: Array,
    options: Array,
  },
  computed: {
    selectedOption: {
      get() {
        return this.option;
      },
      set(value) {
        return value;
      },
    },
  },
  methods: {
    selectOption(selectedOption) {
      this.$emit('select-option', selectedOption);
    },
    handleRemove(selectedOption) {
      this.$emit('remove-option', selectedOption);
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect {
  margin: 0 auto;

  .option__desc {
    margin-left: 10px;
  }
}
</style>
