<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Χρήστες <span v-if="total > 0" class="tag">{{ total }}</span>
        </div>
      </div>
      <div class="card-header-icon">
        <router-link :to="{ name: 'users.add' }" class="button">
          <span class="icon is-small"><i class="fa fa-plus"/></span
          ><span>Προσθήκη</span>
        </router-link>
      </div>
    </div>
    <div class="card-content">
      <div class="table-wrapper">
        <table
          v-if="items.length"
          class="table is-bordered is-striped is-marginless is-fullwidth"
        >
          <thead>
            <tr>
              <th>#</th>
              <th class="has-text-left">Όνομα</th>
              <th class="has-text-left">Email</th>
              <th>Δικαιώματα</th>
              <th>Ενεργός</th>
              <th>Ενέργειες</th>
            </tr>
          </thead>
          <tbody>
            <user
              v-for="(item, index) in items"
              :item="item"
              :index="index + 1"
              :key="item.id"
              @askToDeleteItem="askToDeleteItem"
              @updateStatus="updateStatus"
            />
          </tbody>
        </table>
        <h4 v-else class="title is-4 has-text-centered">Δε βρέθηκαν χρήστες</h4>
      </div>
    </div>

    <confirmation
      :model-name="selectedItem.name"
      :is-open="isOpen"
      description="Είστε βέβαιος για τη διαγραφή αυτού του administrator;"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PaginatedList from '@/views/components/PaginatedList';
import User from './components/User';

export default {
  components: {
    User,
  },
  extends: PaginatedList,
  data() {
    return {
      items: this.$store.getters.getUsers,
    };
  },
  async mounted() {
    await this.getUsers();

    this.items = this.$store.getters.getUsers;
  },
  methods: {
    ...mapActions(['getUsers', 'toggleUserStatus', 'deleteUser']),
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;

        if (response) {
          await this.deleteUser({ user: this.selectedItem });

          this.items = this.$store.getters.getUsers;

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Ο λογαριασμός χρήστη διαγράφτηκε επιτυχώς!!',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    async updateStatus(selectedItem) {
      try {
        const isActive = !selectedItem.active;

        await this.toggleUserStatus({
          user: selectedItem,
          active: isActive,
        });

        this.items = this.$store.getters.getUsers;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: isActive
            ? 'Ο λογαριασμός χρήστη ενεργοποιήθηκε επιτυχώς!!'
            : 'Ο λογαριασμός χρήστη απενεργοποιήθηκε επιτυχώς!!',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
