import state from './state';
import mutations from './mutations';
import getters from './getters';

const module = {
  state,
  mutations,
  getters,
};

export default module;
