import _get from 'lodash/get';
import { loadState } from '@/utils/localStorage';

const state = {
  user: {},
  token: '',
  ..._get(loadState(), 'session', {}),
};

export default state;
