<template>
  <multiselect
    :options="collections"
    :searchable="true"
    :internal-search="false"
    :option-height="104"
    :loading="isSearching"
    :options-limit="50"
    :value="collection"
    track-by="id"
    label="title"
    placeholder="Επιλέξτε collection με χρήση τίτλου..."
    selected-label="Επιλεγμένο"
    select-label="Πατήστε enter για επιλογή"
    deselect-label="Πατήστε enter για απο-επιλογή"
    data-vv-value-path="value"
    data-vv-name="collection"
    @search-change="searchCollections"
    @select="selectCollection"
    @remove="removeCollection"
  >
    <template v-slot:option="props" class="option">
      <Photo :photos="props.option.photos" class="option__image" />
      <div class="option__desc">
        <span class="option__title">{{ props.option.title }}</span>
      </div>
    </template>
    <template v-slot:noResult>
      Δε βρέθηκαν αποτελέσματα. Προσπαθήστε ξανά!!
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import _debounce from 'lodash/debounce';
import request from '@/utils/request';
import Photo from '@/views/components/Photo';

export default {
  inject: ['$validator'],
  components: {
    Multiselect,
    Photo,
  },
  props: {
    brand: Object,
    collection: Object,
  },
  data() {
    return {
      collections: [],
      isSearching: false,
    };
  },
  methods: {
    // eslint-disable-next-line func-names
    searchCollections: _debounce(async function(searchQuery) {
      if (searchQuery.length) {
        this.isSearching = true;
        const { data } = await request.get(
          `/brands/${this.brand.uuid}/collections-search`,
          {
            params: {
              term: searchQuery,
            },
          },
        );
        this.isSearching = false;

        this.collections = data.collections;
      }
    }, 300),
    selectCollection(selectedOption) {
      this.$emit('selectCollection', selectedOption);
    },
    removeCollection(selectedOption) {
      this.$emit('removeCollection', selectedOption);
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect {
  margin: 0 auto;

  .option__desc {
    margin-left: 10px;
  }
}
</style>
