<template>
  <tr>
    <td class="has-text-centered">{{ index }}</td>
    <td>
      <router-link
        :to="{ name: 'users.edit', params: { uuid: item.uuid } }"
        class="name"
      >
        <avatar :email="item.email" :alt="item.name" class="avatar" />
        {{ item.name }}
      </router-link>
    </td>
    <td>
      {{ item.email }}
    </td>
    <td class="has-text-centered" v-html="stars" />
    <td class="has-text-centered">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        :disabled="isAuth"
        @change="onChange"
      />
    </td>
    <td class="has-text-centered">
      <div class="buttons is-centered">
        <router-link
          :to="{ name: 'users.edit', params: { uuid: item.uuid } }"
          class="button is-small"
        >
          <span class="icon is-small">
            <i class="fa fa-fw fa-pencil" />
          </span>
        </router-link>
        <button :disabled="isAuth" class="button is-small" @click="onDelete">
          <span class="icon is-small">
            <i class="fa fa-fw fa-trash-o" />
          </span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import Avatar from '@/views/components/Avatar';

export default {
  components: {
    Avatar,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isAuth() {
      return this.$store.state.session.user.id === this.item.id;
    },
    stars() {
      const { authority } = this.item;

      if (authority === 1) {
        return '<span class="icon is-small"><i class="fa fa-star-o"></i></span> <span class="icon is-small"><i class="fa fa-star-o"></i></span> <span class="icon is-small"><i class="fa fa-star-o"></i></span>';
      }

      if (authority === 2) {
        return '<span class="icon is-small"><i class="fa fa-star-o"></i></span> <span class="icon is-small"><i class="fa fa-star-o"></i></span>';
      }

      return '<span class="icon is-small"><i class="fa fa-star-o"></i></span>';
    },
  },
  methods: {
    onDelete() {
      this.$emit('askToDeleteItem', this.item);
    },
    onChange() {
      this.$emit('updateStatus', this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
.name {
  align-items: center;
  display: inline-flex;
}

.avatar {
  border: 1px solid #22a684;
  height: 20px;
  margin-right: 10px;
  width: 20px;
}
</style>
