<template>
  <multiselect
    :multiple="true"
    :options="options"
    :searchable="true"
    :internal-search="false"
    :option-height="104"
    :loading="isSearching"
    :options-limit="50"
    :value="products"
    track-by="id"
    label="title"
    placeholder="Επιλέξτε προιόν με χρήση barcode, τίτλου..."
    select-label="Πατήστε enter για επιλογή"
    deselect-label="Πατήστε enter για απο-επιλογή"
    class="multiple"
    data-vv-value-path="value"
    data-vv-name="symptom"
    @search-change="searchProducts"
    @select="selectProduct"
    @remove="removeProduct"
  >
    <template v-slot:option="props" class="option">
      <Photo :photos="props.option.photos" class="option__image" />
      <div class="option__desc">
        <span class="option__title">{{ props.option.title }}</span>
        <span class="option__small">{{ props.option.barcode }}</span>
      </div>
    </template>
    <template v-slot:noResult>
      <span>Δε βρέθηκαν αποτελέσματα. Προσπαθήστε ξανά!!</span>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import _debounce from 'lodash/debounce';
import request from '@/utils/request';
import Photo from '@/views/components/Photo';

export default {
  inject: ['$validator'],
  components: {
    Multiselect,
    Photo,
  },
  props: {
    products: Array,
  },
  data() {
    return {
      options: [],
      isSearching: false,
    };
  },
  methods: {
    // eslint-disable-next-line func-names
    searchProducts: _debounce(async function(searchQuery) {
      if (searchQuery.length) {
        this.isSearching = true;
        const { data } = await request.get('/products-search', {
          params: {
            term: searchQuery,
          },
        });
        this.isSearching = false;

        this.options = data.products;
      }
    }, 300),
    selectProduct(selectedOption) {
      this.$emit('selectProduct', selectedOption);
    },
    removeProduct(selectedOption) {
      this.$emit('removeProduct', selectedOption);
    },
  },
};
</script>
