<template>
  <div :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background" @click="closeModal" />
    <LoadingContainer :is-loading="isLoading">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Μass Updates</p>
          <button type="button" class="delete" @click="closeModal" />
        </header>
        <form @submit.prevent="submit">
          <section class="modal-card-body">
            <div class="columns is-multiline">
              <div class="column is-one-third">
                <div class="field">
                  <label class="label">Εμφανές</label>
                  <div class="control">
                    <div class="select is-fullwidth">
                      <select v-model="general.active" name="active">
                        <option value="">Επέλεξε</option>
                        <option :value="false">Όχι</option>
                        <option :value="true">Ναί</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column is-one-third">
                <label class="label">Έκπτωση</label>
                <div class="field has-addons">
                  <div class="control is-expanded">
                    <input
                      v-model="general.discount"
                      class="input"
                      type="number"
                      name="discount"
                      min="0"
                      max="100"
                      step="0.01"
                    />
                  </div>
                  <div class="control">
                    <span class="button is-static">
                      &#x25;
                    </span>
                  </div>
                </div>
              </div>

              <div class="column is-one-third">
                <div class="field">
                  <label class="label">Μόνιμα Διαθέσιμο</label>
                  <div class="control">
                    <div class="select  is-fullwidth">
                      <select
                        v-model="general.available_forever"
                        name="available_forever"
                      >
                        <option value="">Επέλεξε</option>
                        <option :value="false">Όχι</option>
                        <option :value="true">Ναί</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column is-full">
                <label class="label">Periodic Discounts</label>
                <div class="control">
                  <div class="select  is-fullwidth">
                    <select
                      v-model="general.periodic_discount"
                      name="periodic_discount"
                    >
                      <option value="null">Επέλεξε Periodic Discount</option>
                      <option
                        v-for="item in periodicDiscounts"
                        :key="item.id"
                        :value="item.id"
                        :disabled="item.active === false"
                      >
                        {{ item.title }} - {{ item.discount }}%
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="column is-full">
                <div class="field">
                  <label class="label">Συμπτώματα</label>
                  <multiselect
                    v-model="general.symptoms"
                    :options="symptoms"
                    :multiple="true"
                    name="symptoms"
                    class="multiple"
                    track-by="id"
                    label="title"
                    placeholder="Επιλέξτε symptoms"
                    selected-label="Επιλεγμένο"
                    select-label="Πατήστε enter για επιλογή"
                    deselect-label="Πατήστε enter για απο-επιλογή"
                  />
                </div>
              </div>

              <div class="column is-full">
                <div class="field">
                  <label class="label">Tags</label>
                  <div class="control">
                    <multiselect
                      v-model="general.tags"
                      :options="tags"
                      :multiple="true"
                      name="tags"
                      class="multiple"
                      track-by="id"
                      label="title"
                      placeholder="Επιλέξτε tags"
                      selected-label="Επιλεγμένο"
                      select-label="Πατήστε enter για επιλογή"
                      deselect-label="Πατήστε enter για απο-επιλογή"
                    />
                  </div>
                </div>
              </div>

              <div class="column is-full">
                <div class="field">
                  <label class="label">Marketing Pages</label>
                  <div class="control">
                    <multiselect
                      v-model="general.promos"
                      :options="promos"
                      :multiple="true"
                      name="promos"
                      class="multiple"
                      track-by="id"
                      label="title"
                      placeholder="Επιλέξτε Marketing Pages"
                      selected-label="Επιλεγμένο"
                      select-label="Πατήστε enter για επιλογή"
                      deselect-label="Πατήστε enter για απο-επιλογή"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <FormSubmit :is-saving="isUpdating" @reset="reset" />
          </footer>
        </form>
      </div>
    </LoadingContainer>
  </div>
</template>
<script>
import { isEmpty, isArray, isBoolean } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import FormSubmit from '@/views/components/FormSubmit';
import LoadingContainer from '@/views/components/LoadingContainer';
import request from '@/utils/request';

export default {
  components: {
    Multiselect,
    FormSubmit,
    LoadingContainer,
  },
  props: {
    isOpen: Boolean,
    isUpdating: Boolean,
  },
  data() {
    return {
      general: {
        active: '',
        discount: '',
        periodic_discount: null,
        tags: [],
        symptoms: [],
        promos: [],
        categories: [],
        available_forever: '',
      },
      isLoading: false,
      periodicDiscounts: [],
    };
  },
  computed: {
    ...mapGetters({
      tags: 'tags/all/getTags',
      symptoms: 'symptoms/all/getSymptoms',
      promos: 'promos/all/getPromos',
    }),
  },
  watch: {
    isOpen(newVal, oldVal) {
      // Reset the modal after closing the modal
      if (newVal !== oldVal && newVal === false) {
        this.reset();
      }
    },
  },
  async mounted() {
    try {
      this.isLoading = true;
      const [, , , periodicDiscounts] = await Promise.all([
        this.getTags(),
        this.getSymptoms(),
        this.getPromos(),
        request.get('/periodic-discounts'),
      ]);
      this.periodicDiscounts = periodicDiscounts.data.periodicDiscounts;

      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;
      console.log(err);
    }
  },
  methods: {
    ...mapActions({
      getTags: 'tags/all/getTags',
      getSymptoms: 'symptoms/all/getSymptoms',
      getPromos: 'promos/all/getPromos',
    }),
    closeModal() {
      this.$emit('closeModal');
    },
    async submit() {
      const updates = Object.entries(this.general).reduce(
        (acc, [key, value]) => {
          if (!isEmpty(value) || isBoolean(value)) {
            if (isArray(value)) {
              return { ...acc, [key]: value.map(({ id }) => id) };
            }

            return { ...acc, [key]: value };
          }

          return acc;
        },
        {},
      );

      await this.$emit('update', updates);
    },
    reset() {
      this.errors.clear();
      this.general = {
        active: '',
        discount: '',
        tags: [],
        symptoms: [],
        promos: [],
        categories: [],
        available_forever: '',
        periodic_discount: null,
      };
    },
  },
};
</script>
