import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getSendCharges({ commit }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const { data } = await request.get('/sendCharges');

      await commit('setSendCharges', { sendCharges: data.sendCharges });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async updateSendCharges({ commit }, { sendCharges }) {
    try {
      const { data } = await request.put('/sendCharges', {
        sendCharges,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setSendCharges', { sendCharges });
    } catch (err) {
      console.log(err);
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },
};

export default actions;
