<!-- eslint-disable -->
<template>
  <div class="columns is-multiline">
    <div class="column is-full">
      <div class="field">
        <label class="label">
          H1 (tag) *
          <span
            v-if="
              general.description_for_brand_products_page &&
                general.description_for_brand_products_page.length
            "
            class="tag"
            >{{ general.description_for_brand_products_page.length }}</span
          >
          &nbsp;<small>(μέγιστο όριο: 290 χαρακτήρες)</small>
        </label>
        <div class="control">
          <textarea
            v-validate="'required|max:290'"
            v-model.trim="general.description_for_brand_products_page"
            class="textarea"
            name="description_for_brand_products_page"
            @input="updateGeneral"
          />
          <p
            v-show="errors.has('description_for_brand_products_page')"
            class="help is-danger"
          >
            Εισάγετε τίτλο για το H1 tag.
          </p>
        </div>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <!-- eslint-disable -->
        <label class="label"
          >Περιεχόμενο (Η παράγραφος {p tag} που εμφανίζεται κάτω από την τον
          τίτλο {h1 tag})*</label
        >
        <div class="control">
          <editor
            :content="general.content_for_brand_products_page"
            @changeContent="changeContent"
          />
          <p
            v-show="errors.has('content_for_brand_products_page')"
            class="help is-danger"
          >
            Εισάγετε περιεχόμενο. Θα εμφανιστεί στη σελίδα κάτω από το H1.
          </p>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import Editor from '@/views/components/Editor';

export default {
  inject: ['$validator'],

  components: {
    Editor,
  },

  props: {
    general: Object,

    canActivate: {
      type: Boolean,
      default: true,
    },

    orders: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },

    showTitleEn: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },

  computed: {
    hasTitleEn() {
      return this.general.title_en && this.general.title_en.length;
    },
  },

  methods: {
    updateGeneral(e) {
      const { name, value } = e.target;
      this.$emit('updateGeneral', {
        ...this.general,
        [name]: value,
      });
    },

    changeContent(content) {
      this.general.content_for_brand_products_page = content;
      this.$emit('updateGeneral', {
        ...this.general,
      });
    },
  },
};
</script>

<style scoped>
.flag-icon-gb {
  margin-right: 5px;
}
</style>
