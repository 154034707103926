/* eslint-disable */
const getters = {
  getOrderMethods(state) {
    return state.data;
  },

  getOrderMethodsToEditOrder(state) {
    return state.data.filter(({ slug }) => !['skroutz', 'best_price'].includes(slug));
  },
};

export default getters;
