import { render, staticRenderFns } from "./Order.vue?vue&type=template&id=d507f994&scoped=true"
import script from "./Order.vue?vue&type=script&lang=js"
export * from "./Order.vue?vue&type=script&lang=js"
import style0 from "./Order.vue?vue&type=style&index=0&id=d507f994&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d507f994",
  null
  
)

export default component.exports