/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setFilterGroup(state, { filterGroup }) {
    state.data = filterGroup;
  },

  updateFilterGroup(state, { filterGroup }) {
    state.data = {
      ...state.data,
      ...filterGroup,
    };
  },
};

export default mutations;
