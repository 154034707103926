/* eslint-disable */
const mutations = {
  updateIsLoadingEmailTemplates(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setEmailTemplates(state, { emailTemplates }) {
    state.data = emailTemplates;
  },
};

export default mutations;
