<template>
  <CustomersEdit v-bind="$props">
    <form @submit.prevent="submit">
      <div class="card-content">
        <slot />

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Όνομα *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  v-model.trim="newProfile.firstName"
                  class="input"
                  type="text"
                  name="firstName"
                />
                <p v-show="errors.has('firstName')" class="help is-danger">
                  Εισάγετε όνομα
                </p>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Επώνυμο *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  v-model.trim="newProfile.lastName"
                  class="input"
                  type="text"
                  name="lastName"
                />
                <p v-show="errors.has('lastName')" class="help is-danger">
                  Εισάγετε επώνυμο
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-one-third">
            <div class="field">
              <label class="label">Email *</label>
              <div class="control">
                <input
                  v-validate="'required|email'"
                  v-model.trim="newProfile.email"
                  class="input"
                  type="email"
                  name="email"
                />
                <p v-show="errors.has('email:required')" class="help is-danger">
                  Εισάγετε email
                </p>
                <p v-show="errors.has('email:email')" class="help is-danger">
                  Εισάγετε έγκυρο email
                </p>
              </div>
            </div>
          </div>

          <div class="column is-one-third">
            <div class="field">
              <label class="label">Google Email</label>
              <div class="control">
                <input
                  v-validate="'email'"
                  v-model.trim="newProfile.google_email"
                  class="input"
                  type="email"
                  name="google_email"
                />
                <p
                  v-show="errors.has('google_email:email')"
                  class="help is-danger"
                >
                  Εισάγετε έγκυρο email
                </p>
              </div>
            </div>
          </div>

          <div class="column is-one-third">
            <div class="field">
              <label class="label">Facebook Email</label>
              <div class="control">
                <input
                  v-validate="'email'"
                  v-model.trim="newProfile.facebook_email"
                  class="input"
                  type="email"
                  name="facebook_email"
                />
                <p
                  v-show="errors.has('facebook_email:email')"
                  class="help is-danger"
                >
                  Εισάγετε έγκυρο email
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Φύλο</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select v-model="newProfile.gender" name="gender">
                    <option :value="null">Επιλέξτε Φύλο</option>
                    <option value="male">Άνδρας</option>
                    <option value="female">Γυναίκα</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Ημ/νια Γένησης</label>
              <div class="control">
                <Datepicker
                  v-model="newProfile.birthday"
                  name="birthday"
                  format="dd-MM-yyyy"
                  language="el"
                  wrapper-class="field"
                  input-class="input"
                  placeholder="πχ: 02-12-2017"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Κινητό</label>
              <div class="control">
                <vue-tel-input
                  v-model="phoneNumbers.mobile"
                  enabled-country-code
                  placeholder="Εισάγετε Κινητό"
                  name="mobile"
                  input-id="edit_customer_mobile"
                  @input="onInputMobile"
                />
                <p
                  v-show="errors.has('mobile') || errors.has('mobile-invalid')"
                  class="help is-danger"
                >
                  Εισάγετε έγκυρο κινητό τηλέφωνο
                </p>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">Σταθερό</label>
              <div class="control">
                <vue-tel-input
                  v-model="phoneNumbers.telephone"
                  enabled-country-code
                  input-id="edit_customer_telephone"
                  name="telephone"
                  placeholder="Εισάγετε Σταθερό"
                  @input="onInputTelephone"
                />
                <p v-show="errors.has('telephone')" class="help is-danger">
                  Εισάγετε έγκυρο σταθερό τηλέφωνο
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>
  </CustomersEdit>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import _pick from 'lodash/pick';
import Datepicker from 'vuejs-datepicker';
import EditItem from '@/views/components/EditItem';
import CustomersEdit from '../CustomersEdit';

export default {
  name: 'CustomersEditProfile',
  components: {
    CustomersEdit,
    Datepicker,
  },
  extends: EditItem,
  props: {
    customer: Object,
    tabs: Array,
    activeTab: String,
  },

  data() {
    return {
      newProfile: {},
      phoneNumbers: {
        mobile: '',
        telephone: '',
      },
    };
  },

  computed: {
    isDeleted() {
      return this.customer.deleted_at !== null;
    },
  },

  watch: {
    newProfile: {
      handler(newVal) {
        this.phoneNumbers = {
          ...this.phoneNumbers,
          mobile: newVal.mobile || '',
          telephone: newVal.telephone || '',
        };
      },
      deep: true,
    },
  },

  created() {
    this.newProfile = this.customer.profile
      ? _pick(this.customer.profile, [
          'firstName',
          'lastName',
          'email',
          'facebook_email',
          'google_email',
          'gender',
          'birthday',
          'telephone',
          'mobile',
        ])
      : {
          firstName: null,
          lastName: null,
          email: null,
          facebook_email: null,
          google_email: null,
          gender: null,
          birthday: null,
          telephone: null,
          mobile: null,
        };
  },

  methods: {
    ...mapActions({
      updateOrCreateProfile: 'profiles/edit/updateOrCreateProfile',
    }),

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid || this.errors.count()) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateOrCreateProfile({
          profile: {
            ...this.newProfile,
            customer_id: this.customer.id,
            birthday: this.newProfile.birthday
              ? moment(this.newProfile.birthday).format('DD-MM-YYYY')
              : null,
          },
          customer_id: this.customer.uuid,
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Ο πελάτης αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.errors.clear();
      this.newProfile = _pick(this.customer.profile, [
        'firstName',
        'lastName',
        'email',
        'facebook_email',
        'google_email',
        'gender',
        'birthday',
        'telephone',
        'mobile',
      ]);
    },

    onInputTelephone(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'telephone',
          msg: 'Wrong telephone',
        });
      } else {
        this.newProfile.telephone = number.international;
        this.errors.remove('telephone');
      }
    },

    onInputMobile(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'mobile-invalid',
          msg: 'Wrong mobile',
        });
      } else {
        this.newProfile.mobile = number.international;
        this.errors.remove('mobile-invalid');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.vue-tel-input {
  border: 1px solid #dbdbdb;
  border-radius: 2px;

  &:focus-within {
    border-color: #22a684;
    box-shadow: 0 0 0 0.125em rgba(34, 166, 132, 0.25);
    outline: none;
  }

  ::v-deep .dropdown {
    padding: 6px;

    &:focus {
      outline: none;
    }
  }

  ::v-deep ul {
    border-color: #dbdbdb;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;

    li.last-preferred {
      border-bottom-color: #dbdbdb;
    }
  }

  ::v-deep input {
    font-size: 1rem;
  }
}
</style>
