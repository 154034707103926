import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getBrand({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/brands/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setBrand', {
        brand: data.brand,
        orders: data.orders,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getBrandPhotos({ dispatch, commit }, { uuid }) {
    try {
      const { data } = await request.get(`/brands/${uuid}/photos`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateBrandPhotos', {
        photos: data.photos,
      });
    } catch (err) {
      throw err;
    }
  },

  async updateBrand({ dispatch, commit }, { uuid, brand }) {
    try {
      const response1 = await request.put(`/brands/${uuid}`, brand);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/brands/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateBrand', {
        brand: response2.data.brand,
      });
    } catch (err) {
      throw err;
    }
  },
  async getBrandCategory({ commit }, { brand_uuid, category_uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(
        `/brands/${brand_uuid}/categories/${category_uuid}`,
      );

      if (data.error) {
        throw Error('Server-side validation failed');
      }
      await commit('setBrandCategory', {
        brandCategory: data.brand_category,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });
      throw err;
    }
  },

  async updateBrandCategory({ dispatch, commit }, { uuid, brandCategory }) {
    try {
      const response1 = await request.put(
        `/brand-category/${uuid}`,
        brandCategory,
      );

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/brand-category/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateBrandCategory', {
        brandCategory: response2.data.brandCategory,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
