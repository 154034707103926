<template>
  <tr v-if="isEditing">
    <td class="has-text-centered">
      <div class="select">
        <select :value="charge.operator" name="operator" @change="updateItem">
          <option
            v-for="(operator, index) in operators"
            :key="index"
            :value="operator.value"
          >
            {{ operator.label }}
          </option>
        </select>
      </div>
    </td>
    <td class="has-text-centered">
      <div class="field has-addons">
        <div class="control">
          <input
            :value="charge.limit"
            type="number"
            min="0"
            step="0.01"
            class="input has-text-centered"
            name="limit"
            @change="updateItem"
          />
        </div>
        <div class="control">
          <a class="button is-static">
            Kg
          </a>
        </div>
      </div>
    </td>
    <td class="has-text-centered">
      <div class="level">
        <div class="field has-addons level-item">
          <div class="control">
            <input
              :value="charge.charge"
              type="number"
              min="0"
              step="0.01"
              class="input has-text-centered"
              name="charge"
              @change="updateItem"
            />
          </div>
          <div class="control">
            <a class="button is-static">
              &euro;
            </a>
          </div>
        </div>
        <span class="level-item intermediate">ανά</span>
        <div class="field has-addons level-item">
          <div class="control">
            <input
              :value="charge.amount"
              type="number"
              min="0"
              step="0.01"
              class="input has-text-centered"
              name="amount"
              @change="updateItem"
            />
          </div>
          <div class="control">
            <a class="button is-static">
              Kg
            </a>
          </div>
        </div>
      </div>
    </td>
    <td v-if="isEditing" class="has-text-centered">
      <button type="button" class="button is-small" @click="deleteItem">
        <span class="icon is-small">
          <span class="fa fa-fw fa-trash-o" />
        </span>
      </button>
    </td>
  </tr>
  <tr v-else>
    <td class="has-text-centered">{{ operatorLabel }}</td>
    <td class="has-text-centered">{{ charge.limit }} Kg</td>
    <td class="has-text-centered">
      {{ charge.charge }} &euro; ανά {{ charge.amount }} Kg
    </td>
  </tr>
</template>

<script>
import OPERATORS from '@/constants/operators';

export default {
  props: {
    charge: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    operators: OPERATORS,
  }),
  computed: {
    operatorLabel() {
      const operator = this.operators.find(
        ({ value }) => value === this.charge.operator,
      );

      return operator.label;
    },
  },
  methods: {
    updateItem(e) {
      const { name } = e.target;

      let value = '';

      if (name === 'operator') {
        // eslint-disable-next-line prefer-destructuring
        value = e.target.value;
      } else {
        value = Number(e.target.value);
      }

      this.$emit('updateItem', {
        ...this.charge,
        [name]: value,
      });
    },
    deleteItem() {
      this.$emit('deleteItem', this.charge);
    },
  },
};
</script>

<style lang="scss" scoped>
.field.has-addons {
  justify-content: center;
}

.intermediate {
  margin: 0 10px !important;
}

.level {
  align-items: center;

  .level-item {
    margin: 0;
  }
}
</style>
