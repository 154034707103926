import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getCategory({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/categories/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setCategory', {
        category: data.category,
        orders: data.orders,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getCategoryPhotos({ dispatch, commit }, { uuid }) {
    try {
      const { data } = await request.get(`/categories/${uuid}/photos`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateCategoryPhotos', {
        photos: data.photos,
      });
    } catch (err) {
      throw err;
    }
  },

  async updateCategory({ dispatch, commit }, { uuid, category }) {
    try {
      const response1 = await request.put(`/categories/${uuid}`, category);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/categories/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateCategory', {
        category: response2.data.category,
      });

      await commit(
        'categories/list/updateCategoriesTree',
        {
          tree: response1.data.tree,
        },
        {
          root: true,
        },
      );
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
