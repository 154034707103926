<template>
  <v-jstree
    :data="items"
    :show-checkbox="true"
    :multiple="true"
    @item-click="itemClick"
  />
</template>

<script>
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import { mapGetters } from 'vuex';
import VJstree from 'vue-jstree';

export default {
  components: {
    VJstree,
  },
  props: {
    categories: Array,
  },
  data: () => ({
    items: [],
  }),
  computed: {
    ...mapGetters({
      categoriesTree: 'categories/list/getCategoriesTree',
      categoriesReverseTree: 'categories/list/getCategoriesReverseTree',
      categoriesPerType: 'categories/list/getCategoriesPerType',
    }),
  },
  watch: {
    categories: {
      handler(newVal) {
        this.setItems(newVal);
      },
      deep: true,
    },
  },
  created() {
    this.setItems(this.categories);
  },
  methods: {
    setItems(categories) {
      const uuids = categories.map(({ uuid }) => uuid);
      const productCategories = !_isEmpty(this.categoriesReverseTree)
        ? uuids.reduce(
            (acc, uuid) => {
              if (_has(this.categoriesPerType.low, uuid)) {
                return {
                  ...acc,
                  low: [...acc.low, uuid],
                  mid: [...acc.mid, this.categoriesReverseTree[uuid].mid],
                  top: [...acc.top, this.categoriesReverseTree[uuid].top],
                };
              }

              if (_has(this.categoriesPerType.mid, uuid)) {
                return {
                  ...acc,
                  mid: [...acc.mid, uuid],
                  top: [
                    ...acc.top,
                    this.categoriesTree.find(top =>
                      top.children.map(mid => mid.id).includes(uuid),
                    ).id,
                  ],
                };
              }

              return {
                ...acc,
                top: [...acc.top, uuid],
              };
            },
            {
              low: [],
              mid: [],
              top: [],
            },
          )
        : {
            low: [],
            mid: [],
            top: [],
          };

      this.items = this.categoriesTree.map(c1 => {
        const selected1 = productCategories.top.includes(c1.id);

        return {
          ...c1,
          selected: selected1,
          disabled: true,
          opened: selected1,
          children: c1.children.map(c2 => {
            const selected2 = productCategories.mid.includes(c2.id);

            return {
              ...c2,
              selected: selected2,
              disabled: !!c2.children.length,
              opened: selected2,
              children: c2.children.map(c3 => {
                const selected3 = productCategories.low.includes(c3.id);

                return {
                  ...c3,
                  selected: selected3,
                };
              }),
            };
          }),
        };
      });
    },
    itemClick({ model }) {
      if (model.selected) {
        this.$emit('updateCategories', {
          categories: [
            ...this.categories,
            {
              uuid: model.id,
            },
          ],
        });
      } else {
        this.$emit('updateCategories', {
          categories: this.categories.filter(({ uuid }) => uuid !== model.id),
        });
      }
    },
  },
};
</script>
