import all from './all';
import add from './add';
import edit from './edit';
import list from './list';

const module = {
  namespaced: true,
  modules: {
    add,
    edit,
    list,
    all,
  },
};

export default module;
