<template>
  <div>
    <div v-if="isLoading && !isOpaque" class="has-text-centered">
      <vue-loading
        :size="{ width: '60px', height: '60px' }"
        type="spin"
        color="#22A684"
      />
    </div>
    <div
      v-else-if="isLoading && isOpaque"
      :class="[
        'has-text-centered is-opaque',
        { 'is-vertically-aligned': isVerticallyAligned },
      ]"
    >
      <slot />
      <div class="is-overlay backdrop" />
      <vue-loading
        :size="{ width: '60px', height: '60px' }"
        type="spin"
        color="#22A684"
      />
    </div>
    <template v-else>
      <slot />
    </template>
  </div>
</template>

<script>
import VueLoading from 'vue-loading-template';

export default {
  inject: ['$validator'],
  components: {
    VueLoading,
  },
  props: {
    isLoading: false,
    isOpaque: false,
    isVerticallyAligned: false,
  },
};
</script>

<style scoped lang="scss">
.is-opaque {
  position: relative;

  .vue-loading {
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  .backdrop {
    background-color: rgba(255, 255, 255, 0.5);
  }

  &.is-vertically-aligned {
    .vue-loading {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
