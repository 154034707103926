/* eslint-disable */
const getters = {
  getCategoriesTree(state) {
    return state.data.tree;
  },
  getCategoriesReverseTree(state) {
    return state.data.reverseTree;
  },
  getCategoriesPerType(state) {
    return state.data.perType;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
};

export default getters;
