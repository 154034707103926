<!-- eslint-disable -->
<template>
  <div class="columns is-multiline">
    <div class="column is-full">
      <div class="field">
        <label class="label">
          Open Graph Title *
          <span
            v-if="hasContent(socialMeta.og_title_for_category_products_page)"
            class="tag"
            >{{ socialMeta.og_title_for_category_products_page.length }}</span
          >
        </label>
        <div class="control">
          <textarea
            v-validate="'required'"
            :value="socialMeta.og_title_for_category_products_page"
            :disabled="!isSuper"
            class="textarea"
            name="og_title_for_category_products_page"
            readonly
          />
        </div>
        <p
          v-show="errors.has('og_title_for_category_products_page')"
          class="help is-danger"
        >
          Εισάγετε Open Graph Title
        </p>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <label class="label">
          Open Graph Description *
          <span
            v-if="
              hasContent(socialMeta.og_description_for_category_products_page)
            "
            class="tag"
            >{{
              socialMeta.og_description_for_category_products_page.length
            }}</span
          >
          &nbsp;<small>(μέγιστο όριο: 290 χαρακτήρες)</small>
        </label>
        <div class="control">
          <textarea
            v-validate="'required|max:290'"
            :value="socialMeta.og_description_for_category_products_page"
            :disabled="!isSuper"
            class="textarea"
            name="og_description_for_category_products_page"
            readonly
          />
        </div>
        <p
          v-show="
            errors.has('og_description_for_category_products_page:required')
          "
          class="help is-danger"
        >
          Εισάγετε έγκυρο Open Graph Description
        </p>
        <p
          v-show="errors.has('og_description_for_category_products_page:max')"
          class="help is-danger"
        >
          Εισάγετε μέχρι 290 χαρακτήρες
        </p>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <label class="label">Open Graph Type *</label>
        <div class="control">
          <textarea
            v-validate="'required'"
            :value="socialMeta.og_type"
            :disabled="!isSuper"
            class="textarea"
            name="og_type"
            @input="updateSocialMeta"
          />
        </div>
        <p v-show="errors.has('og_type')" class="help is-danger">
          Εισάγετε Open Graph Type
        </p>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <label class="label">Open Graph URL *</label>
        <div class="control">
          <textarea
            v-validate="'required'"
            :value="socialMeta.og_url_for_category_products_page"
            :disabled="!isSuper"
            class="textarea"
            name="og_url_for_category_products_page"
            @input="updateSocialMeta"
          />
        </div>
        <p
          v-show="errors.has('og_url_for_category_products_page')"
          class="help is-danger"
        >
          Εισάγετε Open Graph URL
        </p>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <label class="label">Open Graph Image *</label>
        <div class="control">
          <textarea
            v-validate="'required'"
            :value="socialMeta.new_og_image"
            :disabled="!isSuper"
            class="textarea"
            name="new_og_image"
            @input="updateSocialMeta"
          />
        </div>
        <p v-show="errors.has('new_og_image')" class="help is-danger">
          Εισάγετε Open Graph Image
        </p>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <label class="label">
          Twitter Title *
          <span
            v-if="
              hasContent(socialMeta.twitter_title_for_category_products_page)
            "
            class="tag"
            >{{
              socialMeta.twitter_title_for_category_products_page.length
            }}</span
          >
        </label>
        <div class="control">
          <textarea
            v-validate="'required'"
            :value="socialMeta.twitter_title_for_category_products_page"
            :disabled="!isSuper"
            class="textarea"
            name="twitter_title_for_category_products_page"
            readonly
          />
        </div>
        <p
          v-show="errors.has('twitter_title_for_category_products_page')"
          class="help is-danger"
        >
          Εισάγετε Twitter Title
        </p>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <label class="label">
          Twitter Description *
          <span
            v-if="
              hasContent(
                socialMeta.twitter_description_for_category_products_page,
              )
            "
            class="tag"
            >{{
              socialMeta.twitter_description_for_category_products_page.length
            }}</span
          >
          &nbsp;<small>(μέγιστο όριο: 290 χαρακτήρες)</small>
        </label>
        <div class="control">
          <textarea
            v-validate="'required|max:290'"
            :value="socialMeta.twitter_description_for_category_products_page"
            :disabled="!isSuper"
            class="textarea"
            name="twitter_description_for_category_products_page"
            readonly
          />
        </div>
        <p
          v-show="
            errors.has(
              'twitter_description_for_category_products_page:required',
            )
          "
          class="help is-danger"
        >
          Εισάγετε έγκυρο Twitter Description
        </p>
        <p
          v-show="
            errors.has('twitter_description_for_category_products_page:max')
          "
          class="help is-danger"
        >
          Εισάγετε μέχρι 290 χαρακτήρες
        </p>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <label class="label">Twitter Image *</label>
        <div class="control">
          <textarea
            v-validate="'required'"
            :value="socialMeta.new_twitter_image"
            :disabled="!isSuper"
            class="textarea"
            name="new_twitter_image"
            @input="updateSocialMeta"
          />
        </div>
        <p v-show="errors.has('new_twitter_image')" class="help is-danger">
          Εισάγετε Twitter Image
        </p>
      </div>
    </div>

    <div class="column is-full">
      <div class="field">
        <label class="label">Twitter Card *</label>
        <div class="control">
          <div class="select select is-fullwidth">
            <select
              v-model.number="socialMeta.twitter_card"
              :disabled="!isSuper"
              name="twitter_card"
              @input="updateSocialMeta"
            >
              <option
                v-for="(option, index) in TWITTER_CARD_OPTIONS"
                :key="index"
                :option="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TWITTER_CARD_OPTIONS } from '@/constants/models';

export default {
  inject: ['$validator'],
  props: {
    socialMeta: Object,
  },
  data() {
    return {
      TWITTER_CARD_OPTIONS,
    };
  },
  computed: {
    ...mapGetters({
      isSuper: 'getIsSuper',
    }),
  },
  methods: {
    updateSocialMeta(e) {
      const { name, value } = e.target;

      this.$emit('updateSocialMeta', {
        ...this.socialMeta,
        [name]: value,
      });
    },

    hasContent(text = '') {
      return text.length > 0;
    },
  },
};
</script>
