import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async addQuestion({ dispatch, commit, rootGetters }, question) {
    try {
      const { data } = await request.post('/questions', {
        ...question,
        user_id: rootGetters.getUser.id,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
