/* eslint-disable */
const mutations = {
  updateIsLoadingSettings(state, { isLoading }) {
    state.isLoading = isLoading;
  },
  setERPStatus(state, erpStatus) {
    state.erpStatus = erpStatus;
  },
};

export default mutations;
