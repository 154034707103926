<template>
  <LoadingContainer :is-loading="isLoading">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Ρωτήστε τους Ειδικούς <span class="tag">Απάντηση Ερώτησης</span>
          </div>
        </div>
        <div class="card-header-icon">
          <div class="field has-addons is-marginless">
            <div class="control">
              <router-link
                :to="{ name: 'content.questions.list' }"
                class="button"
              >
                <span class="icon is-small"><i class="fa fa-list"/></span>
                <span>Λίστα</span>
              </router-link>
            </div>
            <div class="control">
              <button class="button" @click="askToDeleteItem">
                <span class="icon is-small"><i class="fa fa-trash"/></span
                ><span>Διαγραφή</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <form @submit.prevent="submit">
        <div class="card-content">
          <div class="field">
            <label class="label">Ερώτηση *</label>
            <div class="control">
              <textarea
                v-validate="'required'"
                v-model.trim="general.content"
                class="textarea"
                type="text"
                name="content"
              />
              <p v-show="errors.has('content')" class="help is-danger">
                Εισάγετε ερώτηση
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label">Προβολή *</label>
            <div class="control">
              <div class="select select is-fullwidth">
                <select
                  v-validate="'required'"
                  v-model.trim="general.active"
                  name="active"
                >
                  <option :value="true">Ναί</option>
                  <option :value="false">Όχι</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Πελάτης *</label>
            <div class="control">
              <customers-search
                :customer="general.customer"
                @selectCustomer="selectCustomer"
              />
              <p v-show="errors.has('customer')" class="help is-danger">
                Επιλέξτε πελάτη
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label">Απάντηση *</label>
            <div class="control">
              <editor
                :content="general.answer"
                @changeContent="changeContent"
              />
              <p v-show="errors.has('answer')" class="help is-danger">
                Εισάγετε απάντηση
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label">Προτεινόμενα Προιόντα</label>
            <div class="control">
              <products-search
                :question="model"
                :products="general.products"
                @selectProduct="selectProduct"
                @removeProduct="removeProduct"
              />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </form>

      <confirmation
        :model-name="question.content"
        :is-open="isOpen"
        description="Είστε βέβαιος για τη διαγραφή αυτής της ερώτησης;"
        @closeModal="closeModal"
      />
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get, pick, omit } from 'lodash';
import request from '@/utils/request';
import EditItem from '@/views/components/EditItem';
import LoadingContainer from '@/views/components/LoadingContainer';
import Editor from '@/views/components/Editor';
import CustomersSearch from '../components/CustomersSearch';
import ProductsSearch from '../components/ProductsSearch';

export default {
  components: {
    Editor,
    CustomersSearch,
    ProductsSearch,
    LoadingContainer,
  },

  extends: EditItem,

  data() {
    return {
      question: {},
      general: {
        content: '',
        customer: '',
        active: '',
        answer: '',
      },
    };
  },

  computed: {
    ...mapGetters({
      model: 'questions/edit/getQuestion',
      isLoading: 'questions/edit/getIsLoading',
    }),
  },

  watch: {
    model: {
      handler(newVal) {
        this.general = {
          ...pick(newVal, [
            'firstName',
            'lastName',
            'email',
            'customer_id',
            'customer',
            'content',
            'active',
            'products',
            'uuid',
          ]),
          answer: get(newVal, 'answer.content', ''),
        };
      },
      deep: true,
    },
  },

  async created() {
    try {
      await this.getQuestion({ uuid: this.$route.params.uuid });
    } catch (err) {
      this.$router.push('/error');
    }
  },

  methods: {
    ...mapActions({
      getQuestion: 'questions/edit/getQuestion',
      updateQuestion: 'questions/edit/updateQuestion',
    }),

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();
        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateQuestion({
          ...omit(this.general, ['customer']),
          products: this.general.products.map(({ id }) => id),
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η απάντηση αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.general = {
        ...pick(this.model, [
          'firstName',
          'lastName',
          'email',
          'customer_id',
          'customer',
          'content',
          'active',
          'products',
          'uuid',
        ]),
        answer: get(this.model, 'answer.content', ''),
      };

      this.$validator.reset();
    },

    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;
        if (response) {
          await request.delete(`/questions/${this.question.uuid}`);
          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Η κατηγορία διαγράφτηκε',
          });
          this.$router.push({ name: 'content.questions.list' });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    selectCustomer(customer) {
      this.general = {
        ...this.general,
        customer,
        ...pick(customer, ['firstName', 'lastName']),
        customer_id: get(customer, 'id', 0),
        email: get(customer, 'emails.profile[0]', 'emails.guests[0]'),
      };
    },

    selectProduct(product) {
      this.general = {
        ...this.general,
        products: [...this.general.products, product],
      };
    },

    removeProduct(product) {
      this.general = {
        ...this.general,
        products: this.general.products.filter(({ id }) => id !== product.id),
      };
    },

    changeContent(content) {
      this.general.answer = content;
    },
  },
};
</script>
