/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setTaxes(state, { taxes }) {
    state.data = taxes;
  },
};

export default mutations;
