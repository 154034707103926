import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getQuestion({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/questions/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setQuestion', {
        question: data.question,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async updateQuestion({ dispatch, commit, rootGetters }, question) {
    try {
      const { data } = await request.put(`/questions/${question.uuid}`, {
        ...question,
        user_id: rootGetters.getUser.id,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/questions/${question.uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateQuestion', {
        question: response2.data.question,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
