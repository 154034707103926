var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"has-text-centered"},[_vm._v(_vm._s(_vm.index))]),_c('td',{staticClass:"has-text-centered"},[_c('router-link',{attrs:{"to":{
        name: 'products.filterGroups.edit',
        params: { uuid: _vm.item.uuid },
      }}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")])],1),_c('td',{staticClass:"has-text-centered"},[_c('router-link',{staticClass:"button is-small",attrs:{"to":{
        name: 'products.filterGroups.filters.list',
        params: { uuid: _vm.item.uuid },
      }}},[_vm._v(" "+_vm._s(_vm.item.filters.length)+" ")])],1),_c('td',{staticClass:"has-text-centered"},[_c('ToggleButton',{attrs:{"sync":true,"value":_vm.item.active === true,"color":{ checked: '#22A684', unchecked: '#ff3860' },"width":30,"height":15},on:{"change":_vm.onChange}})],1),_c('td',{staticClass:"has-text-centered"},[_c('router-link',{staticClass:"button is-small",attrs:{"to":{
        name: 'products.filterGroups.edit',
        params: { uuid: _vm.item.uuid },
      }}},[_c('span',{staticClass:"icon is-small"},[_c('span',{staticClass:"fa fa-fw fa-pencil"})])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }