/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setTax(state, { tax }) {
    state.data = tax;
  },

  updateTax(state, { tax }) {
    state.data = {
      ...state.data,
      ...tax,
    };
  },
};

export default mutations;
