<template>
  <tr>
    <td class="has-text-centered">{{ index + 1 }}</td>
    <td>
      <router-link
        :to="{ name: 'content.posts.edit', params: { uuid: item.uuid } }"
      >
        {{ item.title }}
      </router-link>
    </td>
    <td class="has-text-centered hidden-print">
      <div class="buttons is-centered">
        <router-link
          :to="{ name: 'content.posts.edit', params: { uuid: item.uuid } }"
          class="button is-small"
        >
          <span class="icon is-small">
            <span class="fa fa-fw fa-pencil" />
          </span>
        </router-link>
      </div>
    </td>
  </tr>
</template>

<script>
import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';

export default {
  extends: PaginatedListItem,
};
</script>
