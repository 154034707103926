<template>
  <LoadingContainer :is-loading="isLoading">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          {{ titleDescription }}
        </div>
        <div class="card-header-icon">
          <div class="field has-addons is-marginless">
            <div class="control">
              <router-link
                :to="{ name: 'marketing.contests.list' }"
                class="button"
              >
                <span class="icon is-small"><i class="fa fa-list-ul"/></span
                ><span>Λίστα</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <form @submit.prevent="submit">
        <div class="card-content">
          <AddPhotos
            :title="'Contest Image'"
            :preview="preview"
            :show-error="errors.has('image')"
            @addPhotos="handleAddPhotos"
          />

          <div class="field">
            <label class="label">Τίτλος *</label>
            <div class="control">
              <input
                v-validate="'required'"
                v-model.trim="form.title"
                class="input"
                type="text"
                name="title"
              />
            </div>
            <p v-show="errors.has('title')" class="help is-danger">
              Εισάγετε ονομασία
            </p>
          </div>

          <div class="field">
            <label class="label">Slug *</label>
            <div class="control">
              <input
                v-validate="'required|regex:^[a-z0-9]+(?:-[a-z0-9]+)*$'"
                v-model.trim="form.slug"
                class="input"
                type="text"
                name="slug"
              />
              <div v-show="errors.has('slug')" class="help is-danger">
                Εισάγετε έγκυρο slug
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Περιγραφή *</label>
            <div class="control">
              <textarea
                v-validate="'required'"
                v-model.trim="form.description"
                class="textarea"
                name="description"
              />
              <p v-show="errors.has('description')" class="help is-danger">
                Εισάγετε περιγραφή
              </p>
            </div>
          </div>

          <label class="label">
            Ημερομηνία Εκκίνησης *
          </label>
          <div class="field has-addons">
            <div class="control is-expanded">
              <Datepicker
                v-validate="'required'"
                v-model="form.starts_at"
                name="starts_at"
                format="dd-MM-yyyy"
                language="el"
                wrapper-class="field"
                input-class="input"
                placeholder="πχ: 02-12-2017"
              />
              <p v-show="errors.has('starts_at')" class="help is-danger">
                Εισάγετε ημ/νία εκκίνησης
              </p>
            </div>
          </div>

          <label class="label">
            Ημερομηνία Λήξης *
          </label>
          <div class="field has-addons">
            <div class="control is-expanded">
              <Datepicker
                v-validate="'required'"
                v-model="form.ends_at"
                name="ends_at"
                format="dd-MM-yyyy"
                language="el"
                wrapper-class="field"
                input-class="input"
                placeholder="πχ: 02-12-2017"
              />
              <p v-show="errors.has('ends_at')" class="help is-danger">
                Εισάγετε ημ/νία λήξης
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label">Όροι Διαγωνισμού *</label>
            <div class="control">
              <VueEditor
                v-validate="'required'"
                id="editor"
                v-model="form.terms"
                :editor-toolbar="toolbarOptions"
                data-vv-value-path="value"
                data-vv-name="terms"
                @input="changeContent"
              />
              <p v-show="errors.has('terms')" class="help is-danger">
                Εισάγετε Όρους
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label">Εμφανές</label>
            <div class="control">
              <ToggleButton
                :sync="true"
                :value="form.active"
                :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                :width="30"
                :height="15"
                @change="updateActive"
              />
            </div>
          </div>

          <div class="field">
            <label class="label">E-shop URL</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <input
                  ref="eshopPath"
                  :value="eshopPath"
                  name="eshopPath"
                  class="input"
                  type="text"
                  readonly
                />
              </div>
              <div class="control">
                <button
                  v-tooltip="'Copy eshop path'"
                  class="button"
                  @click.prevent="handleCopy"
                >
                  <span class="icon"><i class="fa fa-clone"/></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </form>
    </div>
  </LoadingContainer>
</template>

<script>
import moment from 'moment';
import pick from 'lodash/pick';
import { mapActions, mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { VueEditor } from 'vue2-editor';
import { isEmpty } from 'lodash';

import slug from 'slug';
import AddItem from '@/views/components/AddItem';
import LoadingContainer from '@/views/components/LoadingContainer';
import AddPhotos from '@/views/components/AddPhotos';
import toolbarOptions from '@/constants/toolbarOptions';
import request from '@/utils/request';

export default {
  components: {
    Datepicker,
    LoadingContainer,
    AddPhotos,
    VueEditor,
  },
  extends: AddItem,

  data() {
    return {
      contest: {},
      form: {
        title: '',
        slug: '',
        description: '',
        image: null,
        active: false,
        terms: '',
        starts_at: moment().toDate(),
        ends_at: null,
      },
      preview: '',
      toolbarOptions,
    };
  },

  computed: {
    ...mapGetters({
      isLoading: 'contests/edit/getIsLoading',
    }),

    eshopPath() {
      return `https://carespot.gr/contests/${this.contest.slug}`;
    },

    titleDescription() {
      /* eslint-disable */
      if (this.contest.deleted_at !== null) {
        return 'Ακυρωμένος Διαγωνισμός';
      } else if (this.contest.active === true) {
        return 'Ενεργός Διαγωνισμός';
      } else if (this.contest.active === false) {
        return 'Μη Ενεργός Διαγωνισμός';
      } else {
        return 'Διαγωνισμός';
      }
    },
  },

  watch: {
    'form.title': function(newVal) {
      this.form = {
        ...this.form,
        slug: slug(newVal.toLowerCase()),
      };
    },
  },

  async created() {
    try {
      const { data } = await request.get(
        `/contests/${this.$route.params.uuid}`,
      );

      const { contest } = data;

      this.contest = contest;
      this.form = {
        ...this.form,
        ...pick(this.contest, [
          'title',
          'slug',
          'description',
          'active',
          'terms',
          'starts_at',
          'ends_at',
        ]),
      };
      this.preview = this.contest.image_url;
      let response = await fetch(this.preview);
      let imageData = await response.blob();
      let metadata = {
        type: 'image/jpeg',
      };
      let file = new File([imageData], 'test.jpg', metadata);
      this.form.image = file;
      // ... do something with the file or return it
    } catch (err) {
      console.log(err);
      // this.$router.push('/error');
    }
  },

  methods: {
    ...mapActions({
      updateContest: 'contests/edit/updateContest',
    }),

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (isEmpty(this.preview)) {
          this.errors.add({
            field: 'image',
            msg: 'Image is required',
          });
        }

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        await this.updateContest({
          uuid: this.contest.uuid,
          contest: this.form,
        });

        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Ο διαγωνισμός αποθηκεύτηκε',
        });
      } catch (err) {
        console.log(err);
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.form = {
        ...this.form,
        ...pick(this.contest, [
          'title',
          'slug',
          'description',
          'image',
          'active',
          'terms',
          'starts_at',
          'ends_at',
        ]),
      };

      this.preview = this.contest.image_url;

      this.$validator.reset();
      this.errors.remove('image');
    },

    handleAddPhotos(image) {
      console.log('image');
      this.form.image = image;

      const reader = new FileReader();

      this.$validator
        .validateAll()
        .then(() => {
          reader.onload = e => {
            this.preview = e.target.result;
            this.errors.remove('image');
          };
          reader.readAsDataURL(image);
        })
        .catch(err => console.log(err));
    },

    updateActive({ value }) {
      this.form = {
        ...this.form,
        active: !!value,
      };
    },

    handleCopy() {
      const copyText = this.$refs.eshopPath;
      copyText.select();
      document.execCommand('copy');

      this.$notify({
        type: 'success',
        title: 'Επιτυχία',
        text: 'Το path αντιγράφτηκε στο clipboard',
      });
    },

    changeContent(terms) {
      this.contest = {
        ...this.contest,
        terms: terms === '<p><br></p>' ? '' : terms,
      };
    },
  },
};
</script>
