import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const module = {
  actions,
  state,
  mutations,
  getters,
};

export default module;
