/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setSet(state, { set }) {
    state.data = set;
  },

  updateSet(state, { set }) {
    state.data = {
      ...state.data,
      ...set,
    };
  },
};

export default mutations;
