import Vue from 'vue';

const eventBus = new Vue({
  methods: {
    showToast(toast) {
      this.$emit('showToast', toast);
    },
    validate() {
      this.$emit('validate');
    },
    clearErrors() {
      this.$emit('clear');
    },
  },
});

export default eventBus;
