<template>
  <li v-if="page !== 0">
    <a
      :class="{ 'is-current': page === currentPage }"
      class="pagination-link"
      @click="navigate"
    >
      {{ page }}
    </a>
  </li>
  <li v-else>
    <span class="pagination-ellipsis">&hellip;</span>
  </li>
</template>

<script>
export default {
  props: {
    page: Number,
    currentPage: {
      type: Number,
      default: 1,
    },
    pagesCount: Number,
  },
  methods: {
    navigate() {
      this.$emit('navigate', this.page);
    },
  },
};
</script>
