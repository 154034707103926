<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Banners <span class="tag">Προσθήκη Banner</span>
        </div>
      </div>
      <div class="card-header-icon">
        <RouterLink :to="{ name: 'content.banners.list' }" class="button">
          <span class="icon is-small"><i class="fa fa-list"/></span>
          <span>Λίστα</span>
        </RouterLink>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="card-content">
        <AddPhotos
          :title="'Banner'"
          :preview="preview"
          :show-error="errors.has('image')"
          rule="image/webp"
          @addPhotos="handleAddPhotos"
        />

        <div class="field">
          <label class="label">Τίτλος *</label>
          <div class="control">
            <input
              v-validate="'required'"
              v-model.trim="form.title"
              class="input"
              type="text"
              name="title"
            />
            <p v-show="errors.has('title')" class="help is-danger">
              Εισάγετε τίτλο
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Αγγλικός Τίτλος </label>
          <div class="control">
            <input
              v-model.trim="form.title_en"
              class="input"
              type="text"
              name="title_en"
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Link *</label>
          <div class="control">
            <input
              v-validate="'required|url'"
              v-model.trim="form.link"
              class="input"
              type="text"
              name="link"
            />
            <p v-show="errors.has('title')" class="help is-danger">
              Εισάγετε link
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Εμφανές *</label>
          <div class="control">
            <ToggleButton
              :sync="true"
              :value="form.active"
              :color="{ checked: '#22A684', unchecked: '#ff3860' }"
              :width="30"
              :height="15"
              @change="updateActive"
            />
          </div>
        </div>
      </div>

      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { isEmpty } from 'lodash';
import AddItem from '@/views/components/AddItem';
import toolbarOptions from '@/constants/toolbarOptions';
import AddPhotos from '@/views/components/AddPhotos';

export default {
  components: {
    AddPhotos,
  },

  extends: AddItem,

  data() {
    return {
      form: {
        title: '',
        title_en: '',
        link: '',
        active: false,
        image: null,
      },
      preview: '',
      isSaving: false,
      toolbarOptions,
    };
  },

  methods: {
    ...mapActions({
      addBanner: 'banners/add/addBanner',
    }),

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (isEmpty(this.preview)) {
          this.errors.add({
            field: 'image',
            msg: 'Image is required',
          });
        }

        if (!isValid || isEmpty(this.preview)) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addBanner(this.form);
        this.isSaving = false;

        this.$router.push({ name: 'content.banners.list' });
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'To banner αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.form = {
        title: '',
        title_en: '',
        link: '',
        active: false,
        image: null,
      };
      this.preview = '';
      this.$validator.reset();
      this.errors.remove('image');
    },

    updateActive({ value }) {
      this.form = {
        ...this.form,
        active: !!value,
      };
    },

    handleAddPhotos(image) {
      this.form.image = image;

      const reader = new FileReader();

      this.$validator
        .validateAll()
        .then(() => {
          reader.onload = e => {
            this.preview = e.target.result;
            this.errors.remove('image');
          };
          reader.readAsDataURL(image);
        })
        .catch(err => console.log(err));
    },
  },
};
</script>
