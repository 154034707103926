<template>
  <div class="card-header">
    <div class="card-header-title">
      <div class="title is-6 is-marginless">
        {{ title }}
        <span v-if="selections.length > 0" class="tag">
          {{ selections.length }} από {{ total }}
        </span>
        <span v-else class="tag">{{ total }}</span>
      </div>
    </div>
    <div class="card-header-icon hidden-print">
      <div class="field has-addons is-marginless">
        <div class="control">
          <router-link :to="{ name: 'orders.add' }" class="button">
            <span class="icon is-small"><i class="fa fa-plus"/></span
            ><span>Προσθήκη</span>
          </router-link>
        </div>
        <div class="control">
          <div
            :class="['dropdown is-right', { 'is-hoverable': hasSelections }]"
          >
            <div class="dropdown-trigger">
              <button
                :disabled="!hasSelections"
                class="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
              >
                <span class="icon is-small"><i class="fa fa-wrench"/></span>
                <span>Επιλογές</span>
                <p class="icon is-small">
                  <i class="fa fa-angle-down" aria-hidden="true" />
                </p>
              </button>
            </div>
            <div id="dropdown-menu" class="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <div class="dropdown-item" @click="print">
                  <span class="icon is-small"><i class="fa fa-print"/></span>
                  <span>Εκτύπωση</span>
                </div>
                <div class="dropdown-item" @click="prepareExportXLS">
                  <p class="icon is-small"><i class="fa fa-file-excel-o" /></p>
                  <span>Εξαγωγή σε XLS</span>
                </div>
                <div class="dropdown-item" @click="prepareExportCSV">
                  <p class="icon is-small"><i class="fa fa-file-text-o" /></p>
                  <span>Εξαγωγή σε CSV</span>
                </div>
                <div class="dropdown-divider" />
                <div class="dropdown-item" @click="massAddTransactions">
                  <span class="icon is-small">
                    <i
                      v-if="isMassAddingTransactions"
                      class="fa fa-spin fa-refresh"
                    />
                    <i v-else class="fa fa-money" />
                  </span>
                  <span>Μαζική Προσθήκη Πληρωμών</span>
                </div>
                <div
                  v-if="canSendOrders"
                  class="dropdown-item"
                  @click="massSendOrders"
                >
                  <span class="icon is-small">
                    <i v-if="isMassSending" class="fa fa-spin fa-refresh" />
                    <i v-else class="fa fa-truck" />
                  </span>
                  <span>Μαζική Αποστολή Παραγγελιών</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaginatedListHeader from '@/views/components/PaginatedList/components/PaginatedListHeader';

export default {
  extends: PaginatedListHeader,
  props: {
    canSendOrders: {
      type: Boolean,
      default: true,
    },
    isMassSending: {
      type: Boolean,
      default: false,
    },
    isMassRestoring: {
      type: Boolean,
      default: false,
    },
    isMassAddingTransactions: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    massAddTransactions() {
      this.$emit('massAddTransactions');
    },
    massSendOrders() {
      this.$emit('massSendOrders');
    },
    navigate() {
      this.$emit('navigate');
    },
  },
};
</script>
