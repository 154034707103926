<template>
  <LoadingContainer :is-loading="isLoading">
    <div class="columns">
      <div class="column is-three-quarters">
        <div class="card">
          <div class="card-header">
            <div class="card-header-title">
              <div class="title is-6 is-marginless">
                Πελάτης
                <span v-if="isDeleted" class="tag is-danger">Διαγραμμένος</span>
              </div>
            </div>
            <div class="card-header-icon">
              <div class="field has-addons is-marginless">
                <div class="control">
                  <router-link :to="{ name: 'customers.list' }" class="button">
                    <span class="icon is-small"><i class="fa fa-list-ul"/></span
                    ><span>Λίστα</span>
                  </router-link>
                </div>
                <div class="dropdown is-right is-hoverable">
                  <div class="dropdown-trigger">
                    <button
                      class="button"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                    >
                      <span class="icon is-small">
                        <i class="fa fa-wrench" />
                      </span>
                      <span>Επιλογές</span>
                      <span class="icon is-small">
                        <i class="fa fa-angle-down" aria-hidden="true" />
                      </span>
                    </button>
                  </div>
                  <div id="dropdown-menu" class="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                      <div class="dropdown-item" @click="startOrder">
                        <span class="icon is-small">
                          <i class="fa fa-shopping-basket" />
                        </span>
                        <span>Νέα Παραγγελία</span>
                      </div>
                      <div class="dropdown-divider" />
                      <div
                        v-if="!isDeleted"
                        class="dropdown-item"
                        @click="askToDeleteItem"
                      >
                        <span class="icon is-small">
                          <i class="fa fa-trash-o" />
                        </span>
                        <span>Ακύρωση</span>
                      </div>
                      <div
                        v-else
                        class="dropdown-item"
                        @click="restoreCustomer"
                      >
                        <span class="icon is-small">
                          <i class="fa fa-refresh" />
                        </span>
                        <span>Επαναφορά</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <component
            v-for="(component, index) in tabs"
            v-if="$route.query.tab === component.tab"
            :is="component.componentName"
            :key="index"
            :tabs="tabs"
            :active-tab="$route.query.tab"
            :customer="customer"
          />

          <confirmation
            :model-name="customer.fullName"
            :is-open="isOpen"
            description="Είστε βέβαιος για τη διαγραφή αυτού του χρήστη;"
            @closeModal="closeModal"
          />
        </div>

        <div class="card">
          <div class="card-content">
            <div class="tabs is-boxed">
              <ul>
                <router-link
                  v-slot="{ href, navigate, isActive }"
                  :to="goTo('customers.edit.orders')"
                  custom
                >
                  <li
                    :class="[isActive && 'router-link-active is-active']"
                    role="link"
                    @click="navigate"
                  >
                    <a
                      :href="href"
                      @click="navigate"
                      @keypress.enter="navigate"
                    >
                      Παραγγελίες
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-slot="{ href, navigate, isActive }"
                  :to="goTo('customers.edit.gyOrders')"
                  custom
                >
                  <li
                    :class="[isActive && 'router-link-active is-active']"
                    role="link"
                    @click="navigate"
                  >
                    <a
                      :href="href"
                      @click="navigate"
                      @keypress.enter="navigate"
                    >
                      GY Παραγγελίες
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-slot="{ href, navigate, isActive }"
                  :to="goTo('customers.edit.products')"
                  custom
                >
                  <li
                    :class="[isActive && 'router-link-active is-active']"
                    role="link"
                    @click="navigate"
                  >
                    <a
                      :href="href"
                      @click="navigate"
                      @keypress.enter="navigate"
                    >
                      Προιόντα
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-slot="{ href, navigate, isActive }"
                  :to="goTo('customers.edit.favorites')"
                  custom
                >
                  <li
                    :class="[isActive && 'router-link-active is-active']"
                    role="link"
                    @click="navigate"
                  >
                    <a
                      :href="href"
                      @click="navigate"
                      @keypress.enter="navigate"
                    >
                      Αγαπημένα
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-slot="{ href, navigate, isActive }"
                  :to="goTo('customers.edit.coupons')"
                  custom
                >
                  <li
                    :class="[isActive && 'router-link-active is-active']"
                    role="link"
                    @click="navigate"
                  >
                    <a
                      :href="href"
                      @click="navigate"
                      @keypress.enter="navigate"
                    >
                      Κουπόνια
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-slot="{ href, navigate, isActive }"
                  :to="goTo('customers.edit.loyalty')"
                  custom
                >
                  <li
                    :class="[isActive && 'router-link-active is-active']"
                    role="link"
                    @click="navigate"
                  >
                    <a
                      :href="href"
                      @click="navigate"
                      @keypress.enter="navigate"
                    >
                      Loyalty
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-slot="{ href, navigate, isActive }"
                  :to="goTo('customers.edit.incomingEmails')"
                  custom
                >
                  <li
                    :class="[isActive && 'router-link-active is-active']"
                    role="link"
                    @click="navigate"
                  >
                    <a
                      :href="href"
                      @click="navigate"
                      @keypress.enter="navigate"
                    >
                      Εξερχόμενη Επικοινωνία
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-slot="{ href, navigate, isActive }"
                  :to="goTo('customers.edit.contact')"
                  custom
                >
                  <li
                    :class="[isActive && 'router-link-active is-active']"
                    role="link"
                    @click="navigate"
                  >
                    <a
                      :href="href"
                      @click="navigate"
                      @keypress.enter="navigate"
                    >
                      Εισερχόμενη Επικοινωνία
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-slot="{ href, navigate, isActive }"
                  :to="goTo('customers.edit.questions')"
                  custom
                >
                  <li
                    :class="[isActive && 'router-link-active is-active']"
                    role="link"
                    @click="navigate"
                  >
                    <a
                      :href="href"
                      @click="navigate"
                      @keypress.enter="navigate"
                    >
                      Ερωτήσεις
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-slot="{ href, navigate, isActive }"
                  :to="goTo('customers.edit.addresses')"
                  custom
                >
                  <li
                    :class="[isActive && 'router-link-active is-active']"
                    role="link"
                    @click="navigate"
                  >
                    <a
                      :href="href"
                      @click="navigate"
                      @keypress.enter="navigate"
                    >
                      Διευθύνσεις
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-slot="{ href, navigate, isActive }"
                  :to="goTo('customers.edit.invoices')"
                  custom
                >
                  <li
                    :class="[isActive && 'router-link-active is-active']"
                    role="link"
                    @click="navigate"
                  >
                    <a
                      :href="href"
                      @click="navigate"
                      @keypress.enter="navigate"
                    >
                      Τιμολόγια
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-slot="{ href, navigate, isActive }"
                  :to="goTo('customers.edit.contests')"
                  custom
                >
                  <li
                    :class="[isActive && 'router-link-active is-active']"
                    role="link"
                    @click="navigate"
                  >
                    <a
                      :href="href"
                      @click="navigate"
                      @keypress.enter="navigate"
                    >
                      Διαγωνισμοί
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-slot="{ href, navigate, isActive }"
                  :to="goTo('customers.edit.reviews')"
                  custom
                >
                  <li
                    :class="[isActive && 'router-link-active is-active']"
                    role="link"
                    @click="navigate"
                  >
                    <a
                      :href="href"
                      @click="navigate"
                      @keypress.enter="navigate"
                    >
                      Σχόλια
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>

            <div class="content">
              <!-- This is a bad practice -->
              <router-view :customer="customer" />
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <CustomerInfo :customer="customer" />

        <SendEmailToCustomer :customer="customer" />

        <CustomerContactViberSms :customer="customer" />

        <CustomerContact :customer="customer" />

        <AdminCustomerNotes :customer="customer" />
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import request from '@/utils/request';
import EditItem from '@/views/components/EditItem';
import CustomerInfo from '@/views/components/CustomerInfo';
import AdminCustomerNotes from '@/views/components/AdminCustomerNotes';
import LoadingContainer from '@/views/components/LoadingContainer';
import CustomersEditCustomer from './components/CustomersEditCustomer';
import CustomersEditProfile from './components/CustomersEditProfile';
import CustomersEditPigeon from './components/CustomersEditPigeon';
import CustomersEditGuestCheckouts from './components/CustomersEditGuestCheckouts';
import CustomersEditContact from './components/CustomersEditContact';
import CustomersEditNewsletter from './components/CustomersEditNewsletter';
import CustomersEditQuestion from './components/CustomersEditQuestion';
import CustomersEditContest from './components/CustomersEditContest';
import CustomersEditOur from './components/CustomersEditOur';
import SendEmailToCustomer from './components/SendEmailToCustomer';
import CustomerContact from './components/CustomerContact';
import CustomerContactViberSms from './components/CustomerContactViberSms';

const TABS = [
  {
    tab: 'customer',
    tabLabel: 'Customer',
    componentName: 'CustomersEditCustomer',
    hasData: false,
  },
  {
    tab: 'profile',
    tabLabel: 'Profile',
    componentName: 'CustomersEditProfile',
    hasData: false,
  },
  {
    tab: 'guests',
    tabLabel: 'Guests',
    componentName: 'CustomersEditGuestCheckouts',
    hasData: false,
  },
  {
    tab: 'newsletter',
    tabLabel: 'Newsletter',
    componentName: 'CustomersEditNewsletter',
    hasData: false,
  },
  {
    tab: 'contest',
    tabLabel: 'Contest',
    componentName: 'CustomersEditContest',
    hasData: false,
  },
  {
    tab: 'contact',
    tabLabel: 'Contact',
    componentName: 'CustomersEditContact',
    hasData: false,
  },
  {
    tab: 'question',
    tabLabel: 'Question',
    componentName: 'CustomersEditQuestion',
    hasData: false,
  },
  {
    tab: 'ours',
    tabLabel: 'Ours',
    componentName: 'CustomersEditOur',
    hasData: false,
  },
  {
    tab: 'pigeon',
    tabLabel: 'Pigeon',
    componentName: 'CustomersEditPigeon',
    hasData: false,
  },
];

export default {
  components: {
    AdminCustomerNotes,
    CustomerInfo,
    LoadingContainer,
    SendEmailToCustomer,
    CustomerContact,
    CustomersEditCustomer,
    CustomersEditProfile,
    CustomersEditPigeon,
    CustomersEditGuestCheckouts,
    CustomersEditContact,
    CustomersEditNewsletter,
    CustomersEditQuestion,
    CustomersEditContest,
    CustomersEditOur,
    CustomerContactViberSms,
  },
  extends: EditItem,
  data() {
    return {
      tabs: TABS,
    };
  },
  computed: {
    ...mapGetters({
      customer: 'customers/edit/getCustomer',
      isLoading: 'customers/edit/getIsLoading',
    }),
    isDeleted() {
      return this.customer.deleted_at !== null;
    },
  },
  watch: {
    customer: {
      handler(newVal) {
        const hasData = {
          profile: newVal.profile !== null,
          guests: !_isEmpty(newVal.guests),
          contact: newVal.contact !== null,
          contest: !_isEmpty(newVal.participations),
          newsletter: newVal.newsletter !== null,
          question: newVal.question !== null,
          pigeon: newVal.pigeon !== null,
          ours: Object.values(
            _pick(newVal.our, [
              'firstName',
              'lastName',
              'email',
              'birthday',
              'gender',
              'mobile',
              'telephone',
            ]),
          ).some(value => value !== null),
        };

        this.tabs = Object.values(this.tabs).reduce(
          (acc, value) => [
            ...acc,
            { ...value, hasData: hasData[value.tab] || false },
          ],
          [],
        );
      },
    },
  },
  async created() {
    try {
      await this.getCustomer({ uuid: this.$route.params.uuid });

      if (!this.$route.query.tab) {
        this.$router.replace({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            ...this.$route.query,
            tab: 'customer',
          },
        });
      }
    } catch (err) {
      this.$router.push('/error');
    }
  },
  methods: {
    ...mapActions({
      getCustomer: 'customers/edit/getCustomer',
      activateCustomer: 'customers/edit/activateCustomer',
    }),
    ...mapMutations(['startNewOrderForACustomer']),
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;
        if (response) {
          await request.delete(`/customers/${this.customer.uuid}`);
          this.$router.push('/customers');
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    startOrder() {
      this.startNewOrderForACustomer({ customer: this.customer });

      this.$router.push({ name: 'orders.add' });
    },
    async restoreCustomer() {
      try {
        await this.activateCustomer({ customer: this.customer });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Ο πελάτης αποκαταστάθηκε επιτυχώς',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    goTo(name) {
      return {
        name,
        params: {
          uuid: this.$route.params.uuid,
        },
        query: {
          ...this.$route.query,
          tab: this.$route.query.tab,
        },
      };
    },
  },
};
</script>

<style lsng="scss" scoped>
.content {
  overflow: auto;
}
</style>
