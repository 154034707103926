<template>
  <stats-card :has-options="true" title="Αριθμός Παραγγελιών" @update="update">
    <template v-slot:graph v-if="chartData">
      <line-graph :chart-data="chartData" :options="options" :height="400" />
    </template>
  </stats-card>
</template>

<script>
import moment from 'moment';
import request from '@/utils/request';
import StatsCard from '@/views/components/StatsCard';
import LineGraph from '@/views/components/LineGraph';

export default {
  components: {
    StatsCard,
    LineGraph,
  },
  data() {
    return {
      chartData: null,
      options: {
        elements: {
          line: {
            tension: 0,
          },
        },
        tooltips: {
          callbacks: {
            title(tooltipItem) {
              return moment(tooltipItem[0].label).format('DD-MM-YYYY');
            },
            label(tooltipItem, data) {
              return `${data.datasets[0].data[tooltipItem.index]} παραγγελίες`;
            },
          },
        },
        animation: false,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                offsetGridLines: true,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
                callback(value) {
                  if (value % 1 === 0) {
                    return value;
                  }

                  return null;
                },
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    async update(options) {
      const { data } = await request.get('/orders-stats/dates', {
        params: {
          ...options,
        },
      });

      this.chartData = {
        datasets: [
          {
            data: data.data,
            backgroundColor: '#f0f0f0',
            borderColor: ['#22A684'],
            pointRadius: 3,
            pointBackgroundColor: '#22A684',
          },
        ],
        labels: data.labels,
      };
    },
  },
};
</script>
