<template>
  <div class="tabs hidden-print">
    <ul>
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :class="[
          'tab',
          { 'is-active': activeTab === tab.tab },
          { 'is-main': tab.tab === 'customer' },
        ]"
      >
        <a @click="selectTab(tab.tab)">{{ tab.tabLabel }}</a>
        <span v-if="tab.hasData" class="dot" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    tabs: Array,
    activeTab: String,
  },
  methods: {
    selectTab(tab) {
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: { tab },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.tabs {
  margin-bottom: 0;
}

.tab {
  position: relative;

  &.is-main {
    background-color: whitesmoke;
    font-weight: 700;
  }
}

.dot {
  background-color: #22a684;
  border-radius: 50%;
  display: inline-block;
  height: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 5px;
}
</style>
