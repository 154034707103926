<template>
  <div class="customer-tab">
    <table
      v-if="customer.addresses.length"
      class="table is-bordered is-striped is-marginless is-fullwidth"
    >
      <thead>
        <tr>
          <th class="has-text-centered">Τύπος</th>
          <th class="has-text-centered">Τ. Κώδικας</th>
          <th class="has-text-centered">Νομός</th>
          <th class="has-text-centered">Πόλη</th>
          <th class="has-text-centered">Οδός</th>
          <th class="has-text-centered">Κινητό</th>
          <th class="has-text-centered hidden-print">Ενέργειες</th>
        </tr>
      </thead>
      <tbody>
        <item
          v-for="(item, index) in customer.addresses"
          :item="item"
          :index="index"
          :key="item.id"
          @selectItem="selectItem"
        />
      </tbody>
    </table>
    <h5 v-else class="title is-5 has-text-centered is-marginless">
      Δε βρέθηκαν διευθύνσεις
    </h5>

    <address-edit
      :is-open="isOpen"
      :customer="customer"
      :address="selectedItem"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import transformations from '@/utils/transformations';
import Item from './components/Address';
import AddressEdit from './components/AddressEdit';

export default {
  components: {
    Item,
    AddressEdit,
  },
  data() {
    return {
      isOpen: false,
      selectedItem: {},
    };
  },
  computed: {
    ...mapGetters({
      customer: 'customers/edit/getCustomer',
    }),
  },
  methods: {
    selectItem(item) {
      this.selectedItem = transformations.capitalizeAddress(item);
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },
  },
};
</script>
