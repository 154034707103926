import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async updateCustomerInvoice({ commit }, { invoice }) {
    try {
      await request.put(`/invoices/${invoice.uuid}`, invoice);

      const { data } = await request.get(`/customer/${invoice.customer_id}/invoices`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateCustomerInvoices', { invoices: data.invoices });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
