<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Χρέωση Box Now
        </div>
      </div>
      <div class="card-header-icon">
        <button
          v-if="isEditing"
          type="button"
          class="button"
          @click="isEditing = !isEditing"
        >
          <span class="icon is-small"><i class="fa fa-eye"/></span
          ><span>Προβολή</span>
        </button>
        <button
          v-else
          type="button"
          class="button"
          @click="isEditing = !isEditing"
        >
          <span class="icon is-small"><i class="fa fa-pencil"/></span
          ><span>Επεξεργασία</span>
        </button>
      </div>
    </div>
    <div class="card-content">
      <div v-if="isEditing" class="columns is-multiline">
        <div class="column is-full">
          <div class="field has-addons level-item">
            <div class="control">
              <input
                :value="form.charge"
                class="input"
                type="number"
                min="0"
                step="0.1"
                name="charge"
                @change="handleChange"
              />
            </div>
            <div class="control">
              <a class="button is-static">
                &euro;
              </a>
            </div>
          </div>
          <p v-show="errors.has('charge')" class="help is-danger">
            Εισάγετε χρέωση
          </p>
        </div>
      </div>
      <div v-else>
        <div class="columns">
          <div class="column is-full">
            <div class="field">
              <div class="control">{{ form.charge }} &euro;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    charges: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        charge: 0,
      },
      isEditing: false,
    };
  },

  watch: {
    'charges.box_now.charge': {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.form = {
            charge: newVal,
          };
        }
      },
      immediate: true,
    },
  },

  methods: {
    handleChange(e) {
      const charge = parseFloat(e.target.value) || 0;

      this.$emit('updateBoxNowCharges', charge);
    },
  },
};
</script>
