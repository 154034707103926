import request from '@/utils/request';
import { calculateParams } from '@/utils/calculations';

/* eslint-disable */
const actions = {
  async getActiveBundles({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get('/bundles-active', {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.bundles);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getAllBundles({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get('/bundles', {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.bundles);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getDeletedBundles({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get('/bundles-deleted', {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.bundles);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getInactiveBundles({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get('/bundles-inactive', {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.bundles);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },
};

export default actions;
