/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setPost(state, { post }) {
    state.data = post;
  },

  updatePost(state, { post }) {
    state.data = {
      ...state.data,
      ...post,
    };
  },

  updatePostPhotos(state, { photos }) {
    state.data.photos = photos;
  },
};

export default mutations;
