const state = {
  data: {
    categories: {
      bronze: {
        discount: 0,
      },
      silver: {
        discount: 2,
      },
      gold: {
        discount: 4,
      },
    },
    limits: {
      low: 8000,
      top: 20000,
    },
    settings: {
      expire_at: 180,
      points: {
        order: 1,
        register: 1000,
        newsletter: 1000,
        profile: 2000,
        product: 200,
        post: 50,
      },
    },
  },
};

export default state;
