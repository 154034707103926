<template>
  <div class="photo">
    <ProductBadge :type="type" />
    <PeriodicDiscountBadge v-if="hasPeriodicDiscount" />
    <svg
      :class="{ hidden: isNotGift }"
      xmlns="http://www.w3.org/2000/svg"
      width="22px"
      height="22px"
      viewBox="0 0 51 52"
      class="flex-shrink-0 gift-badge"
    >
      <g fill="none" fill-rule="evenodd">
        <path
          fill="#FD0166"
          d="M6 52h39a2 2 0 0 0 2-2V25a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v25a2 2 0 0 0 2 2Z"
        />
        <path
          fill="#9E003F"
          d="M5.5 27h40a1.5 1.5 0 0 0 0-3h-40a1.5 1.5 0 0 0 0 3Z"
        />
        <path
          fill="#FD0166"
          d="M2 24h47a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2Z"
        />
        <path fill="#98003D" d="M22 52h7V12h-7z" />
        <path
          fill="#B30249"
          d="M23.739 12.82c-12.62 7.097-8.693 28.202-8.274 27.141.328-.856 1.585-5.108 2.253-6.565 1.525-3.345 3.957 4.801 5.682 6.412.17-.03-1.157-17.19-.798-18.75.19-.825 1.705-8.564 1.137-8.238M27.007 12.82c12.365 7.09 9.865 27.152 9.454 26.094-.332-.854-1.64-4.852-2.285-6.297-1.504-3.347-3.409 3.876-5.098 5.483-.166-.03-.616-15.494-.958-17.06-.185-.814-1.67-8.536-1.113-8.22"
        />
        <path
          fill="#F60063"
          d="M6.059.277c5.297-1.674 14.322 4.65 16.8 8.037-1.014 1.92-11.805 4.198-16.693-6.539-.234-.533-.722-1.303-.107-1.498M46.475 3.886C40.7 1.48 31.625 5.353 29.146 8.727c1.005 1.896 12.275 7.727 17.154-2.946.244-.53.77-1.64.175-1.895"
        />
        <path
          fill="#B30249"
          d="M23.08 8.292C21.654 9.42 17.064 9.46 13.294 7.05 11.4 5.84 6.907 3.143 6.263.26c-3.116 1.436-2.54 8.461-1.094 8.892.293 3.651-1.836 6.933.039 9.292 1.816 2.287 7.559 3.764 12.793-.574.625-.523 5.704-4.82 5.713-4.913.156-2.02.43-3.395-.634-4.666M28.84 8.488c.923-.358 6.783.43 11.483-1.691 2.212-.984 3.844-1.763 5.948-3.137 1.937 1.815-.59 6.017-.659 7.012-.354 5.658 1.524 9.051-1.415 10.353-4.946 2.194-13.598-4.93-17-7.822-.491-.41 2.075-3.29 1.642-4.715"
        />
        <path
          fill="#98003D"
          d="M29.398 10.65c0 1.377-.448 2.886-1.119 3.38-.905.667-5.7.595-5.67-2.168.02-1.745.204-3.06.515-3.46 1.177-1.5 5.048-1.377 5.943-.021.282.421.33 1.078.33 2.27Z"
        />
      </g>
    </svg>
    <photo :photos="photos" />
  </div>
</template>

<script>
import Photo from '@/views/components/Photo';
import ProductBadge from '@/views/components/ProductBadge';
import PeriodicDiscountBadge from '@/views/components/PeriodicDiscountBadge';

export default {
  components: {
    Photo,
    ProductBadge,
    PeriodicDiscountBadge,
  },
  props: {
    type: String,
    photos: Array,
    isGiftActionGift: Boolean,
    hasPeriodicDiscount: Boolean,
  },
  computed: {
    isNotGift() {
      return !this.isGiftActionGift;
    },
  },
};
</script>

<style scoped lang="scss">
.photo {
  display: inline-block;
  position: relative;

  img {
    max-width: initial;
  }

  .badge {
    height: 20px;
    position: absolute;
    right: -5px;
    top: -5px;
    width: 20px;
  }

  .pd-badge {
    height: 20px;
    position: absolute;
    left: -5px;
    bottom: -5px;
    width: 20px;
  }

  .gift-badge {
    height: 22px;
    position: absolute;
    left: -5px;
    top: -7px;
    width: 20px;
  }

  .hidden {
    display: none;
  }
}
</style>
