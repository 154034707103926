<template>
  <div>
    <div class="tabs hidden-print">
      <ul v-sticky sticky-offset="offset">
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'marketing.contests.list.active' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Ενεργοί Διαγωνισμοί
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'marketing.contests.list.inactive' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Μη Ενεργοί Διαγωνισμοί
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'marketing.contests.list.all' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Όλοι οι Διαγωνισμοί
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'marketing.contests.list.deleted' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Ακυρωμένοι Διαγωνισμοί
            </a>
          </li>
        </router-link>
      </ul>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  data: () => ({
    offset: { top: 45 },
  }),
};
</script>
