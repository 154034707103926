<template>
  <div class="card-header">
    <div class="card-header-title">
      <div class="title is-6 is-marginless">
        {{ title }}
        <span v-if="hasSelections" class="tag">
          {{ selections.length }} από {{ total }}
        </span>
        <span v-else class="tag">{{ total }}</span>
      </div>
    </div>
    <div class="card-header-icon hidden-print">
      <slot name="options" />
    </div>

    <slot name="confirmation" />
  </div>
</template>

<script>
export default {
  props: {
    total: Number,
    title: String,
    selections: Array,
    hasSelections: Boolean,
  },
};
</script>
