<template>
  <form @submit.prevent="submit">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          Κουπόνια
        </div>
        <div class="card-header-icon">
          <div class="field has-addons is-marginless">
            <div class="control">
              <router-link
                :to="{ name: 'marketing.coupons.list' }"
                class="button"
              >
                <span class="icon is-small"><i class="fa fa-list-ul"/></span
                ><span>Λίστα</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="columns is-multiline">
          <div class="column is-half">
            <label class="label">Κωδικός *</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <input
                  v-validate="'required'"
                  v-model.trim="newCoupon.code"
                  class="input"
                  type="text"
                  name="code"
                />
                <p v-show="errors.has('code')" class="help is-danger">
                  Εισάγετε κωδικό
                </p>
              </div>
              <div class="control">
                <button class="button" type="button" @click="changeCode">
                  <span class="icon"><i class="fa fa-cog"/></span>
                </button>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Τίτλος</label>
              <div class="control">
                <input
                  v-model.trim="newCoupon.title"
                  class="input"
                  type="text"
                  name="title"
                />
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Ελάχιστη Αξία Παραγγελίας *</label>
              <div class="control">
                <input
                  v-model.number="newCoupon.min_price"
                  class="input"
                  type="number"
                  name="min_price"
                  min="0"
                />
              </div>
            </div>
          </div>
          <div class="column is-half">
            <label class="label">Ποσό Κουπονιού *</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <input
                  v-model.number="newCoupon.value"
                  class="input"
                  type="number"
                  min="0"
                  name="min_price"
                />
              </div>
              <div class="control">
                <div class="select">
                  <select v-model="newCoupon.type" name="type">
                    <option value="euro">&euro;</option>
                    <option value="percentage">&#x25;</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <label class="label">Ημερομηνία Έναρξης</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <Datepicker
                  v-model="newCoupon.date_start"
                  name="date_start"
                  format="dd-MM-yyyy"
                  language="el"
                  wrapper-class="field"
                  input-class="input"
                  placeholder="πχ: 02-12-2017"
                />
              </div>
              <div v-if="newCoupon.date_start" class="control">
                <button
                  class="button"
                  type="button"
                  @click="clearDate('date_start')"
                >
                  <span class="icon"><i class="fa fa-times"/></span>
                </button>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <label class="label">Ημερομηνία Λήξης</label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <Datepicker
                  v-model="newCoupon.date_end"
                  name="date_end"
                  format="dd-MM-yyyy"
                  language="el"
                  wrapper-class="field"
                  input-class="input"
                  placeholder="πχ: 02-12-2017"
                />
              </div>
              <div v-if="newCoupon.date_end" class="control">
                <button
                  class="button"
                  type="button"
                  @click="clearDate('date_end')"
                >
                  <span class="icon"><i class="fa fa-times"/></span>
                </button>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Ενεργό *</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select v-model.number="newCoupon.active" name="active">
                    <option :value="false">Όχι</option>
                    <option :value="true">Ναί</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Μίας Χρήσης *</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select v-model.number="newCoupon.one_timer" name="active">
                    <option :value="false">Όχι</option>
                    <option :value="true">Ναί</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ToggleableCard :is-open="true" title="Συνδέσεις">
      <div class="card-content">
        <div class="columns is-multiline">
          <div class="column is-full">
            <label class="label">Brands</label>
            <BrandsSearch
              :selections="newCoupon.brands"
              @on-select="selectBrand"
              @on-remove="removeBrand"
            />
          </div>
        </div>
      </div>
    </ToggleableCard>

    <div class="card">
      <div class="card-content">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </div>
  </form>
</template>

<script>
import randomstring from 'randomstring';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import AddItem from '@/views/components/AddItem';
import ToggleableCard from '@/views/components/ToggleableCard';
import request from '@/utils/request';
import BrandsSearch from '../components/BrandsSearch';

export default {
  components: {
    Datepicker,
    ToggleableCard,
    BrandsSearch,
  },

  extends: AddItem,

  data() {
    return {
      newCoupon: {
        code: randomstring.generate(6),
        title: '',
        type: 'percentage',
        value: 0,
        date_start: moment().toDate(),
        date_end: null,
        active: false,
        one_timer: true,
        min_price: 0,
        brands: [],
      },
    };
  },

  methods: {
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        const { data } = await request.post('/coupons', {
          ...this.newCoupon,
          brands: this.newCoupon.brands.map(brand => brand.id),
          date_start:
            this.newCoupon.date_start &&
            moment(this.newCoupon.date_start).format('DD-MM-YYYY'),
          date_end:
            this.newCoupon.date_end &&
            moment(this.newCoupon.date_end).format('DD-MM-YYYY'),
        });
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$router.push({ name: 'marketing.coupons.list' });
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το κουπόνι αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.newCoupon = {
        code: randomstring.generate(6),
        title: '',
        type: 'percentage',
        value: 0,
        date_start: moment().toDate(),
        date_end: null,
        active: false,
        one_timer: true,
        min_price: 0,
        brands: [],
      };

      this.$validator.reset();
    },

    changeCode() {
      this.newCoupon.code = randomstring.generate(6);
    },

    clearDate(date) {
      this.newCoupon[date] = null;
    },

    selectBrand(brand) {
      this.newCoupon = {
        ...this.newCoupon,
        brands: [...this.newCoupon.brands, brand],
      };
    },

    removeBrand(brand) {
      this.newCoupon = {
        ...this.newCoupon,
        brands: this.newCoupon.brands.filter(({ id }) => id !== brand.id),
      };
    },
  },
};
</script>
