import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _round from 'lodash/round';
import OPERATORS from '@/constants/operators';

// TODO: Add unit tests
export const calculateSendCharges = (sum, weight, charges) => {
  const methods = OPERATORS.reduce(
    (acc, item) => ({
      ...acc,
      [item.value]: item.method,
    }),
    {},
  );

  let totalSumCharges = 0;
  let totalWeightCharges = 0;

  charges.sum.forEach(s => {
    if (methods[s.operator](sum, s.limit)) {
      totalSumCharges = s.charge;
    }
  });

  charges.weight.forEach(w => {
    if (methods[w.operator](weight, w.limit)) {
      totalWeightCharges += Math.ceil((weight - w.limit) / w.amount) * w.charge;
    }
  });

  return _round(totalSumCharges + totalWeightCharges, 2);
};

export const calculateProductsSum = products =>
  products.reduce(
    ({ sum, weight }, item) => ({
      sum: _round(sum + item.pivot.final_price * item.pivot.count, 2),
      weight: _round(weight + item.weight * item.pivot.count, 2),
    }),
    {
      sum: 0,
      weight: 0,
    },
  );

export const calculateParams = (paginationParams, params) =>
  _pick(
    {
      ...paginationParams,
      ...params,
    },
    ['page', 'size', 'filters', 'sort', 'paginated', 'state'].filter(
      param => !_isEmpty(param),
    ),
  );

export const calculatePages = (currentPage = 1, pageCount = 1, delta = 1) => {
  const range = [];
  for (
    let i = Math.max(2, currentPage - delta);
    i <= Math.min(pageCount - 1, currentPage + delta);
    i += 1
  ) {
    range.push(i);
  }

  if (currentPage - delta > 2) {
    range.unshift('...');
  }

  if (currentPage + delta < pageCount - 1) {
    range.push('...');
  }

  range.unshift(1);
  range.push(pageCount);

  return range;
};
