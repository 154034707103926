<template>
  <tr>
    <td class="has-text-centered">
      <UserType :item="item" relationship="addressable_type" />
    </td>
    <td class="has-text-centered">{{ item.postcode }}</td>
    <td class="has-text-centered">{{ item.region }}</td>
    <td class="has-text-centered">{{ item.city }}</td>
    <td class="has-text-centered">{{ item.street }} {{ item.streetNumber }}</td>
    <td class="has-text-centered">{{ item.mobile }}</td>
    <td class="has-text-centered hidden-print">
      <button class="button is-small" @click="selectItem">
        <span class="icon is-small">
          <span class="fa fa-fw fa-pencil" />
        </span>
      </button>
    </td>
  </tr>
</template>

<script>
import UserType from '@/views/components/UserType';

export default {
  components: {
    UserType,
  },
  props: {
    item: Object,
  },
  methods: {
    selectItem() {
      this.$emit('selectItem', this.item);
    },
  },
};
</script>
