import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getPostCategory({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/postCategories/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setPostCategory', {
        postCategory: data.postCategory,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async updatePostCategory({ dispatch, commit }, { uuid, postCategory }) {
    try {
      const response1 = await request.put(`/postCategories/${uuid}`, postCategory);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/postCategories/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updatePostCategory', {
        postCategory: response2.data.postCategory,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
