import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getSet({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/sets/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setSet', {
        set: data.set,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async updateSet({ dispatch, commit, rootGetters }, { uuid, set }) {
    try {
      const response1 = await request.put(`/sets/${uuid}`, set);

      if (response1.data.error) {
        const err = Error('Server-side validation failed');
        err.messages = response1.data.errors;
        throw err;
      }

      const response2 = await request.get(`/products/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit(
        'products/edit/updateProduct',
        {
          product: response2.data.product,
        },
        {
          root: true,
        },
      );
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
