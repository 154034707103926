<template>
  <span class="tag is-primary">
    {{ filter.label }}: {{ truncatedValue }}&nbsp;
    <button class="delete is-small" @click="dropFilter" />
  </span>
</template>

<script>
import isObject from 'lodash/isObject';

export default {
  props: {
    filter: Object,
  },
  computed: {
    value() {
      const { value, type, title } = this.filter;

      if (isObject(value)) {
        if (['range', 'date_range'].includes(type)) {
          return `${value.start} - ${value.end}`;
        }

        if (type === 'search') {
          return `${value.map(v => v.title).join(', ')}`;
        }

        if (type === 'options') {
          return `${value.map(v => v.title).join(', ')}`;
        }
      }

      if (type === 'boolean') {
        return value === 'true' ? 'Nαί' : 'Όχι';
      }

      if (title === 'gender') {
        return value === 'male' ? 'Άνδρας' : 'Γυναίκα';
      }

      return value;
    },
    truncatedValue() {
      const maxLength = 30;
      return this.value.length > maxLength
        ? `${this.value.slice(0, maxLength)}...`
        : this.value;
    },
  },
  methods: {
    dropFilter() {
      this.$emit('dropFilter', this.filter.title);
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  margin-right: 0.5rem;
}
</style>
