<template>
  <span class="sortable-header">
    <span class="sortable-header__label" v-html="label" />
    <span v-if="!hideSortButtons" class="sortable-header__options hidden-print">
      <i
        :class="{ 'is-active': isActive('asc') }"
        class="fa fa-angle-up"
        @click="sort('asc')"
      />
      <i
        :class="{ 'is-active': isActive('desc') }"
        class="fa fa-angle-down"
        @click="sort('desc')"
      />
    </span>
  </span>
</template>

<script>
export default {
  props: {
    label: String,
    attribute: String,
    sortOptions: Object,
    hideSortButtons: { type: Boolean, default: false },
  },
  methods: {
    isActive(direction) {
      return (
        this.attribute === this.sortOptions.attribute &&
        direction === this.sortOptions.direction
      );
    },
    sort(direction) {
      this.$emit('sort', {
        attribute: this.attribute,
        direction,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sortable-header {
  align-items: center;
  display: flex;
  justify-content: center;

  &__label {
    flex: 1;
  }

  &__options {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    float: right;
    justify-content: center;
    margin-left: 0.5rem;
  }
}

.fa {
  font-size: 12px;

  &:hover {
    cursor: pointer;
  }

  &.is-active {
    color: #22a684;
  }
}
</style>
