<template>
  <div class="field is-grouped">
    <div class="control">
      <button
        :class="{ 'is-loading': isSaving }"
        :disabled="isSaving"
        type="submit"
        class="button is-primary"
      >
        <span class="icon is-small"><i :class="['fa', submitIcon]"/></span
        ><span>{{ submitLabel }}</span>
      </button>
    </div>
    <div class="control">
      <button type="button" class="button" @click="reset">
        <span class="icon is-small"><i class="fa fa-undo"/></span
        ><span>Επαναφορά</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isSaving: false,
    submitLabel: {
      type: String,
      default: 'Αποθήκευση',
    },
    submitIcon: {
      type: String,
      default: 'fa-save',
    },
  },
  methods: {
    reset() {
      this.$emit('reset');
    },
  },
};
</script>
