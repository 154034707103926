/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setCollections(state, { collections }) {
    state.data = collections;
  },
};

export default mutations;
