<template>
  <LoadingContainer :is-loading="isLoading">
    <div class="hidden-print">
      <div class="columns is-multiline">
        <div class="column is-full">
          <OrderInfo :order="order" :is-deleted="isDeleted" />
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-three-quarters">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                Προβολή GY Παραγγελίας
              </div>
            </div>
            <div class="card-content">
              <div v-if="hasItems">
                <div class="table-wrapper">
                  <table class="table is-bordered is-striped is-fullwidth">
                    <thead>
                      <th>#</th>
                      <th>Φωτό</th>
                      <th>Προιόν</th>
                      <th>Π.Λ. Τιμή (&euro;)</th>
                      <th>Έκπτωση (%)</th>
                      <th>Τελική Τιμή (&euro;)</th>
                      <th>Φ.Π.Α. (%)</th>
                      <th class="table__count">Ποσότητα</th>
                      <th>Σύνολο (&euro;)</th>
                    </thead>
                    <tbody>
                      <product
                        v-for="(product, index) in products"
                        :key="index"
                        :product="product"
                        :index="index"
                      />
                    </tbody>
                  </table>
                </div>
                <Logistics :order="order" />
              </div>
            </div>
          </div>

          <CustomerNotes
            v-if="hasComment"
            :notes="order.details.order.comment"
          />

          <OrderAddress :order="order" />

          <OrderInvoice :order="order" />

          <OrderDetails :order="order" />
        </div>

        <div class="column is-one-quarter">
          <OrderStatuses
            :order="order"
            :statuses="orderStatuses"
            :is-deleted="isDeleted"
          />

          <CustomerInfo :customer="customer" :customer-id="customerId" />

          <GiftWishes :order="order" />
        </div>
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { isEmpty, round, get } from 'lodash';
import VueBarcode from 'vue-barcode';
import request from '@/utils/request';
import OrderPrint from '@/views/Dashboard/views/Orders/components/OrderPrint/';
import EditItem from '@/views/components/EditItem';
import LoadingContainer from '@/views/components/LoadingContainer';
import CustomerInfo from './components/CustomerInfo';
import Product from './components/Product';
import Coupon from './components/Coupon';
import Logistics from './components/Logistics';
import AdminNotes from './components/AdminNotes';
import CustomerNotes from './components/CustomerNotes';
import CustomerContact from './components/CustomerContact';
import GiftWishes from './components/GiftWishes';
import OrderStatuses from './components/OrderStatuses';
import OrderInfo from './components/OrderInfo';
import OrderAddress from './components/OrderAddress';
import OrderInvoice from './components/OrderInvoice';
import OrderDetails from './components/OrderDetails';
import Transactions from './components/Transactions';

export default {
  components: {
    Product,
    Coupon,
    Logistics,
    AdminNotes,
    CustomerNotes,
    CustomerInfo,
    OrderStatuses,
    OrderInfo,
    OrderDetails,
    OrderAddress,
    OrderInvoice,
    LoadingContainer,
    Transactions,
    CustomerContact,
    GiftWishes,
    barcode: VueBarcode,
    OrderPrint,
  },

  extends: EditItem,

  data() {
    return {
      order: {},
      products: [],
    };
  },

  computed: {
    ...mapGetters({
      notes: 'getNotes',
      orderStatuses: 'getOrderStatuses',
      sendCharges: 'getSendCharges',
    }),

    hasItems() {
      return this.products.length > 0;
    },

    itemsTotalPrice() {
      return round(
        this.products.reduce(
          (acc, item) => acc + item.final_price * item.pivot.count,
          0,
        ),
        2,
      );
    },

    orderCartIds() {
      if (this.hasItems) {
        /* eslint-disable camelcase */
        return this.products.map(({ cart_id }) => cart_id);
      }

      return [];
    },

    isDeleted() {
      return this.order.deleted_at !== null;
    },

    hasCoupon() {
      return !!this.newOrder.coupon;
    },

    orderState() {
      return this.order.details ? this.order.details.state : '-';
    },

    customer() {
      return !isEmpty(this.order) && this.order.details.customer;
    },

    customerId() {
      return !isEmpty(this.order) && this.order.customer.uuid;
    },

    hasComment() {
      return !isEmpty(this.order) && !!this.order.details.order.comment;
    },
  },

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(
        `/gy-orders/${this.$route.params.uuid}`,
      );

      this.order = data.order;
      this.products = get(this.order, 'products', []);
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapMutations(['copyNewOrder', 'updateOrder']),

    ...mapActions([
      'getOrder',
      'cancelOrder',
      'activateOrder',
      'addStatus',
      'updateOrderProducts',
    ]),
  },
};
</script>

<style scoped lang="scss">
.table-wrapper {
  overflow-x: auto;
}

.table {
  margin: -1px 0 0;

  &__barcode {
    min-width: 170px;
  }

  &__count {
    max-width: 100px;
  }
}

.fa {
  &.fa-shopping-basket {
    font-size: 40px;
    margin-bottom: 1rem;
  }
}
</style>
