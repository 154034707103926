<template>
  <div>
    <form novalidate @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              {{ titleDecription }}
              <span class="tag">{{ model.barcode }} - {{ model.title }}</span>
              <span v-if="isDeleted" class="tag is-danger">
                Απενεργοποιημένο
              </span>
            </div>
          </div>
          <div class="card-header-icon">
            <div class="field has-addons is-marginless">
              <div class="control">
                <router-link :to="{ name: 'products.list' }" class="button">
                  <span class="icon is-small"><i class="fa fa-list"/></span
                  ><span>Λίστα</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="card-content">
          <ProductsEditMenu />

          <div class="columns is-multiline">
            <div class="column is-full">
              <div class="field">
                <label class="label">Ονομασία</label>
                <div class="control">
                  <input
                    v-model.trim="general.title_en"
                    class="input"
                    type="text"
                    name="title_en"
                  />
                </div>
              </div>
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Περιεχόμενο</label>
                <div class="control">
                  <VueEditor
                    id="editor-1"
                    v-model="general.content_en"
                    :editor-toolbar="toolbarOptions"
                    data-vv-value-path="value"
                    data-vv-name="content_en"
                    @input="changeContent('content', $event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </div>
    </form>

    <confirmation
      :model-name="model.title"
      :is-open="isOpen"
      description="Είστε βέβαιος για τη διαγραφή αυτού του προιόντος;"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _pick from 'lodash/pick';
import { VueEditor } from 'vue2-editor';
import toolbarOptions from '@/constants/toolbarOptions';
import request from '@/utils/request';
import EditItem from '@/views/components/EditItem';
import ProductsEditMenu from '../components/ProductsEditMenu';

export default {
  components: {
    VueEditor,
    ProductsEditMenu,
  },
  extends: EditItem,
  data() {
    return {
      general: {},
      toolbarOptions,
    };
  },
  computed: {
    ...mapGetters({
      model: 'products/edit/getProduct',
    }),
    isDeleted() {
      return this.model.deleted_at !== null;
    },
    titleDecription() {
      const product = this.model;
      /* eslint-disable */
      if (product.deleted_at !== null) {
        return 'Απενεργοποιημένο Προιόν';
      } else if (product.active === true) {
        return 'Εμφανές Προιόν';
      } else if (product.active === false) {
        return 'Μη Εμφανές Προιόν';
      } else {
        return 'Προιόν';
      }
    },
  },
  created() {
    this.general = _pick(this.model, ['title_en', 'content_en']);
  },
  methods: {
    ...mapActions({
      updateProduct: 'products/edit/updateEnProduct',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateProduct({
          uuid: this.model.uuid,
          product: this.general,
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το προιόν αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    askToDeleteProduct() {
      this.isOpen = !this.isOpen;
    },
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;
        if (response) {
          await request.delete(`/products/${this.model.uuid}`);
          this.$router.push('/products');
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = _pick(this.model, ['title_en', 'content_en']);

      this.$validator.reset();
    },
    changeContent(el, content) {
      this.general[el] = content === '<p><br></p>' ? '' : content;
    },
  },
};
</script>
