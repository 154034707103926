<template>
  <stats-card title="Σύνολο Πωλήσεων" @update="update">
    <template v-slot:graph v-if="chartData">
      <horizontal-bar-chart
        :chart-data="chartData"
        :options="chartOptions"
        :height="1000"
      />
    </template>
  </stats-card>
</template>

<script>
import { HorizontalBar, mixins } from 'vue-chartjs';
import request from '@/utils/request';
import StatsCard from '@/views/components/StatsCard';

const { reactiveProp } = mixins;

const HorizontalBarChart = {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: ['options'],
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};

export default {
  components: {
    HorizontalBarChart,
    StatsCard,
  },
  data() {
    return {
      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: true, // Ensure the x-axis is always displayed
              ticks: {
                beginAtZero: true,
                callback(value) {
                  // This callback can be used to format tick marks (e.g., adding units or modifying text)
                  return value;
                },
              },
              gridLines: {
                display: true, // Controls the grid lines for x-axis
                drawBorder: true, // Draw border at the edge of the chart
                drawOnChartArea: true, // Draw grid lines for the chart area
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true, // Optionally hide the grid lines for y-axis
              },
            },
          ],
        },
      },
    };
  },

  methods: {
    async update() {
      const { data } = await request.get('/products-stats/top-sales');

      this.chartData = {
        datasets: [
          {
            label: 'Σύνολο Πωλήσεων',
            data: data.products.map(product => product.total_count),
            backgroundColor: '#22A684',
          },
        ],
        labels: data.products.map(product => product.title),
      };
    },
  },
};
</script>
