<template>
  <multiselect
    v-validate="'required'"
    :options="locations"
    :searchable="true"
    :internal-search="false"
    :option-height="104"
    :loading="isSearching"
    :options-limit="50"
    :value="postcode"
    track-by="id"
    placeholder="Αναζήτηση με ΤΚ..."
    select-label="Enter"
    deselect-label="Enter"
    selected-label="Επιλεγμένο"
    data-vv-value-path="value"
    data-vv-name="postcode"
    @search-change="searchLocations"
    @select="selectPostcode"
  >
    <template slot="option" slot-scope="{ option }" class="option">
      <div class="option__desc">
        <div class="option__title">
          <span>{{ option.postcode }}</span>
          <template v-if="option.difficult">
            (Δυσπρόσιτο)
          </template>
        </div>
        <span class="option__small">{{ option.region }}</span>
        <span class="option__small">{{ option.city }}</span>
      </div>
    </template>
    <template v-slot:noResult>
      Δε βρέθηκαν αποτελέσματα. Προσπαθήστε ξανά!!
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import _debounce from 'lodash/debounce';
import request from '@/utils/request';

export default {
  inject: ['$validator'],
  components: {
    Multiselect,
  },
  props: {
    postcode: '',
    region: '',
    city: '',
  },
  data() {
    return {
      locations: [],
      isSearching: false,
    };
  },
  methods: {
    // eslint-disable-next-line func-names
    searchLocations: _debounce(async function(searchQuery) {
      if (searchQuery.length) {
        this.isSearching = true;
        const { data } = await request.get('/locations-search', {
          params: {
            term: '',
            postcode: searchQuery,
            region: this.region,
            city: this.city,
          },
        });
        this.isSearching = false;

        this.locations = data.locations;
      }
    }, 300),
    selectPostcode(selectedOption) {
      this.$emit('selectPostcode', selectedOption);
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect {
  .option__title {
    margin-left: 0;
  }
}
</style>
