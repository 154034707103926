<script>
import Confirmation from '@/views/components/Confirmation';
import RestoreConfirmation from '@/views/components/RestoreConfirmation';
import PaginatedListHeaderTemplate from '../PaginatedListHeaderTemplate';

export default {
  components: {
    Confirmation,
    RestoreConfirmation,
    PaginatedListHeaderTemplate,
  },
  props: {
    title: String,
    total: Number,
    selections: Array,
    hasSelections: Boolean,
    canDeleteItems: {
      type: Boolean,
      default: true,
    },
    canRestoreItems: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      isRestoreModalOpen: false,
    };
  },

  methods: {
    print() {
      this.$emit('print');
    },
    update() {
      this.$emit('update');
    },
    prepareExportXLS() {
      this.$emit('prepareExportXLS');
    },
    prepareExportCSV() {
      this.$emit('prepareExportCSV');
    },
    askToDeleteItems() {
      this.isOpen = true;
    },
    askToRestoreItems() {
      this.isRestoreModalOpen = true;
    },
  },
};
</script>
