import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getCollection({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/collections/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setCollection', {
        collection: data.collection,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getCollectionPhotos({ dispatch, commit }, { uuid }) {
    try {
      const { data } = await request.get(`/collections/${uuid}/photos`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateCollectionPhotos', {
        photos: data.photos,
      });
    } catch (err) {
      throw err;
    }
  },

  async updateCollection(
    { dispatch, commit, rootGetters },
    { uuid, collection },
  ) {
    try {
      const response1 = await request.put(`/collections/${uuid}`, collection);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/collections/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateCollection', {
        collection: response2.data.collection,
      });

      const collections = rootGetters['collections/all/getCollections'].map(
        c => {
          if (uuid === c.uuid) {
            return {
              ...c,
              ...collection,
            };
          }

          return c;
        },
      );

      await commit(
        'collections/all/setCollections',
        {
          collections,
        },
        {
          root: true,
        },
      );
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
