<template>
  <ExistingItems :items="items" title="Υφιστάμενες Μάρκες">
    <template v-slot:item="props">
      <router-link
        :to="{
          name: 'products.brands.edit',
          params: { uuid: props.item.uuid },
        }"
      >
        <Photo :photos="props.item.photos" />
        <span>{{ props.item.title }}</span>
      </router-link>
    </template>
  </ExistingItems>
</template>

<script>
import Photo from '@/views/components/Photo';
import ExistingItems from '@/views/components/ExistingItems';

export default {
  components: {
    Photo,
    ExistingItems,
  },
  props: {
    items: Array,
  },
};
</script>

<style lang="scss" scoped>
a {
  align-items: center;
  display: flex;
}

img {
  height: 30px;
  margin-right: 10px;
  width: 30px;
}
</style>
