<template>
  <div class="columns is-multiline">
    <div class="column is-full">
      <TopSales />
    </div>
    <div class="column is-full">
      <TopRevenue />
    </div>
  </div>
</template>

<script>
import TopRevenue from './components/TopRevenue';
import TopSales from './components/TopSales';

export default {
  components: {
    TopRevenue,
    TopSales,
  },
};
</script>
