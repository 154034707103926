<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        Άμεση Επικοινωνία
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="field">
          <label class="label">Επέλεξε τύπο email</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="selectedType" name="selectedType">
                <option value="">
                  -- Επέλεξε --
                </option>
                <option
                  v-for="notification in emailTemplates"
                  :value="notification.id"
                  :key="notification.id"
                >
                  {{ notification.title }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div v-if="selectedType">
          <div class="field">
            <label class="label">Τίτλος</label>
            <div class="control">
              <textarea
                v-validate="'required'"
                v-model="email.title"
                cols="30"
                rows="2"
                name="title"
                class="textarea"
              />
            </div>
            <p v-show="errors.has('title')" class="help is-danger">
              Εισάγετε τίτλο
            </p>
          </div>

          <div class="field">
            <label class="label">Περιεχόμενο</label>
            <div class="control">
              <textarea
                v-validate="'required'"
                v-model="email.content"
                cols="30"
                rows="8"
                name="content"
                class="textarea"
              />
            </div>
            <p v-show="errors.has('content')" class="help is-danger">
              Εισάγετε περιεχόμενο
            </p>
          </div>
        </div>
      </div>
      <div v-if="selectedType" class="card-footer">
        <FormSubmit
          :is-saving="isSaving"
          submit-label="Αποστολή"
          submit-icon="fa-envelope-o"
          @reset="reset"
        />
      </div>
    </form>
  </div>
</template>

<script>
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _replace from 'lodash/replace';
import _trim from 'lodash/trim';
import { mapGetters } from 'vuex';
import request from '@/utils/request';
import EditItem from '@/views/components/EditItem';

export default {
  extends: EditItem,
  props: {
    order: Object,
    customer: Object,
  },
  data() {
    return {
      selectedType: '',
      email: {
        title: '',
        content: '',
      },
      isSaving: false,
    };
  },
  computed: {
    ...mapGetters({
      emailTemplates: 'getEmailTemplates',
    }),
  },
  watch: {
    selectedType(newValue) {
      if (newValue === '') {
        this.email = {
          title: '',
          description: '',
        };
      } else {
        const option = this.emailTemplates.find(
          ({ id }) => id === this.selectedType,
        );

        this.email = _pick(option, ['title', 'content']);
        this.email.content = _replace(this.email.content, /\[.*?\]/g, term => {
          const cleanTerm = _trim(term, '[]');

          return _get(this, cleanTerm, term);
        });
      }
    },
  },
  methods: {
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        const { data } = await request.post(
          `/orders/${this.order.uuid}/notify`,
          this.email,
        );
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το email απεστάλη',
        });

        this.reset();
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.selectedType = '';

      this.$validator.reset();
    },
  },
};
</script>
