<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        Πληροφορίες Δώρου
      </div>
      <div class="card-header-icon">
        <button
          v-tooltip="{ content: 'Προβολή' }"
          v-if="isEditing"
          class="button is-small"
          @click="handleEditing"
        >
          <span class="icon is-small"><i class="fa fa-eye"/></span>
        </button>
        <button
          v-tooltip="{ content: 'Επεξεργασία' }"
          v-else
          class="button is-small"
          @click="isEditing = !isEditing"
        >
          <span class="icon is-small"><i class="fa fa-pencil"/></span>
        </button>
      </div>
    </div>
    <div v-if="isEditing">
      <form @submit.prevent="submit">
        <div class="card-content">
          <div class="field">
            <div class="control">
              <checkbox v-model="isGift" name="inform_client" />
              <label>Συσκευασία Δώρου</label>
            </div>
          </div>

          <div v-if="isGift" class="field">
            <label class="label">Ευχητήριο Μήνυμα</label>
            <div class="control">
              <textarea
                v-model="newGift.gift_wishes"
                name="gift_wishes"
                class="textarea"
                cols="30"
                rows="5"
              />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </form>
    </div>
    <div v-else class="card-content">
      <div class="field">
        <div class="control">
          <checkbox :checked="isGift" class="disabled" />
          <label>Συσκευασία Δώρου</label>
        </div>
      </div>
      <div v-if="isGift" class="field">
        <label class="label">Ευχητήριο Μήνυμα</label>
        <div class="control">
          {{ newGift.gift_wishes }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import { pick } from 'lodash';
import EditItem from '@/views/components/EditItem';
import { GIFT_COST } from '@/constants/costs';

export default {
  extends: EditItem,

  props: {
    order: Object,
  },

  data() {
    return {
      copyOrderGift: {},
      newGift: {},
      isGift: false,
      isSaving: false,
      isEditing: false,
    };
  },

  watch: {
    isGift: {
      handler(newValue) {
        this.newGift = {
          is_gift: !!newValue,
          gift_wishes: newValue ? this.newGift.gift_wishes : null,
          gift_cost: newValue ? GIFT_COST : 0,
        };

        this.updateOrder({
          order: {
            is_gift: this.newGift.is_gift,
            gift_cost: this.newGift.gift_cost,
          },
        });
      },
    },
  },

  created() {
    this.copyOrderGift = pick(this.order, [
      'is_gift',
      'gift_wishes',
      'gift_cost',
    ]);
    this.newGift = {
      ...this.copyOrderGift,
    };
    this.isGift = this.copyOrderGift.is_gift;
  },

  methods: {
    ...mapMutations(['updateOrder']),
    ...mapActions(['updateGift']),

    async submit() {
      try {
        this.isSaving = true;
        await this.updateGift({
          gift: this.newGift,
        });

        this.copyOrderGift = {
          ...this.newGift,
        };

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Οι πληροφορίες δώρου αποθηκεύτηκαν',
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      } finally {
        this.isEditing = false;
        this.isSaving = false;
      }
    },

    reset() {
      this.isGift = this.copyOrderGift.is_gift;

      this.newGift = {
        ...this.copyOrderGift,
      };
    },

    handleEditing() {
      this.isEditing = !this.isEditing;
      this.reset();
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox-component,
.label {
  display: inline-block;
}

::v-deep .checkbox-component {
  margin-right: 10px;
  vertical-align: middle;

  &.disabled input {
    pointer-events: none;

    & + label {
      opacity: 0.6;
      pointer-events: none;

      .input-box {
        background: #ededed;
        border: 1px solid #dbdbdb;
        color: #35495e;
      }
    }
  }
}
</style>
