<template>
  <LoadingContainer :is-loading="isLoadingItems">
    <div class="columns">
      <div class="column is-three-quarters">
        <form novalidate @submit.prevent="submit">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Προσθήκη Μάρκας
                </div>
              </div>
              <div class="card-header-icon">
                <div class="field has-addons is-marginless">
                  <div class="control">
                    <router-link
                      :to="{ name: 'products.brands.list' }"
                      class="button"
                    >
                      <span class="icon is-small">
                        <i class="fa fa-list-ul" />
                      </span>
                      <span>Λίστα</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content">
              <AddGeneralDetails
                :general="general"
                @updateGeneral="updateGeneral"
              />
            </div>
          </div>

          <div class="card">
            <div class="card-footer">
              <FormSubmit :is-saving="isSaving" @reset="reset" />
            </div>
          </div>
        </form>
      </div>
      <div class="column">
        <ExistingBrands :items="items" />
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddItem from '@/views/components/AddItem';
import AddGeneralDetails from '@/views/components/AddGeneralDetails';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import ExistingBrands from '../../components/ExistingBrands';
import ExistingCollections from '../../components/ExistingCollections';

export default {
  components: {
    AddGeneralDetails,
    ToggleableCard,
    LoadingContainer,
    ExistingBrands,
    ExistingCollections,
  },
  extends: AddItem,
  data() {
    return {
      general: {
        title: '',
        slug: '',
        description: '',
        content: '',
        active: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      items: 'brands/list/getBrands',
      collections: 'collections/all/getCollections',
      isLoadingItems: 'brands/list/getIsLoading',
    }),
  },
  async created() {
    try {
      await this.getBrands();
    } catch (err) {
      this.$router.push('/error');
    }
  },
  methods: {
    ...mapActions({
      getBrands: 'brands/list/getBrands',
      addBrand: 'brands/add/addBrand',
    }),
    async submit() {
      try {
        const [isValid] = await Promise.all(
          this.$children.map(child => child.$validator.validateAll()),
        );

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addBrand({
          ...this.general,
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η μάρκα αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = {
        title: '',
        slug: '',
        description: '',
        content: '',
        active: false,
      };

      this.$children.forEach(child => child.$validator.reset());
    },
    updateGeneral(general) {
      this.general = general;
    },
  },
};
</script>
