<template>
  <div>
    <nav class="level">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">STATUS</p>
          <p class="title">
            <span :class="['tag is-medium', classes]">{{ status }}</span>
          </p>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    set: Object,
  },
  computed: {
    status() {
      const { set } = this;
      /* eslint-disable */
      if (set.deleted_at !== null) {
        return 'Απενεργοποιημένo Set';
      } else if (set.active === true) {
        return 'Εμφανές Set';
      } else if (set.active === false) {
        return 'Μη Εμφανές Set';
      } else {
        return 'Set';
      }
    },
    classes() {
      const set = this.set;
      /* eslint-disable */
      if (set.deleted_at !== null) {
        return 'is-danger';
      } else if (set.active === true) {
        return 'is-primary';
      } else if (set.active === false) {
        return 'is-warning';
      } else {
        return 'is-white';
      }
    },
  },
};
</script>
