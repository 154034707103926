<template>
  <LoadingContainer :is-loading="isLoadingModel || isLoadingItems">
    <div class="columns">
      <div class="column is-three-quarters">
        <form novalidate @submit.prevent="submit">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Επεξεργασία Ομάδας Φίλτρων
                  <span class="tag">
                    <strong>{{ model.title }}</strong>
                  </span>
                </div>
              </div>
              <div class="card-header-icon">
                <div class="field has-addons is-marginless">
                  <div class="control">
                    <router-link
                      :to="{ name: 'products.filterGroups.list' }"
                      class="button"
                    >
                      <span class="icon is-small">
                        <i class="fa fa-list-ul" />
                      </span>
                      <span>Λίστα</span>
                    </router-link>
                  </div>
                  <div class="control">
                    <router-link
                      :to="{
                        name: 'products.filterGroups.filters.add',
                        params: { uuid: $route.params.uuid },
                      }"
                      class="button"
                    >
                      <span class="icon is-small"><i class="fa fa-plus"/></span
                      ><span>Προσθήκη Φίλτρου</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content">
              <div class="field">
                <label class="label">Ονομασία *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="newFilterGroup.title"
                    class="input"
                    type="text"
                    name="title"
                  />
                  <p v-show="errors.has('title')" class="help is-danger">
                    Εισάγετε ονομασία
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">
                  Ονομασία
                  <span class="icon is-small fa flag-icon flag-icon-gb" />
                </label>
                <div class="control">
                  <input
                    v-model.trim="newFilterGroup.title_en"
                    class="input"
                    type="text"
                    name="title_en"
                  />
                </div>
              </div>

              <div class="field">
                <label class="label">
                  Ονομασία e-shop
                  <span class="icon is-small fa fa-shopping-bag" />
                </label>
                <div class="control">
                  <input
                    v-model.trim="newFilterGroup.eshop_title"
                    class="input"
                    type="text"
                    name="eshop_title"
                  />
                </div>
              </div>

              <div class="field">
                <label class="label">Προβολή *</label>
                <div class="control">
                  <div class="select select is-fullwidth">
                    <select
                      v-validate="'required'"
                      v-model.trim="newFilterGroup.active"
                      name="active"
                    >
                      <option :value="true">Ναί</option>
                      <option :value="false">Όχι</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <FormSubmit :is-saving="isSaving" @reset="reset" />
            </div>
          </div>
        </form>
      </div>
      <div class="column">
        <ExistingFilters :items="filters" :filter-group="model" />
      </div>
    </div>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _pick from 'lodash/pick';
import AddItem from '@/views/components/AddItem';
import EditGeneralDetails from '@/views/components/EditGeneralDetails';
import EditMetaDetails from '@/views/components/EditMetaDetails';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import ExistingFilterGroups from '../../components/ExistingFilterGroups';
import ExistingFilters from '../../components/ExistingFilters';

export default {
  components: {
    EditMetaDetails,
    EditGeneralDetails,
    ToggleableCard,
    LoadingContainer,
    ExistingFilterGroups,
    ExistingFilters,
  },
  extends: AddItem,
  data() {
    return {
      newFilterGroup: {},
    };
  },
  computed: {
    ...mapGetters({
      model: 'filterGroups/edit/getFilterGroup',
      filters: 'filters/all/getFilters',
      isLoadingModel: 'filterGroups/edit/getIsLoading',
      isLoadingItems: 'filterGroups/list/getIsLoading',
    }),
  },
  watch: {
    $route: 'fetchData',
    model: {
      handler(newVal) {
        this.newFilterGroup = _pick(newVal, [
          'title',
          'title_en',
          'eshop_title',
          'active',
        ]);
      },
      deep: true,
    },
  },
  async created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      getFilterGroup: 'filterGroups/edit/getFilterGroup',
      updateFilterGroup: 'filterGroups/edit/updateFilterGroup',
      getFilters: 'filters/all/getFilters',
    }),
    async fetchData() {
      try {
        await Promise.all([
          this.getFilterGroup({ uuid: this.$route.params.uuid }),
          this.getFilters({ uuid: this.$route.params.uuid }),
        ]);
      } catch (err) {
        this.$router.push('/error');
      }
    },
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateFilterGroup({
          uuid: this.model.uuid,
          filterGroup: this.newFilterGroup,
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η ομάδα φίλτρων αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.newFilterGroup = _pick(this.model, [
        'title',
        'title_en',
        'eshop_title',
        'active',
      ]);

      this.$validator.reset();
    },
  },
};
</script>
