import request from '@/utils/request';
import { calculateParams } from '@/utils/calculations';

/* eslint-disable */
const actions = {
  async getActiveProducts({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get('/products-active', {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.products);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getAllProducts({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get('/products', {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.products);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getDeletedProducts({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get('/products-deleted', {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.products);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async getInactiveProducts({ dispatch, commit, getters }, params = {}) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      const newParams = calculateParams(getters['getPaginationParams'], params);

      await commit('setPaginationParams', newParams);

      const { data } = await request.get('/products-inactive', {
        params: newParams,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setData', data.products);

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async massUpdateProducts(
    { dispatch, commit, getters },
    { selections, updates },
  ) {
    try {
      await request.put('/products', {
        selections,
        updates,
      });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },
};

export default actions;
