import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getEmailTemplates({ commit }) {
    try {
      const { data } = await request.get('/emailTemplates');

      await commit('setEmailTemplates', {
        emailTemplates: data.emailTemplates,
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  },

  async addEmailTemplate({ dispatch }, { emailTemplate }) {
    try {
      const { data } = await request.post(`/emailTemplates`, emailTemplate);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await dispatch('getEmailTemplates');
    } catch (err) {
      throw err;
    }
  },

  async editEmailTemplate({ dispatch }, { uuid, emailTemplate }) {
    try {
      const { data } = await request.put(
        `/emailTemplates/${uuid}`,
        emailTemplate,
      );

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await dispatch('getEmailTemplates');
    } catch (err) {
      throw err;
    }
  },

  async deleteEmailTemplate({ dispatch }, { emailTemplate }) {
    try {
      const { data } = await request.delete(
        `/emailTemplates/${emailTemplate.uuid}`,
      );

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await dispatch('getEmailTemplates');
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
