/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setCustomer(state, { customer }) {
    state.data = customer;
  },

  updateCustomer(state, { customer }) {
    state.data = {
      ...state.data,
      ...customer,
    };
  },

  updateCustomerEmails(state, { emails }) {
    state.data.incoming_emails = emails;
  },

  addCustomerContact(state, { contact }) {
    state.data.contacts = [contact, ...state.data.contacts];
  },
};

export default mutations;
