<template>
  <div class="tabs is-boxed">
    <ul>
      <RouterLink
        v-slot="{ href, navigate, isActive }"
        :to="{ name: 'products.categories.edit', params: { uuid } }"
        custom
      >
        <li :class="[isActive && 'router-link-active is-active']" role="link">
          <a :href="href" @keypress.enter="navigate" @click="navigate">
            <span>Main page</span>
          </a>
        </li>
      </RouterLink>
      <RouterLink
        v-slot="{ href, navigate, isActive }"
        :to="{ name: 'products.categories.edit.products', params: { uuid } }"
        custom
      >
        <li :class="[isActive && 'router-link-active is-active']" role="link">
          <a :href="href" @keypress.enter="navigate" @click="navigate">
            <span>Products page</span>
          </a>
        </li>
      </RouterLink>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uuid: this.$route.params.uuid,
    };
  },
};
</script>
