<template>
  <tr>
    <td class="has-text-centered">{{ index + 1 }}</td>
    <td class="has-text-centered">
      <ProductPhoto :type="item.type" :photos="item.photos" />
    </td>
    <td class="has-text-centered">
      <p v-for="(barcode, index) in item.barcodes" :key="index">
        {{ barcode.code }}
      </p>
    </td>
    <td class="has-text-centered">
      {{ item.common_id }}
    </td>
    <td class="has-text-centered" style="maxWidth: 250px">
      <router-link :to="{ name: 'products.edit', params: { uuid: item.uuid } }">
        {{ item.title }}
      </router-link>
    </td>
    <td>
      <div class="select">
        <select
          :value="item.pivot.color_id"
          name="color_id"
          @change="handleChangeColor"
        >
          <option :value="null">Επέλεξε</option>
          <option v-for="color in colors" :key="color.id" :value="color.id">
            {{ color.title }}
          </option>
        </select>
      </div>
    </td>
    <td>
      <div class="select">
        <select
          :value="item.pivot.size_id"
          name="size_id"
          @change="handleChangeSize"
        >
          <option :value="null">Επέλεξε</option>
          <option v-for="size in sizes" :key="size.id" :value="size.id">
            {{ size.title }}
          </option>
        </select>
      </div>
    </td>
    <td class="has-text-centered">
      {{ item.stock }}
    </td>
    <td class="has-text-centered">
      {{ item.sell_price }}
    </td>
    <td class="has-text-centered">
      {{ item.discount }}
    </td>
    <td class="has-text-centered">
      {{ item.final_price }}
    </td>
    <td class="has-text-centered">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        disabled
      />
    </td>
    <td class="has-text-centered">
      <div class="buttons is-centered">
        <router-link
          :to="{ name: 'products.edit', params: { uuid: item.uuid } }"
          class="button is-small"
        >
          <span class="icon is-small">
            <span class="fa fa-fw fa-pencil" />
          </span>
        </router-link>
        <button type="button" class="button is-small" @click="onDelete">
          <span class="icon is-small">
            <span class="fa fa-fw fa-trash-o" />
          </span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import ProductPhoto from '@/views/components/ProductPhoto';

export default {
  components: {
    ProductPhoto,
  },
  props: {
    index: Number,
    item: Object,
    colors: Array,
    sizes: Array,
  },
  computed: {
    hasAvatar() {
      if (!this.item.photos.length) {
        return false;
      }
      /* eslint-disable camelcase */
      return (
        this.item.photos.find(({ is_avatar }) => is_avatar === true) !==
        undefined
      );
    },
  },
  methods: {
    onDelete() {
      this.$emit('onDelete', this.item);
    },
    handleChangeColor(e) {
      this.$emit('onChangeColor', {
        id: this.item.id,
        color_id: parseInt(e.target.value, 10),
      });
    },
    handleChangeSize(e) {
      this.$emit('onChangeSize', {
        id: this.item.id,
        size_id: parseInt(e.target.value, 10),
      });
    },
  },
};
</script>

<style scoped>
.select select {
  width: 150px;
}
</style>
