<template>
  <tr>
    <td class="has-text-centered">{{ index + 1 }}</td>
    <td class="has-text-centered" style="maxWidth: 250px">
      <div v-if="item.products.length">
        <p v-for="product in item.products" :key="product.id">
          <router-link
            v-if="model.id !== product.id"
            :to="{ name: 'products.edit', params: { uuid: product.uuid } }"
          >
            <i class="fa fa-angle-double-right" /> {{ product.title }}
          </router-link>
          <template v-else>
            <i class="fa fa-angle-double-right" />
            {{ product.title }}
          </template>
        </p>
      </div>
      <div v-else>Δε βρέθηκαν προϊόντα</div>
    </td>
    <td class="has-text-centered">{{ item.discount }} &#x25;</td>
    <td class="has-text-centered">
      {{ item.expires_at | timestamp('YYYY-MM-DD', 'DD-MM-YYYY') }}
    </td>
    <td class="has-text-centered">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        disabled
      />
    </td>
    <td class="has-text-centered">
      <router-link
        :to="{ name: 'marketing.bundles.edit', params: { uuid: item.uuid } }"
        class="button is-small"
      >
        <span class="icon is-small">
          <span class="fa fa-fw fa-pencil" />
        </span>
      </router-link>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    index: Number,
    model: Object,
    item: Object,
  },
};
</script>
