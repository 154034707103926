import get from 'lodash/get';
import axios from 'axios';
import Qs from 'qs';

import getters from '../store/session/getters';
import store from '../store';
import router from '../router';

/* eslint-disable no-undef */
const request = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}admin/`,
  headers: {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'brackets' }),
});

const isHandlerEnabled = (config = {}) => get(config, 'handlerEnabled', true);

request.interceptors.request.use(
  config => {
    const token = getters.getToken(store.state.session);

    if (token) {
      return {
        ...config,
        headers: {
          Authorization: token,
        },
      };
    }

    return config;
  },
  error => Promise.reject(error),
);

request.interceptors.response.use(
  response => {
    const newToken = get(response, 'headers.authorization');

    if (newToken) {
      console.log('newToken', newToken);

      store.commit('updateToken', {
        token: newToken,
      });
    }

    return response;
  },
  async error => {
    if (isHandlerEnabled(error.config)) {
      const message = get(error, 'response.data.error_message', error.message);
      const status = get(error, 'response.status', 500);

      console.log(message, status);

      if (status === 401) {
        console.log('should logout here');

        store.dispatch('logoutUser');
      }

      if (status === 500) {
        router.push('/error');
      }
    }

    throw error;
  },
);

export default request;
