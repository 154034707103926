<script>
import { mapGetters } from 'vuex';
import request from '@/utils/request';
import PaginatedList from '@/views/components/PaginatedList';
import PromosHeader from './components/PromosHeader';
import Promo from './components/Promo';

export default {
  components: {
    PromosHeader,
    Promo,
  },
  extends: PaginatedList,
  computed: {
    ...mapGetters({
      items: 'promos/list/getData',
      paginationParams: 'promos/list/getPaginationParams',
      isLoadingPromos: 'promos/list/getIsLoading',
      isLoaded: 'promos/list/getIsLoaded',
    }),
  },
  methods: {
    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;

        if (response) {
          await request.delete(`/promos/${this.selectedItem.uuid}`);

          await this.navigate({
            timestamp: new Date().getTime(),
          });

          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Το Marketing Page διαγράφτηκε επιτυχώς!!',
          });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
