<template>
  <div class="customer-tab">
    <table
      v-if="customer.products.length"
      class="table is-bordered is-striped is-marginless is-fullwidth"
    >
      <thead>
        <tr>
          <th class="has-text-centered">Φώτο</th>
          <th class="has-text-centered">Barcodes</th>
          <th class="has-text-centered">Common ID</th>
          <th class="has-text-centered">Τίτλος</th>
          <th class="has-text-centered">Π.Λ. Τιμή (&euro;)</th>
          <th class="has-text-centered">Έκπτωση (&#x25;)</th>
          <th class="has-text-centered">Τελική Τιμή (&euro;)</th>
          <th class="has-text-centered">Προβολή</th>
          <th class="has-text-centered">Ενέργειες</th>
        </tr>
      </thead>
      <tbody>
        <product
          v-for="(item, index) in customer.products"
          :item="item"
          :index="index"
          :key="index"
        />
      </tbody>
    </table>
    <h5 v-else class="title is-5 has-text-centered is-marginless">
      Δε βρέθηκαν προϊόντα
    </h5>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Product from './components/Product';

export default {
  components: {
    Product,
  },

  computed: {
    ...mapGetters({
      customer: 'customers/edit/getCustomer',
    }),
  },
};
</script>
