<template>
  <LoadingContainer :is-loading="isLoading">
    <div class="columns is-multiline">
      <div class="column is-full">
        <BundleInfo :bundle="model" />
      </div>
    </div>

    <form class="has-sticky-submit" @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            Επεξεργασία Bundle
          </div>
          <div class="card-header-icon">
            <div class="field has-addons is-marginless">
              <div class="control">
                <router-link
                  :to="{ name: 'marketing.bundles.list' }"
                  class="button"
                >
                  <span class="icon is-small"><i class="fa fa-list-ul"/></span
                  ><span>Λίστα</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-half">
              <div class="field">
                <label class="label">Εμφανές *</label>
                <div class="control">
                  <div class="select select is-fullwidth">
                    <select v-model="general.active" name="active">
                      <option :value="false">Όχι</option>
                      <option :value="true">Ναί</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="column is-half">
              <div class="field">
                <label class="label">Ημ/νία Λήξης *</label>
                <div class="control">
                  <Datepicker
                    v-validate="'required'"
                    v-model="general.expires_at"
                    name="expires_at"
                    format="dd-MM-yyyy"
                    language="el"
                    input-class="input"
                    placeholder="πχ: 02-12-2017"
                    data-vv-name="expires_at"
                  />
                  <p v-show="errors.has('expires_at')" class="help is-danger">
                    Εισάγετε ημ/νία λήξης
                  </p>
                </div>
              </div>
            </div>

            <div class="column is-half">
              <div class="field">
                <label class="label">Βάρος *</label>
                <div class="control">
                  <input
                    v-validate="'required|excluded:0'"
                    v-model.trim.number="general.weight"
                    class="input"
                    type="number"
                    name="weight"
                    min="0"
                    step="0.01"
                  />
                  <p v-show="errors.has('weight')" class="help is-danger">
                    Εισάγετε βάρος
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToggleableCard :is-open="true" title="Bundle Items">
        <template v-slot:info>
          <ul>
            <li>
              <i class="fa fa-check" /> Τυχόν μεταβολή των bundle items θα
              μεταβάλλει το συνολικό <strong>Βάρος</strong> αυτόματα
            </li>
            <li>
              <i class="fa fa-check" /> Τυχόν μεταβολή των bundle items θα
              μεταβάλλει την <strong>Π.Λ. Τιμή Πώλησης</strong> και την
              <strong>Τελική Τιμή</strong> αυτόματα
            </li>
          </ul>
        </template>
        <div class="card-content">
          <table
            v-if="Boolean(hasProducts)"
            class="table is-bordered is-striped is-fullwidth"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Φώτο</th>
                <th>Barcodes</th>
                <th>Common ID</th>
                <th>Τίτλος</th>
                <th>Π.Λ. Τιμή (&euro;)</th>
                <th>Έκπτωση (&#x25;)</th>
                <th>Τελική Τιμή (&euro;)</th>
                <th>Προβολή</th>
                <th>Βάρος<br />(ανά προιόν)</th>
                <th>Ενέργειες</th>
              </tr>
            </thead>
            <tbody>
              <product
                v-for="(item, index) in general.products"
                :item="item"
                :index="index"
                :key="item.id"
                @onDelete="onDeleteProduct"
              />
            </tbody>
          </table>

          <products-search
            v-if="hasProducts < 3"
            :bundle="general"
            @selectProduct="onSelectProduct"
          />
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Τιμολογιακή Πολιτική">
        <template v-slot:info>
          <ul>
            <li>
              <i class="fa fa-check" /> Τυχόν μεταβολή των προιόντων θα
              μεταβάλλει την <strong>Τελική Τιμή</strong> και την
              <strong>Τελική Τιμή Bundle</strong> αυτόματα
            </li>
            <li>
              <i class="fa fa-check" /> Στα bundles η
              <strong>Τελική Τιμή Bundle</strong> δεν μπορεί να μεταβληθεί άμεσα
              απο το χρήστη. Προτιμήστε την αλλαγή του
              <strong>Ποσοστού Έκπτωσης</strong>
            </li>
          </ul>
        </template>
        <div class="card-content">
          <EditBundlePrice :product="general" @updatePrice="updatePrice">
            <div class="column is-half">
              <div class="field">
                <label class="label">ΦΠΑ *</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model.trim.number="general.tax_id" name="tax_id">
                      <option
                        v-for="item in taxes"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.percentage }} %
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </EditBundlePrice>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Συμπτώματα & Tags">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-full">
              <label class="label">Συμπτώματα</label>
              <symptoms-search
                :symptoms="general.symptoms"
                @selectSymptom="selectSymptom"
                @removeSymptom="removeSymptom"
              />
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Tags</label>
                <div class="control">
                  <multiselect
                    v-model="general.tags"
                    :options="tags"
                    :multiple="true"
                    name="tags"
                    class="multiple"
                    track-by="id"
                    label="title"
                    placeholder="Επιλέξτε tags"
                    selected-label="Επιλεγμένο"
                    select-label="Πατήστε enter για επιλογή"
                    deselect-label="Πατήστε enter για απο-επιλογή"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ToggleableCard>

      <StickyFormFooter>
        <template v-slot:left>
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </template>
      </StickyFormFooter>
    </form>
  </LoadingContainer>
</template>

<script>
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import _round from 'lodash/round';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import AddItem from '@/views/components/AddItem';
import LoadingContainer from '@/views/components/LoadingContainer';
import ToggleableCard from '@/views/components/ToggleableCard';
import StickyFormFooter from '@/views/components/StickyFormFooter';
import EditBundlePrice from '../components/EditBundlePrice';
import ProductsSearch from '../components/ProductsSearch';
import SymptomsSearch from '../components/SymptomsSearch';
import Product from '../components/Product';
import BundleInfo from './components/BundleInfo';

export default {
  components: {
    Datepicker,
    ProductsSearch,
    SymptomsSearch,
    Product,
    ToggleableCard,
    StickyFormFooter,
    EditBundlePrice,
    Multiselect,
    LoadingContainer,
    BundleInfo,
  },
  extends: AddItem,
  data() {
    return {
      general: {},
    };
  },
  computed: {
    ...mapGetters({
      model: 'bundles/edit/getBundle',
      tags: 'tags/all/getTags',
      taxes: 'taxes/list/getTaxes',
      isLoading: 'bundles/edit/getIsLoading',
    }),
    hasProducts() {
      return _get(this.general, 'products', []).length;
    },
  },
  watch: {
    model: {
      handler(newVal) {
        this.setBundle(newVal);
      },
      deep: true,
    },
    'general.products': {
      /* eslint-disable camelcase */
      handler(newVal, oldVal) {
        if (!_isEqual(newVal, oldVal)) {
          const totalWeight = _round(
            newVal.reduce((acc, { weight }) => acc + Number(weight), 0),
            2,
          );

          const totalBuyPrice = _round(
            newVal.reduce((acc, { buy_price }) => acc + buy_price, 0),
            2,
          );

          const totalSellPrice = _round(
            newVal.reduce((acc, { sell_price }) => acc + sell_price, 0),
            2,
          );

          const totalFinalPrice = _round(
            newVal.reduce((acc, { final_price }) => acc + final_price, 0),
            2,
          );

          const totalBundlePrice = _round(
            totalFinalPrice - (this.general.discount * totalFinalPrice) / 100,
            2,
          );

          this.general = {
            ...this.general,
            weight: totalWeight,
            buy_price: totalBuyPrice,
            sell_price: totalSellPrice,
            final_price: totalFinalPrice,
            bundle_price: totalBundlePrice,
          };
        }
      },
      deep: true,
    },
  },
  async created() {
    try {
      await Promise.all([
        this.getBundle({ uuid: this.$route.params.uuid }),
        this.getTags(),
        this.getTaxes(),
      ]);
    } catch (err) {
      this.$router.push('/error');
    }
  },
  methods: {
    ...mapActions({
      getBundle: 'bundles/edit/getBundle',
      updateBundle: 'bundles/edit/updateBundle',
      getTags: 'tags/all/getTags',
      getTaxes: 'taxes/list/getTaxes',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateBundle({
          uuid: this.model.uuid,
          bundle: {
            ...this.general,
            expires_at: this.general.expires_at
              ? moment(this.general.expires_at).format('DD-MM-YYYY')
              : null,
            products: this.general.products.map(({ id }) => id),
            tags: this.general.tags.map(({ id }) => id),
            symptoms: this.general.symptoms.map(({ id }) => id),
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το bundle αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    setBundle(bundle) {
      this.general = _pick(bundle, [
        'active',
        'expires_at',
        'buy_price',
        'sell_price',
        'discount',
        'final_price',
        'bundle_price',
        'tax_id',
        'weight',
        'products',
        'tags',
        'symptoms',
      ]);
    },
    reset() {
      this.setBundle(this.model);

      this.$validator.reset();
    },
    updatePrice(price) {
      this.general = {
        ...this.general,
        ...price,
      };
    },
    selectSymptom(symptom) {
      this.general = {
        ...this.general,
        symptoms: [...this.general.symptoms, symptom],
      };
    },
    removeSymptom(symptom) {
      this.general = {
        ...this.general,
        symptoms: this.general.symptoms.filter(({ id }) => id !== symptom.id),
      };
    },
    onDeleteProduct(product) {
      this.general = {
        ...this.general,
        products: this.general.products.filter(({ id }) => id !== product.id),
      };
    },
    onSelectProduct(product) {
      this.general = {
        ...this.general,
        products: [
          ...this.general.products,
          {
            ...product,
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.multiselect {
  margin: 0 auto !important;
}
</style>
