import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getFilterGroup({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/filterGroups/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setFilterGroup', {
        filterGroup: data.filterGroup,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async updateFilterGroup({ dispatch, commit }, { uuid, filterGroup }) {
    try {
      const { data } = await request.put(`/filterGroups/${uuid}`, filterGroup);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updateFilterGroup', {
        filterGroup,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
