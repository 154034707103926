import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getCheckoutMethods({ commit }) {
    try {
      const { data } = await request.get('/checkoutMethods');

      await commit('setCheckoutMethods', { checkoutMethods: data.checkoutMethods });
    } catch (err) {
      throw err;
    }
  },

  async toggleCheckoutMethodStatus({ commit, getters }, { uuid, active }) {
    try {
      const { data } = await request.put(`/checkoutMethods/${uuid}/active`, {
        active,
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      const checkoutMethods = getters.getCheckoutMethods.map(method => ({
        ...method,
        active: method.uuid === uuid ? active : method.active,
      }));

      await commit('setCheckoutMethods', { checkoutMethods });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
