/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setPaginationParams(state, params) {
    state.paginationParams = {
      ...state.paginationParams,
      ...params,
    };
  },

  setData(state, payload) {
    const { data, current_page, per_page, total, from } = payload;

    state.isLoaded = true;
    state.data = data;
    state.paginationParams = {
      ...state.paginationParams,
      page: current_page,
      size: parseInt(per_page, 10),
      count: total,
      indexStartsFrom: from,
      pages: Math.ceil(total / per_page),
    };
  },
};

export default mutations;
