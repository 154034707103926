<template>
  <tr>
    <td class="has-text-centered">{{ index }}</td>
    <td>
      <router-link
        :to="{ name: 'content.posts.edit', params: { uuid: item.uuid } }"
      >
        {{ item.title }}
      </router-link>
    </td>
    <td class="has-text-centered">{{ item.published_at }}</td>
    <td class="has-text-centered">
      <span class="button is-small is-static">{{ item.views }}</span>
    </td>
    <td class="has-text-centered">
      <span class="button is-small is-static">{{ item.tags_count }}</span>
    </td>
    <td class="has-text-centered">
      <span class="button is-small is-static">{{ item.symptoms_count }}</span>
    </td>
    <td class="has-text-centered">
      <span class="button is-small is-static">{{
        item.post_categories_count
      }}</span>
    </td>
    <td class="has-text-centered hidden-print">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        :disabled="true"
      />
    </td>
    <td class="has-text-centered visible-print">
      {{ item.active === true ? 'Ναί' : 'Όχι' }}
    </td>
    <td class="has-text-centered hidden-print">
      <div class="buttons is-centered">
        <router-link
          :to="{ name: 'content.posts.edit', params: { uuid: item.uuid } }"
          class="button is-small"
        >
          <span class="icon is-small">
            <span class="fa fa-fw fa-pencil" />
          </span>
        </router-link>
        <button class="button is-small" @click="onDelete">
          <span class="icon is-small">
            <span class="fa fa-fw fa-trash-o" />
          </span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';

export default {
  extends: PaginatedListItem,
};
</script>
