/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setAuthor(state, { author }) {
    state.data = author;
  },

  updateAuthor(state, { author }) {
    state.data = {
      ...state.data,
      ...author,
    };
  },
};

export default mutations;
