<template>
  <div class="columns is-multiline product">
    <div class="column is-full">
      <div class="field">
        <div class="product__field">
          <label class="label">{{ title }}</label>
          <button class="button" type="button" @click="handleDeleteProduct">
            <span class="icon is-small">
              <i class="fa fa-fw fa-trash-o" />
            </span>
            <span>Διαγραφή προϊόντος</span>
          </button>
        </div>
        <ProductsSearch
          v-model="product.product_id"
          :product-id="parentProduct.id"
          :family="family"
          data-vv-name="product_id"
          @select="handleSelectProduct"
        />
        <p v-if="!hasProduct" class="help is-danger">
          Επιλέξτε προϊόν
        </p>
      </div>
    </div>
    <div class="column is-full">
      <div v-if="hasProduct" class="columns">
        <div class="column is-half">
          <table class="table is-bordered is-striped is-fullwidth">
            <thead>
              <th>Φωτό</th>
              <th>Προϊόν</th>
            </thead>
            <tbody>
              <FamilyProductItem :product="selectedProduct" />
            </tbody>
          </table>
        </div>

        <div class="column is-half">
          <div v-if="hasAttributes" class="attributes">
            <FamilyProductAttribute
              v-for="(attribute, attributeIndex) in formProduct.attributes"
              :key="attributeIndex"
              :attribute="attribute"
              :index="attributeIndex"
              @change="handleChangeAttribute"
              @delete="handleDeleteAttribute"
            />
          </div>
          <div class="has-text-centered">
            <button class="button" type="button" @click="handleAttribute">
              <span class="icon is-small">
                <i class="fa fa-plus" />
              </span>
              <span>Προσθήκη Χαρακτηριστικού</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import ProductsSearch from './ProductsSearch';
import FamilyProductItem from './FamilyProductItem';
import FamilyProductAttribute from './FamilyProductAttribute';

export default {
  inject: ['$validator'],

  components: {
    ProductsSearch,
    FamilyProductItem,
    FamilyProductAttribute,
  },

  props: {
    parentProduct: {
      type: Object,
      required: true,
    },

    family: {
      type: Object,
      required: true,
    },

    product: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      formProduct: null,
      selectedProduct: {},
    };
  },

  computed: {
    title() {
      return `Προϊόν ${this.index + 1}`;
    },

    hasProduct() {
      return !isEmpty(this.selectedProduct);
    },

    hasAttributes() {
      return !isEmpty(this.formProduct.attributes);
    },
  },

  watch: {
    product: {
      handler(newVal) {
        if (newVal) {
          this.formProduct = { ...newVal };

          this.selectedProduct = this.formProduct.product;
        }
      },
      immediate: true,
    },
  },

  methods: {
    handleSelectProduct(product) {
      this.selectedProduct = product;

      this.formProduct = {
        ...this.formProduct,
        product,
      };
      this.$emit('change', { index: this.index, product: this.formProduct });
    },

    handleDeleteProduct() {
      this.$emit('delete', this.index);
    },

    handleAttribute() {
      this.formProduct = {
        ...this.formProduct,
        attributes: [
          ...this.formProduct.attributes,
          {
            key: '',
            value: '',
          },
        ],
      };
      this.$emit('change', { index: this.index, product: this.formProduct });
    },

    handleChangeAttribute(item) {
      this.formProduct.attributes[item.index] = { ...item.attribute };

      this.$emit('change', { index: this.index, product: this.formProduct });
    },

    handleDeleteAttribute(index) {
      const attributes = this.formProduct.attributes.filter(
        (attribute, attributesIndex) => attributesIndex !== index,
      );

      this.formProduct = {
        ...this.formProduct,
        attributes: [...attributes],
      };

      this.$emit('change', { index: this.index, product: this.formProduct });
    },
  },
};
</script>

<style lang="scss" scoped>
.product {
  &:not(:first-child) {
    border-top: 1px solid #dbdbdb;
  }

  &__field {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
}

.attributes {
  margin-bottom: 1.5rem;
}
</style>
