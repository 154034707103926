export const META = {
  title: '',
  content: '',
  keywords: '',
  schema: '',
};

export const SOCIAL_META = {
  og_title: '',
  og_description: '',
  og_type: '',
  og_url: '',
  new_og_image: '',
  og_site_name: '',
  twitter_title: '',
  twitter_description: '',
  new_twitter_image: '',
  twitter_card: '',
};

export const TWITTER_CARD_OPTIONS = [
  'summary',
  'summary_large_image',
  'app',
  'player',
];
