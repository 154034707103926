<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          ΦΠΑ <span v-if="total > 0" class="tag">{{ total }}</span>
        </div>
      </div>
      <div class="card-header-icon">
        <div class="field">
          <div class="control">
            <router-link :to="{ name: 'settings.taxes.add' }" class="button">
              <span class="icon is-small"><i class="fa fa-plus"/></span
              ><span>Προσθήκη</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="table-wrapper">
        <table
          v-if="items.length"
          class="table is-bordered is-striped is-marginless is-fullwidth"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Τίτλος</th>
              <th>Ποσοστό</th>
              <th>Κύρια</th>
            </tr>
          </thead>
          <tbody>
            <tax
              v-for="(item, index) in items"
              :item="item"
              :index="index + 1"
              :key="item.id"
              @makeDefaultTax="makeDefaultTax"
            />
          </tbody>
        </table>
        <h4 v-else class="title is-4 has-text-centered">
          Δε βρέθηκαν επιλογές φόρων
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Tax from './components/Tax';

export default {
  components: {
    Tax,
  },
  computed: {
    ...mapGetters({
      items: 'taxes/list/getTaxes',
    }),
    total() {
      return this.items.length;
    },
  },
  async created() {
    await this.getTaxes();
  },
  methods: {
    ...mapActions({
      setDefaultTax: 'taxes/list/setDefaultTax',
      getTaxes: 'taxes/list/getTaxes',
    }),
    async makeDefaultTax(selectedItem) {
      try {
        await this.setDefaultTax({ tax: selectedItem });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: `Η επιλογή ΦΠΑ "${selectedItem.title}" αποθηκεύτηκε ως κύρια!!`,
        });
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
