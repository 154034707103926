<template>
  <div :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background" @click="closeModal(false)" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button type="button" class="delete" @click="closeModal(false)" />
      </header>
      <section class="modal-card-body">
        <div v-if="content">
          <h5 class="title is-5">{{ content }}</h5>
        </div>

        <div v-if="description">
          <h5 class="title is-5">{{ description }}</h5>
          <p v-if="modelName" class="is-6">{{ modelName }}</p>
        </div>

        <slot />
      </section>
      <footer class="modal-card-foot">
        <button
          :class="{ 'is-loading': isSaving }"
          :disabled="isSaving"
          type="button"
          class="button is-success"
          @click="closeModal(true)"
        >
          Ναί
        </button>
        <button
          type="button"
          class="button is-danger"
          @click="closeModal(false)"
        >
          Όχι
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RestoreConfirmation',
  props: {
    isSaving: false,
    title: {
      type: String,
      default: 'Επιβεβαίωση Επαναφοράς',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    modelName: {
      type: [String, Number],
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal(isOk) {
      this.$emit('closeRestoreModal', isOk);
    },
  },
};
</script>
