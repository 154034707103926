<template>
  <div class="tabs is-boxed">
    <ul>
      <router-link
        v-slot="{ href, navigate, isExactActive }"
        :to="{ name: 'marketing.giftActions.giftActions.edit', params: { id } }"
        custom
      >
        <li
          :class="[isExactActive && 'router-link-active is-active']"
          role="link"
        >
          <a :href="href" @keypress.enter="navigate" @click="navigate">
            <span>Gift Action</span>
          </a>
        </li>
      </router-link>
      <router-link
        v-slot="{ href, navigate, isActive }"
        :to="{
          name: 'marketing.giftActions.giftActions.edit.orders',
          params: { id },
        }"
        custom
      >
        <li :class="[isActive && 'router-link-active is-active']" role="link">
          <a :href="href" @keypress.enter="navigate" @click="navigate">
            <span>Orders</span>
          </a>
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
    };
  },
};
</script>
