import list from './list';
import add from './add';
import edit from './edit';
import destroy from './destroy';

const module = {
  namespaced: true,
  modules: {
    list,
    add,
    edit,
    destroy,
  },
};

export default module;
