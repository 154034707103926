<template>
  <tr>
    <td class="has-text-centered">{{ index + 1 }}</td>
    <td class="has-text-centered">
      <ProductPhoto
        :type="product.type"
        :is-gift-action-gift="isGiftActionGift"
        :photos="photos"
      />
    </td>
    <td class="has-text-centered print__cell-show">
      <p class="visible-print is-size-5">{{ product.common_id }}</p>
      <p class="hidden-print">{{ product.common_id }}</p>

      <template v-if="hasCode">
        <p class="is-size-5">{{ product.pivot.code }}</p>
      </template>
      <template v-else>
        <p
          v-for="(barcode, index) in product.barcodes"
          :key="index"
          class="is-size-5"
        >
          {{ barcode.code }}
        </p>
      </template>
    </td>
    <td class="has-text-centered" style="maxWidth: 250px">
      <router-link
        :to="{ name: 'products.edit', params: { uuid: product.uuid } }"
        class="hidden-print"
      >
        {{ product.title }}
      </router-link>
      <p class="visible-print is-size-5">{{ product.title }}</p>
      <p class="hidden-print">{{ product.common_id }}</p>

      <template v-if="hasCode">
        <p class="hidden-print">{{ product.pivot.code }}</p>
      </template>
      <template v-else>
        <p
          v-for="(barcode, index) in product.barcodes"
          :key="index"
          class="hidden-print"
        >
          {{ barcode.code }}
        </p>
      </template>
    </td>
    <td class="has-text-centered td__barcode">
      <div v-if="hasVariations" class="field">
        <VariationsList
          v-if="isEditing"
          :items="variationItems"
          :variation-type="variationType"
          :selected-variation="selectedVariation"
          @on-select="handleSelectVariation"
        />
        <p v-else>{{ selectedVariationTitle }}</p>
      </div>
    </td>
    <td class="has-text-centered">{{ stock }}</td>
    <td class="has-text-centered hidden-print">
      <ToggleButton
        :sync="true"
        :value="active"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        :disabled="true"
      />
    </td>
    <td class="has-text-centered">
      {{ isGiftActionGift ? '-' : product.pivot.sell_price }}
    </td>
    <td class="has-text-centered">
      {{ isGiftActionGift ? '-' : product.pivot.discount }}
    </td>
    <td class="has-text-centered">
      {{ isGiftActionGift ? '-' : product.pivot.final_price }}
    </td>
    <td class="has-text-centered">
      {{ isGiftActionGift ? '-' : product.tax.percentage }}
    </td>
    <td class="has-text-centered td__count">
      <input
        v-if="isEditing"
        v-model.number="product.pivot.count"
        class="input has-text-centered"
        type="number"
        name="count"
        min="1"
      />
      <div v-else class="count">
        &times;
        <span
          :class="[
            'title',
            'hidden-print',
            {
              'is-5': product.pivot.count === 1,
              'is-3': product.pivot.count > 1,
            },
          ]"
        >
          {{ isGiftActionGift ? product.count : product.pivot.count }}
        </span>
        <span
          :class="[
            'title',
            'visible-print',
            {
              'is-5': product.pivot.count === 1,

              'is-1': product.pivot.count > 1,
              'has-text-weight-bold	': product.pivot.count > 1,
            },
          ]"
        >
          {{ product.pivot.count || product.count }}
        </span>
      </div>
    </td>
    <td class="has-text-centered">
      <strong>{{ isGiftActionGift ? 'Δώρο' : total }}</strong>
    </td>
    <td v-if="isEditing" class="has-text-centered hidden-print">
      <button class="button is-small" @click="deleteItem">
        <span class="icon is-small">
          <span class="fa fa-fw fa-trash-o" />
        </span>
      </button>
    </td>
  </tr>
</template>

<script>
import { round, isNil, isEmpty } from 'lodash';
import ProductPhoto from '@/views/components/ProductPhoto';
import VariationsList from './components/VariationsList';

export default {
  components: {
    ProductPhoto,
    VariationsList,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },

    orderCartIds: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },

    index: {
      type: Number,
      required: true,
    },

    isEditing: {
      type: Boolean,
      required: true,
    },

    isGiftActionGift: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      selectedVariation: {},
    };
  },

  computed: {
    photos() {
      if (this.product.pivot.new_path) {
        return [{ new_path: this.product.pivot.new_path, is_avatar: true }];
      }

      if (this.product.photos[0].new_path) {
        return [{ new_path: this.product.photos[0].new_path, is_avatar: true }];
      }

      return [];
    },

    hasCode() {
      return !!this.product.pivot.code;
    },

    hasVariations() {
      if (!isEmpty(this.product.subproducts)) {
        return this.product.subproducts.length > 0;
      }

      return false;
    },

    sizes() {
      if (this.hasVariations && !isEmpty(this.product.subproducts)) {
        return this.product.subproducts.filter(
          product =>
            !isNil(product.size_title) &&
            (product.cart_id === this.product.cart_id ||
              !this.orderCartIds.includes(product.cart_id)),
        );
      }

      return [];
    },

    hasSizes() {
      return this.sizes.length > 0;
    },

    colors() {
      if (this.hasVariations && !isEmpty(this.product.subproducts)) {
        return this.product.subproducts.filter(
          product =>
            !isNil(product.color_title) &&
            (product.cart_id === this.product.cart_id ||
              !this.orderCartIds.includes(product.cart_id)),
        );
      }

      return [];
    },

    hasColors() {
      return this.colors.length > 0;
    },

    variationType() {
      if (this.hasSizes) {
        return 'size';
      }

      if (this.hasColors) {
        return 'color';
      }

      return '';
    },

    selectedVariationTitle() {
      if (this.variationType === 'size') {
        return this.selectedVariation.size_title;
      }

      if (this.variationType === 'color') {
        return this.selectedVariation.color_title;
      }

      return '';
    },

    variationItems() {
      if (this.variationType === 'size') {
        return this.sizes;
      }

      if (this.variationType === 'color') {
        return this.colors;
      }

      return [];
    },

    stock() {
      if (this.hasVariations) {
        return this.selectedVariation.stock;
      }

      return this.product.stock;
    },

    total() {
      return round(
        this.product.pivot.final_price * this.product.pivot.count,
        2,
      );
    },

    active() {
      return isEmpty(this.selectedVariation)
        ? this.product.active
        : this.selectedVariation.active;
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    'product.pivot.count': {
      handler(newVal) {
        this.$emit('on-change-count', {
          count: newVal,
          product: this.product,
        });
      },
    },

    'product.cart_id': {
      handler(newVal) {
        if (this.hasVariations) {
          this.selectedVariation = this.variationItems.find(
            variation => variation.cart_id === newVal,
          );

          if (isEmpty(this.selectedVariation) && this.variationItems[0]) {
            this.selectedVariation = { ...this.variationItems[0] };
          }

          this.$emit('on-change-variation', {
            variation: this.selectedVariation,
            product: this.product,
          });
        }
      },
      immediate: true,
    },
  },

  methods: {
    handleSelectVariation(item) {
      this.$emit('on-change-variation', {
        variation: item,
        product: this.product,
      });
    },

    deleteItem() {
      this.$emit('on-delete-item', this.product);
    },
  },
};
</script>

<style lang="scss" scoped>
.td {
  &__barcode {
    min-width: 170px;

    @media print {
      min-width: 0px;
    }
  }

  &__count {
    max-width: 100px;
  }
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    margin-bottom: 0px;
    margin-left: 2px;
  }
}

.print__cell-show {
  display: none;

  @media print {
    display: table-cell;
  }
}
</style>
