<template>
  <tr>
    <td class="has-text-centered">{{ index + 1 }}</td>
    <td class="has-text-centered">
      <ProductPhoto :type="product.type || 'product'" :photos="photos" />
    </td>
    <td class="has-text-centered" style="maxWidth: 250px">
      <RouterLink
        :to="{ name: 'products.edit', params: { uuid: product.uuid } }"
      >
        {{ product.product_name }}
      </RouterLink>
      <p class="visible-print is-size-5">{{ product.product_name }}</p>
      <p>Shop/Common uid: {{ product.shop_uid }}</p>
      <p>Skroutz ean: {{ product.ean }}</p>
      <p v-for="(barcode, index) in product.barcodes" :key="index">
        Barcode: {{ barcode.code }}
      </p>
    </td>
    <td class="has-text-centered">{{ product.unit_price }}</td>
    <td class="has-text-centered">
      <strong>{{ product.total_price }}</strong>
    </td>
    <td class="has-text-centered td__count">
      <p
        :class="[
          'title',
          {
            'is-5': product.quantity === 1,
            'is-3': product.quantity > 1,
          },
        ]"
      >
        &times; {{ product.quantity }}
      </p>
    </td>
    <td class="has-text-centered stock-status">
      <strong>{{ product.stock }}</strong>
      <span v-if="isIconVisible && !aboutToPrint">
        <i
          v-if="product.stock >= product.quantity"
          class="fa fa-check icon-stock"
        />
        <i v-else class="fa fa-times icon-stock" />
      </span>
    </td>
  </tr>
</template>

<script>
import ProductPhoto from '@/views/components/ProductPhoto';

export default {
  components: {
    ProductPhoto,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    aboutToPrint: {
      type: Boolean,
    },
  },

  data: () => ({ isIconVisible: true }),

  computed: {
    photos() {
      return this.product?.photos || [];
    },
  },

  mounted() {
    setTimeout(() => {
      this.isIconVisible = false;
    }, 30000);
  },
};
</script>

<style lang="scss" scoped>
.td {
  &__barcode {
    min-width: 170px;

    @media print {
      min-width: 0px;
    }
  }

  &__count {
    max-width: 100px;
  }
}

.print__cell-show {
  display: none;

  @media print {
    display: table-cell;
  }
}

i {
  margin-left: 7px;
}

.stock-status {
  position: relative;
}

@media print {
  .stock-status {
    position: static;
  }
}

.icon-stock {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
  font-size: 1.55rem;
  position: absolute;
  line-height: 25px;
  right: 5px;
  top: 5px;
  width: 28px;
  height: 28px;
}
</style>
