<template>
  <div>
    <div class="tabs hidden-print">
      <ul v-sticky sticky-offset="offset">
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'customers.list.all' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Όλοι οι Πελάτες
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'customers.list.empty' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Πελάτες χωρίς Πλήρη Στοιχεία
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'customers.list.full' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Πελάτες με Πλήρη Στοιχεία
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'customers.list.guests' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Guests
            </a>
          </li>
        </router-link>
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'customers.list.deleted' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Διαγραμένοι Πελάτες
            </a>
          </li>
        </router-link>
      </ul>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  data: () => ({
    offset: { top: 45 },
  }),
};
</script>
