<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <div class="title is-6 is-marginless">
            Επεξεργασία Παραπόνου
          </div>
        </div>
        <div class="card-header-icon">
          <div class="field has-addons is-marginless">
            <div class="control">
              <router-link :to="{ name: 'complaints.list' }" class="button">
                <span class="icon is-small">
                  <i class="fa fa-list-ul" />
                </span>
                <span>Λίστα</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <form @submit.prevent="submit">
        <div class="card-content">
          <div class="columns">
            <div class="column is-full">
              <div class="field">
                <label class="label">Πελάτης *</label>
                <div class="control">
                  <customers-search
                    :customer="customer"
                    @selectCustomer="selectCustomer"
                  />
                  <p v-show="errors.has('customer')" class="help is-danger">
                    Επιλέξτε πελάτη
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column is-full">
              <div class="field">
                <label class="label">Παράπονο *</label>
                <div class="control">
                  <textarea
                    v-validate="'required'"
                    v-model.trim="complaint.comment"
                    class="textarea"
                    type="text"
                    name="comment"
                  />
                  <p v-show="errors.has('comment')" class="help is-danger">
                    Εισάγετε παράπονο
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column is-half">
              <div class="field">
                <label class="label">Ολοκληρώθηκε</label>
                <div class="control">
                  <ToggleButton
                    :sync="true"
                    :value="complaint.resolved"
                    :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                    :width="30"
                    :height="15"
                    @change="handleChangeResolved"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </form>

      <confirmation
        :model-name="complaint.comment"
        :is-open="isOpen"
        description="Είστε βέβαιος για τη διαγραφή αυτού του παραπόνου;"
        @closeModal="closeModal"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { pick } from 'lodash';
import Datepicker from 'vuejs-datepicker';
import request from '@/utils/request';
import FormSubmit from '@/views/components/FormSubmit';
import Notification from '@/views/components/Notification';
import EditItem from '@/views/components/EditItem';
import LoadingContainer from '@/views/components/LoadingContainer';
import CustomersSearch from '../../../Content/views/Questions/views/components/CustomersSearch';

export default {
  components: {
    Datepicker,
    Notification,
    FormSubmit,
    CustomersSearch,
    LoadingContainer,
  },

  extends: EditItem,

  data() {
    return {
      complaint: {
        comment: '',
        customer_id: '',
        resolved: false,
      },
      customer: {},
      isSaving: false,
    };
  },

  computed: {
    ...mapGetters({
      savedComplaintInStore: 'complaints/edit/getComplaint',
      isLoading: 'complaints/edit/getIsLoading',
    }),
  },

  watch: {
    savedComplaintInStore: {
      handler(newVal) {
        this.complaint = {
          ...pick(newVal, ['comment', 'customer_id', 'resolved']),
        };
        this.customer = newVal.customer;
      },
      deep: true,
    },
  },

  async created() {
    try {
      await this.getComplaint({ uuid: this.$route.params.uuid });
    } catch (err) {
      this.$router.push('/error');
    }
  },

  methods: {
    ...mapActions({
      getComplaint: 'complaints/edit/getComplaint',
      updateComplaint: 'complaints/edit/updateComplaint',
    }),

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();
        if (!isValid || this.errors.count()) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateComplaint({
          complaint: this.complaint,
          uuid: this.$route.params.uuid,
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το παράπονο αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.complaint = {
        ...pick(this.savedComplaintInStore, [
          'comment',
          'customer_id',
          'resolved',
        ]),
      };
      this.customer = this.savedComplaintInStore.customer;

      this.$validator.reset();
    },

    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;
        if (response) {
          await request.delete(`/complaints/${this.complaint.uuid}`);
          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Η κατηγορία διαγράφτηκε',
          });
          this.$router.push({ name: 'complaints.list' });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    selectCustomer(customer) {
      this.customer = customer;
      this.complaint.customer_id = customer.id;
    },

    handleChangeResolved(e) {
      this.complaint = {
        ...this.complaint,
        resolved: e.value,
      };
    },
  },
};
</script>
