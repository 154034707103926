<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Banners <span class="tag">Επεξεργασία Banner</span>
        </div>
      </div>
      <div class="card-header-icon">
        <div class="field has-addons is-marginless">
          <div class="control">
            <router-link
              :to="{ name: 'marketing.giftActions.banners.list' }"
              class="button"
            >
              <span class="icon is-small"><i class="fa fa-list"/></span
              ><span>Λίστα</span>
            </router-link>
          </div>
          <div class="control">
            <button class="button" @click="askToDeleteItem">
              <span class="icon is-small"><i class="fa fa-trash"/></span>
              <span>Διαγραφή</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="card-content">
        <AddPhotos
          :title="'Banner'"
          :preview="preview"
          :show-error="errors.has('image')"
          @addPhotos="handleAddPhotos"
        />

        <div class="field">
          <label class="label">Τίτλος *</label>
          <div class="control">
            <input
              v-validate="'required'"
              v-model.trim="form.title"
              class="input"
              type="text"
              name="title"
            />
            <p v-show="errors.has('title')" class="help is-danger">
              Εισάγετε τίτλο
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Αγγλικός Τίτλος </label>
          <div class="control">
            <input
              v-model.trim="form.title_en"
              class="input"
              type="text"
              name="title_en"
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Link *</label>
          <div class="control">
            <input
              v-validate="'required|url'"
              v-model.trim="form.link"
              class="input"
              type="text"
              name="link"
            />
            <p v-show="errors.has('title')" class="help is-danger">
              Εισάγετε link
            </p>
          </div>
        </div>

        <div class="field">
          <label class="label">Εμφανές *</label>
          <div class="control">
            <ToggleButton
              :sync="true"
              :value="form.active"
              :color="{ checked: '#22A684', unchecked: '#ff3860' }"
              :width="30"
              :height="15"
              @change="updateActive"
            />
          </div>
        </div>
      </div>

      <div class="card-footer">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </form>

    <confirmation
      :model-name="banner.title"
      :is-open="isOpen"
      description="Είστε βέβαιος για τη διαγραφή αυτού του banner;"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import pick from 'lodash/pick';
import EditItem from '@/views/components/EditItem';
import toolbarOptions from '@/constants/toolbarOptions';
import AddPhotos from '@/views/components/AddPhotos';
import request from '@/utils/request';
import { isEmpty } from 'lodash';

export default {
  components: {
    AddPhotos,
  },

  extends: EditItem,

  data() {
    return {
      banner: {},
      form: {
        title: '',
        title_en: '',
        link: '',
        active: false,
        image: null,
      },
      preview: '',
      toolbarOptions,
    };
  },

  async created() {
    try {
      const { data } = await request.get(
        `/gift-actions-banners/${this.$route.params.uuid}`,
      );

      const { banner } = data;
      this.banner = banner;
      this.form = {
        ...this.form,
        ...pick(this.banner, ['title', 'title_en', 'link', 'active']),
      };
      this.preview = this.banner.image_url;
    } catch (err) {
      console.log(err);
    }
  },

  methods: {
    ...mapActions({
      updateBanner: 'giftActionsBanners/edit/updateBanner',
    }),

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (isEmpty(this.preview)) {
          this.errors.add({
            field: 'image',
            msg: 'Image is required',
          });
        }

        if (!isValid || isEmpty(this.preview)) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        await this.updateBanner({ uuid: this.banner.id, banner: this.form });

        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'To banner αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.form = {
        ...this.form,
        ...pick(this.banner, ['title', 'title_en', 'link', 'active']),
      };
      this.preview = this.banner.image_url;

      this.$validator.reset();
      this.errors.remove('image');
    },

    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;
        if (response) {
          await request.delete(`/gift-action-banners/${this.banner.id}`);
          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'To banner διαγράφτηκε',
          });
          this.$router.push({ name: 'content.banners.list' });
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    updateActive({ value }) {
      this.form = {
        ...this.form,
        active: !!value,
      };
    },

    handleAddPhotos(image) {
      this.form.image = image;

      const reader = new FileReader();

      this.$validator
        .validateAll()
        .then(() => {
          reader.onload = e => {
            this.preview = e.target.result;
            this.errors.remove('image');
          };
          reader.readAsDataURL(image);
        })
        .catch(err => console.log(err));
    },
  },
};
</script>
