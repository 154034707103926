<template>
  <div ref="container">
    <img v-lazy="imgUrl" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgUrl: '../../../../static/logo.png',
    };
  },
};
</script>
