<template>
  <div v-sticky sticky-offset="offset">
    <nav class="level">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Κοινο ID</p>
          <p class="title">{{ brand.common_id }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">UUID</p>
          <p class="title">{{ brand.uuid }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">STATUS</p>
          <p class="title">
            <span :class="['tag is-medium', classes]">{{ status }}</span>
          </p>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    brand: Object,
  },
  data: () => ({
    offset: { top: 45 },
  }),
  computed: {
    status() {
      const { brand } = this;
      /* eslint-disable */
      if (brand.deleted_at !== null) {
        return 'Απενεργοποιημένη Μάρκα';
      } else if (brand.active === true) {
        return 'Εμφανής Μάρκα';
      } else if (brand.active === false) {
        return 'Μη Εμφανής Μάρκα';
      } else {
        return 'Μάρκα';
      }
    },
    classes() {
      const brand = this.brand;
      /* eslint-disable */
      if (brand.deleted_at !== null) {
        return 'is-danger';
      } else if (brand.active === true) {
        return 'is-primary';
      } else if (brand.active === false) {
        return 'is-warning';
      } else {
        return 'is-white';
      }
    },
  },
};
</script>
