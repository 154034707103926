import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async sendEmail({ commit, rootGetters }, { email }) {
    try {
      const { data } = await request.post(
        `/customers/${rootGetters['customers/edit/getCustomer'].uuid}/notify`,
        email,
      );

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('customers/edit/updateCustomerEmails', {
        emails: data.emails,
      }, {
        root: true,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
