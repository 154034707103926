<template>
  <PaginatedListHeaderTemplate
    :title="title"
    :total="total"
    :selections="selections"
    :has-selections="hasSelections"
  >
    <template v-slot:options>
      <div class="field has-addons is-marginless">
        <div class="control">
          <router-link :to="{ name: 'complaints.add' }" class="button">
            <span class="icon is-small"><i class="fa fa-plus"/></span
            ><span>Προσθήκη</span>
          </router-link>
        </div>
      </div>
    </template>
  </PaginatedListHeaderTemplate>
</template>

<script>
import PaginatedListHeader from '@/views/components/PaginatedList/components/PaginatedListHeader';

export default {
  extends: PaginatedListHeader,
};
</script>
