<template>
  <div class="card hidden-print">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Διεύθυνση Αποστολής
          <span v-if="isSame" class="tag">Ίδια με στοιχεία χρέωσης</span>
          <span v-if="isBoxnow" class="tag">Θυρίδα Box Now</span>
        </div>
      </div>
      <div v-if="!isBoxnow" class="card-header-icon">
        <button
          v-tooltip="{ content: 'Προβολή' }"
          v-if="isEditing"
          class="button is-small"
          @click="isEditing = !isEditing"
        >
          <span class="icon is-small"><i class="fa fa-eye"/></span>
        </button>
        <button
          v-tooltip="{ content: 'Επεξεργασία' }"
          v-else
          :disabled="isDeleted"
          class="button is-small"
          @click="isEditing = !isEditing"
        >
          <span class="icon is-small"><i class="fa fa-pencil"/></span>
        </button>
      </div>
    </div>
    <div v-if="isEditing">
      <form @submit.prevent="submit">
        <div class="card-content">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Όνομα *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    :value="newAddress.firstName"
                    type="text"
                    class="input"
                    name="firstName"
                    @input="updateAddress"
                  />
                </div>
                <div v-show="errors.has('firstName')" class="help is-danger">
                  Εισάγετε όνομα
                </div>
              </div>

              <div class="field">
                <label class="label">Επώνυμο *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    :value="newAddress.lastName"
                    type="text"
                    class="input"
                    name="lastName"
                    @input="updateAddress"
                  />
                </div>
                <div v-show="errors.has('lastName')" class="help is-danger">
                  Εισάγετε επώνυμο
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Κινητό *</label>
                <div class="control">
                  <vue-tel-input
                    v-validate="'required'"
                    :value="phoneNumbers.mobile"
                    enabled-country-code
                    placeholder="Εισάγετε Κινητό"
                    name="send_mobile"
                    input-id="add_order_send_mobile"
                    @input="onInputMobile"
                  />
                  <p
                    v-show="
                      errors.has('send_mobile') ||
                        errors.has('send_mobile-invalid')
                    "
                    class="help is-danger"
                  >
                    Εισάγετε έγκυρο κινητό τηλέφωνο
                  </p>
                </div>
              </div>

              <div class="field">
                <label class="label">Σταθερό</label>
                <div class="control">
                  <vue-tel-input
                    :value="phoneNumbers.telephone"
                    enabled-country-code
                    input-id="add_order_send_telephone"
                    name="send_telephone"
                    placeholder="Εισάγετε Σταθερό"
                    @input="onInputTelephone"
                  />
                  <p
                    v-show="errors.has('send_telephone')"
                    class="help is-danger"
                  >
                    Εισάγετε έγκυρο σταθερό τηλέφωνο
                  </p>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Οδός *</label>
                <div class="control">
                  <GmapAutocomplete
                    v-validate="'required'"
                    :value="newAddress.street"
                    :options="{
                      types: ['geocode'],
                      componentRestrictions: {
                        country: ['gr', 'cy'],
                      },
                    }"
                    class="input"
                    type="text"
                    name="street"
                    placeholder="Εισάγετε Οδό, Αριθμό & Πόλη"
                    autocomplete="new-password"
                    @focus.native="resetAutocomplete"
                    @keydown.enter.prevent.native
                    @place_changed="updatePlace"
                    @input.native="handleStreetChange"
                  />
                </div>
                <div v-show="errors.has('street')" class="help is-danger">
                  Εισάγετε Οδό, Αριθμό & Πόλη
                </div>
              </div>

              <div class="field">
                <label class="label">Αριθμός *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    :value="newAddress.streetNumber"
                    class="input"
                    type="text"
                    name="streetNumber"
                    placeholder="Εισάγετε Aριθμό"
                    autocomplete="new-password"
                    @focus="resetAutocomplete"
                    @input="updateAddress"
                  />
                </div>
                <div v-show="errors.has('streetNumber')" class="help is-danger">
                  Εισάγετε αριθμό
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">ΤΚ *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    :value="newAddress.postcode"
                    class="input"
                    name="postcode"
                    placeholder="Εισάγετε ΤΚ"
                    autocomplete="new-password"
                    @focus="resetAutocomplete"
                    @input="updateAddress"
                  />
                </div>
                <div v-show="errors.has('postcode')" class="help is-danger">
                  Εισάγετε ΤΚ
                </div>
              </div>

              <div class="field">
                <label class="label">Πόλη *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    :value="newAddress.city"
                    class="input"
                    name="city"
                    placeholder="Εισάγετε Πόλη"
                    autocomplete="new-password"
                    @focus="resetAutocomplete"
                    @input="updateAddress"
                  />
                </div>
                <div v-show="errors.has('city')" class="help is-danger">
                  Εισάγετε πόλη
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Νομός *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    :value="newAddress.region"
                    class="input"
                    name="region"
                    placeholder="Εισάγετε Νομό"
                    autocomplete="new-password"
                    @focus="resetAutocomplete"
                    @input="updateAddress"
                  />
                </div>
                <div v-show="errors.has('region')" class="help is-danger">
                  Εισάγετε νομό
                </div>
              </div>

              <div class="field">
                <label class="label">Χώρα *</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select
                      v-validate="'required'"
                      :value="newAddress.country"
                      name="country"
                      @change="updateAddress"
                    >
                      <option
                        v-for="(country, index) in countryOptions"
                        :key="index"
                        :value="country"
                      >
                        {{ country }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </form>
    </div>
    <div v-if="isBoxnow">
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Όνομα</label>
              <div class="control">
                {{ newAddress.firstName }}
              </div>
            </div>

            <div class="field">
              <label class="label">Επώνυμο</label>
              <div class="control">
                {{ newAddress.lastName }}
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Κιν. Τηλέφωνο</label>
              <div class="control">
                {{ newAddress.mobile }}
              </div>
            </div>

            <div class="field">
              <label class="label">Σταθ. Τηλέφωνο</label>
              <div class="control">
                {{ newAddress.telephone }}
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Θυρίδα Box Now</label>
              <div class="control">
                {{ boxnow.boxnowLockerName }}
              </div>
            </div>

            <div class="field">
              <label class="label">Οδός</label>
              <div class="control">
                {{ boxnow.boxnowLockerAddressLine1 }}
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Τ. Κώδικας</label>
              <div class="control">
                {{ boxnow.boxnowLockerPostalCode }}
              </div>
            </div>

            <div class="field">
              <label class="label">Χώρα</label>
              <div class="control">
                {{ newAddress.country }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Όνομα</label>
              <div class="control">
                {{ newAddress.firstName }}
              </div>
            </div>

            <div class="field">
              <label class="label">Επώνυμο</label>
              <div class="control">
                {{ newAddress.lastName }}
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Κιν. Τηλέφωνο</label>
              <div class="control">
                {{ newAddress.mobile }}
              </div>
            </div>

            <div class="field">
              <label class="label">Σταθ. Τηλέφωνο</label>
              <div class="control">
                {{ newAddress.telephone }}
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Οδός</label>
              <div class="control">
                {{ newAddress.street }}
              </div>
            </div>

            <div class="field">
              <label class="label">Αριθμός</label>
              <div class="control">
                {{ newAddress.streetNumber }}
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Τ. Κώδικας</label>
              <div class="control">
                {{ newAddress.postcode }}
              </div>
            </div>

            <div class="field">
              <label class="label">Πόλη</label>
              <div class="control">
                {{ newAddress.city }}
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Νομός</label>
              <div class="control">
                {{ newAddress.region }}
              </div>
            </div>

            <div class="field">
              <label class="label">Χώρα</label>
              <div class="control">
                {{ newAddress.country }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep, isEmpty, isEqual, pick } from 'lodash';
import EditItem from '@/views/components/EditItem';
import gMapAutocomplete from '@/utils/gMapAutocomplete';
import {
  calculateSendCharges,
  calculateProductsSum,
} from '@/utils/calculations';
import CHECKOUT_CHARGES from '@/constants/checkoutCharges';
import AddressMixin from '@/mixins/Address';
import { MIN_COST_FOR_FREE_SHIPPING } from '@/constants/costs';

const BILLING = {
  1: { value: 'same_address', text: 'Διεύθυνση Χρέωσης' },
  2: { value: 'different_address', text: 'Διαφορετική Διεύθυνση' },
};

export default {
  inject: ['$validator'],

  extends: EditItem,

  mixins: [AddressMixin],

  props: {
    isDeleted: Boolean,
    order: Object,
    isBoxnow: {
      type: Boolean,
      required: true,
    },
    isPharmacy: {
      type: Boolean,
      required: true,
    },
    boxnow: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      isEditing: false,
      isSaving: false,
      newAddress: null,
      newOrder: {},
      billing: BILLING,
      phoneNumbers: {
        mobile: '',
        telephone: '',
      },
    };
  },

  computed: {
    ...mapGetters({
      sendCharges: 'getSendCharges',
      sendMethods: 'getSendMethods',
    }),

    isSame() {
      return (
        isEqual(this.newOrder.charge_address, this.newOrder.send_address) &&
        !this.isBoxnow
      );
    },
  },

  watch: {
    order: {
      handler(newValue) {
        this.newOrder = cloneDeep(newValue);

        if (this.newOrder.send_address) {
          this.newAddress = pick(this.newOrder.send_address, [
            'firstName',
            'lastName',
            'telephone',
            'mobile',
            'postcode',
            'street',
            'streetNumber',
            'city',
            'region',
            'country',
          ]);

          this.phoneNumbers = {
            mobile: this.newAddress.mobile || '',
            telephone: this.newAddress.telephone || '',
          };
        } else {
          this.newAddress = pick(this.newOrder.charge_address, [
            'firstName',
            'lastName',
            'telephone',
            'mobile',
            'postcode',
            'street',
            'streetNumber',
            'city',
            'region',
            'country',
          ]);

          this.phoneNumbers = {
            mobile: this.newAddress.mobile || '',
            telephone: this.newAddress.telephone || '',
          };
        }
      },
      immediate: true,
    },

    'newAddress.country': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.updateCharges();
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['updateOrderAddress']),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid || this.errors.count()) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        await this.updateOrderAddress({
          send_address: {
            ...this.newAddress,
          },
          send_charge: this.newOrder.send_charge,
          checkout_charge: this.newOrder.checkout_charge,
        });

        this.isSaving = false;
        this.isEditing = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η διεύθυνση αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.newOrder = cloneDeep(this.order);

      if (this.newOrder.send_address) {
        this.newAddress = pick(this.newOrder.send_address, [
          'firstName',
          'lastName',
          'telephone',
          'mobile',
          'postcode',
          'street',
          'streetNumber',
          'city',
          'region',
          'country',
        ]);

        this.phoneNumbers = {
          mobile: this.newAddress.mobile || '',
          telephone: this.newAddress.telephone || '',
        };
      } else {
        this.newAddress = null;

        this.phoneNumbers = {
          mobile: '',
          telephone: '',
        };
      }

      this.errors.remove('send_telephone');
      this.errors.remove('send_mobile-invalid');

      this.$nextTick(() => {
        this.$validator.validateAll();
      });
    },

    handleStreetChange($event) {
      const { name, value } = $event.target;

      this.newAddress = {
        ...this.newAddress,
        street: value,
      };

      this.$nextTick(() => {
        this.$validator.validate(name);
      });
    },

    onInputTelephone(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'send_telephone',
          msg: 'Wrong telephone',
        });
      } else {
        this.newAddress = {
          ...this.newAddress,
          telephone: number.international,
        };
        this.phoneNumbers.telephone = number.national || '';
        this.errors.remove('send_telephone');
      }
    },

    onInputMobile(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'send_mobile-invalid',
          msg: 'Wrong mobile',
        });
      } else {
        this.errors.remove('send_mobile-invalid');
        this.newAddress = {
          ...this.newAddress,
          mobile: number.international,
        };

        this.phoneNumbers.mobile = number.national || '';
      }
    },

    /* eslint-disable camelcase */
    updateAddress(e) {
      const { name } = e.target;

      this.newAddress = {
        ...this.newAddress,
        [name]: e.target.value,
      };
    },

    updatePlace(place) {
      const newPlace = gMapAutocomplete.setPlace(place);

      this.newAddress = {
        ...this.newAddress,
        ...newPlace,
      };
    },

    updateCharges() {
      if (!isEmpty(this.newOrder.charge_address)) {
        let { country } = this.newAddress;

        if (country) {
          country = this.$options.filters.capitalizeGreek(country);

          const isCyprus = country.toLowerCase() === 'κυπρος';
          const boxNowCharge = isCyprus
            ? this.sendCharges.cy.box_now.charge
            : this.sendCharges.gr.box_now.charge;

          let send_charge = 0;
          let checkoutCharge = this.newOrder.checkout_charge;

          if (this.isPharmacy) {
            send_charge = 0;
          } else if (this.isBoxnow) {
            const { sum } = calculateProductsSum(this.newOrder.products);
            send_charge = sum >= MIN_COST_FOR_FREE_SHIPPING ? 0 : boxNowCharge;
          } else {
            const { sum, weight } = calculateProductsSum(
              this.newOrder.products,
            );
            send_charge = calculateSendCharges(
              sum,
              weight,
              isCyprus ? this.sendCharges.cy : this.sendCharges.gr,
            );
          }

          if (this.newOrder.checkout_method.slug === 'pay_on_delivery') {
            checkoutCharge = isCyprus
              ? CHECKOUT_CHARGES.cyprus
              : CHECKOUT_CHARGES.greece;
          }

          this.newOrder = {
            ...this.newOrder,
            send_charge,
            checkout_charge: checkoutCharge,
          };
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.vue-tel-input {
  border: 1px solid #dbdbdb;
  border-radius: 2px;

  &:focus-within {
    border-color: #22a684;
    box-shadow: 0 0 0 0.125em rgba(34, 166, 132, 0.25);
    outline: none;
  }

  ::v-deep .dropdown {
    padding: 6px;

    &:focus {
      outline: none;
    }
  }

  ::v-deep ul {
    border-color: #dbdbdb;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;

    li.last-preferred {
      border-bottom-color: #dbdbdb;
    }
  }

  ::v-deep input {
    font-size: 1rem;
  }
}
</style>
