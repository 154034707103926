import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async getPage({ dispatch, commit }, { uuid }) {
    try {
      await commit('updateIsLoading', { isLoading: true });

      this.isLoading = true;
      const { data } = await request.get(`/pages/${uuid}`);

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('setPage', {
        page: data.page,
      });

      await commit('updateIsLoading', { isLoading: false });
    } catch (err) {
      await commit('updateIsLoading', { isLoading: false });

      throw err;
    }
  },

  async updatePage({ dispatch, commit }, { uuid, page }) {
    try {
      const response1 = await request.put(`/pages/${uuid}`, page);

      if (response1.data.error) {
        throw Error('Server-side validation failed');
      }

      const response2 = await request.get(`/pages/${uuid}`);

      if (response2.data.error) {
        throw Error('Server-side validation failed');
      }

      await commit('updatePage', {
        page: response2.data.page,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
