/* eslint-disable */
const getters = {
  getIsLoadingEmailTemplates(state) {
    return state.isLoading;
  },
  getEmailTemplates(state) {
    return state.data;
  },
};

export default getters;
