import classnames from 'classnames';
import get from 'lodash/get';
import _last from 'lodash/last';

export default {
  computed: {
    isDeleted() {
      return this.item.deleted_at !== null;
    },

    isGuest() {
      return this.item.guest_id === null;
    },

    isPaid() {
      return !!this.item.transaction;
    },

    isUnfinished() {
      return this.item.is_unfinished;
    },

    isNew() {
      return get(this, 'status.slug', 'new') === 'new';
    },

    status() {
      return _last(this.item.statuses);
    },

    classes() {
      if (this.isDeleted || this.isUnfinished) {
        return 'tag is-medium is-danger';
      }

      if (this.isNew) {
        return 'tag is-medium is-warning';
      }

      return classnames('tag is-medium', {
        'is-primary': this.status.slug === 'for_delivery',
        'is-info': this.status.slug === 'confirm',
        'is-white': !['for_delivery', 'new', 'confirm'].includes(
          this.status.slug,
        ),
      });
    },

    orderMethodTitle() {
      return get(this.item, 'order_method.title', '');
    },

    checkoutMethodTitle() {
      return get(this.item, 'checkout_method.title', '');
    },

    sendMethodTitle() {
      return get(this.item, 'send_method.title', '');
    },
  },
};
