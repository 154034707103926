/* eslint-disable */
const mutations = {
  updateIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setContest(state, { contest }) {
    state.data = contest;
  },

  updateContest(state, { contest }) {
    state.data = {
      ...state.data,
      ...contest,
    };
  },
};

export default mutations;
