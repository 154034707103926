<template>
  <tr>
    <td class="has-text-centered">{{ index }}</td>
    <td>
      <router-link
        :to="{ name: 'content.pages.edit', params: { uuid: item.uuid } }"
      >
        {{ item.title }}
      </router-link>
    </td>
    <td class="has-text-centered visible-print">
      {{ item.active === true ? 'Ναί' : 'Όχι' }}
    </td>
    <td class="has-text-centered hidden-print">
      <router-link
        :to="{ name: 'content.pages.edit', params: { uuid: item.uuid } }"
        class="button is-small"
      >
        <span class="icon is-small">
          <span class="fa fa-fw fa-pencil" />
        </span>
      </router-link>
    </td>
  </tr>
</template>

<script>
import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';

export default {
  extends: PaginatedListItem,
};
</script>
