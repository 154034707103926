<template>
  <div class="card-header">
    <div class="card-header-title">
      <div class="title is-6 is-marginless">
        {{ title }}
        <span v-if="selections.length > 0" class="tag">
          {{ selections.length }} από {{ total }}
        </span>
        <span v-else class="tag">{{ total }}</span>
      </div>
    </div>
    <div class="card-header-icon hidden-print">
      <div class="field has-addons is-marginless">
        <div class="control">
          <router-link :to="{ name: 'marketing.contests.add' }" class="button">
            <span class="icon is-small"><i class="fa fa-plus"/></span
            ><span>Προσθήκη</span>
          </router-link>
        </div>
        <div v-if="canDeleteItems" class="control">
          <button
            :disabled="!hasSelections"
            class="button"
            type="button"
            @click="askToDeleteItems"
          >
            <span class="icon is-small"><i class="fa fa-trash-o"/></span>
            <span>Ακύρωση</span>
          </button>
        </div>
      </div>
    </div>

    <confirmation
      :model-name="modelName"
      :is-open="isOpen"
      description="Είστε βέβαιος για την ακύρωση αυτών των διαγωνισμών;"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import PaginatedListHeader from '@/views/components/PaginatedList/components/PaginatedListHeader';
import request from '@/utils/request';

export default {
  extends: PaginatedListHeader,
  computed: {
    modelName() {
      const count = this.selections.length;

      return count === 1 ? '1 κουπόνι' : `${count} κουπόνια`;
    },
  },
  methods: {
    async closeModal(response) {
      try {
        this.isOpen = false;

        if (response) {
          this.$notify({
            type: 'success',
            title: 'Επιτυχία',
            text: 'Οι διαγωνισμοί διαγράφτηκαν επιτυχώς!!',
          });
          await request.delete('/contests', {
            params: {
              contests: this.selections,
            },
          });
          this.$emit('navigate');
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
